<template>
<div class='slide-poll_visual'>
  <div class='slide-poll_options'>
    <button
      v-for='option in options'
      :key='option.id'
      class='slide-poll_option body-one-light'
      :class='optionClass(option.id)'
      :disabled='selected_answer'
      @click.prevent='selectPollOption({selected: option.id})'
    >
      <div class='slide-poll_option-inner'>
        <span
          v-if='show_stats && selected_answer'
          class='slide-poll_option-percent h-three-light'
        >
          {{ resultPercent(option.id) }}%
        </span>

        <svg
          v-if='option.image'
          class='slide-poll_option-image'
          viewBox="0 0 100 100"
        >
          <defs>
            <filter id="pictureFilter">
              <feColorMatrix
                in="SourceGraphic"
                type="matrix"
                values="-1 0 0 0 1 0 -1 0 0 1 0 0 -1 0 1 0 0 0 1 0"
              />
            </filter>
          </defs>
          <image
            v-if='selected_answer && selected_answer === option.id'
            x="0"
            y="0"
            width="100"
            height="100"
            filter="url(#pictureFilter)"
            :xlink:href="option.image"
          />

          <image
            v-else
            x="0"
            y="0"
            width="100"
            height="100"
            :xlink:href="option.image"
          />
        </svg>

        <span
          v-else
          class='slide-poll_option-text'
        >
          {{ option.text }}
        </span>
      </div>
    </button>
  </div>

  <div
    v-if='show_feedback && selected_answer'
    class='slide-poll_results'
  >
    <div class='slide-poll_results-headline h-three'>
      {{ response_headline }}
    </div>

    <p
      class='slide-poll_results-description body-two'
      v-html='response_body'
    />
  </div>
</div>
</template>

<script>
export default {
  props: [
    'options',
    'selected_answer',
    'activity',
    'response_headline',
    'response_body',
    'total_responses',
    'show_stats',

    'selectPollOption',
    'resultPercent'
  ],

  computed: {
    show_feedback() {
      return this.activity.show_response
    }
  },

  methods: {
    optionClass(id) {
      if (this.selected_answer === id) {
        return 'slide-poll_option--selected'
      }
    }

  }
}
</script>
