// TriggeredSlide model based on API::V1::TriggeredSlideSerializer
export default {

  // Relationships
  lesson: {
    jsonApi: 'hasOne',
    type: 'lessons'
  },
  activity: {
    jsonApi: 'hasOne',
    type: 'activities'
  },

  // Attributes
  author_description: null,
  trigger: null,
  slide_id: null,
  percent: null,
  show_if_complete: false,
  position: 0
}
