<template>
<div
  class='section-drawer'
  :class='mainClass'
>
  <div class='section-drawer_inner'>
    <template v-if="is_one_column">
        <template v-if="is_the_only_heading">
          <h1
            v-if="title"
            class='section-drawer_title heading-four'
          >
            {{ title }}
          </h1>
        </template>

        <template v-else>
          <h2
            v-if="title"
            class='section-drawer_title heading-four'
          >
            {{ title }}
          </h2>
        </template>

        <div
          v-if='description'
          class='section-drawer_description copy-two'
        >
          {{ description }}
        </div>

        <div
          v-if='link'
          class='section-drawer_cta'
        >
          <router-link
            :to="{ path: link.href }"
            class='section-drawer_cta-link action-two'
          >
            {{ link.title }}
          </router-link>
        </div>

        <slot />
    </template>

    <template v-else>
      <div class='section-drawer_info'>
        <template v-if="is_the_only_heading">
          <h1
            v-if="title"
            class='section-drawer_title heading-four'
          >
            {{ title }}
          </h1>
        </template>

        <template v-else>
          <h2
            v-if="title"
            class='section-drawer_title heading-four'
          >
            {{ title }}
          </h2>
        </template>

        <div
          v-if='description'
          class='section-drawer_description copy-two'
        >
          {{ description }}
        </div>

        <div
          v-if='link'
          class='section-drawer_cta'
        >
          <router-link
            :to="{ path: link.href }"
            class='section-drawer_cta-link action-two'
          >
            {{ link.title }}
          </router-link>
        </div>
      </div>

      <div :class='drawer_list_class'>
        <slot />
      </div>
    </template>
  </div>
</div>
</template>

<script>
export default {
  props: [
    'title',
    'description',
    'link',
    'type',
    'modifier',
    'context'
  ],

  computed: {
    mainClass() {
      return [`${this.type}-section`, `section-drawer--${this.modifier}`, `section-drawer--${this.context}`]
    },

    is_the_only_heading() {
      return this.$route.name !== 'home'   &&
        this.$route.name !== 'audio-overview'   &&
        this.$route.name !== 'my_activity'  &&
        !this.should_show_catalog_nav
    },

    is_one_column() {
      return this.type === 'one_column'
    },

    is_catalog_enabled() {
      return (this.$root.configuration && this.$root.configuration.header_menu_catalog_visible) || false
    },

    should_show_catalog_nav() {
      return (this.is_catalog_enabled &&
        (this.$route.name === 'audio-series' ||
          this.$route.name === 'learning-plans' ||
          this.$route.name === 'learning-plan' ||
          this.$route.name === 'courses' ||
          this.$route.name === 'course'
        )
      )
    },

    drawer_list_class() {
      if (this.type === 'audio_series') return 'section-drawer_audio-series-list'

      return (this.type === 'courses' || this.type === 'learning_plans')
        ? 'section-drawer_courses-list'
        : 'section-drawer_list'
    }
  }

}
</script>

<style lang='sass' scoped>
  @import './SectionDrawer'
</style>
