<template>
  <transition name='fast-fade'>
    <div
      v-if='ready'
      id='slide-poll_overlay'
      class="slide-poll_overlay"
    >
      <h2 class='slide-poll_overlay-headline h-four'>
        {{ activity.prompt }}
      </h2>

      <PollMain
        :activity='activity'
        :slide='slide'
        :slide_result='slide_result'
        :view_style='view_style || "textual"'
        :is_desktop_view='is_desktop_view || "false"'
        :updateSlideResult='updateSlideResult'
      />

      <div
        v-if='has_response'
        class='slide-poll_overlay-bottom'
      >
        <a
          href="#"
          class='slide-poll_overlay-keep button-one-branded'
          @click.prevent='skip'
        >
          {{ $t('keep_learning') }}
        </a>
      </div>
    </div>
  </transition>
</template>

<script>
import PollMain from './poll-v1'

export default {
  props: [
    'slide',
    'slide_result',
    'activity',
    'goToNextSlide',
    'toggle',
    'view_style',
    'is_desktop_view',
    'updateSlideResult'
  ],

  data() {
    return {
      ready: false
    }
  },

  components: {
    PollMain
  },

  created() {
    this.ready = true
  },

  computed: {
    has_response() {
      return this.slide_result.results && this.slide_result.results.selected
    }
  },

  methods: {
    toggleOverlay() {
      this.toggle()
    },

    skip() {
      this.toggleOverlay()
      this.goToNextSlide()
    }
  }
}
</script>
