<template>
<div class='learning-plan-instructor'>
  <div
    :style='instructor_image'
    class='learning-plan-instructor_image'
  />

  <div class='learning-plan-instructor_title heading-five'>
    {{ instructor.full_name }}
  </div>

  <div class='learning-plan-instructor_description copy-three'>
    {{ instructor.title }}
  </div>
</div>
</template>

<script>
export default {
  props: ['instructor'],

  computed: {
    instructor_image() {
      return `background-image: url(${this.instructor.image})`
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './Instructor'
</style>
