import HypeComponent from './hype-component'
import { createApp } from 'vue'

export default {

  data() {
    return {
      hypes: []
    }
  },

  mounted() {
    this.initHype()
  },

  methods: {
    initHype() {
      if ('HYPE_eventListeners' in window === false) {
        window.HYPE_eventListeners = []
      }
      window.HYPE_eventListeners.push({ type:'HypeSceneLoad', callback: this.hypeSceneLoaded })

      const nodes = this.$el.querySelectorAll('[data-hype-resource]')

      if (!nodes.length) return false

      for (const node of nodes) {
        const props = {
          bucket:    node.dataset.hypeBucket,
          resource:  node.dataset.hypeResource
        }
        if (node.dataset.hypeNamespace) {
          props.namespace = node.dataset.hypeNamespace
        }

        const app = createApp(HypeComponent)
        app.provide('init_options', props)
        app.mount(node)
        this.hypes.push(app)
      }

      // Adding additional class for BasicContent Item
      const basic_content_body = this.$el.querySelector('.slide-basic-content_body')
      if (basic_content_body) {
        basic_content_body.className += ' slide-basic-content_body--hype'
      }
    },

    hypeBecameVisible() {
      for (const h of this.hypes) {
        h._component.methods.becameVisible()
      }
    },

    hypeBecameHidden() {
      for (const h of this.hypes) {
        h._component.methods.becameHidden()
      }
    },

    hypeSceneLoaded(hypeDocument, element, event) {
      const locale = (this.$i18n && this.$i18n.locale) || 'en'
      const doc_name = this._underscoreCase(hypeDocument.documentName())

      let translations = null
      if (this.activity) translations = this.activity.hype_translations
      if (this.resource) translations = this.resource.activity.hype_translations

      translations = translations && translations[doc_name]
      if (translations) {
        element = this.$el
        if (!element) {
          element = $('.slide-basic-content_body--hype').find('.HYPE_scene[hype_scene_index="1"]')[0]
        }
        Object.keys(translations).forEach(function(key) {
          element.querySelectorAll(`#${hypeDocument.documentId()} .${key}`).forEach(function(node) {
            node.innerHTML = translations[key][locale]
          })
        })
      }
      return true
    },

    _underscoreCase(str) {
      return str.replace(/[-\s]+/, '_')
        .replace(/([A-Z])/g, '_$1')
        .replace(/^_/, '')
        .toLowerCase()
    }
  }
}
