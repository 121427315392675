<template>
<router-link
  :to='`/instructors/${instructor.identifier}`'
  class='instructor-card'
>
  <div
    class='instructor-card_image'
    :style='instructor_image'
  />

  <div class='instructor-card_name'>
    {{ instructor.full_name }}
  </div>
</router-link>
</template>

<script>
export default {
  props: ['instructor'],

  computed: {
    instructor_image() {
      return `background-image: url(${this.instructor.detail_image})`
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './InstructorCard'
</style>
