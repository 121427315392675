<template>
  <div class='paired-comparison-results'>
    <h2 class='paired-comparison_title body-four'>
      {{ $t('results') }}
    </h2>

    <div class='paired-comparison_text h-four'>
      {{ step_title }}
    </div>

    <h3 class='paired-comparison_info-text body-four-branded'>
      {{ $t('your_top_results', {amount: top_results.length}) }}
    </h3>

    <results-list-desktop :top_results="top_results" />

    <results-list-mobile :top_results="top_results" />

    <button
      class='paired-comparison_button button-two'
      @click.prevent='finish'
    >
      {{ $t('continue') }}
    </button>

    <button
      class='paired-comparison_button button-two'
      @click.prevent='retake'
    >
      {{ $t('retake') }}
    </button>
  </div>
</template>

<script>
import ResultsListDesktop from './results/list-desktop'
import ResultsListMobile from './results/list-mobile'

export default {
  props: [
    'options',
    'activity_results',
    'step_title',
    'retake',
    'finish'
  ],

  components: {
    ResultsListDesktop,
    ResultsListMobile
  },

  data() {
    return {
      currentOptionNum:  1
    }
  },

  computed: {
    top_results() {
      const sorted_keys = Object.keys(this.activity_results.results).sort((a, b) => {
        return this.activity_results.results[a] - this.activity_results.results[b]
      })
      return sorted_keys.map(key => this.options.find((opt) => opt.id === key))
    }
  },

  methods: {
    isCurrent(index) {
      return this.currentOptionNum === index + 1
    },

    addCurrentClass(index) {
      return this.isCurrent(index) ? 'current-option' : 'not-current-option'
    }
  }
}
</script>
