<template>
<div class='course-drawer_overview'>
  <!-- Desktop -->
  <div
    v-if='!is_mobile'
    class='course-drawer_overview-info'
  >
    <!-- Trailer -->
    <div
      v-if='audio_series.trailer_url'
      class='course-drawer_trailer'
    >
      <AudioPlayer
        :mini_mode='true'
        :audio_title='audio_series.title + " - Trailer"'
        :audio_playlist_title='audio_series.title'
        :audio_image='audio_series.card_image'
        :file_url='audio_series.trailer_url'
        :play_title="$t('listen_to_trailer')"
      />
    </div>

    <p class='course-drawer_overview-description copy-two'>
      {{ audio_series.overview_intro }}
    </p>

    <!-- Actions -->
    <div class='course-drawer_overview-actions'>
      <a
        href='#play'
        class='action-one course-drawer_overview-action course-drawer_overview-action--primary'
        @click.prevent='playToggle(audio_series, -1)'
      >
        {{ play_button_text }}
      </a>

      <router-link
        :to="{
          name: 'audio-overview',
          params: { identifier: audio_series.identifier }
        }"
        class='action-one course-drawer_overview-action course-drawer_overview-action--secondary'
      >
        {{ $t('learn_more') }}
      </router-link>
    </div>
  </div>
  <!-- End Desktop -->

  <!-- Mobile  -->
  <div
    v-if='is_mobile'
    class='course-drawer_overview-info course-drawer_overview-info--mobile'
  >
    <!-- Actions -->
    <div class='course-drawer_overview-actions'>
      <a
        href='#play'
        class='action-one course-drawer_overview-action course-drawer_overview-action--primary'
        @click.prevent='playToggle(audio_series, -1)'
      >
        {{ play_button_text }}
      </a>

      <router-link
        :to="{
          name: 'audio-overview',
          params: { identifier: audio_series.identifier }
        }"
        class='action-one course-drawer_overview-action course-drawer_overview-action--secondary'
      >
        {{ $t('learn_more') }}
      </router-link>
    </div>

    <div class='course-drawer_overview-label heading-six'>
      {{ $t('overview') }}
    </div>

    <!-- Trailer -->
    <div
      v-if='audio_series.trailer_url'
      class='course-drawer_trailer'
    >
      <AudioPlayer
        :mini_mode='true'
        :audio_title='audio_series.title + " - Trailer"'
        :audio_playlist_title='audio_series.title'
        :audio_image='audio_series.card_image'
        :file_url='audio_series.trailer_url'
        :play_title="$t('listen_to_trailer')"
      />
    </div>

    <p class='course-drawer_overview-description copy-two'>
      {{ audio_series.overview_intro }}
    </p>
  </div>
  <!-- End Mobile  -->

  <!-- Instructor -->
  <template v-if='audio_series.instructor'>
    <router-link
      :to="{
        name: 'instructor',
        params: { identifier: audio_series.instructor.identifier }
      }"
      class='course-drawer_overview-instructor'
    >
      <Avatar :image='audio_series.instructor.image' />

      <div class='course-drawer_overview-instructor-info'>
        <span
          class='course-drawer_overview-instructor-label copy-four'
        >
          {{ $t('audio_series.host') }}
        </span>

        <p class='course-drawer_overview-instructor-description copy-three'>
          <strong class="heading-six">{{ audio_series.instructor.full_name }}</strong>
          {{ audio_series.instructor.title }}
        </p>
      </div>
    </router-link>
  </template>
</div>
</template>

<script>
import Avatar from 'components/Avatar/UserAvatar'
import AudioPlayer from 'components/Shared/AudioPlayer/AudioPlayerInline'

import { useHomeStore } from 'home/stores/home'
import { mapState } from 'pinia'

export default {
  components: {
    Avatar,
    AudioPlayer
  },

  props: {
    audio_series: { type: Object, required: true },
    is_mobile: Boolean,
    playToggle: { type: Function, required: true }
  },

  computed: {
    ...mapState(useHomeStore, [
      'currentAudio',
      'isAudioPlaying'
    ]),

    play_button_text() {
      if (this.audio_series.identifier === this.currentAudio.identifier) {
        return this.isAudioPlaying
          ? this.$t('audio_player.pause')
          : this.$t('audio_player.resume')
      } else {
        return this.$t('audio_player.play')
      }
    }
  }
}
</script>

<style lang='sass' scoped>
  @import 'home/styles/shared/CourseDrawerOverview'
</style>
