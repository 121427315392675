<template>
<div
  id='instructor-page'
  class='instructor-page'
>
  <!-- Custom Page Title -->
  <PageTitle :title="page_title" />

  <!-- Back button -->
  <div class='instructor-page_back'>
    <button
      class='instructor-page_back-link'
      @click.prevent='returnBack'
    >
      {{ $t('back') }}
      <BackIcon />
    </button>
  </div>

  <div class='instructor-page_content'>
    <!-- Hero Image and Trailer -->
    <div
      class='instructor-page_hero-image'
      :style='instructor_image'
    >
      <VideoTrailer
        v-if="instructor.trailer"
        :text='trailer_text'
        :video='instructor.trailer'
      />
    </div>

    <div class='instructor-page_info'>
      <!-- Basic Info -->
      <div
        role="heading"
        aria-level="1"
        class='instructor-page_name heading-two'
      >
        {{ instructor.full_name }}
      </div>

      <div class='instructor-page_title heading-six'>
        {{ instructor.title }}
      </div>

      <div class='instructor-page_description copy-one'>
        <span v-html="instructor.bio" />
      </div>

      <!-- Connect With Icons and Links -->
      <div
        v-if="has_connect_links"
        class='instructor-page_connect'
      >
        <div class='instructor-page_connect-headline copy-three'>
          {{ instructor_share_headline }}
        </div>

        <div class='instructor-page_connect-links'>
          <a
            v-if='instructor.instagram_link'
            :href='instructor.instagram_link'
            class='instructor-page_connect-link instagram'
            target='_blank'
            rel='noreferrer'
          >
            Instagram<InstagramIcon />
          </a>
          <a
            v-if='instructor.linkedin_link'
            :href='instructor.linkedin_link'
            class='instructor-page_connect-link linkedin'
            target='_blank'
            rel='noreferrer'
          >
            LinkedIn<LinkedinIcon />
          </a>

          <a
            v-if='instructor.facebook_link'
            :href='instructor.facebook_link'
            class='instructor-page_connect-link facebook'
            target='_blank'
            rel='noreferrer'
          >
            Facebook<FacebookIcon />
          </a>

          <a
            v-if='instructor.twitter_link'
            :href='instructor.twitter_link'
            class='instructor-page_connect-link twitter'
            target='_blank'
            rel='noreferrer'
          >
            Twitter<TwitterIcon />
          </a>

          <a
            v-if='instructor.website_link'
            :href='instructor.website_link'
            class='instructor-page_connect-link website'
            target='_blank'
            rel='noreferrer'
          >
            Website<WebsiteIcon />
          </a>
        </div>
      </div>

      <!-- Interview -->
      <div class='instructor-page_interview'>
        <div class='instructor-page_interview-headline heading-four'>
          {{ instructor.interview_headline }}
        </div>

        <div class='instructor-page_interview-content copy-one'>
          <p>{{ instructor.interview_content }} </p>
        </div>
      </div>

      <!-- Lessons of Instructor -->
      <div
        v-if='instructor.lessons.length'
        class='instructor-page_lessons'
      >
        <div
          role="heading"
          aria-level="2"
          class='instructor-page_lessons-headline heading-four'
        >
          {{ $t('instructor_page.lessons') }}
        </div>

        <div class='instructor-page_lessons-list'>
          <LessonCard
            v-for='(lesson, index) in instructor.lessons'
            :key='index'
            :lesson='lesson'
            :instructor='instructor'
          />
        </div>
      </div>

      <!-- Audio Series Episodes of Instructor -->
      <div
        v-if='instructor.audio_series.length'
        class='instructor-page_lessons'
      >
        <div
          role="heading"
          aria-level="2"
          class='instructor-page_lessons-headline heading-four'
        >
          {{ $t('instructor_page.podcast_episodes') }}
        </div>

        <div class='instructor-page_lessons-list'>
          <template v-for='(audio_series, series_index) in instructor.audio_series'>
            <AudioEpisodeCard
              v-for='(episode, index) in audio_series.episodes'
              :key='`${series_index}_${index}`'
              :episode='episode'
              :episode_index='index'
              :series='audio_series'
              :playToggle='playToggle'
              :isPlaying='isEpisodePlaying'
            />
          </template>
        </div>
      </div>

      <!-- LearningPlans of Instructor -->
      <div
        v-if='instructor.learning_plans.length'
        class='instructor-page_lessons'
      >
        <div
          role="heading"
          aria-level="2"
          class='instructor-page_lessons-headline heading-four'
        >
          {{ $t('instructor_page.learning_plans') }}
        </div>

        <div class='instructor-page_lessons-list'>
          <LearningPlanCard
            v-for='(lp, index) in instructor.learning_plans'
            :key='index'
            :learning_plan='lp'
            :instructor='instructor'
          />
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapState, mapActions } from 'pinia'

import AudioEpisodeCard from '../AudioEpisodeCard/AudioEpisodeCard'
import LessonCard from '../LessonCard/InstructorsLessonCard'
import LearningPlanCard from '../LearningPlanCard/InstructorsLearningPlanCard'
import VideoTrailer from 'home/components/shared/VideoTrailer/VideoTrailer'
import PageTitle from 'components/Header/PageTitle'

import BackIcon from './back.svg'
import InstagramIcon from './instagram.svg'
import LinkedinIcon from './linkedin.svg'
import FacebookIcon from './facebook.svg'
import TwitterIcon from './twitter.svg'
import WebsiteIcon from './website.svg'

export default {
  props: ['identifier'],

  components: {
    PageTitle,
    AudioEpisodeCard,
    LessonCard,
    LearningPlanCard,
    VideoTrailer,
    BackIcon,
    InstagramIcon,
    LinkedinIcon,
    FacebookIcon,
    TwitterIcon,
    WebsiteIcon
  },

  created() {
    this.$scrollTo($('#instructor-page'), 200, { offset: -30 })
  },

  computed: {
    ...mapState(useHomeStore, [
      'instructors',
      'partner_name',
      'currentAudio',
      'currentEpisodeIndex',
      'isAudioPlaying'
    ]),

    page_title() {
      return this.instructor.full_name +
        ' | ' +
        this.$t('instructor') +
        ' | ' +
        this.partner_name
    },

    instructor() {
      return this.instructors[this.identifier]
    },

    instructor_image() {
      return `background-image: url(${this.instructor.detail_image})`
    },

    trailer_text() {
      return this.$t('instructor_trailer', { full_name: this.instructor.full_name.replace(/ +$/,  '') })
        .replace("s's", "s'")
    },

    instructor_share_headline() {
      return this.instructor.share_headline
    },

    has_connect_links() {
      return this.instructor.instagram_link || this.instructor.linkedin_link || this.instructor.facebook_link || this.instructor.twitter_link || this.instructor.website_link
    }
  },

  methods: {
    ...mapActions(useHomeStore, [
      'playEpisode',
      'startPlayback',
      'pausePlayback'
    ]),

    returnBack() {
      if (window.history.length > 2) {
        this.$router.back()
      } else {
        if (this.$router.currentRoute.value.name === 'instructor') {
          this.$router.push({ name: 'instructors' })
        }
      }
    },

    playToggle(series, episodeIndex) {
      if (episodeIndex < 0) { // if play/resume button
        if (series.identifier === this.currentAudio.identifier) {
          // if the series has already started
          if (this.isAudioPlaying) {
            this.pausePlayback()
          } else {
            this.startPlayback()
          }
        } else {
          // start the series from the very begining
          this.playEpisode({
            audio: series,
            episode: 0
          })
        }
      } else {
        this.playEpisode({
          audio: series,
          episode: episodeIndex
        })
      }
    },

    isEpisodePlaying(series, episodeIndex) {
      if (this.currentAudio.identifier === series.identifier &&
        this.currentEpisodeIndex === episodeIndex &&
        this.isAudioPlaying) {
        return true
      }
      return false
    }
  }
}

</script>

<style lang='sass' scoped>
  @import './InstructorPage'
</style>

<style lang='sass'>
.back-icon
  .back-icon_space
    transition: fill 0.1s ease-in

  &:hover
    .back-icon_space
      fill: #4A4AD5

</style>
