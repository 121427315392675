export default {
  data() {
    return {
      shift_is_pressed: false,
      closeAction: null,
      keyUpCallback: (e) => this.detectKeyup(e),
      keyDownCallback: (e) => this.detectKeydown(e),
      lastFocusedSelector: null,
      main_ref_name: 'activity-overlay'
    }
  },

  mounted() {
    // Get main_ref_name from parent's component
    if (this.overlay_ref_name) this.main_ref_name = this.overlay_ref_name

    // Store classes of the element that initiated the overlay
    const whatClasses = $('html').attr('data-whatclasses')
    let lastFocusedClasses = []
    if (whatClasses) lastFocusedClasses = whatClasses.split(',')
    if (lastFocusedClasses.length) {
      this.lastFocusedSelector = '.' + lastFocusedClasses.join('.')
    }

    // Setup events with some magic delay
    setTimeout(() => {
      this.setupEvents()
    }, 1)
  },

  beforeUnmount() {
    document.body.removeEventListener('keyup', this.keyUpCallback)
    document.body.removeEventListener('keydown', this.keyDownCallback)

    if ($(this.$el).hasClass('interactive-section_overlay')) {
      $(this.$el).parents('.interactive-section').find(this.lastFocusedSelector).focus()
    } else {
      // some workarouds related to resource carousel (TBK-3708)
      if (this.lastFocusedSelector === '.resources-carousel_resource') {
        $('.part_next-slide').focus()
      // some workarouds related to hero section (PDEV-7540)
      } else if (this.lastFocusedSelector === '.hero-section_action.action-one-branded') {
        const whatInput = $('html').attr('data-whatinput')
        if (whatInput === 'keyboard') {
          $(this.lastFocusedSelector).focus()
        }
      } else {
        $(this.lastFocusedSelector).focus()
      }
    }
    this.lastFocusedSelector = null
  },

  methods: {
    setupEvents() {
      this.setOverlayAttributes()
      this.setFocus('close-button')

      if (this.$refs['close-button']) {
        this.$refs['close-button'].addEventListener('blur', (e) => { this.checkFocus(e) })
      } else {
        $('.self-assessment_close')[0].addEventListener('blur', (e) => { this.checkFocus(e) })
      }

      this.addRestricter(this.main_ref_name)

      document.body.addEventListener('keyup', this.keyUpCallback)
      document.body.addEventListener('keydown', this.keyDownCallback)
    },

    detectKeyup(e) {
      if ((e.key !== undefined && e.key === 'Escape') ||
        (e.keyCode !== undefined && e.keyCode === 27)) {
        // Prevent closing overlay after esc on file upload
        // And do not close if SelfAssessment V2
        if (!event.target.className.match(/discussion-comment-form_upload-label/g) &&
            (document.querySelectorAll('.activity-overlay .self-assessment').length !== 1)
        ) {
          this.closeAction()
        }
      }

      if ((e.key !== undefined && e.key === 'Enter') ||
        (e.keyCode !== undefined && e.keyCode === 13)) {
        // Prevent closing overlay after enter on file upload in IE
        if (event.target.className.match(/discussion-comment-form_upload-label/g)) {
          event.target.click()
          return false
        }
      }

      if (e.key === 'Shift') {
        this.shift_is_pressed = false
      }
    },

    detectKeydown(e) {
      if (e.key === 'Shift') {
        this.shift_is_pressed = true
      }
    },

    setFocus(ref_name) {
      if (this.$refs[ref_name]) {
        this.$refs[ref_name].focus()
      }
    },

    addRestricter(ref_name) {
      const restricter = document.createElement('div')
      restricter.setAttribute('tabindex', 0)
      restricter.addEventListener('focus', () => { this.setFocus('close-button') })
      this.$refs[ref_name].appendChild(restricter)
    },

    checkFocus(e) {
      if (this.shift_is_pressed) {
        const focusable = this.$refs[this.main_ref_name]
          .querySelectorAll('button:not([disabled]), [href], input:not([disabled]), select, textarea, [tabindex]:not([tabindex="-1"])')
        const lastFocusable = focusable[focusable.length - 2]
        lastFocusable.focus()
      }
    },

    setOverlayAttributes() {
      this.$refs[this.main_ref_name].setAttribute('role', 'dialog')
      this.$refs[this.main_ref_name].setAttribute('aria-modal', true)
      this.$refs[this.main_ref_name].setAttribute('aria-label', this.label)
    }
  }
}
