<template>
  <label
    class='question-option'
    :class='optionClass()'
    :disabled="question_was_submitted ? true : null"
  >

    <input
      :type='optionType()'
      :value='index'
      :name='question.id'
      :aria-checked='isOptionSelected(option.id) ? "true" : "false"'
      @change='select(option.id)'
    >

      <div
        class='question-option_answer-top'
        :class="{'no-image': !option.option_image}"
      >
        <div class='question-option_answer-top_inner'>
          <div
            class='question-option_answer-diamond body-two-light'
            :aria-label='$t("option") + " " + letters[index] + "."'
            v-html='letters[index]'
          />

          <div
            class='question-option_answer-title body-two-light'
            v-html='option.text'
          />
        </div>

        <div
          v-if='option.option_image'
          class='question-option_answer-top_image'
          :style="{backgroundImage: 'url(' + option.option_image + ')'}"
        />
      </div>

      <div
        v-if='option.option_image'
        class='question-option_answer-bottom'
      >
        <div
          class='question-option_answer-bottom_image'
          :style="{backgroundImage: 'url(' + option.option_image + ')'}"
        />
      </div>

  </label>
</template>

<script>

export default {
  props: [
    'option',
    'question_was_submitted',
    'isOptionSelected',
    'question',
    'select',
    'index'
  ],

  data() {
    return {
      letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    }
  },

  computed: {

    is_correct() {
      return (this.option.is_correct && this.question_was_submitted)
    }

  },

  methods: {

    optionClass() {
      const classes = []

      if (this.isOptionSelected(this.option.id)) classes.push('question-option--selected')
      if (this.question_was_submitted) {
        this.is_correct ? classes.push('question-option--correct') : classes.push('question-option--incorrect')
      }
      return classes
    },

    optionType() {
      switch (this.question.kind) {
        case 'multiple_choice': return 'radio'
        case 'multiple_select': return 'checkbox'
        default: return 'checkbox'
      }
    }

  }
}
</script>

<style lang="sass" scoped>
//@import 'lesson/styles/components/quiz/question-option'
</style>
