<template>
<div
  class='course-drawer'
  :class='main_class'
>
  <button
    ref='close-button'
    :title="$t('close')"
    class='course-drawer_close'
    @click.prevent='close'
  >
    <CloseImage />
  </button>

  <!-- Preloader -->
  <div
    v-if='loading'
    class='course-drawer_loading page-loader'
  >
    <div class='loader' />
  </div>

  <template v-if='!loading && !is_mobile'>
    <!-- Navigation -->
    <div class='course-drawer_nav'>
      <template
        v-for='(link, index) in links'
        :key='index'
      >
        <a
          v-if='showTabType(link.name)'
          :id="link.name"
          :class='tabClass(link.name)'
          class='main-nav-link'
          href='#'
          role='tab'
          :aria-controls="'panel-tab' + link.name"
          :aria-selected=" (link.name === 'overview') ? 'true' : 'false' "
          @click.prevent='changeTab(link.name, $event)'
          @keyup='switchTabOnArrowPress($event)'
        >
          {{ link.text }}
        </a>
      </template>
    </div>

    <div class="course-drawer_content">
      <transition name="tab-change">
        <template v-if="active_tab === 'overview'">
          <div
            id="panel-tab-overview"
            aria-labelledby="overview"
            role="tabpanel"
          >
            <Overview :learning_plan="learning_plan" />
          </div>
        </template>
      </transition>

      <transition name="tab-change">
        <template v-if="active_tab === 'lessons'">
          <div
            id="panel-tab-courses"
            :key="'lessons'"
            aria-labelledby="lessons"
            class="course-drawer_list"
            role="tabpanel"
          >
            <LessonCard
              v-for="(lesson, index) in learning_plan.lessons"
              :key="index"
              :learning_plan="learning_plan"
              :lesson="lesson"
              :index="index"
            />
          </div>
        </template>
      </transition>
    </div>
  </template>
</div>
</template>

<script>
import WindowSizeMixin from 'home/components/shared/WindowSizeMixin'
import TabAccessibilityMixin from 'home/components/shared/TabAccessibilityMixin'
import Overview from './LearningPlanDrawerOverview'
import LessonCard from './LessonCard/LessonCard'
import LearningPlan from 'home/models/learning_plan'

import CloseImage from './close.svg'

export default {
  mixins: [WindowSizeMixin, TabAccessibilityMixin],

  components: {
    Overview,
    LessonCard,
    CloseImage
  },

  props: {
    identifier: { type: [String, Number], required: true },
    setOpenedDrawer: { type: Function, required: true },
    setOpenedLearningPlan: { type: Function, required: true }
  },

  data() {
    return {
      links: [
        { name: 'overview', text: this.$t('overview') },
        { name: 'lessons', text: this.$tc('learning_plan_lesson', 2) }
      ],
      valid_tabs: ['overview', 'lessons'],
      loading: false,
      activeTab: null,
      learning_plan: null
    }
  },

  created() {
    this.loadLearningPlan()

    // Setting default opened tab
    window.activeDrawerTabs = window.activeDrawerTabs || {}
    if (
      this.use_router &&
      this.$router.currentRoute.value.params.identifier === this.identifier
    ) {
      this.activeTab = this.$router.currentRoute.value.query.tab || 'overview'
    } else if (window.activeDrawerTabs[this.identifier]) {
      this.activeTab = window.activeDrawerTabs[this.identifier]
    } else {
      this.activeTab = 'overview'
    }
    window.activeDrawerTabs[this.identifier] = this.activeTab
  },

  watch: {
    identifier: 'loadLearningPlan'
  },

  mounted() {
    setTimeout(() => {
      this.$refs['close-button'].focus()
    }, 200)
  },

  computed: {
    main_class() {
      return this.is_mobile ? 'course-drawer--mobile' : 'course-drawer--desktop'
    },

    active_tab() {
      // return this.$route.query.tab || 'overview'
      return this.activeTab
    },

    use_router() {
      return this.$route.name.match(/^learning-plan/)
    }
  },

  methods: {
    tabClass(tab) {
      return tab === this.active_tab ? 'active' : ''
    },

    changeTab(tab, e) {
      if (this._isValidTab(tab)) {
        window.activeDrawerTabs[this.identifier] = tab
        this.activeTab = window.activeDrawerTabs[this.identifier]

        this.toggleTabsAria(e)

        if (this.use_router) {
          const q = tab === 'overview' ? {} : { tab }
          this.$router.push({
            name: 'learning-plans',
            params: { identifier: this.identifier },
            query: q
          })
        }
      }
    },

    _isValidTab(tab) {
      return this.valid_tabs.indexOf(tab) >= 0
    },

    close() {
      this.setOpenedDrawer(false)
      this.setOpenedLearningPlan(null)

      if (this.use_router) {
        // clear page url without page re-render
        window.history.pushState({}, null, '/home/learning-plans/')
      }
    },

    showTabType(type) {
      // let key = `lesson_plan_drawer_${type}_visible`
      // if (this.$root.configuration && this.$root.configuration.hasOwnProperty(key)) {
      //   return this.$root.configuration[key]
      // } else {
      //   return true
      // }
      return true
    },

    loadLearningPlan() {
      this.loading = true
      this.learning_plan = LearningPlan.load(
        this.identifier,
        () => {
          this.loading = false
        }
      )
    }
  }
}
</script>

<style lang='sass' scoped>
  @import 'home/styles/shared/CourseDrawer'
</style>
