<template>
  <div>
    <template v-for='(section, index) in sections'>
      <HeroContainer
        v-if="section.type === 'hero_sections'"
        :key='index'
        :section='section'
      />

      <!-- In Progress Section with Lessons -->
      <CarouselSection
        v-if="section.type === 'in_progress_sections'"
        :key='index'
        :headline='$t("in_progress_section.headline")'
        :lessons='section.lessons'
        type='in-progress-lessons'
        :link='section.link'
      />

      <!-- Interactive -->
      <InteractiveSection
        v-if="section.type === 'interactive_sections'"
        :key='index'
        :section='section'
        :submitWelcomeSurveyCallback='submitWelcomeSurveyCallback'
      />

      <!-- Learning Plans -->
      <LearningPlanSection
        v-if="section.type === 'learning_plan_sections'"
        :key='index'
        :section='section'
        context='home'
      />

      <!-- Courses -->
      <template v-if="section.type === 'course_sections'">
        <CourseSection
          v-if='!section.configuration.carousel_enabled'
          :key='index'
          :section='section'
          context='home'
        />

        <CarouselSection
          v-else
          :key="index + '_carousel'"
          :headline='section.headline'
          :subheadline='section.subheadline'
          :link='section.link'
          :courses='section.courses'
          :alignment='section.configuration.carousel_headline_alignment'
          type='courses'
        />
      </template>

      <!-- Experts -->
      <SectionDrawer
        v-if="section.type === 'instructor_sections'"
        :key='index'
        :description='section.subheadline'
        :link='section.link'
        :title='section.headline'
        type='experts'
        modifier='experts'
      >
        <ul class="instructor-section_ul">
          <template
            v-for='(expert, i) in section.instructors'
            :key='i'
          >
            <ExpertCard :expert='expert' />
          </template>
        </ul>
      </SectionDrawer>

      <!-- Popular Lessons -->
      <SectionDrawer
        v-if="section.type === 'lesson_sections'"
        :key='index'
        :description='section.subheadline'
        :title='section.headline'
        type='lessons'
        modifier='lessons'
      >
        <ul class='section-drawer_list-inner'>
          <li
            v-for='(lesson, i) in section.lessons'
            :key='i'
            class='lesson-card_list-item'
          >
            <LessonCard :lesson='lesson' />
          </li>
        </ul>
      </SectionDrawer>

      <!-- Resources -->
      <SectionDrawer
        v-if="section.type === 'resource_sections' && section.resources"
        :key='index'
        :description='section.subheadline'
        :link='section.link'
        :title='section.headline'
        type='one_column'
        modifier='resources_carousel'
      >
        <CardSlider
          :cards='resourcesAsCards(section.resources)'
          :infinite='false'
          :autoplay='false'
          :speed='4000'
          :cardClick='resourceClick'
          :centerIfLessItems='true'
        />
      </SectionDrawer>

      <!-- Audio Series Section -->
      <AudioSection
        v-if="section.type === 'audio_series_sections'"
        :key='index'
        :section='section'
      />
    </template>
  </div>
</template>

<script>
import { useHomeStore } from '../../stores/home'
import { mapActions, mapState } from 'pinia'

import HeroContainer from './HeroContainer/HeroContainer'
import SectionDrawer from 'home/components/shared/SectionDrawer/SectionDrawer.vue'
import CourseSection from 'home/components/shared/SectionDrawer/CourseSection/CourseSection.vue'
import CarouselSection from 'home/components/shared/CarouselSection/CarouselSection.vue'
import ExpertCard from 'home/components/shared/ExpertCard/ExpertCard'
import LessonCard from 'home/components/shared/LessonCard/LessonCard'
import CardSlider from 'components/Shared/CardSlider/CardSlider'
import LearningPlanSection from 'home/components/shared/SectionDrawer/LearningPlanSection/LearningPlanSection.vue'
import InteractiveSection from 'components/InteractiveSection/InteractiveSection'
import AudioSection from 'home/components/shared/AudioSection/AudioSection'

export default {
  data() {
    return {
      filter: 'all'
    }
  },

  props: [
    'has_basic_auth',
    'submitWelcomeSurveyCallback'
  ],

  components: {
    HeroContainer,
    SectionDrawer,
    CourseSection,
    CarouselSection,
    ExpertCard,
    LessonCard,
    CardSlider,
    LearningPlanSection,
    InteractiveSection,
    AudioSection
  },

  computed: {
    ...mapState(useHomeStore, [
      'sections',
      'courses',
      'course_load_ts'
    ])
  },

  methods: {
    ...mapActions(useHomeStore, [
      'toggleResourceOverlay'
    ]),

    resourcesAsCards(resources) {
      const cards = resources.map((resource) => {
        return {
          id:           resource.id,
          image:        resource.image,
          type:         this._getResourceType(resource),
          headline:     resource.headline,
          course_info:  this._getResourceCourseInfo(resource),
          activity:     resource.activity
        }
      })
      return cards
    },

    _getResourceType(resource) {
      switch (resource.activity.type) {
        case 'activity_v1_videos': return 'video'
        case 'activity_v1_audios': return 'audio'
        case 'activity_v1_articles': return 'article'
        case 'activity_v1_resources':
          if (resource.activity.link.href.match(/pdf/) !== null) return 'PDF'
          if (resource.activity.link.href.match(/file/) !== null) return 'download'
          break
        default: return 'link'
      }
    },

    _getCourseInfoById(course_id) {
      if (!course_id) return null

      const courses = _.values(this.courses).filter((c) => c.id === course_id)

      if (courses) return { title: courses[0].title, identifier: courses[0].identifier }

      return null
    },

    _getResourceCourseInfo(resource) {
      if (!resource.course_info) {
        resource.course_info = resource.course_ids
          .map((course_id) => { return this._getCourseInfoById(course_id) })
      }
      return _.sortBy(resource.course_info, 'title')
    },

    resourceClick(resource) {
      this.toggleResourceOverlay({ resource })
    }
  }

}

</script>

<style lang='sass'>
  @import './app'
</style>
