// Resource Section model based on API::V1::Section::ResourceSerializer
export default {
  // Relationships
  resources: {
    jsonApi: 'hasMany',
    type: 'resource_cards'
  },

  // Images
  gradient_start: null,
  gradient_end: null,

  // Attributes
  headline: null,
  subheadline: null,
  link: null
}
