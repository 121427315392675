// model based on API::V1::Activity::V1::AudioSerializer
export default {

  // Images
  background_image: null,
  card_image: null,
  carousel_image: null,
  gradient_start: null,
  gradient_end: null,

  // Attributes
  identifier: null,
  label: null,
  headline: null,
  subheadline: null,
  provider_url: null,
  card_subheadline: null,
  complete_threshold: null
}
