<template>
<div class='account-overlay_sign-in'>
  <h1 class='heading-four'>
    {{ $t('log_in') }}
  </h1>

  <!-- Basic Auth -->
  <div
    v-if='basic_auth'
    class='account-overlay_sign-in_group'
  >
    <SignInForm
      :auth_data='basic_auth'
      :redirect_to_location='redirect_to_location'
      :toggleAccountOverlay='toggleAccountOverlay'
    />
  </div>

  <!-- SamlAuth -->
  <div
    v-if='saml_auth'
    class='account-overlay_sign-in_group'
  >
    <SamlAuth
      :auth_data='saml_auth'
      :redirect_to_location='redirect_to_location'
    />
  </div>

  <!-- OauthAuth -->
  <div
    v-if='oauth_auth'
    class='account-overlay_sign-in_group'
  >
    <OauthAuth
      :auth_data='oauth_auth'
      :redirect_to_location='redirect_to_location'
    />
  </div>

  <!-- OTPAuth -->
  <div
    v-if='otp_auth'
    class='account-overlay_sign-in_group'
  >
    <OTPAuth
      :toggleAccountOverlay='toggleAccountOverlay'
    />
  </div>

  <!-- SignUp Link -->
  <div
    v-if='basic_auth'
    class='account-overlay_sign-in_group'
  >
    <span class='copy-four'>{{ $t('first_time_here') }}</span>

    <a
      href='/account/sign-up'
      class='copy-four'
      @click.prevent='toggleAccountOverlay({context: "sign-up"})'
    >
      {{ $t('sign_up') }}
    </a>
  </div>
</div>
</template>

<script>
import OauthAuth  from '../../account/components/SignInPage/OauthAuth/OauthAuth'
import SignInForm from '../../account/components/SignInPage/SignInForm/SignInForm'
import SamlAuth   from '../../account/components/SignInPage/SamlAuth/SamlAuth'
import OTPAuth    from '../../account/components/SignInPage/OTPAuth/OTPAuth'

export default {
  props: {
    toggleAccountOverlay: Function,
    redirect_to_location: String
  },

  components: {
    OTPAuth,
    OauthAuth,
    SignInForm,
    SamlAuth
  },

  data() {
    return {
      basic_auth: null,
      saml_auth: null,
      oauth_auth: null,
      otp_auth: null
    }
  },

  created() {
    this.$parent.authentications.forEach((auth) => {
      if (auth.auth_type === 'basic') {
        this.basic_auth = auth
      }

      if (auth.auth_type === 'oauth') {
        this.oauth_auth = auth
      }

      if (auth.auth_type === 'saml') {
        this.saml_auth = auth
      }

      if (auth.auth_type === 'otp') {
        this.otp_auth = auth
      }
    })
  }
}
</script>

<style lang='sass' scoped>
.account-overlay_sign-in_group
  margin: 0 auto 20px
  max-width: 410px

  &:last-child
    margin-bottom: 0
</style>
