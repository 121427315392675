<template>
<div
  ref='card-button'
  class='course-card course-card--learning-plan'
  :class='main_class'
  @click.stop='cardClick(learning_plan, $event)'
>
  <div
    v-if='learning_plan.duration'
    class='course-card_duration'
  >
    {{ learning_plan.duration }} {{ $t('minutes') }}
  </div>

  <div
    class='course-card_image'
    :style='image'
  />

  <div class='course-card_inner'>
    <div class='course-card_inner-wrapper'>
      <div class='course-card_title heading-four'>
        {{ learning_plan.title }}
      </div>

      <div
        v-if='learning_plan.card_intro'
        class='course-card_attribution heading-six'
      >
        <CertIcon v-if="learning_plan.badging_enabled" />
        {{ learning_plan.card_intro }}
      </div>
    </div>

    <span
      v-if='learning_plan.completion_date'
      class='course-card_completed'
    >
      <CompletedIcon />
    </span>

    <a
      v-if="learning_plan.enrolled && !learning_plan.completion_date"
      :href='`/learning-plans/${learning_plan.identifier}/continue`'
      class="course-card_action action-two"
    >
      {{ $t('resume') }}
    </a>

    <a
      v-if="!learning_plan.enrolled"
      :href='`/learning-plans/${learning_plan.identifier}/continue`'
      class="course-card_action action-two"
      @click='authGateway($event)'
    >
      {{ $t('begin') }}
    </a>

    <button
      ref='more-button'
      class='course-card_button'
      @click.stop='selectLearningPlan(learning_plan)'
    >
      {{ $t('more') }}
      <SelectCourseIcon />
    </button>
  </div>
</div>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapActions } from 'pinia'

import CertIcon from './cert.svg'
import SelectCourseIcon from './down-arrow.svg'
import CompletedIcon from './completed.svg'

export default {
  props: {
    learning_plan : { type: Object, required: true },
    opened_learning_plan: { type: String, required: false, default: null },
    is_selected: { type: Boolean, required: true },
    selectLearningPlan: { type: Function, required: true }
  },

  components: {
    CertIcon,
    SelectCourseIcon,
    CompletedIcon
  },

  watch: {
    'is_selected'(value) {
      if (value) { return false }
      setTimeout(() => {
        if ($('html').attr('data-whatintent') === 'keyboard') {
          this.$refs['more-button'].focus()
        }
      }, 1)
    }
  },

  computed: {
    main_class() {
      const classes = []
      if (this.is_selected) classes.push('active')
      if (this.opened_learning_plan && !this.is_selected) classes.push('opaque')

      return classes
    },

    image() {
      return `background-image: url(${this.learning_plan.card_image})`
    }
  },

  methods: {
    ...mapActions(useHomeStore, ['authGateway']),

    cardClick(learning_plan, event) {
      if (!event.target.className.match(/course-card_action/g)) {
        this.selectLearningPlan(learning_plan)
      }
    }
  }

}
</script>

<style lang='sass' scoped>
  @import 'home/styles/shared/CourseCard'
</style>
