<template>
  <transition name='fast-fade'>
    <div
      v-if='ready'
      ref='activity-overlay'
      class='part-quiz'
    >
      <component
        :is='"quiz-" + current_question_type'
        v-if='!is_quiz_finished'
        :label="label"
        :activity_results="activity_results"
        :question="current_question"
        :options="current_question_options"
        :nextQuestion="nextQuestion"
        :is_question_last="is_question_last"
      />

      <template v-else>
        <self-assessment-results
          v-if='current_question_type === "self-assessment"'
          :activity_results="activity_results"
          :quiz="quiz"
          :finishQuiz="finish"
          :retakeQuiz="retakeQuiz"
        />

        <quiz-results
          v-else
          :activity_results="activity_results"
          :quiz="quiz"
          :finishQuiz="finish"
          :retakeQuiz="retakeQuiz"
        />
      </template>

      <quiz-pagination
        v-if="!is_quiz_finished"
        :questions='questions'
        :question_index='question_index'
      />

      <!--
      <preload-images :links='linksToPreload' v-if='currentQuizType === "self-assessment"'></preload-images>
      -->
    </div>
  </transition>
</template>

<script>
import QuizMultipleChoice from './quiz-v1-multiple-choice'
import QuizMultipleSelect from './quiz-v1-multiple-select'
import QuizSelfAssessment from './quiz-v1-self-assessment'
import QuizPagination from './quiz-v1-pagination'
import QuizResults from './quiz-v1-results'
import SelfAssessmentResults from './quiz-v1-self-assessment-results'
// import PreloadImages from './preload-images.vue'

export default {
  props: [
    'slide',
    'slide_result',
    'quiz', // activity object
    'finish',
    'updateSlideResult'
  ],

  data() {
    return {
      ready: false,
      question_index: 0,
      results_data: {},
      steps: [
        'questions',
        'results'
      ],
      step_index: null,
      activity_results: {}
    }
  },

  created() {
    this.ready = true
  },

  mounted() {
    if (this.slide_result.status === 'passed' && Object.keys(this.slide_result.results).length) {
      // deep copy
      this.activity_results = JSON.parse(JSON.stringify(this.slide_result.results))
      this.step_index = 1
    } else {
      this.step_index = 0
    }
  },

  components: {
    QuizMultipleChoice,
    QuizMultipleSelect,
    QuizSelfAssessment,
    QuizPagination,
    QuizResults,
    SelfAssessmentResults//,
    // PreloadImages
  },

  computed: {
    questions() {
      return this.quiz.questions
    },

    current_question() {
      if (this.quiz.randomize) {
        return this.current_quiz_sorted_questions[this.question_index]
      } else {
        return this.questions[this.question_index]
      }
    },

    current_question_options() {
      if (this.quiz.randomize) {
        const options = this.current_question.options

        return options.slice(0, options.length).sort(function() {
          return 0.5 - Math.random()
        })
      } else {
        return this.current_question.options
      }
    },

    current_question_type() {
      return this.current_question.kind.replace(/_/g, '-')
    },

    current_step() {
      return this.steps[this.step_index]
    },

    is_question_last() {
      return this.quiz.questions.length === (this.question_index + 1)
    },

    is_quiz_finished() {
      return this.current_step === 'results'
    },

    current_quiz_sorted_questions() {
      const questions = this.quiz.questions

      return questions.slice(0, questions.length).sort(function() {
        return 0.5 - Math.random()
      })
    },

    label() {
      if (this.quiz.label) {
        return this.quiz.label
      } else if (this.quiz.type === 'activity_v1_quizzes') {
        return this.$t('quiz')
      } else { // 'activity_v1_self_assessments'
        return this.$t('self_assessment')
      }
    }

    /*
    linksToPreload() {
      let images = [];

      this.currentQuiz.results.map((r) => {
        if (r && r.image) images.push(r.image)
      })

      return images;
    }
    */
  },

  methods: {
    nextStep() {
      this.step_index++
    },

    nextQuestion() {
      if (this.is_question_last) {
        this.submit()
        this.nextStep()
      } else {
        this.question_index++
      }
    },

    retakeQuiz() {
      this.question_index = 0
      this.retake()
    },

    submit() {
      this.updateSlideResult({
        slide_id:    this.slide.id,
        slide_type:  this.slide.type,
        event:       'finished',
        payload:     this.activity_results
      })
    },

    stop() {
      this.activity_results = null
      this.step_index = null
    },

    retake() {
      this.activity_results = {}
      this.step_index = 0
    }

  }
}
</script>

<style lang='sass'>
  @import 'lesson/styles/components/quiz/results'
  @import 'lesson/styles/components/quiz/question-option'
  @import 'lesson/styles/components/quiz/quiz-pagination'
  @import 'lesson/styles/components/quiz/question'
  @import 'lesson/styles/components/quiz/response'
  @import 'lesson/styles/components/part-quiz'
</style>
