<template>
  <div><!-- empty --></div>
</template>

<script>
export default {
  name: 'page-title',
  props: ['title'],
  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = this.title
      }
    }
  }
}
</script>
