// model based on API::V1::Activity::V1::TitleSerializer
export default {

  // Images
  background_image: null,
  partner_logo: null,
  gradient_start: null,
  gradient_end: null,

  // Attributes
  identifier: null,
  label: null,
  headline: null,
  subheadline: null,
  lesson_title: null
}
