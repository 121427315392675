<template>
  <div
    ref='card-button'
    class='course-card course-card--course'
    :class='main_class'
    @click.stop='cardClick(course, $event)'
  >
    <div
      v-if='course.duration'
      class='course-card_duration'
    >
      {{ course.duration }} {{ $t('minutes') }}
    </div>

    <div
      class='course-card_image'
      :style='course_image'
    />

    <div class='course-card_inner'>
      <div class='course-card_inner-wrapper'>
        <div class='course-card_title heading-five'>
          {{ course.title }}
        </div>

        <div
          v-if='instructor_attribution'
          class='course-card_attribution copy-three'
        >
          {{ instructor_attribution }}
        </div>
      </div>

      <a
        v-if='is_enrolled && progress !== 100'
        :href='`/courses/${course.identifier}/continue?skip_intro=true`'
        class='course-card_action action-two'
      >
        {{ $t('resume') }}
      </a>

      <a
        v-if='!is_enrolled'
        :href='`/courses/${course.identifier}/continue?skip_intro=true`'
        class='course-card_action action-two'
        @click='authGateway($event)'
      >
        {{ $t('begin') }}
      </a>

      <div
        v-if='course.card_intro'
        class='course-card_description copy-three'
      >
        {{ course.card_intro }}
      </div>

      <button
        ref='more-button'
        class='course-card_button'
        @click.stop='selectCourse(course)'
      >
        {{ $t('more') }}
        <SelectCourseImage />
      </button>
    </div>

    <div
      v-if='progress && progress !== 100'
      class='course-card_progress-bar'
    >
      <div
        class='course-card_current-progress'
        :style="{width: '' + progress + '%'}"
      >
        <span class='ada-hidden'>Completed: {{ progress }}%</span>
      </div>
    </div>

    <div
      v-if='course.completion_date'
      class='course-card_completed'
    >
      <CompletedImage />
    </div>
  </div>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapActions } from 'pinia'

import SelectCourseImage from './down-arrow.svg'
import CompletedImage from './completed.svg'

export default {
  props: {
    course: { type: Object, required: true },
    opened_course: { type: String, required: false, default: null },
    is_selected: { type: Boolean, required: true },
    selectCourse: { type: Function, required: true }
  },

  data() {
    return {
    }
  },

  components: {
    SelectCourseImage,
    CompletedImage
  },

  created() {
  },

  watch: {
    'is_selected'(value) {
      if (value) { return false }
      setTimeout(() => {
        if (this.opened_course) { return false }
        if ($('html').attr('data-whatintent') === 'keyboard') {
          this.$refs['more-button'].focus()
        }
      }, 1)
    }
  },

  computed: {
    main_class() {
      const classes = []
      if (this.is_selected) classes.push('active')
      if (this.is_enrolled) classes.push('in-progress')
      if (this.opened_course && !this.is_selected) classes.push('opaque')

      return classes
    },

    progress() {
      return this.course.progress
    },

    is_enrolled() {
      return !!this.course.enrolled
    },

    rating() {
      // return this.course.rating
      return false
    },

    instructor_attribution() {
      return (this.course.instructor && this.course.card_attribution_visible && this.$t('attribution.with', { value: this.course.instructor.full_name })) || null
    },

    course_image() {
      return `background-image: url(${this.course.card_image})`
    }
  },

  methods: {
    ...mapActions(useHomeStore, ['authGateway']),

    cardClick(course, event) {
      if (!event.target.className.match(/course-card_action/g) &&
          !event.target.className.match(/course-card_button/g)) {
        this.selectCourse(course)
      }
    }

  }

}
</script>

<style lang='sass' scoped>
  @import 'home/styles/shared/CourseCard'
</style>
