<template>
<div
  class='resource-card-small'
  :class='main_class'
>
  <a
    href='#'
    class='resource-card-small_inner'
    @click.prevent='openOverlay'
  >

    <div class='resource-card-small_image--wrapper'>
      <div
        class='resource-card-small_image'
        :style='resourceImage'
      />
    </div>

    <div class='resource-card-small_content'>
      <div class='resource-card-small_title'>
        {{ resource.headline }}
      </div>

      <div class='resource-card-small_attribution'>
        {{ $t('attribution.from', {value: resource.lesson_title}) }}
      </div>
    </div>
  </a>

  <ResourceOverlay
    v-if='show_overlay'
    :resource='resource'
    :closeOverlay='closeOverlay'
  />
</div>
</template>

<script>
import ResourceOverlay from 'home/components/shared/ResourceOverlay/ResourceOverlay'

export default {
  props: ['resource', 'modifier'],

  data() {
    return {
      show_overlay: false
    }
  },

  components: {
    ResourceOverlay
  },

  computed: {
    resourceImage() {
      return `background-image: url(${this.resource.background_image})`
    },

    main_class() {
      return this.modifier ? `resource-card-small--${this.modifier}` : ''
    }
  },

  methods: {
    openOverlay() {
      this.show_overlay = true

      const url = this.resource.type === 'activity_v1_articles' ? window.location.href : this.resource.link.href
      const destination = url.search(window.location.hostname) > 0 || /^\//.test(this.resource.link.href) ? 'internal' : 'external'
      let category = this.$route.name

      if (category === 'course') {
        category = 'courses'
      }

      const gtm_event = {
        event:                'resourceCardClick',
        pageCategory:         category,
        resourceTitle:        this.resource.headline,
        resourceSubTitle:     this.resource.link ? this.resource.link.title : this.resource.cta,
        resourceID:           this.resource.id,
        resourceURL:          url,
        resourceDestination:  destination
      }

      dataLayer.push(gtm_event)
    },

    closeOverlay(event) {
      this.show_overlay = false
      $('body').removeClass('popup-opened')
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './ResourceCardSmall'
</style>
