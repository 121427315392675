<template>
<a
  :href='`/courses/${course.identifier}/continue`'
  class='course-card'
>

  <div
    class='course-card_image'
    :style='courseImage'
  />

  <div class='course-card_content'>
    <div class='course-card_title'>
      {{ course.title }}
    </div>

    <div class='course-card_attribution'>
      {{ instructor }}
    </div>

    <div
      v-if='progress && progress !== 100'
      class='course-card_progress-bar'
    >
      <div
        class='course-card_current-progress'
        :style="{width: '' + progress + '%'}"
      />
    </div>

    <div
      v-if='progress && progress === 100'
      class='course-card_completed'
    />
  </div>
</a>
</template>

<script>
export default {
  props: ['course', 'course_instructor'],

  computed: {
    courseImage() {
      return `background-image: url(${this.course.card_image})`
    },

    progress() {
      return this.course.progress
    },

    instructor() {
      if (this.course.instructor) {
        return this.$t('attribution.with', { value: this.course.instructor.full_name })
      }
      return null
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './CourseCard'
</style>
