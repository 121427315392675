<template>
<PageLoader v-if='!is_loaded' />

<div
  v-else
  class='audio-series-page'
>
  <!-- Custom Page Title -->
  <PageTitle :title='page_title' />

  <AudioSeriesSection
    :section='all_audio_series_section'
    context='catalog'
  />
</div>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapState, mapActions } from 'pinia'

import PageLoader from 'components/PageLoader/PageLoader'
import PageTitle from 'components/Header/PageTitle'
import AudioSeriesSection from 'home/components/shared/SectionDrawer/AudioSeriesSection/AudioSeriesSection.vue'

export default {
  data() {
    return {
      is_loaded: false
    }
  },

  components: {
    PageLoader,
    PageTitle,
    AudioSeriesSection
  },

  mounted() {
    this._fetchData()
  },

  computed: {
    ...mapState(useHomeStore, [
      'api_client',
      'audio_series',
      'audio_series_full_load_ts',
      'partner_name'
    ]),

    page_title() {
      return this.$t('audio_series.podcasts') +
        ' | ' +
        this.partner_name
    },

    all_audio_series_section() {
      return {
        headline:     this.$t('audio_series.podcasts'),
        subheadline:  '',
        audio_series: Object.keys(this.audio_series).map(e => this.audio_series[e])
      }
    }
  },

  methods: {
    ...mapActions(useHomeStore, [
      'setAudioSeries'
    ]),

    _fetchData() {
      // Get from store
      if (this.audio_series && this.audio_series_full_load_ts) {
        this.is_loaded = true
        return false
      }

      // Fetch from API
      this.api_client
        .findAll('audio_series', {
          include: 'instructor,resources.activity',
          fields: {
            instructors: ['identifier', 'full_name', 'featured_image', 'image', 'title']
          }
        })
        .then(({ data }) => {
          this.setAudioSeries(data)
          this.is_loaded = true
        })
    }
  }
}
</script>
