import BaseClient from './base-client'
import { toRaw } from 'vue'

export default class WistiaClient extends BaseClient {
  constructor(videoCode) {
    super(videoCode)
    this.baseUrl    = 'https://fast.wistia.com/oembed/?url='
    this.accountUrl = encodeURIComponent('https://thebigknow.wistia.com/medias/')
    this.params     = '&format=json&embedType=async&videoFoam=true'

    window._wq = window._wq || []
  }

  getEmbedCode(callback) {
    $.getJSON(this._embedURL)
      .done(function(data) {
        callback(data.html, data)
      })
  }

  loadCaptions(twoCodeLang) {
    const threeCodeLang = this._langLookup(twoCodeLang)
    if (!threeCodeLang) return
    _wq.push({
      id: this.videoCode,
      options: {
        plugin: {
          'captions-v1': {
            onByDefault: true,
            language: threeCodeLang
          }
        }
      }
    })
  }

  pause() {
    this._video && this._video.pause()
  }

  handleEvents(callback) {
    this._video.bind('play', function() {
      callback(true)
    })

    this._video.bind('pause', function() {
      callback(false)
    })

    this._video.bind('end', function() {
      callback(false)
    })
  }

  play() {
    if (this._video) {
      toRaw(this._video).play()
    } else {
      _wq.push({
        id: this.videoCode,
        onReady: function(video) {
          video.play()
        }
      })
    }
  }

  cancelFullscreen() {
    this._video && this._video.cancelFullscreen()
  }

  loadCrossTime(percent, callback) {
    window._wq.push({
      id: this.videoCode,
      onHasData: function(video) {
        const crosstime = Math.floor(video.duration() * (percent * 0.01))
        if (video.iframe) {
          this._loadIframeCrosstime(video, crosstime, callback)
        } else {
          this._loadNormalCrosstime(video, crosstime, callback)
        }
      }.bind(this)
    })
  }

  _loadNormalCrosstime(video, crosstime, callback) {
    video.bind('crosstime', crosstime, function() {
      callback(video)
    })
  }

  // IFrame embeds do not support the "crosstime" event so we check for it manually
  _loadIframeCrosstime(video, crosstime, callback) {
    this.fired = false
    video.bind('secondchange', function(s) {
      if (s === crosstime && !this.fired) {
        this.fired = true
        callback(video)
      }
    }.bind(this))
  }

  _langLookup(twoCodeLang) {
    return {
      en: 'eng',
      es: 'spa',
      pt: 'por',
      ru: 'rus'
    }[twoCodeLang]
  }

  get _video() {
    /* global Wistia */
    if (typeof Wistia === 'undefined') {
      return null
    } else {
      return Wistia.api.all().find(function(v) {
        return v._hashedId === this.videoCode
      }.bind(this))
    }
  }

  get _embedURL() {
    return `${this.baseUrl}${this.accountUrl}${this.videoCode}${this.params}`
  }
}
