<template>
  <div class='section-row'>
    <ul
      :key='row_index'
      class='section-drawer_learning-plan-list-row'
    >
      <li
        v-for='(learning_plan, learning_plan_index) in row'
        :key='learning_plan_index'
        class='learning-plan_list-item'
      >
        <LearningPlanCard
          :learning_plan='learning_plan'
          :is_selected='isSelected(learning_plan)'
          :opened_learning_plan='opened_learning_plan'
          :selectLearningPlan='selectLearningPlan'
        />
      </li>
    </ul>

    <transition name='drawer-fade'>
    <template v-if='!is_mobile && show_drawer'>
      <div
        :key='opened_learning_plan'
        class='section-drawer_drawer'
      >
        <div class='section-drawer_drawer-inner'>
          <template v-if='use_router'>
            <router-view
              :setOpenedDrawer='setOpenedDrawer'
              :setOpenedLearningPlan='setOpenedLearningPlan'
            />
          </template>

          <template v-else>
            <LearningPlanDrawer
              :identifier='opened_learning_plan'
              :setOpenedDrawer='setOpenedDrawer'
              :setOpenedLearningPlan='setOpenedLearningPlan'
            />
          </template>
        </div>
      </div>
    </template>
    </transition>

    <transition
      name="popup-appear"
      mode="out-in"
    >
      <template v-if='is_mobile && show_drawer'>
        <LearningPlanDrawerPopup
          :identifier='opened_learning_plan'
          :setOpenedDrawer='setOpenedDrawer'
          :setOpenedLearningPlan='setOpenedLearningPlan'
        />
      </template>
    </transition>
  </div>
</template>

<script>
import WindowSizeMixin from 'home/components/shared/WindowSizeMixin'
import LearningPlanDrawer from 'home/components/shared/LearningPlanDrawer/LearningPlanDrawer'
import LearningPlanDrawerPopup from 'home/components/shared/LearningPlanDrawer/LearningPlanDrawerPopup'
import LearningPlanCard from 'home/components/shared/LearningPlanCard/LearningPlanCard'

export default {
  mixins: [WindowSizeMixin],

  props: [
    'section', 'row', 'row_index',
    'opened_learning_plan',
    'opened_drawer',
    'setOpenedDrawer',
    'setOpenedLearningPlan'
  ],

  components: {
    LearningPlanCard,
    LearningPlanDrawer,
    LearningPlanDrawerPopup
  },

  mounted() {
    if (this.use_router) {
      this.selectLearningPlan({ identifier: this.$route.params.identifier })

      if (this.$router.options.history.state.back.match(/learning-plans/)) {
        setTimeout(() => {
          this.$scrollTo(this.$el, 400, { offset: -30 })
        }, 700)
      }
    }
  },

  computed: {
    use_router() {
      return (this.$route.name === 'learning-plans' && this._weOwnRoutedLP(this.$route.params.identifier))
    },

    show_drawer() {
      return this.opened_drawer &&
        this.row_has_opened_lp &&
        this.opened_learning_plan !== null
    },

    row_has_opened_lp() {
      return this.row.find((lp) => lp.identifier === this.opened_learning_plan)
    }
  },

  methods: {
    isSelected(learning_plan) {
      return learning_plan && learning_plan.identifier === this.opened_learning_plan
    },

    selectLearningPlan(learning_plan) {
      if (this.$route.name.match(/^learning-plans/)) {
        const q = (this.$route.params.identifier === learning_plan.identifier) ? this.$route.query : {}

        this.$router.push({
          name: 'learning-plans',
          params: {
            identifier: learning_plan.identifier
            // previous_route: this.$route.name
          },
          query: q
        })
      }

      this.setOpenedDrawer(true)
      this.setOpenedLearningPlan(learning_plan.identifier)

      // if (this.$route.params.previous_route !== 'learning-plans') {
      setTimeout(() => {
        this.$scrollTo(this.$el, 400, { offset: -30 })
      }, 200)
      // }
    },

    _weOwnRoutedLP(identifier) {
      return !!this.row.find((lp) => {
        return lp.identifier === identifier
      })
    }

  }

}
</script>

<style lang='sass' scoped>
  @import './LearningPlanSectionRow'
</style>
