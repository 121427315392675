import { createI18n } from 'vue-i18n/index'

function setUpI18n(messages = {}) {
  return createI18n({
    locale:          'en',
    fallbackLocale:  'en',
    messages: messages
  })
}

export { setUpI18n }
