// Tag model based on API::V1::TagSerializer

export default {

  // Relationships
  courses: {
    jsonApi: 'hasMany',
    type: 'courses'
  },

  lessons: {
    jsonApi: 'hasMany',
    type: 'lessons'
  },

  instructors: {
    jsonApi: 'hasMany',
    type: 'instructors'
  },

  program_drivers: {
    jsonApi: 'hasMany',
    type: 'program_drivers'
  },

  resources: {
    jsonApi: 'hasMany',
    type: 'resources'
  },

  // Attributes
  identifier: null,
  name: null
}
