<template>
<section
  v-if='cards && cards.length'
  class='card-slider'
  :class='{ "card-slider--mini" : miniMode }'
  :aria-label="carousel_title"
>
  <p
    v-if='carouselInstructions'
    class='ada-hidden'
  >
    {{ carouselInstructions }}
  </p>

  <button
    v-if="is_navigation_shown && is_prev_navigation_shown"
    class="carousel__prev"
    @click.prevent="slidePrev"
  >
    <span class="ada-hidden">{{ $t('previous') }}</span>

    <!-- eslint-disable -->
    <svg v-if="miniMode" aria-hidden="true" width="40" height="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 30">
      <path class="chevron" d="M12 2L3 15l9 13" stroke="#FFF" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round"/>
    </svg>
    <!-- eslint-enable -->

    <!-- eslint-disable -->
    <svg v-else aria-hidden="true" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(-1 0 0 1 40 .298)" fill="none" fill-rule="evenodd">
        <circle class="circle" fill="#FFF" cx="20" cy="20" r="20"/>
        <path class="arrow" fill="#23203C" d="M20.307 13l-1.559 1.458 4.187 4.475H14v2.134h8.935l-4.187 4.475L20.308 27l6.55-7z"/>
      </g>
    </svg>
    <!-- eslint-enable -->
  </button>

  <carousel
    ref='carousel'
    :itemsToShow='sliderSettings.itemsToShow'
    :itemsToSlide='sliderSettings.itemsToSlide'
    :initialSlide='sliderSettings.initialSlide'
    :mouseDrag='sliderSettings.mouseDrag'
    :touchDrag='sliderSettings.touchDrag'
    :autoplay='sliderSettings.autoplay ? sliderSettings.playSpeed : 0'
    :breakpoints='sliderSettings.breakpoints'
    :class="{ 'is-centered': is_centered, 'is-sliding': is_sliding }"
    :snapAlign="'start'"
    @slide-start='beforeSlideEvent'
    @slide-end='slideEvent'
    @init='initEvent'
  >
    <slide
      v-for="(card, index) in cards"
      :key="index"
      :index="index"
    >
      <template v-if='type !== "courses"'>
      <Card
        :card='card'
        :cardClick='sliderClick'
        :index='index + 1'
        :length='cards.length'
        :miniMode='miniMode'
        :hideType='hideType'
      />
      </template>

      <div
        v-if='type === "courses"'
        class='card-slider_course-card-container'
      >
        <CourseCard
          :course='card.course'
          :selectCourse='sliderClick'
          :is_selected='isSelected(card.course)'
        />
      </div>
    </slide>
  </carousel>

  <button
    v-if="is_navigation_shown && is_next_navigation_shown"
    class="carousel__next"
    @click.prevent="slideNext"
  >
    <span class="ada-hidden">{{ $t('next') }}</span>

    <!-- eslint-disable -->
    <svg v-if="miniMode" aria-hidden="true" width="40" height="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 30">
      <path class="chevron" d="M2 28l9-13L2 2" stroke="#FFF" stroke-width="4" fill="none" fill-rule="evenodd" stroke-linecap="round"/>
    </svg>
    <!-- eslint-enable -->

    <!-- eslint-disable -->
    <svg v-else aria-hidden="true" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(.173 .298)" fill="none" fill-rule="evenodd">
        <circle class="circle"  fill="#FFF" cx="20" cy="20" r="20"/>
        <path class="arrow" fill="#23203C" d="M20.307 13l-1.559 1.458 4.187 4.475H14v2.134h8.935l-4.187 4.475L20.308 27l6.55-7z"/>
      </g>
    </svg>
    <!-- eslint-enable -->
  </button>
</section>
</template>

<script>
import {
  Carousel,
  Slide
} from 'vue3-carousel'

import Card from './CardSliderCard'
import CourseCard from 'home/components/shared/CourseCard/CourseCard'

export default {
  props: [
    'cardClick',
    'cards',
    'autoplay',
    'breakpoints',
    'centerIfLessItems',
    'infinite',
    'instructions',
    'hideNavigation',
    'hideType',
    'miniMode',
    'mouseDrag',
    'speed',
    'title',
    'type',
    'isSelected',
    'slideCallback'
  ],

  data() {
    return {
      windowWidth: window.innerWidth,
      itemsVisible: this.cards.length,
      is_sliding: false,
      carouselInstructions: this.instructions || this.$t('carousel_instructions'),
      sliderSettings: {
        itemsToShow: 1.3,
        itemsToSlide: 1,
        initialSlide: 0,
        mouseDrag: !!this.mouseDrag,
        touchDrag: true,
        autoplay: this.autoplay || false,
        playSpeed: this.speed || 3000,
        breakpoints: this.breakpoints || {
          320: {
            itemsToShow: this.miniMode ? 3 : 1.3
          },
          640: {
            itemsToShow: this.miniMode ? 3 : 2.4
          },
          800: {
            itemsToShow: this.miniMode ? 3 : 3
          },
          1100: {
            itemsToShow: this.miniMode ? 3 : 4
          },
          1360: {
            itemsToShow: this.miniMode ? 3 : 5
          }
        }
      },
      current_slide: null,
      current_items_to_show: null
    }
  },

  components: {
    Carousel,
    Slide,
    Card,
    CourseCard
  },

  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
      this.initEvent()
    }
  },

  computed: {
    carousel_title() {
      return (this.title ? this.title + ' ' : '') + this.$t('carousel')
    },

    is_centered() {
      let result = false
      if (this.centerIfLessItems) {
        result =
          (this.itemsVisible < (this.miniMode ? 3 : 5) && this.windowWidth >= 1360) ||
          (this.itemsVisible < (this.miniMode ? 3 : 4) && this.windowWidth >= 1100) ||
          (this.itemsVisible < 3 && this.windowWidth >= 640)  ||
          (this.itemsVisible < 2)
      }
      return result
    },

    is_navigation_shown() {
      return !this.hideNavigation && !this.is_centered
    },

    is_prev_navigation_shown() {
      return this.current_slide !== 0
    },

    is_next_navigation_shown() {
      return this.itemsVisible - this.current_slide - this.current_items_to_show > 0
    }

  },

  methods: {
    initEvent() {
      if (this.$refs.carousel) {
        this.current_slide = this.$refs.carousel.data.currentSlide
        this.current_items_to_show  = this.$refs.carousel.data.config.itemsToShow
      }
    },

    sliderClick(card, index) {
      if (!this.$refs.carousel.isSliding) {
        this.cardClick(card, this.$refs.carousel, index)
      }
    },

    slideEvent(payload) {
      this.current_slide = this.$refs.carousel.data.currentSlide
      this.current_items_to_show  = this.$refs.carousel.data.config.itemsToShow

      if (typeof (this.slideCallback) === 'function') {
        this.slideCallback(payload)
      }
      setTimeout(() => {
        this.is_sliding = false
      }, 200)
    },

    slidePrev() {
      this.$refs.carousel.prev()
    },

    slideNext() {
      this.$refs.carousel.next()
    },

    beforeSlideEvent() {
      this.is_sliding = true
    }
  }
}
</script>

<style lang='sass'>
  @import './CardSlider'
  @import 'vue3-carousel/dist/carousel.css'
</style>
