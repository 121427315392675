<template>
<VeeForm
  v-slot="{ meta, errors }"
  class='forgot-pass_form'
  @submit='submitForm'
>
  <div class='forgot-pass_field'>
    <Field
      id='user_email'
      v-model='user_email'
      name='user_email'
      type='text'
      :class="{'not-empty': user_email }"
      rules='required|email'
      :validateOnInput="true"
    />

    <label for='user_email'>{{ $t('email') }}</label>

    <span
      v-if='errors.user_email'
      class='form-error'
    >
      Email must be a valid email
    </span>
  </div>

  <div class='forgot-pass_field'>
    <input
      type='submit'
      :value="$t('send_reset_link')"
      class='forgot-pass_submit-button action-one-branded'
      :disabled='!meta.valid'
      @click.prevent='submitForm'
    >
  </div>
</VeeForm>
</template>

<script>
import { Form as VeeForm, Field } from 'vee-validate'
import { defineRule } from 'vee-validate'
import { required, email } from '@vee-validate/rules'
import { sendEmailRequest } from '../../api'


export default {
  props: ['basic_auth'],

  data() {
    return {
      user_email: null
    }
  },

  components: {
    VeeForm,
    Field
  },

  created() {
    // validation rules
    defineRule('required', required);
    defineRule('email', email);
  },

  methods: {
    submitForm() {
      const data = {
        path: this.basic_auth.send_reset_password_instructions_path,
        email: this.user_email
      }

      sendEmailRequest(data, (response) => {
        if (response.data.message) {
          this.$parent.response_message = response.data.message
        } else if (response.data.success) {
          this.$parent.response_message = this.$t('reset_password_email_sent')
        }
      })
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './ForgotPassForm'
</style>
