<template>
<div
  ref='account-overlay'
  :key='context'
  class='account-overlay'
>
  <div
    v-if='context'
    class='account-overlay_inner'
  >
    <button
      ref='close-button'
      class='account-overlay_close'
      @click.prevent='toggleAccountOverlay'
    >
      {{ $t('close') }}
    </button>

    <template v-if='context === "sign-in"'>
      <SignIn
        :toggleAccountOverlay='toggleAccountOverlay'
        :redirect_to_location='redirect_to_location'
      />
    </template>

    <template v-if='context === "sign-up"'>
      <SignUp
        :toggleAccountOverlay='toggleAccountOverlay'
        :redirect_to_location='redirect_to_location'
        :api_url='api_url'
        :subdomain='subdomain'
        :invited_user='invited_user'
      />
    </template>

    <template v-if='context === "forgot-password"'>
      <ForgotPass
        :toggleAccountOverlay='toggleAccountOverlay'
        :redirect_to_location='redirect_to_location'
        :basic_auth='basic_auth'
      />
    </template>

    <template v-if='context === "magic-link-send"'>
      <MagicLinkSend
        :toggleAccountOverlay='toggleAccountOverlay'
        :auth='otp_auth'
        :redirect_to_location='redirect_to_location'
      />
    </template>
  </div>
</div>
</template>

<script>
import OverlayAccessibilityMixin from 'lesson/components/slide/overlay-accessibility-mixin'
import SignIn from './SignIn'
import SignUp from './SignUp'
import ForgotPass from './ForgotPass'
import MagicLinkSend from './MagicLinkSend'

import DictionaryMixin from '../../account/components/dictionary-mixin'

import { useHomeStore } from 'home/stores/home'
import { mapState } from 'pinia'

export default {
  mixins: [OverlayAccessibilityMixin, DictionaryMixin],

  props: {
    toggleAccountOverlay: Function,
    type: String,
    redirect: String,
    authentications: Array,
    invited_user: Object
  },

  data() {
    return {
      context: null,
      overlay_ref_name: 'account-overlay'
    }
  },

  components: {
    SignIn,
    SignUp,
    ForgotPass,
    MagicLinkSend
  },

  created() {
    $('body').addClass('popup-opened')
  },

  mounted() {
    this.detectContext()
    this.closeAction = this.toggleAccountOverlay
  },

  watch: {
    type: function(type) {
      this.detectContext()
    }
  },

  beforeUnmount() {
    $('body').removeClass('popup-opened')
  },

  computed: {
    ...mapState(useHomeStore, [
      'api_url',
      'subdomain'
    ]),

    redirect_to_location() {
      return this.redirect ? this.redirect : '/home'
    },

    basic_auth() {
      return this.authentications.filter((a) => a.auth_type === 'basic')[0]
    },

    otp_auth() {
      return this.authentications.filter((a) => a.auth_type === 'otp')[0]
    },

    oauth_auth() {
      return this.authentications.filter((a) => a.auth_type === 'oauth')[0]
    },

    saml_auth() {
      return this.authentications.filter((a) => a.auth_type === 'saml')[0]
    }
  },

  methods: {
    detectContext() {
      // Auto detect
      if (this.type === 'auto' && (this.basic_auth || this.otp_auth)) {
        this.context = 'sign-up'
        return false
      }

      // Only OTP
      if (this.otp_auth && this.authentications.length === 1) {
        this.context = 'magic-link-send'
        return false
      }

      // Only SAML
      if (this.saml_auth && this.authentications.length === 1) {
        if (this.saml_auth.identity_providers.length === 1) {
          window.location = this.saml_auth.identity_providers[0].target_url
        } else {
          this.context = 'sign-in'
        }
        return false
      }

      // Default
      this.context = this.type === 'auto' ? 'sign-in' : this.type
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './AccountOverlay'
</style>
