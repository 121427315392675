<template>
<div class='account-overlay_magic-link'>
  <template v-if='!response_message'>
    <h1 class='heading-four'>
      {{ $t('magic_link.title') }}
    </h1>

    <p class='account-overlay_magic-link_description copy-two'>
      {{ $t('magic_link.description') }}
    </p>

    <MagicLinkForm
      :auth='auth'
      :redirect_to_location='redirect_to_location'
    />

    <a
      v-if="$parent.authentications.length > 1"
      href='/account/sign-in'
      class='copy-four'
      @click.prevent='toggleAccountOverlay({context: "sign-in"})'
    >
      {{ $t('back_to_log_in') }}
    </a>
  </template>

  <transition name='fast-fade'>
  <div v-if='response_message'>
    <h1 class='heading-four'>
      {{ response_message }}
    </h1>

    <a
      v-if="$parent.authentications.length > 1"
      href='/account/sign-in'
      class='copy-four'
      @click.prevent='toggleAccountOverlay({context: "sign-in"})'
    >
      {{ $t('back_to_log_in') }}
    </a>
  </div>
  </transition>
</div>
</template>

<script>
import MagicLinkForm from '../../account/components/SignInPage/OTPAuth/MagicLinkForm'

export default {
  props: {
    toggleAccountOverlay: Function,
    auth: Object,
    redirect_to_location: String
  },

  components: {
    MagicLinkForm
  },

  data() {
    return {
      response_message: null
    }
  }
}
</script>

<style lang='sass' scoped>
.account-overlay_magic-link_description
  max-width: 70%
  margin: 0 auto 30px

  @media (max-width: 450px)
    max-width: 95%
</style>
