<template>
<a
  :href='driver.link.href'
  class='program-driver-card'
  target='_blank'
>
  <div
    class='program-driver-card_image'
    :style='driverImage'
  />

  <div class='program-driver-card_name'>
    {{ driver.headline }}
  </div>
</a>
</template>

<script>
export default {
  props: ['driver'],

  computed: {
    driverImage() {
      return `background-image: url(${this.driver.image})`
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './ProgramDriverCard'
</style>
