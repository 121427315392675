<template>
<div class='video-trailer'>
  <a
    v-if='!hide_link'
    ref='trailer-link'
    class='video-trailer_link'
    href='#'
    @click.prevent='initTrailer'
  >
    <span class='video-trailer_text heading-six'>
      {{ trailerText }}
    </span>
  </a>

  <sweet-modal
    ref='trailerOverlay'
    class='video-trailer_overlay'
    modal-theme='dark'
    overlay-theme='dark'
    :hide-close-button='true'
    @open='openTrailer'
    @close='closeTrailer'
  >
    <div
      class='video-trailer_restricter'
      tabindex='0'
      @focus='returnToClose'
    />

    <button
      ref='trailer-close'
      class='video-trailer_close'
      @click='$refs.trailerOverlay.close()'
    >
      {{ $t('close') }}
      <CloseIcon />
    </button>

    <h1 class='video-trailer_title'>
      {{ $t('watch_trailer') }}
    </h1>

    <div class='video-trailer_trailer' />

    <div
      class='video-trailer_restricter'
      tabindex='0'
      @focus='returnToClose'
    />
  </sweet-modal>
</div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue-3'
import TbkVideo from 'tbk-utils/video/tbk-video'
import CloseIcon from './close.svg'

export default {
  props: [
    'text',
    'video',
    'hide_link',
    'auto_init',
    'closeCb'
  ],

  data() {
    return {
      videoCode: null,

      video_width: null,
      video_height: null,
      aspect_ratio: null
    }
  },

  components: {
    SweetModal,
    CloseIcon
  },

  watch: {
    video_height: function(val) {
      this._resizeVideoToAspectRatio()
    }
  },

  mounted() {
    if (this.auto_init) this.initTrailer()
  },

  computed: {
    trailerText() {
      return this.text || this.$t('watch_trailer')
    }
  },

  methods: {
    returnToClose() {
      this.$refs['trailer-close'].focus()
    },

    initTrailer() {
      this.getEmbedCode()
      this.$refs.trailerOverlay.open()

      setTimeout(() => {
        this.$refs['trailer-close'].focus()
      }, 1)
    },

    getEmbedCode() {
      const provider = this.video.provider
      const code = this.video.code

      this.vClient = TbkVideo.new(provider, code)

      if (provider === 'wistia') {
        // Autoplay video
        const vid = {}
        vid[code.substr(0, 3)] = function(video) {
          video.play()
        }
        _wq.push(vid)

        $(this.$el).find('.video-trailer_trailer').addClass('video-trailer_trailer--wistia')
      }

      const that = this
      this.vClient.getEmbedCode((code, video) => {
        this.videoCode = code

        // Hack to support vertical videos
        _wq.push({
          id: that.video.code,
          options: {
            videoFoam: true,
            playButton: false
          },
          onReady: function(video) {
            that.video_width = video.data.media.assets[0].width
            that.video_height = video.data.media.assets[0].height

            // make video container visible after video was resized
            video.bind('heightchange', () => {
              $(that.$el).find('.video-trailer_trailer').css({ opacity: '1' })
            })
          }
        })

        $(this.$el).find('.video-trailer_trailer').html(code)
      })
    },

    closeTrailer() {
      this.vClient && this.vClient.pause($(this.$el).find('.video-trailer_trailer')[0])
      this.videoCode = null
      if (this.$refs['trailer-link']) {
        this.$refs['trailer-link'].focus()
      }

      if (typeof (this.closeCb) === 'function') {
        this.closeCb()
      }
    },

    openTrailer() {
      // Accessibility Fixes
      this.$refs.trailerOverlay.$el.setAttribute('role', 'dialog')
      this.$refs.trailerOverlay.$el.setAttribute('aria-modal', 'true')
      this.$refs.trailerOverlay.$el.setAttribute('aria-label', this.$t('watch_trailer'))
    },

    _resizeVideoToAspectRatio() {
      this.aspect_ratio = this.video_height / this.video_width
      let containerHeight = null
      let containerWidth = null

      if (this.aspect_ratio > 0.5625) {
        if ($(window).outerWidth() < 480) {
          // mobile
          containerWidth = $(window).outerWidth() * 0.65 // this number is magic (65% of screen window)
          containerHeight = containerWidth * this.aspect_ratio
        } else {
          // desktop
          containerHeight = $(window).outerHeight() * 0.8 // this number is magic (80% of screen height)
          containerWidth = containerHeight / this.aspect_ratio
        }
      }

      if (containerWidth && containerHeight) {
        $(this.$el).find('.video-trailer_trailer').css({
          'padding-bottom': '0',
          height: containerHeight,
          width: containerWidth
        })
        $(this.$el).find('.sweet-content-content').css({
          'padding-bottom': '0',
          'padding-top': '0',
          height: containerHeight,
          width: containerWidth
        })
        $(this.$el).find('.sweet-modal').addClass('is-vertical')
      } else {
        // make wistia video container visible after video was loaded
        $(this.$el).find('.video-trailer_trailer').css({ opacity: '1' })
      }
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './VideoTrailer'
</style>

<style lang='sass'>
  // Sweet modal overrides
  .video-trailer
    .sweet-modal
      overflow: visible
      width: 127vh
      max-width: 80%
      box-shadow: none

      @media (max-width: 768px)
        max-width: 80%
        position: relative
        margin: 0 auto

      @media (max-width: 450px)
        max-width: 90%

      &.theme-dark,
      &.theme-light
        background: transparent

      &.is-mobile-fullscreen.is-visible
        @media (max-width: 768px)
          margin: 0

        @media (max-width: 450px)
          margin: 50% auto 0

      &.is-mobile-fullscreen.is-visible.is-vertical
        @media (max-width: 450px) and (min-height: 560px)
          margin-top: 15%

        @media (max-width: 450px) and (min-height: 800px)
          margin-top: 35%

    .sweet-content
      /* stylelint-disable annotation-no-unknown */
      padding-left: 0 !important
      padding-right: 0 !important
      padding-top: 0 !important
      padding-bottom: 0 !important
      /* stylelint-enaable annotation-no-unknown */

    .sweet-content-content
      font-size: 0
      height: 0
      margin: 0 auto
      padding-top: 56.125%
      position: relative
      width: 100%

    .video-trailer_trailer
      position: absolute
      width: 100%
      height: 100%
      left: 0
      top: 0

      iframe
        display: block
        position: absolute
        width: 100%
        height: 100%
        left: 0
        top: 0

      &.video-trailer_trailer--wistia
        opacity: 0
        will-change: opacity
        transition: 0.2s ease-in opacity

</style>
