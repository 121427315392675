// model based on API::V1::Activity::V1::JournalSerializer
export default {
  // Images
  background_image: null,
  gradient_start: null,
  gradient_end: null,

  // Attributes
  identifier: null,
  label: null,
  headline: null,
  subheadline: null,
  cta: null,
  overlay_headline: null,
  overlay_instructions: null,
  entry_headline: null
}
