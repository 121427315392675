// model based on API::V1::Activity::V1::QuizSerializer
export default {

  // Images
  background_image: null,
  gradient_start: null,
  gradient_end: null,

  // Attributes
  identifier: null,
  label: null,
  headline: null,
  instructions: null,
  result_message: null,
  secondary_result_message: null,
  randomize: null,
  questions: null
}
