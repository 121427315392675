<template>
<div
  id='overview-page'
  class='overview-page'
>
  <!-- Hero -->
  <div
    ref='hero_block'
    class='overview-page_hero-container'
  >
    <slot name='hero' />
  </div>

  <!-- Main Content -->
  <div
    ref='content_block'
    class='overview-page_content'
  >
    <!-- Left Block Start -->
    <div class='overview-page_content-main'>
      <slot name='content-main' />
    </div>
    <!-- Left Block End -->

    <!-- Right Block Start -->
    <div class='overview-page_content-info'>
      <div
        ref='right_block'
        class='overview-page_content-info_inner'
        :class='right_block_class'
      >
        <slot name='content-info' />
      </div>
    </div>
    <!-- Right Block End -->
  </div>
  <!-- Main Content End -->

  <!-- Bottom Section -->
  <div
    ref='bottom_block'
    class='overview-page_bottom-container'
  >
    <slot name='bottom-section' />
  </div>
  <!-- Bottom Section End -->
</div>
</template>

<script>
export default {
  data() {
    return {
      right_block_sticked_to_top: false,
      right_block_sticked_to_bottom: false
    }
  },

  mounted() {
    setTimeout(() => {
      this.initBadgeScrolling()
    }, 400)
  },

  computed: {
    right_block_class() {
      if (this.right_block_sticked_to_top) return 'sticked-to-top'
      if (this.right_block_sticked_to_bottom) return 'sticked-to-bottom'

      return false
    }
  },

  methods: {
    initBadgeScrolling() {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
      if (isMobile) { return false }

      window.onscroll = () => this.setBadgeStyle()
      window.onresize = () => this.setBadgeStyle()
    },

    setBadgeStyle() {
      if (window.innerWidth < 1024) {
        this.right_block_sticked_to_top = false
        this.right_block_sticked_to_bottom = false
        return false
      }

      const headerHeight = Math.round($('.header').height())
      const heroHeight = Math.round($(this.$refs.hero_block).height())
      const pageHeight = Math.round($('html').height())
      const footerHeight = Math.round($('.footer').height())
      const bottomBLockHeight = Math.round($(this.$refs.bottom_block).height())
      const rightBLockHeight = Math.round($(this.$refs.right_block).height())
      const windowScrollTop = Math.round($(window).scrollTop())
      const padding = 90
      const offset = 20

      if (windowScrollTop > heroHeight + headerHeight + padding - offset) {
        this.right_block_sticked_to_top = true

        if (windowScrollTop > pageHeight - footerHeight - bottomBLockHeight - rightBLockHeight - offset - padding) {
          this.right_block_sticked_to_top = false
          this.right_block_sticked_to_bottom = true
        } else {
          this.right_block_sticked_to_bottom = false
        }
      } else {
        this.right_block_sticked_to_top = false
        this.right_block_sticked_to_bottom = false
      }
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './Page'
</style>
