<template>
  <section
    v-if='should_show_cookie_consent && !is_accepted'
    class='cookies-consent'
    role='dialog'
    aria-modal='false'
    aria-label='Cookies consent'
    aria-describedby='cookies-consent_content'
  >
    <div class='cookies-consent_inner'>
      <div
        id='cookies-consent_content'
        ref='content'
        class='cookies-consent_content copy-two'
        v-html="$t('cookies_consent_message')"
      />

      <button
        class='cookies-consent_cta action-two action-two-branded'
        @click='hideCookiesConsent'
      >
        {{ $t('cookies_consent_ok') }}
      </button>
    </div>

    <PrivacyPopup
      v-if='show_privacy_popup'
      :closeCb='closePrivacyPopup'
      :subdomain='subdomain'
    />
  </section>
</template>

<script>
import PrivacyPopup from './CookiesConsentPrivacyOverlay.vue'

export default {
  props: {
    is_embed: Boolean, // just in case we need to know we are in embeds
    enabled: Boolean,
    subdomain: String
  },

  components: {
    PrivacyPopup
  },

  data() {
    return {
      is_accepted: false,
      show_privacy_popup: false
    }
  },

  mounted() {
    if (this.should_show_cookie_consent) {
      this.$refs.content.querySelector('a').addEventListener('click', this.openPrivacyPopup)
    }
  },

  beforeUnmount() {
    if (this.should_show_cookie_consent) {
      this.$refs.content.querySelector('a').removeEventListener('click', this.openPrivacyPopup)
    }
  },

  computed: {
    should_show_cookie_consent() {
      return this.enabled || false
    }
  },

  methods: {
    hideCookiesConsent() {
      this.is_accepted = true
      // TODO: save cookies consent result somewhere
    },

    openPrivacyPopup(event) {
      event.preventDefault()
      this.show_privacy_popup = true
    },

    closePrivacyPopup() {
      this.show_privacy_popup = false
      this.$refs.content.querySelector('a').focus()
    }
  }
}
</script>

<style lang="sass" scoped>
.cookies-consent
  position: fixed
  bottom: 0
  left: 0
  right: 0
  z-index: 25
  box-shadow: 0 -2px 5px 0 rgba(0,0,0,0.2)
  background: transparentize(#fff,.05)

.cookies-consent_inner
  max-width: 1410px
  padding: 20px 45px
  margin: 0 auto
  display: flex
  gap: 20px
  justify-content: space-between
  align-items: center

  @media (max-width: 960px)
    padding: 20px

.cookies-consent_content
  color: var(--color-secondary-1, #333)

  :deep(a)
    color: inherit
    font-family: inherit

  @media (max-width: 960px)
    font-size: .9em
    line-height: normal

.cookies-consent_cta
  white-space: nowrap

.theme--default,
.theme--base-dark,
.theme--rally,
.theme--usbank,
.theme--walmart
  .cookies-consent_cta
    border-color: var(--color-primary-1, #333)
    color: var(--color-primary-1, #333)
    background: transparent
    &:hover
      background: var(--color-primary-1, #333)
      color: #fff
</style>
