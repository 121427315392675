<template>
  <div
    class='page-loader'
    :class='main_class'
  >
    <div
      v-if='message'
      class='page-loader_message copy-two'
      v-html='message'
    />

    <div class='loader' />
  </div>
</template>

<script>
export default {
  props: ['theme', 'message'],

  computed: {
    main_class() {
      return this.theme ? 'page-loader--' + this.theme : false
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './PageLoader'
</style>
