<template>
  <section
    class='hero-section'
    :class='{ "hero-section--centered" : is_centered_layout }'
  >
    <div
      class='hero-section_bg'
      :style='heroImage'
    >
      <div
        v-if='!!section.content_image'
        class='hero-section_image-container'
      >
        <img
          class='hero-section_content-image'
          :src='section.content_image'
          :alt='section.content_image_alt || ""'
          :aria-hidden="section.content_image_alt ? 'false' : 'true'"
        >
      </div>
    </div>

    <div class='hero-section_inner'>
      <img
        v-if='!!section.content_image'
        class='hero-section_content-image'
        :src='section.content_image'
        :alt='section.content_image_alt || ""'
        :aria-hidden="section.content_image_alt ? 'false' : 'true'"
      >

      <h1 class='hero-section_title heading-one'>
        <template v-if='headline && !has_result'>
          {{ headline }}
        </template>
        <template v-if='has_result && section_has_outro'>
          {{ activity.results.headline }}
        </template>
      </h1>

      <div
        v-if='expert'
        class='hero-section_instructor heading-six'
      >
        <span class='hero-section_instructor-help-text'>
          {{ expert_attribution }}
        </span>

        <router-link
          :to='routableLink(expert.links.overview)'
          class='hero-section_instructor-link'
        >
          {{ expert.full_name }}
        </router-link>
      </div>

      <div class='hero-section_description copy-one'>
        <template v-if="subheadline && !has_result">
          {{ subheadline }}
        </template>

        <template v-if="has_result && section_has_outro">
          {{ activity.results.content }}
        </template>
      </div>

      <!-- Routable Internal Link -->
      <router-link
        v-if="cta_href && isRoutableLink(cta_href)"
        :to='routableLink(cta_href)'
        class='hero-section_action action-one-branded'
      >
        {{ cta_title }}
      </router-link>

      <!-- Generic Link -->
      <a
        v-if="cta_href && !isRoutableLink(cta_href)"
        class="hero-section_action action-one-branded"
        :href="cta_href"
        :target='linkTarget(cta_new_tab)'
        @click='linkClick($event, cta_href)'
      >
        {{ cta_title }}
      </a>

      <!-- Video Trailer Link -->
      <a
        v-if="cta_title && trailer"
        href="#video"
        class="hero-section_action action-one-branded"
        @click.prevent='openVideoTrailer(trailer)'
      >
        {{ cta_title }}
      </a>

      <!-- Start Activity Link -->
      <a
        v-if="cta_title && isActivityLink() && !has_result"
        href="#activity"
        class="hero-section_action action-one-branded"
        @click.prevent='openActivity'
      >
        {{ cta_title }}
      </a>

      <template v-if='isActivityLink() && has_result && section_has_outro'>
        <a
          v-if="hero_outcome && outcome_href"
          :href="outcome_href"
          class="hero-section_action action-one-branded"
          target='_blank'
        >
          {{ activity.results.cta || hero_outcome.outcome.link.title }}
        </a>

        <button
          class="hero-section_action-secondary heading-six"
          @click.prevent='openActivity'
        >
          {{ $t('view_self_assessment_result') }}
        </button>
      </template>
    </div>
  </section>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { useUserResultsStore } from 'home/stores/user-results'
import { mapState, mapActions, mapGetters } from 'pinia'

import ResultsMixin from 'components/activities/v2/SelfAssessment/results-mixin'

export default {
  mixins: [ResultsMixin],

  props: [
    'section',
    'openVideoTrailer',
    'openActivityOverlay'
  ],

  mounted() {
    if (this.slide && this.slide.result) {
      this.setSlideResult({
        slide_id: this.slide.id,
        slide_type: this.slide.type,
        slide_result: this.slide.result
      })
    } else if (this.slide) {
      this._setViewed()
    }

    if (this.slide && this.slide_result.status === 'passed' && Object.keys(this.slide_result.results).length) {
      this._resultsDeepCopy()
    }
  },

  watch: {
    'slide_result.status'(status) {
      if (status === 'passed' && Object.keys(this.slide_result.results).length) {
        this._resultsDeepCopy()
      }
    },

    'slide_result.event'(event) {
      if (event === 'finished' && Object.keys(this.slide_result.results).length) {
        this._resultsDeepCopy()
      }
    }
  },

  methods: {
    ...mapActions(useUserResultsStore, [
      'updateSlideResult',
      'setSlideResult'
    ]),

    ...mapActions(useHomeStore, [
      'toggleAccountOverlay'
    ]),

    routableLink(fullpath) {
      return fullpath ? fullpath.replace(/^\/home/, '') : null
    },

    isRoutableLink(path) {
      return path ? path.match(/^\/home/) : false
    },

    isActivityLink() {
      return this.slide && this.activity
    },

    isSignUpLink(path) {
      return path ? path.match(/^\/account\/sign-up/) : false
    },

    linkTarget(new_tab) {
      return new_tab ? '_blank' : '_self'
    },

    linkClick(event, path) {
      if (this.modalAuthEnabled && this.isSignUpLink(path)) {
        event.preventDefault()
        this.toggleAccountOverlay({
          context: 'sign-up',
          redirect: window.location.href
        })
      }
    },

    openActivity() {
      if (this.activity.type === 'activity_v2_self_assessments') {
        if (this.has_result) {
          dataLayer.push({
            event:        'viewSelfAssessmentResult',
            pageCategory: 'hero-section'
          })
        } else {
          dataLayer.push({
            event:        'startSelfAssessment',
            pageCategory: 'hero-section'
          })
        }
      }
      this.openActivityOverlay(this.slide)
    },

    _setViewed() {
      if (!this.user) return false
      this.updateSlideResult({
        slide_id:    this.slide.id,
        slide_type:  this.slide.type,
        event:       'viewed',
        payload: {}
      })
    },

    _resultsDeepCopy() {
      // deep copy
      this.activity_results = JSON.parse(JSON.stringify(this.slide_result.results.answers))
      this.categories = JSON.parse(JSON.stringify(this.slide_result.results.categories))
    }
  },

  computed: {
    ...mapState(useHomeStore, [
      'user',
      'modalAuthEnabled'
    ]),

    ...mapGetters(useUserResultsStore, [
      'getSlideResult'
    ]),

    heroImage() {
      return `background-image: url('${this.section.image}')`
    },

    associated_object() {
      return this.section.learning_plan || this.section.course || null
    },

    _associated_object_type() {
      return this.associated_object && this.associated_object.type
    },

    expert() {
      switch (this._associated_object_type) {
        case 'courses':
        case 'learning_plans':
          return this.associated_object.instructor
        default:
          return null
      }
    },

    _expert_type() {
      return this.expert && this.expert.type
    },

    expert_attribution() {
      const custom_attribution = this.section.attribution
      switch (this._expert_type) {
        case 'instructors':
          return custom_attribution || this.$t('attribution.with', { value: '' })
        default:
          return null
      }
    },

    cta_title() {
      return this.section.cta.title
    },

    cta_href() {
      return this.section.cta.href || (this.associated_object && this.associated_object.links.overview)
    },

    cta_new_tab() {
      return this.section.cta.new_tab
    },

    is_centered_layout() {
      return this.section.alignment === 'center'
    },

    slide() {
      return this.section.global_slide
    },

    activity() {
      return this.slide.activity
    },

    slide_result() {
      if (!this.slide) return false
      const result = this.getSlideResult(this.slide.id, this.slide.type)

      if (Object.keys(result) === 0) {
        return {
          status: 'started'
        }
      } else return result
    },

    trailer() {
      return this.section.trailer
    },

    section_has_outro() {
      // if section has authored outro
      if (this.activity && this.activity.type === 'activity_v2_self_assessments') {
        return true
      }

      return false
    },

    has_result() {
      // if user already passed activity
      return this.slide &&
        this.slide_result.status === 'passed' &&
        Object.keys(this.slide_result.results).length
    },

    outcome_href() {
      if (this.hero_outcome &&
       this.hero_outcome.outcome &&
       this.hero_outcome.outcome.link.href) {
        return this.hero_outcome.outcome.link.href
      }

      return false
    },

    headline() {
      if (this.section.headline) return this.section.headline

      if (this.activity) return this.activity.headline

      return null
    },

    subheadline() {
      if (this.section.subheadline) return this.section.subheadline

      if (this.activity) {
        return this.activity.instructions || this.activity.subheadline || this.activity.prompt
      }

      return null
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './HeroSection'
</style>
