// Slide model based on API::V1::GlobalSlideSerializer
export default {
  // Relationships
  activity: {
    jsonApi: 'hasOne',
    type: 'activities'
  },

  result: {
    jsonApi: 'hasOne',
    type: 'global_slides'
  },

  // Attributes
  author_description: null,
  identifier: null,
  global_slide_type: null
}
