<template>
<div>
  <aside
    v-if="show_style_switcher"
    class="style-editor"
    :class="{'is-open' : is_open }"
  >
    <button
      class="style-editor_toggle"
      title="Show/hide style editor"
      @click="toggleOpen"
    />

    <div class="style-editor_panel">
      <h2 class="style-editor_heading">
        Style editor
      </h2>

      <div class="style-editor_field">
        <label title='Theme currently used by the style-editor'>
          Current theme:
        </label>

        <select @change="onThemeChange">
          <option
            v-for="theme in THEMES"
            :key="theme"
            :value="theme"
            :selected="theme === current_theme"
          >
            {{ theme }}
          </option>
        </select>
      </div>

      <!-- Theme colors (shown if they exsist in the theme CSS) -->
      <h3 class="style-editor_field-group-title">
        Colors
      </h3>
      <div class="style-editor_field-group">
        <div
          v-if="color_primary_1 !== null"
          class="style-editor_field style-editor_field--color"
        >
          <label>Primary 1:</label>
          <input
            v-model="color_primary_1"
            type="color"
            class="style-editor_color"
          >
          <input
            v-model="color_primary_1"
            type="text"
          >
        </div>

        <div
          v-if="color_primary_2 !== null"
          class="style-editor_field style-editor_field--color"
        >
          <label>Primary 2:</label>
          <input
            v-model="color_primary_2"
            type="color"
            class="style-editor_color"
          >
          <input
            v-model="color_primary_2"
            type="text"
          >
        </div>

        <div
          v-if="color_primary_3 !== null"
          class="style-editor_field style-editor_field--color"
        >
          <label>Primary 3:</label>
          <input
            v-model="color_primary_3"
            type="color"
            class="style-editor_color"
          >
          <input
            v-model="color_primary_3"
            type="text"
          >
        </div>

        <div
          v-if="color_secondary_1 !== null"
          class="style-editor_field style-editor_field--color"
        >
          <label>Secondary 1:</label>
          <input
            v-model="color_secondary_1"
            type="color"
            class="style-editor_color"
          >
          <input
            v-model="color_secondary_1"
            type="text"
          >
        </div>

        <div
          v-if="color_secondary_2 !== null"
          class="style-editor_field style-editor_field--color"
        >
          <label>Secondary 2:</label>
          <input
            v-model="color_secondary_2"
            type="color"
            class="style-editor_color"
          >
          <input
            v-model="color_secondary_2"
            type="text"
          >
        </div>

        <div
          v-if="color_secondary_3 !== null"
          class="style-editor_field style-editor_field--color"
        >
          <label>Secondary 3:</label>
          <input
            v-model="color_secondary_3"
            type="color"
            class="style-editor_color"
          >
          <input
            v-model="color_secondary_3"
            type="text"
          >
        </div>

        <div
          v-if="color_secondary_4 !== null"
          class="style-editor_field style-editor_field--color"
        >
          <label>Secondary 4:</label>
          <input
            v-model="color_secondary_4"
            type="color"
            class="style-editor_color"
          >
          <input
            v-model="color_secondary_4"
            type="text"
          >
        </div>

        <!-- Custom color for active elements (overrides other colors) -->

        <div class="style-editor_field style-editor_field--color">
          <label
            title='This color will override all other theme colors for active elements (buttons, arrows, links etc.) '
          >Key color:</label>
          <input
            v-model="key_color"
            type="color"
            class="style-editor_color"
          >
          <input
            v-model="key_color"
            type="text"
          >
        </div>
      </div>

      <!-- Theme Fonts -->
      <h3 class="style-editor_field-group-title">
        Fonts
      </h3>
      <div class="style-editor_field-group">
        <div class="style-editor_field">
          <label
            for="style-editor_font-family"
            title="CSS 'font-family' property for general texts. Examples: Arial, Helvetica, sans-serif"
          >Font family:</label>
          <input
            id="style-editor_font-family"
            v-model="font_family"
            type="text"
          >
        </div>

        <div class="style-editor_field">
          <label
            for="style-editor_font-url"
            title="URL of .WOFF font file to be applied in general texts. Should be on HTTPS server. Example: https://site.com/font.woff"
          >Font URL:</label>
          <input
            id="style-editor_font-url"
            v-model="font_url"
            type="text"
          >
        </div>

        <div class="style-editor_field">
          <label
            for="style-editor_headings-family"
            title="CSS 'font-family' property for headings. Examples: Arial, Helvetica, sans-serif"
          >Headings:</label>
          <input
            id="style-editor_headings-family"
            v-model="headings_family"
            type="text"
          >
        </div>

        <div class="style-editor_field">
          <label
            for="style-editor_headings-url"
            title="URL of .WOFF font file to be applied in headings. Should be on HTTPS server. Example: https://site.com/font.woff"
          >Headings URL:</label>
          <input
            id="style-editor_headings-url"
            v-model="headings_url"
            type="text"
          >
        </div>

        <div class="style-editor_field">
          <label
            for="style-editor_typekit-id"
            title="Kit ID from typekit.net. Included font name should be used in the above fields 'Font family' and/or 'Headings'."
          >Typekit ID:</label>
          <input
            id="style-editor_typekit-id"
            v-model="typekit_id"
            type="text"
          >
        </div>

        <div class="style-editor_field">
          <label
            for="style-editor_google-fonts-family"
            title="Font families to be included from Google Fonts.  Included font names should be used in the above fields 'Font family' and/or 'Headings'. Example: Open+Sans:wght@400;600. Several families may be included: Open+Sans:wght@400;600&family=Gothic+A1:wght@400;500"
          >Google Fonts:</label>
          <input
            id="style-editor_google-fonts-family"
            v-model="google_fonts_family"
            type="text"
          >
        </div>
      </div>

      <h3 class="style-editor_field-group-title">
        YAML
      </h3>
      <div class="style-editor_field-group">
        <div class="style-editor_field style-editor_field--yaml">
          <textarea
            id="style-editor_yaml"
            readonly
            rows="9"
            v-html="yaml_text"
          />
        </div>
      </div>

      <div class="style-editor_actions">
        <button
          class="action-two action-two-branded"
          title="Copy all YAML code to clipboard"
          @click="copyYaml"
        >
          Copy YAML
        </button>
        <button
          class="action-two"
          title="Reset all fields to saved settings"
          @click="reset"
        >
          Reset
        </button>
        <!-- <button class="action-two" @click="save">Save</button> -->
      </div>
    </div>
  </aside>

  <component
    :is="'style'"
    :key="key_color"
  >
    <template v-if="typekit_id">
      @import url('https://use.typekit.net/{{ typekit_id }}.css');
    </template>

    <template v-if="google_fonts_family">
      @import url('https://fonts.googleapis.com/css2?family={{ google_fonts_family }}&display=swap');
    </template>

    <template v-if="headings_url">
      @font-face {
        font-family: {{ CUSTOM_HEADINGS_NAME }};
        src: url('{{ headings_url }}');
      }
    </template>

    <template v-if="font_url">
      @font-face {
        font-family: {{ CUSTOM_FONT_NAME }};
        src: url('{{ font_url }}');
      }
    </template>

    <template v-if="font_url || font_family">
      .body-one,
      .body-one-light,
      .body-two,
      .body-two-light,
      .body-three,
      .body-three-light,
      .body-four,
      .body-four-light,
      .body-four-branded,
      .body-five,
      .body-five-light,
      .body-six,
      .body-six-light,

      .copy-one,
      .copy-two,
      .copy-three,
      .copy-four,

      .action-one,
      .action-two,

      .link-one,
      .link-one-light,
      .link-one-grey,
      .link-one-gray,
      .link-one-branded,
      .link-two,
      .link-two-light,
      .link-two-grey,
      .link-two-gray,
      .link-two-branded,
      .link-three,
      .link-three-light,
      .link-three-grey,
      .link-three-gray,
      .link-three-branded,

      .button-one,
      .button-one-light,
      .button-one-branded,
      .button-two,
      .button-two-light,
      .button-three,
      .button-three-light,
      .button-three-grey,
      .button-three-blue,
      .button-three-branded,
      .action-one-branded,

      .slide-basic-content_body,

      .main-nav-link,
      .secondary-nav-link,

      form input,
      form label,
      form select,
      form textarea,
      form button,

      .assessment_section-content,
      .assessment-result_message,
      .assessment_question-card_label,
      .assessment_question-card_text,
      .assessment_question-title,
      .assessment_question-options,
      .assessment_question-options label,
      .assessment_submit-error-inner,

      .article-overlay_body p,
      .article-overlay_body a,
      .article-overlay_body ul li,
      .article-overlay_body ol li,

      .lesson-container .slide-article_body a,
      .lesson-container .slide-article_body ol li,
      .lesson-container .slide-article_body p,
      .lesson-container .slide-article_body ul li,
      p, span, a, ul, ol,
      body {
        font-family: {{ CUSTOM_FONT_NAME }} {{ font_family ? ', ' + font_family : '' }} !important;
      }
    </template>

    <template v-if="headings_url || headings_family">
      h1, h2, h3, h4, h5, h6,
      .assessment-result_headline,
      .heading-one,
      .heading-two,
      .heading-three,
      .heading-four,
      .heading-five,
      .heading-six,
      .h-one,
      .h-one-light,
      .h-two,
      .h-two-light,
      .h-three,
      .h-three-light,
      .h-four,
      .h-four-light,
      .h-four-branded,
      .h-five,
      .h-five-light {
        font-family: {{ CUSTOM_HEADINGS_NAME }} {{ headings_family ? ', ' + headings_family : '' }} !important;
        {{ headings_url ? 'font-weight: 400 !important;' : '' }}
      }
    </template>

    <template v-if="key_color">
      /* ======================== Custom background */
        .header .header_mobile-menu,

        .self-assessment_question_options--text .self-assessment_question_options_option:hover,
        .self-assessment_question_options--text .self-assessment_question_options_confirm,
        .self-assessment_question_options--text .self-assessment_question_options_option.selected,
        body:not(.is-ios-device) .self-assessment_question_options--text .self-assessment_question_options_option:hover .self-assessment_question_options_option-circle:after,
        .self-assessment_question_options--text .self-assessment_question_options_option.selected .self-assessment_question_options_option-circle:after,
        .self-assessment_question_options--scale .self-assessment_question_options_option:hover .self-assessment_question_options_option-circle,
        .self-assessment_question_options--scale .self-assessment_question_options_option.selected .self-assessment_question_options_option-circle,
        .self-assessment_question_options--round-button .self-assessment_question_options_option:hover,
        .self-assessment_close-confirm,
        .self-assessment_encouragement,

        .scale-select .scale-select_option.selected .scale-select_option-circle,
        .scale-select .scale-select_option.selected .scale-select_option-circle,
        body:not(.is-ios-device) .scale-select .scale-select_option:not(.selected):hover .scale-select_option-circle,

        .intention-setter_question_options_option:hover,
        .intention-setter_question_options_option.selected,
        body:not(.is-ios-device) .intention-setter_question_options_option:hover .intention-setter_question_options_option-circle:after,
        .intention-setter_question_options_option.selected .intention-setter_question_options_option-circle:after,
        .intention-setter_close-confirm,

        .switch_input:checked + .switch_label:before,
        .text-email-switch_tab.active,
        .text-email-switch_terms input[type="checkbox"]:checked,

        .course-card_current-progress,
        .card_current-progress,
        .course-drawer .course-drawer_overview-current-progress,
        .course-drawer .lesson-card_current-progress,
        .course-drawer .video-trailer_link:hover:before,
        .course-drawer .course-drawer_overview-action--primary,
        .course-drawer .course-drawer_overview-action--secondary:hover,
        .course-drawer .course-drawer_overview-action--secondary:focus,
        .course-card_label,

        .mobile-outline_link.mobile-outline_link--current,
        .user-menu_link:hover,
        .notifications-informer_indicator,

        .instructor-page_connect-link,
        .instructor-lesson-card_current-progress,

        .slide-nav_progress-bar,
        .action-one-branded,
        .action-one:hover,
        .action-two:hover,

        .style-editor_toggle,

        .profile-page_upload-button,
        .learning-plan_progress-line,

        .lesson-container .outline-menu_link:hover,
        .lesson-container .outline-menu_link.outline-menu_link--current,
        .lesson-container .outline-menu_link.router-link-active,
        .lesson-container .outline-menu-lessons_link:hover,
        .lesson-container .outline-menu-lessons_link.current,

        .poll-option:hover .poll-option_circle:after,
        .poll-option:focus .poll-option_circle:after,

        .poll-option.selected .poll-option_progress-bar,
        .poll-option.selected .poll-option_circle,

        .button-one-branded,
        .button-three-branded:hover
          { background-color: {{ key_color }} !important; }

        .intention-setter_results-image {
          background-image: radial-gradient({{ key_color }} 70%, transparent calc(70% + 1px));
        }

      /* ======================== Custom text color */
        .h-four-branded,
        .body-four-branded,
        .button-three-branded,

        .link-one-branded,
        .link-two-branded,
        .link-three-branded,

        .course-drawer .video-trailer_link,
        .course-drawer .video-trailer_text,
        .course-drawer .course-drawer_overview-action--primary:hover,
        .course-drawer .course-drawer_overview-action--secondary,

        .action-one,
        .action-two,
        .action-one-branded:hover,
        .action-two-transparent,

        .resources-app .resource_course,
        .resources_applied-filters_clear,

        .audio-player_info-resources .card_headline,
        .audio-player_play-title,
        .settings-menu_link .current-speed,
        .self-assessment-results_outcome-cta.copy-two,

        .text-email-switch_title,
        .text-email-switch_field input[type="tel"]:focus + label,

        .profile-page_field:focus-within label,
        .sign-in-form_field:focus-within label,
        .sign-up-form_field:focus-within label,
        .forgot-pass_field:focus-within label,
        .notifications-informer_flyout-heading a,
        .notification-item_link,
        .user-menu_link.sign-out,

        .activity-app .learning-plan_achievement-date,
        .activity-app .complete .learning-plan_course-status,

        .poll-option.selected .poll-option_percent,

        .page-loader .loader,
        .lesson-slides_loader-inner,
        .course-app_loader-inner,
        .lesson-app_loader-inner
          { color: {{ key_color }} !important; }

      /* ======================== Custom borders */
        .action-one,
        .action-two,
        .action-one-branded,
        .button-three-branded,

        .main-nav-link.active,
        .main-nav-link:hover,

        .course-drawer .main-nav-link.active,
        .course-drawer .main-nav-link:hover,
        .course-drawer .video-trailer_link:before,
        .course-drawer .course-drawer_overview-action--primary,
        .course-drawer .course-drawer_overview-action--secondary,

        .instructor-page_connect-link,

        .forgot-pass_field input[type="text"]:focus,
        .forgot-pass_field input[type="password"]:focus,
        .forgot-pass_field select:focus,
        .sign-up-form_field input[type="text"]:focus,
        .sign-up-form_field input[type="password"]:focus,
        .sign-up-form_field select:focus,
        .sign-in-form_field input[type="text"]:focus,
        .sign-in-form_field input[type="password"]:focus,
        .sign-in-form_field select:focus,

        .profile-page_field input[type="text"]:focus,
        .profile-page_field input[type="password"]:focus,
        .profile-page_field select:focus,

        .activity-app .learning-plan_course:hover .learning-plan_course-inner,

        .assessment,

        .intention-setter_navigation button:hover,
        .text-email-switch_tab.active,
        .text-email-switch_terms input[type="checkbox"],
        .text-email-switch_field input[type="tel"]:focus:not(.has-error),

        .self-assessment_navigation_next:hover,
        .self-assessment_navigation_back:hover
          { border-color: {{ key_color }} !important; }

        .course-drawer .video-trailer_link:after
          { border-left-color: {{ key_color }} !important; }

        .user-menu_menu li:first-child .user-menu_link:hover:before
          { border-bottom-color: {{ key_color }} !important; }

      /* ======================== Custom fill */
        .expert-card:hover .forward-icon_inner,
        .instructor-page_back-link .back-icon_border + polygon,
        .instructor-page_back-link:hover .back-icon_space,
        .overview-hero_back-link .back-icon_space,
        .overview-hero_back-link:hover .back-icon_arrow,

        .floating-cta_arrow .arrow,
        .floating-cta:hover .floating-cta_arrow .border,
        .floating-cta:focus .floating-cta_arrow .border,

        .card-slider .carousel__prev .circle,
        .card-slider .carousel__next .circle,
        .course-card .course-card_button .arrow
          { fill: {{ key_color }} !important; }

      /* ======================== Custom stroke */
        .outline-menu .checkmark__circle,
        .outline-menu .checkmark__check,
        .resourses-filters .checkmark,
        .instructor-page_back-link .back-icon_border,
        .overview-hero_back-link .back-icon_space,
        .activity-app .learning-plan_course-checkmark .icon,
        .floating-cta_arrow .border,
        .expert-card .forward-icon_inner
          { stroke: {{ key_color }} !important; }

      /* ======================== Inversions */

        .course-drawer .course-drawer_overview-action--primary:hover,
        .action-one,
        .action-one-branded:hover
          { background-color: transparent !important; }

        .action-one:hover,
        .action-one-branded,
        .course-drawer .course-drawer_overview-action--primary,
        .course-drawer .course-drawer_overview-action--secondary:hover,
        .course-drawer .course-drawer_overview-action--secondary:focus,
        .user-menu_link.sign-out:hover,
        .settings-menu_link:hover .current-speed,
        .action-two:hover
          { color: #fff !important; }

        .course-drawer .video-trailer_link:hover:after
          { border-left-color: #fff !important; }

        .card-slider .carousel__prev .arrow,
        .card-slider .carousel__next .arrow,
        .floating-cta:hover .floating-cta_arrow .arrow,
        .overview-hero_back-link:hover .back-icon_space,
        .instructor-page_back-link:hover .back-icon_border + polygon
          { fill: #fff !important; }

      /* ======================== Audio player */
        .audio-player .audio-player_playlist-link,
        .audio-player_volume--muted:after,
        .audio-player .audio-player_settings-current-speed
          { color: {{ key_color }} !important }

        .audio-player .audio-player_info-toggle .icon,
        .audio-player .audio-player_back .icon-copy,
        .audio-player .audio-player_forward .icon-copy,
        .audio-player .audio-player_back .icon-copy,
        .audio-player .audio-player_playlist-toggle .icon,
        .audio-player .audio-player_play .icon-circle,

        .audio-player .playlist-item .audio-player_play .icon-item,
        .audio-player .playlist-item.is-active .icon-circle,
        .audio-player .playlist-item .audio-player_play:hover .icon-circle,

        .audio-player-inline--mini .audio-player_play .icon-item,
        .audio-player-inline--mini .audio-player_play:hover .icon-circle,

        .audio-episode .audio-player_info .icon,
        .audio-episode .audio-episode_play .icon-item,
        .audio-episode.is-active .audio-episode_play .icon-circle,
        .audio-episode:hover .audio-episode_play .icon-circle
          { fill: {{ key_color }} !important }

        .audio-episode_play .icon-circle,
        .audio-episode_play .icon-item,
        .audio-episode .audio-player_info .icon,

        .audio-player .audio-player_play .icon-item,
        .audio-player .audio-player_play .icon-circle,
        .audio-player-inline--mini .audio-player_play .icon-item,
        .audio-player-inline--mini .audio-player_play .icon-circle,

        .audio-player .playlist-item .audio-player_play .icon-item,

        .audio-player .audio-player_volume-button .volume-speaker,
        .audio-player .audio-player_volume-button .volume-level,
        .audio-player .audio-player_back .icon,
        .audio-player .audio-player_forward .icon,
        .audio-player .audio-player_fullscreen-toggle .icon,
        .audio-player .audio-player_playlist-toggle .icon,
        .audio-player .audio-player_info-toggle .icon,
        .audio-player .audio-player_settings-toggle .icon
          { stroke: {{ key_color }} !important }

        .audio-player-inline--mini .audio-player_play:hover .icon-item,
        .audio-player-inline--mini .audio-player_play:focus .icon-item,
        .audio-player-inline--mini .audio-player_play:active .icon-item,

        .audio-episode.is-active .audio-episode_play .icon-item,
        .audio-episode:hover .audio-episode_play .icon-item,

        .audio-player .playlist-item.is-active .audio-player_play .icon-item,
        .audio-player .playlist-item .audio-player_play:hover .icon-item,

        .audio-player .audio-player_play .icon-item
          { stroke: #fff !important; }

        .audio-player-inline--mini .audio-player_play:hover .icon-item,
        .audio-player-inline--mini .audio-player_play:focus .icon-item,
        .audio-player-inline--mini .audio-player_play:active .icon-item,

        .audio-episode.is-active .audio-episode_play .icon-item,
        .audio-episode:hover .audio-episode_play .icon-item,

        .audio-player .playlist-item.is-active .audio-player_play .icon-item,
        .audio-player .playlist-item .audio-player_play:hover .icon-item,

        .audio-player .audio-player_play .icon-item,
        .audio-player .audio-player_play:hover .icon-item,
        .audio-player .audio-player_play:focus .icon-item,
        .audio-player .audio-player_play:active .icon-item
          { fill: #fff !important; }

        .audio-player .playlist-item .icon-circle
          { fill: transparent !important; }

        .audio-player .settings-menu_link:hover,
        .audio-player-inline .vue-slider-process,
        .audio-player .vue-slider-process
          { background-color: {{ key_color }} !important }
    </template>

    <template v-if="has_css_variables && !is_in_reset">
      :root {
        --color-primary-1: {{ color_primary_1 }};
        --color-primary-2: {{ color_primary_2 }};
        --color-primary-3: {{ color_primary_3 }};
        --color-secondary-1: {{ color_secondary_1 }};
        --color-secondary-2: {{ color_secondary_2 }};
        --color-secondary-3: {{ color_secondary_3 }};
        --color-secondary-4: {{ color_secondary_4 }};
      }
    </template>
  </component>
</div>
</template>

<script>
export default {

  data() {
    return {
      key_color:       this.hexExpand(this.$root.configuration.theming_color),
      typekit_id:      this.$root.configuration.theming_typekit_id,
      google_fonts_family: this.$root.configuration.theming_google_fonts_family,
      font_family:     this.$root.configuration.theming_font,
      font_url:        this.$root.configuration.theming_font_url,
      headings_family: this.$root.configuration.theming_headings,
      headings_url:    this.$root.configuration.theming_headings_url,

      color_primary_1:   this.getColor('primary_1'),
      color_primary_2:   this.getColor('primary_2'),
      color_primary_3:   this.getColor('primary_3'),
      color_secondary_1: this.getColor('secondary_1'),
      color_secondary_2: this.getColor('secondary_2'),
      color_secondary_3: this.getColor('secondary_3'),
      color_secondary_4: this.getColor('secondary_4'),

      is_in_reset: false,
      is_open: false,
      was_shown: false,
      THEMES: [
        'base-light',
        'base-medium',
        'base-dark',
        'betterfly',
        'carebridge',
        'covidvax',
        'default',
        'light',
        'medela',
        'mindfull',
        'mobe',
        'nps',
        'optumbank',
        'phs',
        'phs-optum',
        'phs-uhc',
        'rally',
        'ula',
        'usbank',
        'walmart'
      ],
      CUSTOM_FONT_NAME: 'TBKCustomFont',
      CUSTOM_HEADINGS_NAME: 'TBKCustomHeadingsFont'
    }
  },

  mounted() {
    this.initAccordions('style-editor_field-group-title')
  },

  computed: {
    show_style_switcher() {
      return (this.$route && this.$route.query.hasOwnProperty('style'))
    },

    current_theme() {
      // return this.$root.configuration.theme_identifier
      return document.body.className.match(/theme--([\w-]*)\b/)[1]
    },

    yaml_text() {
      return 'theme_identifier: ' + this.current_theme +
      '\ntheming:' +
      (this.key_color       ? '\n  color: "'        + this.key_color + '"' : '') +
      (this.typekit_id      ? '\n  typekit_id: "'   + this.typekit_id + '"' : '') +
      (this.google_fonts_family ? '\n  google_fonts_family: "'   + this.google_fonts_family + '"' : '') +
      (this.font_family     ? '\n  font: "'         + this.font_family + '"' : '') +
      (this.font_url        ? '\n  font_url: "'     + this.font_url + '"' : '') +
      (this.headings_family ? '\n  headings: "'     + this.headings_family + '"' : '') +
      (this.headings_url    ? '\n  headings_url: "' + this.headings_url + '"' : '') +
      (this.color_primary_1    ? '\n  color_primary_1: "' + this.color_primary_1 + '"' : '') +
      (this.color_primary_2    ? '\n  color_primary_2: "' + this.color_primary_2 + '"' : '') +
      (this.color_primary_3    ? '\n  color_primary_3: "' + this.color_primary_3 + '"' : '') +
      (this.color_secondary_1  ? '\n  color_secondary_1: "' + this.color_secondary_1 + '"' : '') +
      (this.color_secondary_2  ? '\n  color_secondary_2: "' + this.color_secondary_2 + '"' : '') +
      (this.color_secondary_3  ? '\n  color_secondary_3: "' + this.color_secondary_3 + '"' : '') +
      (this.color_secondary_4  ? '\n  color_secondary_4: "' + this.color_secondary_4 + '"' : '')
    },

    has_css_variables() {
      return !!(this.color_primary_1 ||
      this.color_primary_2 ||
      this.color_primary_3 ||
      this.color_secondary_1 ||
      this.color_secondary_2 ||
      this.color_secondary_3 ||
      this.color_secondary_4
      )
    }

  },

  methods: {
    toggleOpen() {
      this.is_open = !this.is_open
    },

    reset() {
      this.is_in_reset = true

      this.key_color       = this.$root.configuration.theming_color
      this.typekit_id      = this.$root.configuration.theming_typekit_id
      this.google_fonts_family = this.$root.configuration.theming_google_fonts_family
      this.font_family     = this.$root.configuration.theming_font
      this.font_url        = this.$root.configuration.theming_font_url
      this.headings_family = this.$root.configuration.theming_headings
      this.headings_url    = this.$root.configuration.theming_headings_url

      this.$nextTick(() => {
        this.color_primary_1   = this.getColor('primary_1')
        this.color_primary_2   = this.getColor('primary_2')
        this.color_primary_3   = this.getColor('primary_3')
        this.color_secondary_1 = this.getColor('secondary_1')
        this.color_secondary_2 = this.getColor('secondary_2')
        this.color_secondary_3 = this.getColor('secondary_3')
        this.color_secondary_4 = this.getColor('secondary_4')

        this.is_in_reset = false
      })
    },

    save() {
      alert('Not implemented yet. That would be too easy! =)')
    },

    copyYaml() {
      document.getElementById('style-editor_yaml').select()
      document.execCommand('copy')
    },

    onThemeChange(e) {
      const new_theme = e.target.value
      if (new_theme !== this.current_theme) {
        const new_url = new URL(window.location.href)
        new_url.search = `?theme=${new_theme}&style`
        window.location.href = new_url.href
      }
    },

    getColor(name) {
      if (name) {
        const cssVar = this.getCSSvariable('color-' + name.replace('_', '-'))
        // if CSS variable is defined in theme,
        // then take a value for it from the configuration or grab it directly from CSS
        return cssVar ? this.hexExpand(this.$root.configuration['theming_color_' + name]) || cssVar : null
      }
    },

    getCSSvariable(name = false) {
      if (name) {
        const result = getComputedStyle(document.documentElement).getPropertyValue('--' + name).trim()
        return this.hexExpand(result)
      }
    },

    hexExpand(value) {
      if (typeof value === 'string' && value.length === 4) {
        value = value.replaceAll(/#([0-9a-fA-F])([0-9a-fA-F])([0-9a-fA-F])/g, '#$1$1$2$2$3$3')
      }
      return value
    },

    initAccordions(className) {
      const accordions = document.querySelectorAll('.' + className)
      if (!accordions.length) return
      accordions.forEach(item => {
        item.onclick = () => {
          item.classList.toggle('active')
        }
      })
    }

  }

}
</script>

<style lang='sass' scoped>
  .style-editor
    position: fixed
    right: -300px
    top: 15vh
    width: 300px
    z-index: 99999
    font-family: Arial, sans-serif !important
    font-size: 14px
    transition: right ease .3s

    &.is-open
      right: 0

      .style-editor_panel
        box-shadow: 0 3px 10px rgba(0,0,0,.3)

  .style-editor_heading
    font-family: Arial, sans-serif !important

  .style-editor_toggle
    position: absolute
    left: -50px
    top: 0
    width: 50px
    height: 50px
    line-height: 50px
    text-align: center
    padding: 0
    border: 0
    color: #fff
    background: #666
    border-radius: 6px 0 0 6px
    font-size: 30px
    box-shadow: 0 3px 10px rgba(0,0,0,.3)
    cursor: pointer

    &:before
      // content: '\1F6E0' // hammer and Wrench
      content: '\1F58C' // brush

  .style-editor_panel
    padding: 0 20px 20px
    position: absolute
    top: 0
    left: 0
    width: 100%
    color: #333
    background: #fff
    box-sizing: border-box
    max-height: 80vh
    overflow-y: auto
    border-radius: 0 0 0 6px

  .style-editor_field-group-title
    cursor: pointer
    border-top: 1px solid #eee
    padding: 10px 20px 0
    margin: 10px -20px 0
    position: relative

    &:after
      content: '\276F'
      display: inline-block
      position: absolute
      right: 25px
      top: 18px
      color: #ccc
      transform: translateY(-50%)
      transition: transform ease .3s

    & + .style-editor_field-group
      height: 0
      overflow: hidden
      transition: height ease .3s

    &.active
      &:after
        transform: translateY(-50%) rotate(90deg)

      & + .style-editor_field-group
        height: auto

  .style-editor_field
    border-top: 1px solid #eee
    margin-top: 10px
    padding-top: 10px
    width: 100%
    display: flex
    justify-content: space-between

    label
      width: 45%
      flex-grow: 0
      flex-shrink: 0
      position: relative
      padding-right: 17px

      &[title]:after
        content: '?'
        color: #999
        font-size: 9px
        line-height: 12px
        display: inline-block
        border: 1px solid #999
        border-radius: 50%
        width: 12px
        height: 12px
        text-align: center
        position: absolute
        right: 5px
        top: 3px

    select,
    input[type="text"]
      width: 55%
      border: 0
      margin: -10px 0
      padding: 0 0 0 5px
      font-weight: bold

      &:focus
        background: #eee

  .style-editor_field--color
      input[type="text"]
        width: 30%
        flex-grow: 1

  .style-editor_color
    height: 20px
    width: 40px
    margin-right: 5px
    border: none
    outline: none
    vertical-align: middle
    -webkit-appearance: none

    &::-webkit-color-swatch-wrapper
      padding: 0

    &::-webkit-color-swatch
      border: none

  .style-editor_field--yaml
    border-top: 0
    margin-top: 0

  #style-editor_yaml
    display: block
    width: 100%
    white-space: pre
    overflow: auto
    border: 1px solid #eee

  .style-editor_copy-yaml
    border: 2px solid #eee
    background: #fff
    padding: 0 5px
    margin-top: 12px
    color: #999
    font-size: 40px
    cursor: pointer
    display: block

    &:hover,
    &:active,
    &:focus
      background: #eee
      color: #666

  .style-editor_actions
    border-top: 1px solid #eee
    margin: 10px -20px 0
    padding: 10px 20px 0
    width: 100%

    .theme--base-dark & .action-two
      border-color: var(--color-primary-1, #666)
      color: var(--color-primary-1, #666)
      &:hover
        background: var(--color-primary-1, #666)
        color: #fff

</style>
