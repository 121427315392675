<template>
  <div class='search-header'>
    <template v-if='results'>
      {{ $t('search_results_count', { value: results }) }} {{ keyword }}
    </template>

    <template v-else>
      {{ $t('search_no_results') }}
    </template>
  </div>
</template>

<script>
export default {
  props: ['results', 'keyword']
}
</script>

<style lang='sass' scoped>
  @import './SearchHeader'
</style>
