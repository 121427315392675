<template>
<div class='self-assessment_encouragement self-assessment_encouragement--feedback'>
  <div
    v-if='feedback.image'
    class='self-assessment_encouragement_image'
  >
    <img
      :src='feedback.image'
      alt=''
    >
  </div>

  <div
    class='self-assessment_encouragement_description copy-two'
    v-html='feedback.text'
  />
</div>
</template>

<script>
export default {
  props: [
    'feedback'
  ]
}
</script>

<style lang='sass' scoped>
  @import 'Encouragement'
</style>
