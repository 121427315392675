<template>
  <div
    id="panel-tab-courses"
    aria-labelledby="courses"
    class='course-sections'
    role="tabpanel"
  >
    <template
      v-for='(section, index) in sections'
      :key='index'
    >
      <CourseSection :section='section' />
    </template>
  </div>
</template>

<script>
import WindowSizeMixin from 'home/components/shared/WindowSizeMixin'
import CourseSection from 'home/components/shared/SectionDrawer/CourseSection/CourseSection.vue'

export default {
  mixins: [WindowSizeMixin],

  props: ['sections'],

  components: {
    CourseSection
  }

}
</script>
