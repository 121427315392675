// Instructor Section model based on API::V1::Section::InstructorSerializer
export default {

  // Relationships
  instructors: {
    jsonApi: 'hasMany',
    type: 'instructors'
  },

  // Images
  gradient_start: null,
  gradient_end: null,

  // Attributes
  headline: null,
  subheadline: null,
  link: null
}
