// model based on API::V1::Activity::V1::SelfAssessmentSerializer
export default {

  // Images
  background_image: null,
  gradient_start: null,
  gradient_end: null,

  // Attributes
  identifier: null,
  label: null,
  headline: null,
  instructions: null,
  randomize: null,
  questions: null,
  results: null
}
