// model based on API::V1::Activity::V1::ResourceSerializer
export default {

  // Images
  background_image: null,
  card_image: null,
  carousel_image: null,
  gradient_start: null,
  gradient_end: null,

  // Attributes
  identifier: null,
  label: null,
  headline: null,
  subheadline: null,
  link: null,
  lesson_title: null,
  card_subheadline: null
}
