<template>
  <div class='section-row'>
    <ul
      :key='row_index'
      class='section-drawer_audio-series-list-row'
    >
      <li
        v-for='(audio_series, audio_series_index) in row'
        :key='audio_series_index'
        class='audio-series_list-item'
      >
        <AudioSeriesCard
          :audio_series='audio_series'
          :is_selected='isSelected(audio_series)'
          :opened_audio_series='opened_audio_series'
          :selectAudioSeries='selectAudioSeries'
          :playToggle='playToggle'
        />
      </li>
    </ul>

    <transition name='drawer-fade'>
      <template v-if='!is_mobile && show_drawer'>
        <div
          :key='opened_audio_series'
          class='section-drawer_drawer'
        >
          <div class='section-drawer_drawer-inner'>
            <AudioSeriesDrawer
              :identifier='opened_audio_series'
              :playToggle='playToggle'
              :setOpenedDrawer='setOpenedDrawer'
              :setOpenedAudioSeries='setOpenedAudioSeries'
            />
          </div>
        </div>
      </template>
    </transition>

    <transition
      name="popup-appear"
      mode="out-in"
    >
      <template v-if='is_mobile && show_drawer'>
        <AudioSeriesDrawerPopup
          :identifier='opened_audio_series'
          :playToggle='playToggle'
          :setOpenedDrawer='setOpenedDrawer'
          :setOpenedAudioSeries='setOpenedAudioSeries'
        />
      </template>
    </transition>
  </div>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapState, mapActions } from 'pinia'

import WindowSizeMixin from 'home/components/shared/WindowSizeMixin'
import AudioSeriesDrawer from 'home/components/shared/AudioSeriesDrawer/AudioSeriesDrawer'
import AudioSeriesDrawerPopup from 'home/components/shared/AudioSeriesDrawer/AudioSeriesDrawerPopup'
import AudioSeriesCard from 'home/components/shared/AudioSeriesCard/AudioSeriesCard'

export default {
  mixins: [WindowSizeMixin],

  props: [
    'section', 'row', 'row_index',
    'opened_audio_series',
    'opened_drawer',
    'setOpenedDrawer',
    'setOpenedAudioSeries'
  ],

  components: {
    AudioSeriesCard,
    AudioSeriesDrawer,
    AudioSeriesDrawerPopup
  },

  mounted() {
    if (this.use_router) {
      this.selectAudioSeries({ identifier: this.$route.params.identifier })

      if (this.$router.options.history.state.back.match(/audio-series/)) {
        setTimeout(() => {
          this.$scrollTo(this.$el, 400, { offset: -30 })
        }, 700)
      }
    }
  },

  computed: {
    ...mapState(useHomeStore, [
      'currentAudio',
      'isAudioPlaying'
    ]),

    use_router() {
      return (this.$route.name === 'audio-series' && this._weOwnRoutedAS(this.$route.params.identifier))
    },

    show_drawer() {
      return this.opened_drawer &&
        this.row_has_opened_as &&
        this.opened_audio_series !== null
    },

    row_has_opened_as() {
      return this.row.find((as) => as.identifier === this.opened_audio_series)
    }
  },

  methods: {
    ...mapActions(useHomeStore, [
      'playEpisode',
      'startPlayback',
      'pausePlayback'
    ]),

    isSelected(audio_series) {
      return audio_series && audio_series.identifier === this.opened_audio_series
    },

    selectAudioSeries(audio_series) {
      if (this.$route.name.match(/^audio-series/)) {
        const q = (this.$route.params.identifier === audio_series.identifier) ? this.$route.query : {}

        this.$router.push({
          name: 'audio-series',
          params: {
            identifier: audio_series.identifier
            // previous_route: this.$route.name
          },
          query: q
        })
      }

      this.setOpenedDrawer(true)
      this.setOpenedAudioSeries(audio_series.identifier)

      // if (this.$route.params.previous_route !== 'audio-series') {
      setTimeout(() => {
        this.$scrollTo(this.$el, 400, { offset: -30 })
      }, 200)
      // }
    },

    _weOwnRoutedAS(identifier) {
      return !!this.row.find((as) => {
        return as.identifier === identifier
      })
    },

    playToggle(series, episodeIndex) {
      if (episodeIndex < 0) { // if play/resume button
        if (series.identifier === this.currentAudio.identifier) {
          // if the series has already started
          if (this.isAudioPlaying) {
            this.pausePlayback()
          } else {
            this.startPlayback()
          }
        } else {
          // start the series from the very begining
          this.playEpisode({
            audio: series,
            episode: 0
          })
        }
      } else {
        this.playEpisode({
          audio: series,
          episode: episodeIndex
        })
      }
    }

  }
}
</script>

<style lang='sass' scoped>
  @import './AudioSeriesSectionRow'
</style>
