<template>
<a
  class='self-assessment-results_hero-card'
  :href='card.link'
  target='_blank'
  :style="{backgroundImage: `url('${card.image}')`}"
>
  <div class='self-assessment-results_hero-card_inner'>
    <div
      v-if='card.title'
      class='self-assessment-results_hero-card_title heading-four'
    >
      {{ card.title }}
    </div>

    <div
      v-if='card.subtitle'
      class='self-assessment-results_hero-card_subtitle heading-six'
    >
      <CertIcon
        v-if="card.badging_enabled"
      />
      {{ card.subtitle }}
    </div>
  </div>
</a>
</template>

<script>
import CertIcon from './cert.svg'

export default {
  props: ['card'],

  components: {
    CertIcon
  }
}
</script>

<style lang='sass' scoped>
  @import './HeroCard'
</style>
