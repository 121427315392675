<template>
  <a
    :href="card_link"
    class="course-card"
    @click='authGateway($event)'
  >
    <div
      class="course-card_image"
      :style="lesson_image"
    >
      <div class="course-card_overlay" />

      <span
        v-if="lesson.completion_date"
        class="course-card_completion-mark"
        title="Completed"
      />

      <span
        v-if="!is_enrolled"
        class="course-card_action action-two"
      >
        {{ $t('begin') }}
      </span>

      <span
        v-if="is_enrolled && !lesson.completion_date"
        class="course-card_action action-two"
      >
        {{ $t('resume') }}
      </span>

    </div>

    <div
      v-if="is_enrolled"
      class="course-card_progress-bar"
    >
      <div
        class="course-card_current-progress"
        :style="{ width: '' + progress + '%' }"
      >
        <span class='ada-hidden'>
          Completed: {{ progress }}%
        </span>
      </div>
    </div>

    <div
      v-if='show_numbering'
      class="course-card_position copy-four"
    >
      {{ lesson_position }}
    </div>

    <div class="course-card_title heading-six">
      {{ lesson.title }}
    </div>

    <div class="course-card_description copy-four">
      {{ lesson.overview_intro }}
    </div>
  </a>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapActions } from 'pinia'

export default {
  props: ['learning_plan', 'lesson', 'index'],

  computed: {
    lesson_image() {
      return `background-image: url(${this.lesson.featured_image})`
    },

    lesson_position() {
      return `${this.$tc('learning_plan_lesson', 1)} ${this.index + 1}`
    },

    progress() {
      return this.lesson.progress
    },

    is_enrolled() {
      return this.lesson.enrolled
    },

    card_link() {
      return `/learning-plans/${this.learning_plan.identifier}/lessons/${
        this.lesson.identifier
      }/continue`
    },

    show_numbering() {
      if (this.$root.configuration && this.$root.configuration.hasOwnProperty('learning_plan_drawer_lessons_numbering_visible')) {
        return this.$root.configuration.learning_plan_drawer_lessons_numbering_visible
      } else {
        return true
      }
    }
  },

  methods: {
    ...mapActions(useHomeStore, ['authGateway'])
  }
}
</script>

<style lang='sass' scoped>
  @import './LessonCard'
</style>
