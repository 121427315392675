/* eslint-disable no-unused-vars */
// import Vue from 'vue'
// import VeeValidate, { Validator } from 'vee-validate'
/* eslint-enable no-unused-vars */

// TODO
const DictionaryMixin = {
  // created() {
  //   const dictionary = {
  //     custom: {
  //       messages:{
  //         email: this.$t('email_error_message'),
  //         password: this.$t('password_error_message'),
  //
  //         name: (field) => {
  //           if (field === 'user_first_name') {
  //             return this.$t('your_first_name_is_not_valid')
  //           }
  //
  //           if (field === 'user_last_name') {
  //             return this.$t('your_last_name_is_not_valid')
  //           }
  //         },
  //
  //         required: (field) => {
  //           if (field === 'user_email') {
  //             return this.$t('email_error_message')
  //           }
  //
  //           if (field === 'user_password') {
  //             return this.$t('password_error_message')
  //           }
  //
  //           if (field === 'user_first_name') {
  //             return this.$t('first_name_error_message')
  //           }
  //
  //           if (field === 'user_last_name') {
  //             return this.$t('last_name_error_message')
  //           }
  //
  //           if (field === 'user_zipcode') {
  //             return this.$t('please_enter_your_zip')
  //           }
  //
  //           if (field === 'user_birthday') {
  //             return this.$t('birthday_error_message')
  //           }
  //         },
  //
  //         zipcode: (field) => {
  //           return this.$t('please_enter_correct_zipcode')
  //         },
  //
  //         sex: (field) => {
  //           return this.$t('please_enter_correct_sex')
  //         },
  //
  //         birthday: (field) => {
  //           return this.$t('birthday_error_message')
  //         }
  //       }
  //     }
  //   }
  //
  //   Validator.localize(dictionary)
  //
  //   Validator.localize('custom')
  // }
}

export default DictionaryMixin
