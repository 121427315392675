<template>
<div class='notifications-informer'>
<div class='notifications-informer_inner'>
  <button
    id='notifications-informer_button'
    :aria-expanded="opened ? 'true' : 'false'"
    :class="opened ? 'active' : ''"
    :title="opened ? $t('notifications.close') : $t('notifications.open')"
    aria-controls='notifications-informer_flyout'
    aria-haspopup="true"
    class='notifications-informer_button copy-four'
    @click.prevent='openMenu'
  >
    <div
      v-if='has_unread_notifications'
      aria-hidden="true"
      class='notifications-informer_indicator'
    >
      {{ bell_notifications_count }}
    </div>

    <BellIcon />
  </button>

  <transition name='fast-fade'>
  <div
    v-if='opened'
    id="notifications-informer_flyout"
    aria-labelledby='notifications-informer_button'
    class='notifications-informer_flyout'
  >
    <div
      :class="new_notifications.length || read_notifications.length ? '' : 'notifications-informer_flyout-inner--empty'"
      class='notifications-informer_flyout-inner'
    >
      <!-- Empty List -->
      <template v-if='no_notifications'>
        <div class='notifications-informer_empty'>
          <div class='notifications-informer_empty-image' />
          <div class='heading-four'>
            {{ $t('notifications.nothing_to_see') }}
          </div>

          <div class='copy-four'>
            {{ $t('notifications.nothing_to_see_copy') }}
          </div>
        </div>
      </template>

      <!-- Notification List -->
      <template v-if='show_notifications'>
        <!-- New -->
        <div
          v-if='new_notifications.length'
          class='notifications-informer_flyout-heading copy-four'
        >
          <span>
            {{ $t('notifications.recent') }}
          </span>

          <a
            v-if='unread_notifications_count > 0'
            href='#'
            @click.prevent='markAllAsRead'
          >
            {{ $t('notifications.mark_all_as_read') }}
          </a>
        </div>

        <!-- New List -->
        <ul
          v-if='new_notifications.length'
          class='notifications-informer_list notifications-informer_list--new'
        >
          <li
            v-for='(notification, index) in new_notifications'
            :key='index'
          >
            <Notification
              :clickAction='clickAction'
              :featuredClickAction='featuredClickAction'
              :notification='notification'
            />
          </li>
        </ul>

        <!-- Earlier -->
        <div
          v-if='read_notifications.length'
          class='notifications-informer_flyout-heading copy-four'
        >
          <span>{{ $t('notifications.earlier') }}</span>
        </div>

        <!-- Earlier List -->
        <ul
          v-if='read_notifications.length'
          class='notifications-informer_list'
        >
          <li
            v-for='(notification, index) in read_notifications'
            :key='index'
          >
            <Notification
              :clickAction='clickAction'
              :featuredClickAction='featuredClickAction'
              :notification='notification'
            />
          </li>
        </ul>
      </template>

      <infinite-loading
        v-if='show_infinite_scroller'
        class='notifications-informer_flyout-footer'
        @infinite="loadNotifications"
      >
        <template #spinner>
          <PageLoader />
        </template>

        <template #no-more>
          <div class='copy-four'>
            {{ $t('notifications.no_more') }}
          </div>
        </template>

        <template #error>
          <div class='copy-four'>
            Oops, something went wrong :(
          </div>
        </template>
      </infinite-loading>
    </div>
  </div>
  </transition>
</div>
</div>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapActions, mapState } from 'pinia'

import Notification from 'components/Notifications/NotificationsInformerNotification'
import PageLoader from 'components/PageLoader/PageLoader'
import BellIcon from './bell.svg'

import InfiniteLoading from 'v3-infinite-loading'
import 'v3-infinite-loading/lib/style.css'

import {
  getNotifications,
  markAllNotificationsAsRead,
  markNotificationAsRead
} from 'api/index'

export default {
  components: {
    PageLoader,
    Notification,
    BellIcon,
    InfiniteLoading
  },

  data() {
    return {
      notifications: [],
      new_notifications: [],
      notifications_loaded: false,

      unread_notifications: 0,
      should_update_data: true,

      opened: false,
      page: 1,
      total_pages: 0
    }
  },

  mounted() {
    this._subscribeToUpdates()
  },

  watch: {
    unread_notifications(value) {
      if (this.unread_notifications_count !== null &&
        this.unread_notifications_count < value) {
        // init data update
        this.updateNotifications()
      }
    }
  },

  computed: {
    ...mapState(useHomeStore, [
      'jwt'
    ]),

    has_unread_notifications() {
      return this.unread_notifications > 0
    },

    show_notifications() {
      return this.notifications_loaded &&
        (this.new_notifications.length || this.read_notifications.length)
    },

    no_notifications() {
      return this.notifications_loaded &&
        (!this.new_notifications.length && !this.read_notifications.length)
    },

    show_infinite_scroller() {
      const value =  !this.notifications_loaded ||
        (this.notifications_loaded && (this.notifications.length === 0 && this.new_notifications.length === 0)) ||
        (!(this.total_pages === 1 && this.page === 1) && this.total_pages >= this.page)
      return value
    },

    read_notifications() {
      return this.notifications.filter((notification) => notification.read === true)
    },

    bell_notifications_count() {
      return this.unread_notifications
    },

    unread_notifications_count() {
      if (this.notifications_loaded) {
        const filteredNotifications = this.new_notifications.filter((notification) => notification.read === false)
        return filteredNotifications.length
      }
      return null
    }
  },

  methods: {
    ...mapActions(useHomeStore, [
      'toggleNotificationActivityOverlay'
    ]),

    loadNotifications($loadingState) {
      // do not make additional request if no notifications
      if (this.notifications.length === 0 && !this.should_update_data) {
        $loadingState.complete()
        return false
      }

      getNotifications(
        this.page,
        (data, meta) => { // success
          this.notifications_loaded = true
          this.should_update_data = false
          this.total_pages = meta.total_pages

          if (data.length) {
            this.page += 1
            this._filterNotifications(data)
            $loadingState.loaded()
          } else {
            $loadingState.complete()
          }
        },
        (error) => { // error
          console.log('Notifications: error!', error)
          $loadingState.error()
        }
      )
    },

    updateNotifications() {
      getNotifications(
        1, // first page
        (data, meta) => { // success
          this.should_update_data = false
          this.total_pages = meta.total_pages

          if (data.length) {
            this._filterUpdatedNotifications(data)
          }
        }
      )
    },

    openMenu() {
      this.opened = !this.opened

      if (this.should_update_data) {
        this._cleanupNotificationsBeforeUpdate()
      }

      if (this.opened) {
        document.body.addEventListener('click', this.menuListener)
      } else {
        document.body.removeEventListener('click', this.menuListener)
      }
    },

    menuListener(e) {
      const menuRegex = /notifications-informer/g
      const overlayRegex = /notifications-informer/g
      if (menuRegex.test(e.target.className) || $(e.target).parents('.notifications-informer').length ||
        $(e.target).parents('.interactive-section_overlay').length ||
        overlayRegex.test(e.target.className)
      ) {
        return false
      }

      this.opened = false
      document.body.removeEventListener('click', this.menuListener)
    },

    clickAction(notification) {
      if (notification.read === false) {
        this.should_update_data = true

        markNotificationAsRead(
          notification,
          () => { // success
            notification.read = true
          },
          (error) => { // error
            console.log('Notifications: error!', error)
          }
        )
      }

      this.sendAnalyticsEvent(notification)

      if (notification.target.type === 'activity') {
        this.toggleNotificationActivityOverlay({
          topic_id: notification.target.link.topic_id
        })
      }
    },

    featuredClickAction(url) {
      const path = url.replace(/^\/home/, '')

      if (this.$router.currentRoute.value.name !== 'courses') {
        this.$router.push(path)
      } else {
        this.$router.push('/')
        setTimeout(() => {
          this.$router.push(path)
        }, 100)
      }

      this.opened = false
      document.body.removeEventListener('click', this.menuListener)

      this.sendAnalyticsEvent(null, url)
    },

    sendAnalyticsEvent(notification, url) {
      if (!notification && !url) return

      const gtmEvent = {
        event: 'notificationClick'
      }

      if (notification) {
        gtmEvent.notificationType =
          (notification.target.type === 'activity')
            ? notification.target.link.activity_type.replace(/activity_v1_/, '')
            : notification.target.type

        if (gtmEvent.notificationType === 'discussion') {
          gtmEvent.notificationTopicId = notification.target.link.topic_id
        }
      } else if (url) {
        gtmEvent.notificationType = 'link'
        gtmEvent.notificationUrl =  url
      }

      dataLayer.push(gtmEvent)
    },

    markAllAsRead() {
      this.should_update_data = true

      markAllNotificationsAsRead(
        () => { // success
          this.new_notifications.map((n) => (n.read = true))
          this.unread_notifications = 0
        },
        (error) => { // error
          console.log('Notifications: error!', error)
        }
      )
    },

    _subscribeToUpdates() {
      const cable = require('@rails/actioncable')
      // url is pulled from meta data (see app/views/layouts/application.html.slim action_cable_meta_tag)
      const websocketURL = cable.getConfig('url')
      if (!websocketURL) return

      const that = this
      // token param is required for user authentication
      const consumer = cable.createConsumer(`${websocketURL}?token=${this.jwt}`)

      consumer.subscriptions.create('NotificationsChannel', {
        connected: function() { },
        disconnected: function() { },
        received: function(data) {
          that.unread_notifications = data.unread_notifications
          that.should_update_data = true
        }
      })
    },

    _cleanupNotificationsBeforeUpdate() {
      this.notifications_loaded = false
      this.notifications = []
      this.new_notifications = []
      this.page = 1
      this.total_pages = 0
    },

    _filterNotifications(data) {
      const array = JSON.parse(JSON.stringify(data))
      array.forEach((n) => {
        if (n.read === false) this.new_notifications.push(n)
      })
      this.notifications.push(...data)
    },

    _filterUpdatedNotifications(data) {
      const array = JSON.parse(JSON.stringify(data))
      const existing_ids = []
      this.new_notifications.forEach((n) => {
        existing_ids.push(n.id)
      })

      array.forEach((n) => {
        if (n.read === false && existing_ids.indexOf(n.id) === -1) {
          this.new_notifications.unshift(n)
        }
      })
    }
  }
}
</script>

<style lang='sass' scoped>
@import 'NotificationsInformer'
</style>
