// Instructor model based on API::V1::InstructorSerializer
export default {

  // Relationships
  academy: {
    jsonApi: 'hasOne',
    type: 'academies'
  },

  lessons: {
    jsonApi: 'hasMany',
    type: 'lessons'
  },

  tags: {
    jsonApi: 'hasMany',
    type: 'tags'
  },

  audio_series: {
    jsonApi: 'hasMany',
    type: 'audio_series'
  },

  learning_plans: {
    jsonApi: 'hasMany',
    type: 'learning_plans'
  },

  // Images
  image: null,
  featured_image: null,
  detail_image: null,

  // Attributes
  identifier: null,
  first_name: null,
  last_name: null,
  full_name: null,
  title: null,
  bio: null,
  interview_headline: null,
  interview_content: null,
  share_headline: null,
  linkedin_link: null,
  facebook_link: null,
  twitter_link: null,
  instagram_link: null,
  website_link: null,
  trailer: null
}
