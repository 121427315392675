// Lesson Section model based on API::V1::Section::LessonSerializer
export default {

  // Relationships
  learning_plans: {
    jsonApi: 'hasMany',
    type: 'learning_plans'
  },

  // Images
  gradient_start: null,
  gradient_end: null,

  // Attributes
  headline: null,
  subheadline: null,
  link: null,
  position: 0
}
