<template>
<div
  class='self-assessment_question_options self-assessment_question_options--round-card'
  role="list"
>
  <div
    v-for='(option, index) in options'
    :key='index'
    class="self-assessment_question_options_option-wrapper"
    role="listitem"
  >
    <button
      class='self-assessment_question_options_option'
      :class="{'selected' : isOptionSelected(option)}"
      :style="{backgroundImage: 'url(' + option.option_image + ')'}"
      @click.prevent='selectOption(option)'
    >
      <span class='self-assessment_question_options_option-text copy-four'>
        {{ option.text }}
      </span>
    </button>
  </div>
</div>
</template>

<script>
export default {
  props: [
    'options',
    'selectOption',
    'isOptionSelected'
  ]
}
</script>

<style lang='sass' scoped>
  @import 'RoundCard'
</style>
