<template>
  <a
    class="learning-plan-lesson"
    :href="lesson_link"
    @click='authGateway($event)'
  >
    <div
      class="learning-plan-lesson_image"
      :style="lesson_image"
    >
      <div
        v-if="is_enrolled"
        class="learning-plan-lesson_overlay"
        :class="{ completed: lesson.completion_date }"
      />

      <span
        v-if="!is_enrolled"
        class="resume action-two"
      >
        {{ $t('begin') }}
      </span>

      <span
        v-else-if="is_enrolled && !lesson.completion_date"
        class="resume action-two"
      >
        {{ $t('resume') }}
      </span>

      <span
        v-if='lesson.completion_date'
        class='learning-plan-lesson_completion-mark'
        title='Completed'
      />
    </div>

    <div class="learning-plan-lesson_subtitle copy-four">
      {{ lesson_position }}
    </div>

    <div class="learning-plan-lesson_title heading-five">
      {{ lesson.title }}
    </div>
  </a>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapActions } from 'pinia'

export default {
  props: ['lesson', 'index', 'learning_plan'],

  computed: {
    lesson_position() {
      return `${this.$tc('learning_plan_lesson', 1)} ${this.index + 1}`
    },

    lesson_image() {
      return `background-image: url(${this.lesson.featured_image})`
    },

    is_enrolled() {
      return this.lesson.enrolled
    },

    lesson_link() {
      return `/learning-plans/${
        this.learning_plan.identifier
      }/lessons/${this.lesson.identifier}/continue`
    }
  },

  methods: {
    ...mapActions(useHomeStore, ['authGateway'])
  }
}
</script>

<style lang='sass' scoped>
  @import './Lesson'
</style>
