import apiClient from 'api'
import BaseLearningPlan from 'api/models/learning_plan'
import { useHomeStore } from 'home/stores/home'

const LearningPlan = class {
  _home_store
  _object
  _identifier

  constructor(identifier) {
    this._home_store = useHomeStore()
    this._identifier = identifier
  }

  static load(identifier, callback = null, update = false) {
    const learning_plan = new LearningPlan(identifier)
    learning_plan._fetch(callback, update)
    return learning_plan
  }

  _fetch(callback, update) {
    if (this._home_store.learning_plans[this._identifier] && this._home_store.learning_plans[this._identifier].fully_loaded && !update) {
      this._object = this._home_store.learning_plans[this._identifier]
      if (callback) callback()
    } else {
      apiClient.find('learning_plans', this._identifier, { include: 'overview_page,instructor,lessons,instructors' })
        .then(({ data }) => {
          data.fully_loaded = true
          this._home_store.setLearningPlan(data)
          this._object = data
          if (callback) callback()
        })
    }
  }
}

Object.keys(BaseLearningPlan).forEach((key) => {
  // const prop = BaseLearningPlan[key]
  if (!LearningPlan.prototype.hasOwnProperty(key)) {
    Object.defineProperty(LearningPlan.prototype, key, {
      get: function() {
        return this._object[key]
      }
    })
  }
})

export default LearningPlan
