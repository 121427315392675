// Slide model based on API::V1::SlideSerializer
export default {

  // Relationships
  activity: {
    jsonApi: 'hasOne',
    type: 'activities'
  },

  resources: {
    jsonApi: 'hasMany',
    type: 'activities'
  },

  part: {
    jsonApi: 'hasOne',
    type: 'part'
  },

  // Attributes
  author_description: null,
  identifier: null,
  stylesheet: null,
  resource_carousel_headline_visible: null
}
