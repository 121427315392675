import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { setUpI18n } from 'tbk-utils/i18n'
import _ from 'lodash' // eslint-disable-line no-unused-vars

import VueScrollTo from 'vue-scrollto'

// pinia stores
import { createPinia} from 'pinia'

import SweetModal from 'sweet-modal-vue-3'

import HomePageApp from './components/app.vue'
import HomeBase from './components/Home/app.vue'

import CoursesPage from './components/Courses/CoursesPage/CoursesPage.vue'

import InstructorsBase from './components/Instructors/InstructorsApp.vue'
import InstructorsPage from './components/Instructors/InstructorsPage/InstructorsPage.vue'
import InstructorPage from './components/Instructors/InstructorPage/InstructorPage.vue'

import LearningPlansBase from './components/LearningPlans/LearningPlansApp.vue'
import LearningPlansPage from './components/LearningPlans/LearningPlansPage.vue'
import LearningPlanPage from './components/LearningPlans/LearningPlanPage.vue'
import LearningPlanDrawer from './components/shared/LearningPlanDrawer/LearningPlanDrawer'

import AudiosBase from './components/Audios/AudiosApp.vue'
import AudiosPage from './components/Audios/AudiosPage.vue'
import AudioPage from './components/Audios/AudioPage.vue'
import AudioSeriesDrawer from './components/shared/AudioSeriesDrawer/AudioSeriesDrawer'

import ActivityBase from './components/Activity/ActivityApp.vue'
import ProfileBase from './components/Profile/ProfileApp.vue'
import ResourcesBase from './components/Resources/ResourcesApp.vue'
import SearchBase from './components/Search/SearchApp.vue'
import NotFound from './components/NotFound.vue'
import TextPage from './components/TextPage/TextPageApp.vue'
import Styleguide from './components/Styleguide/StyleguideApp.vue'

const routes = [
  { path: '/', component: HomeBase, name: 'home' },
  { path: '/activity', component: ActivityBase, name: 'my_activity' },
  { path: '/profile', component: ProfileBase, name: 'profile' },
  { path: '/resources', component: ResourcesBase, name: 'resources' },
  { path: '/search', component: SearchBase, name: 'search' },
  { path: '/styleguide', component: Styleguide, name: 'styleguide' },
  {
    path: '/instructors',
    component: InstructorsBase,
    children: [
      { path: '', component: InstructorsPage, name: 'instructors' },
      { path: ':identifier', component: InstructorPage, name: 'instructor', props: true }
    ]
  },
  { path: '/courses/:identifier?', component: CoursesPage, name: 'courses', props: true },

  {
    path: '/learning-plans',
    component: LearningPlansBase,
    children: [
      {
        path: '',
        component: LearningPlansPage,
        // name: 'learning-plans',
        children: [
          {
            path: ':identifier?',
            component: LearningPlanDrawer,
            name: 'learning-plans',
            props: true
          }
        ]
      },
      { path: ':identifier/overview', component: LearningPlanPage, name: 'learning-plan-overview', props: true }
    ]
  },

  {
    path: '/audio-series',
    component: AudiosBase,
    children: [
      {
        path: '',
        component: AudiosPage,
        // name: 'audio-series',
        children: [
          {
            path: ':identifier?',
            component: AudioSeriesDrawer,
            name: 'audio-series',
            props: true
          }
        ]
      },
      { path: ':identifier/overview', component: AudioPage, name: 'audio-overview', props: true }
    ]
  },

  { path: '/:pathMatch(.*)*', component: NotFound, name: 'not_found' }
]

const configurable_routes = [
  {
    configuration_key: 'show_terms_of_use',
    route: { path: '/terms', component: TextPage, name: 'terms_of_use' }
  },
  {
    configuration_key: 'show_privacy_policy',
    route: { path: '/privacy', component: TextPage, name: 'privacy_policy' }
  },
  {
    configuration_key: 'show_help',
    route: { path: '/help', component: TextPage, name: 'help' }
  }
]

const _initRouter = (basePath = '/home') => {
  return createRouter({
    history: createWebHistory(basePath),
    routes,
    scrollBehavior(to, from, savedPosition) {
      // secret undocumented teqhnique to support scroll to hashed/anchored links
      let position = {}
      if (to.hash) {
        position = {
          selector: to.hash,
          offset: { left: 0, top: 20 },
          behavior: 'smooth'
        }
      }
      if (Object.keys(position)) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(position)
          }, 50)
        })
      }
    }
  })
}

const _configureRoutes = (configuration) => {
  configurable_routes.forEach((config) => {
    if (configuration.hasOwnProperty(config.configuration_key) && configuration[config.configuration_key]) {
      routes.push(config.route)
    }
  })
}

window.initHome = function(options) {
  // setup i18n
  const i18n = setUpI18n(options.translations)
  if (options.translations) {
    delete options.translations
  }

  // setup router
  _configureRoutes(options.configuration)
  const router = _initRouter(options.base_path)

  // Vue3 init
  const pinia = createPinia()
  const app = createApp(HomePageApp)

  // register plugins
  app.use(router)
  app.use(pinia)
  app.use(i18n)
  app.use(SweetModal)
  app.use(VueScrollTo)

  // provide options
  app.provide('init_options', options)
  app.provide('configuration', options.configuration)

  // finally mount the app
  app.mount(options.bind_element)
}
