// User model based on API::V1::UserSerializer
export default {
  // Images
  avatar: null,
  external_avatar: null,

  // Attributes
  subdomain: null,
  identifier: null,
  email: null,
  first_name: null,
  last_name: null,
  sex: null,
  username: null,
  phone: null,
  organization: null,
  zip: null,
  birthday: null,
  preferred_locale: null,
  current_password: null,
  password: null,
  password_confirmation: null,
  company_code: null,
  sso_only: false,
  password_changeable: false,
  mobile_number: null,
  sms_enabled: false,
  sms_subscribed: false
}
