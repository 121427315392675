<template>
  <div class="course-drawer-popup">
    <a
      href='#'
      class='course-drawer-popup_close'
      :title="$t('close')"
      @click.prevent='close'
    >
      <CloseIcon />
    </a>

    <div class='course-card course-card--audio-series active'>
      <div
        class='course-card_image'
        :style='audio_series_image'
      />

      <div class='course-card_inner'>
        <div class='course-card_inner-wrapper'>
          <div class='course-card_title heading-five'>
            {{ series.title }}
          </div>

          <div
            v-if='series.instructor'
            class='course-card_attribution copy-three'
          >
            {{ $t('attribution.hosted_by', { value: series.instructor.full_name }) }}
          </div>
        </div>
      </div>
    </div>

    <Overview
      :audio_series='series'
      is_mobile
      :playToggle='playToggle'
    />

    <div class='course-drawer-popup_content'>
      <div class='course-drawer-popup_nav'>
        <a
          href='#'
          :class="tabClass('episodes')"
          class='main-nav-link'
          @click.prevent="changeTab('episodes')"
        >
          {{ $t('audio_overview.episodes') }}
        </a>
      </div>

      <div
        v-if="activeTab === 'episodes'"
        :key="'episodes'"
        class='course-drawer-popup_list'
      >
        <Episode
          v-for="(episode, index) in series.episodes"
          :key="index"
          :audio_series="series"
          :episode='episode'
          :index="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Overview from './AudioSeriesDrawerOverview'
import Episode from './AudioSeriesDrawerEpisode'
import CloseIcon from './close.svg'

import { useHomeStore } from 'home/stores/home'
import { mapState } from 'pinia'

export default {
  props: {
    identifier: { type: [Number, String], required: true },
    playToggle: { type: Function, required: true },
    setOpenedDrawer: { type: Function, required: true },
    setOpenedAudioSeries: { type: Function, required: true }
  },

  components: {
    Overview,
    Episode,
    CloseIcon
  },

  data() {
    return {
      loading: false,
      activeTab: 'episodes'
    }
  },

  created() {
    $('body').addClass('popup-opened')
  },

  beforeUnmount() {
    $('body').removeClass('popup-opened')
  },

  computed: {
    ...mapState(useHomeStore, ['audio_series']),

    series() {
      return this.audio_series[this.identifier]
    },

    audio_series_image() {
      return `background-image: url(${this.series.card_image})`
    }
  },

  methods: {
    close() {
      this.setOpenedDrawer(false)
      this.setOpenedAudioSeries(null)

      $('body').removeClass('popup-opened')
    },

    tabClass(tab) {
      return tab === this.activeTab ? 'active' : ''
    },

    changeTab(tab) {
      this.activeTab = tab
    }
  }
}
</script>

<style lang='sass' scoped>
  @import 'home/styles/shared/CourseCard'
</style>

<style lang='sass' scoped>
  @import 'home/styles/shared/CourseDrawerPopup'
</style>
