<template>
<div
  :id='comment.id'
  class='discussion-comment'
  :class='main_class'
>
<template v-if='!is_editing'>
  <template v-if='!comment.deleted'>
    <div class='discussion-comment_commenter'>
      <Avatar
        :className="'discussion-comment_commenter-avatar'"
        :image='avatar_url'
      />

      <div class='discussion-comment_commenter-name copy-three'>
        {{ comment.user.username }}
      </div>

      <template v-if='parent_reply_name'>
        <a
          :href='parent_reply_link'
          class='discussion-comment_commenter-parent-link copy-three'
          @click.prevent='goToParentReply'
        >
          <span class='discussion-comment_commenter-replied-to'>{{ $t('replied_to') }}</span>
          {{ parent_reply_name }}
        </a>
      </template>
    </div>

    <div class='discussion-comment_date copy-four'>
      <span class='copy-four'>{{ comment_date_value }}</span>
    </div>

    <!-- Attachments -->
    <div
      v-if='comment.files'
      class='discussion-comment_files'
    >
      <div
        v-for='file in comment.files'
        :key='file.id'
        class='discussion-comment_file-container'
      >
        <!-- Images -->
        <template v-if='isImage(file) && file.content_type'>
          <div
            class='discussion-comment_image'
            :data-id='file.id'
            :style="{backgroundImage: `url('${file.thumbnail}')`}"
          >
            <div class='discussion-comment_image-actions'>
              <a
                class='discussion-comment_image-actions_zoom'
                href='#zoom'
                :title='$t("zoom")'
                @click.prevent='zoomImage(file.original)'
              >
                <ZoomIcon />
              </a>
            </div>
          </div>
        </template>

        <!-- Files -->
        <template v-if='!isImage(file) && file.content_type'>
          <a
            :href='file.original'
            target='_blank'
            class='discussion-comment_file'
          >
            <span class='discussion-comment_file-icon'><FileIcon /></span>
            <span class='discussion-comment_file-name'>{{ file.file_name }}</span>
          </a>
        </template>
      </div>
    </div>

    <!-- Comment -->
    <div
      class='discussion-comment_content'
      v-html='comment.formatted_content'
    />
  </template>

  <transition name='fast-fade'>
    <template v-if='comment.deleted'>
      <div class='discussion-comment_content-deleted'>
        {{ $t('deleted_post') }}
      </div>
    </template>
  </transition>

  <button
    v-if='replies_text'
    class='discussion-comment_replies-count copy-three'
    @click.prevent='toggleReplies'
  >
    {{ replies_text }}
  </button>

  <ul
    v-if='!comment.deleted'
    class='discussion-comment_actions'
  >
    <li class='discussion-comment_action-item'>
      <button
        class='discussion-comment_button discussion-comment_like'
        :class="{'is-liked': comment.liked}"
        @click.prevent='like'
      >
        <LikeIcon />
        <span>{{ $t('like') }}</span>
        <span
          v-if='comment.likes_amount > 0'
          class='discussion-comment_like-count'
        >
          {{ comment.likes_amount }}
        </span>
      </button>
    </li>

    <li class='discussion-comment_action-item'>
      <button
        class='discussion-comment_button discussion-comment_reply'
        @click.prevent='reply'
      >
        <ReplyIcon />
        <span>{{ $t('reply') }}</span>
      </button>
    </li>

    <li class='discussion-comment_action-item discussion-comment_additional'>
      <button
        class='discussion-comment_button discussion-comment_more'
        @click.prevent='more'
      >
        <MoreIcon />
        <span>{{ $t('more') }}</span>
      </button>

      <ul
        v-if='show_more_actions'
        class='discussion-comment_additional-actions'
      >
        <li v-if='comment.from_current_user'>
          <button
            class='discussion-comment_additional-button discussion-comment_edit action-two'
            @click.prevent='edit'
          >
            {{ $t('edit') }}
          </button>
        </li>

        <li v-if='comment.from_current_user'>
          <button
            class='discussion-comment_additional-button discussion-comment_delete action-two'
            @click.prevent='openDelete'
          >
            {{ $t('delete') }}
          </button>
        </li>

        <li v-if='!comment.from_current_user'>
          <button
            class='discussion-comment_additional-button discussion-comment_flag action-two'
            @click.prevent='flag'
          >
            {{ report_button_text }}
          </button>
        </li>
      </ul>
    </li>
  </ul>

  <template v-if='is_replying'>
    <DiscussionCommentForm
      ref='commentForm'
      :is_replying='is_replying'
      :thread_id='thread_id'
      :parent_id='comment.id'
      :createComment='createComment'
      :editComment='editComment'
      :deleteComment='deleteComment'
      :cancel='cancelEdit'
      :supported_file_types='supported_file_types'
    />
  </template>

  <transition name="fast-fade">
    <div
      v-if='comment.replies && show_replies'
      class='discussion-comment_replies'
    >
      <DiscussionComment
        v-for='reply in comment.replies'
        :key='reply.id'
        :thread_id='thread_id'
        :createComment='createComment'
        :editComment='editComment'
        :deleteComment='deleteComment'
        :cancel='cancelEdit'
        :comment='reply'
        :parent_comment='comment'
        :api_client='api_client'
      />
    </div>
  </transition>
</template>

<template v-if='is_editing'>
  <DiscussionCommentForm
    :is_editing='is_editing'
    :thread_id='thread_id'
    :comment='comment'
    :editComment='editComment'
    :cancel='cancelEdit'
    :supported_file_types='supported_file_types'
  />
</template>

<!-- Delete Comment -->
<transition name='fast-fade'>
  <DiscussionModal
    v-if='show_delete'
    :closeModal='closeDelete'
    :modalAction='deleteCommentAction'
    :button_text="$t('delete_post_button_text')"
    :modal_description="$t('delete_post_description')"
    :modal_title="$t('delete_post_title')"
  />
</transition>

<!-- Zoom Image -->
<transition name='fast-fade'>
  <DiscussionModal
    v-if='zoomed_image'
    :zoomed_image='zoomed_image'
    :closeModal='closeImage'
  />
</transition>
</div>
</template>

<script>
import { useRootStore } from 'lesson/stores/root'
import { mapState } from 'pinia'

import DiscussionComment from './DiscussionComment'
import DiscussionCommentForm from './DiscussionCommentForm'
import DiscussionModal from './DiscussionModal'
import Avatar from 'components/Avatar/UserAvatar'
import { format, formatDistanceToNow } from 'date-fns'
import { enUS, es, pt } from 'date-fns/locale'
import LikeIcon from './like.svg'
import ReplyIcon from './reply.svg'
import MoreIcon from './more.svg'
import ZoomIcon from './zoom.svg'
import FileIcon from './file-icon.svg'

export default {
  name: 'DiscussionComment',
  props: [
    'comment',
    'parent_comment',
    'createComment',
    'editComment',
    'deleteComment',
    'thread_id',
    'api_client'
  ],

  components: {
    DiscussionComment,
    DiscussionCommentForm,
    DiscussionModal,
    Avatar,

    LikeIcon,
    ReplyIcon,
    MoreIcon,
    ZoomIcon,
    FileIcon
  },

  data() {
    return {
      is_editing: false,
      is_replying: false,
      show_more_actions: false,
      show_replies: false,
      show_delete: false,
      locales: {
        en : enUS,
        es,
        pt
      },
      zoomed_image: null
    }
  },

  watch: {
    zoomed_image: function(value) {
      $('.activity-overlay_close').css({ display: value ? 'none' : 'block' })
    },

    show_delete: function(value) {
      $('.activity-overlay_close').css({ display: value ? 'none' : 'block' })
    }
  },

  computed: {
    ...mapState(useRootStore, [
      'api_url'
    ]),

    avatar_url() {
      return this.comment.user.avatar
    },

    main_class() {
      if (this.is_editing) return 'discussion-comment--editing'
      if (this.is_replying) return 'discussion-comment--replying'
      if (this.comment.deleted) return 'discussion-comment--deleted'
      return null
    },

    replies_text() {
      if (this.comment.replies && this.comment.replies.length) {
        const text = this.comment.replies.length === 1 ? `${this.$t('reply')}` : `${this.$t('replies')}`
        return `${this.comment.replies.length} ${text}`
      }
      return null
    },

    comment_date_value() {
      let comment_date = null
      const now = new Date()
      const seven_days = 24 * 60 * 60 * 1000 * 7

      if (this.comment.created_at === this.comment.updated_at) {
        comment_date = new Date(this.comment.created_at)
      } else {
        comment_date = new Date(this.comment.updated_at)
      }

      if ((now - comment_date) > seven_days) {
        return format(comment_date, 'PPpp', { locale: this.locales[this.$i18n.locale] })
      } else {
        return formatDistanceToNow(comment_date, { locale: this.locales[this.$i18n.locale], addSuffix: true })
      }
    },

    report_button_text() {
      if (this.comment.flagged) {
        return `${this.$t('undo')} ${this.$t('report')}`
      } else {
        return `${this.$t('report')} `
      }
    },

    parent_reply_name() {
      if (this.comment.parent_id) {
        const replies = this.parent_comment.replies
        const index = _.findIndex(replies, (o) => { return o.id === this.comment.parent_id })
        const parent_name = replies[index].user.username

        return parent_name
      }
      return null
    },

    parent_reply_link() {
      return `#${this.comment.parent_id}`
    },

    supported_file_types() {
      return this.$root.configuration.discussions_supported_uploads
    }
  },

  methods: {
    like() {
      this.likeRequest(this.comment.liked ? 'DELETE' : 'POST')
      this.reverseLike()
    },

    likeRequest(method) {
      this.api_client.runMiddleware({
        url: `${this.api_url}/discussion_comments/${this.comment.id}/reaction/like`,
        method,
        data: {},
        model: 'discussion_comment'
      })
        .catch((error) => {
          console.log(error)
          this.reverseLike()
        })
    },

    reverseLike() {
      if (this.comment.liked) {
        this.comment.likes_amount--
      } else {
        this.comment.likes_amount++
      }

      this.comment.liked = !this.comment.liked
    },

    reply() {
      this.is_replying = !this.is_replying

      if (!this.is_replying) { return false }

      const containerId = document.getElementById('activity-overlay-content') ? '#activity-overlay-content' : '#discussion-app'
      this.$scrollTo(this.$el, {
        container: containerId,
        duration: 200,
        offset: -15
      })

      setTimeout(() => {
        this.$refs.commentForm.$refs.textArea.focus()
      }, 100)
    },

    flag() {
      this.flagRequest(this.comment.flagged ? 'DELETE' : 'POST')
      this.comment.flagged = !this.comment.flagged
    },

    flagRequest(method) {
      this.api_client.runMiddleware({
        url: `${this.api_url}/discussion_comments/${this.comment.id}/reaction/flag`,
        method,
        data: {},
        model: 'discussion_comment'
      })
        .catch((error) => {
          console.log(error)
          this.comment.flagged = !this.comment.flagged
        })
    },

    more() {
      if (!this.show_more_actions) {
        this.show_more_actions = true
        setTimeout(() => {
          document.body.addEventListener('click', this.closeMore)
        }, 100)
      }
    },

    edit() {
      this.is_editing = true
    },

    cancelEdit() {
      this.is_editing = false
      this.is_replying = false
    },

    openDelete() {
      this.show_delete = true
    },

    closeDelete() {
      this.show_delete = false
    },

    deleteCommentAction() {
      this.deleteComment(this.comment.id, this.deleteCallback)
      this.show_delete = false
    },

    deleteCallback() {
      this.comment.deleted = true
    },

    closeMore(event) {
      this.show_more_actions = false
      document.body.removeEventListener('click', this.closeMore)
    },

    toggleReplies() {
      this.show_replies = !this.show_replies
    },

    goToParentReply() {
      const targetElement = document.getElementById(this.comment.parent_id)

      const containerId = document.getElementById('activity-overlay-content') ? '#activity-overlay-content' : '#discussion-app'
      this.$scrollTo(targetElement, {
        container: containerId,
        duration: 200,
        offset: -15
      })
    },

    isImage(file) {
      const type = file.type || file.content_type
      const supported = ((this.$root.configuration || {}).discussions_supported_uploads || [])
      return supported.indexOf(type) >= 0 && !!type.match(/^image/)
    },

    zoomImage(image) {
      this.zoomed_image = image
    },

    closeImage() {
      this.zoomed_image = null
    }

  }
}
</script>

<style lang='sass' scoped>
  @import './DiscussionComment'
</style>

<style lang='sass'>
  .discussion-comment_button
    .comment-icon
      transition: opacity 0.1s ease-in

    &:hover
      .comment-icon,
      .comment-icon_full
        opacity: 1

  .discussion-comment_like
    &.is-liked
      .comment-icon,
      .comment-icon_full
        opacity: 1
        fill: #f00

  .discussion-comment--editing,
  .discussion-comment--replying
    .discussion-comment-form textarea
      min-height: 120px

</style>
