<template>
  <li class='expert-card'>
    <router-link :to="link_path">
      <div
        class='expert-card_image'
        :style='expertImage'
      />

      <div class='expert-card_title heading-five'>
        {{ expert.full_name }}
      </div>

      <div class='expert-card_description copy-three'>
        {{ expert.title }}
      </div>

      <span class='expert-card_action'>
        Go
        <ForwardIcon />
      </span>
    </router-link>
  </li>
</template>

<script>
import ForwardIcon from './forward.svg'

export default {
  props: ['expert'],

  components: {
    ForwardIcon
  },

  computed: {
    link_path() {
      return { name: 'instructor', params: { identifier: this.expert.identifier } }
    },

    expertImage() {
      return `background-image: url(${this.expert.detail_image})`
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './ExpertCard'
</style>

<style lang='sass'>
  .expert-card
    .forward-icon_inner,
    .forward-icon_arrow
      transition: fill 0.2s ease-in

    &:hover
      .forward-icon_inner
        fill: #4A4AD5

      .forward-icon_arrow
        fill: #fff

</style>
