<template>
  <div class="oauth-auth">
    <p
      v-if="!is_signing_up"
      class="oauth-auth_description copy-four"
    >
      {{ $t("oauth_description") }}
    </p>

    <a
      v-for="provider in auth_data.oauth_providers"
      :key="provider.name"
      :href="provider.target_url"
      class="oauth-auth_link action-one-branded"
      :class="'oauth-auth_link--' + provider.name"
      @click='authGatewayClick($event)'
    >
      {{ sign_in_up_text(provider) }}
    </a>
  </div>
</template>

<script>
export default {
  props: ['auth_data', 'sign_up', 'redirect_to_location'],

  computed: {
    is_signing_up() {
      return !!this.sign_up
    }
  },

  methods: {
    authGatewayClick(event) {
      event.preventDefault()

      let new_href = event.target.href
      if (this.redirect_to_location) {
        new_href = new_href.concat(`&redirect_to=${encodeURIComponent(this.redirect_to_location)}`)
      }
      window.location = new_href
    },

    sign_in_up_text(provider) {
      return this.is_signing_up ? this.$t('sign_up_with', { value: provider.name }) : this.$t('sign_in_with', { value: provider.name })
    }
  }
}
</script>

<style lang='sass' scoped>
@import './OauthAuth'
</style>
