<template>
  <div class="otp-auth">
    <template v-if='toggleAccountOverlay'>
      <p
        v-if='description_visible'
        class='otp-auth_description copy-four'
      >
        {{ $t('otp_description' ) }}
      </p>

      <a
        href='/account/magic-link/send'
        class='otp-auth_link action-two'
        @click.prevent='toggleAccountOverlay({context: "magic-link-send"})'
      >
        {{ $t('magic_link.send') }}
      </a>
    </template>

    <template v-else>
      <router-link
        :to="{ path: '/magic-link/send' }"
        class='otp-auth_link action-two'
      >
        {{ $t('magic_link.send') }}
      </router-link>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    toggleAccountOverlay: Function
  },

  computed: {
    description_visible() {
      return this.configurationValue('log_in_otp_description_visible')
    }
  },

  methods: {
    configurationValue(key) {
      return this.$root.configuration &&
             this.$root.configuration.hasOwnProperty(key) &&
             this.$root.configuration[key]
    }
  }
}
</script>

<style lang='sass' scoped>
@import './OTPAuth'
</style>
