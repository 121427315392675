<template>
<a
  href="#play"
  class='instructor-audio-series-card'
  :class='main_class'
  @click.prevent='playToggle(series, episode_index)'
>
  <div
    class='instructor-audio-series-card_image'
    :style='card_image'
  >
    <VolumeIcon />
  </div>
  <div class='instructor-audio-series-card_content'>
    <div class='instructor-audio-series-card_title heading-five'>
      {{ episode.title }}
    </div>

    <div class='instructor-audio-series-card_series copy-four'>
      {{ series.title }}
    </div>
  </div>
</a>
</template>

<script>
import VolumeIcon from './volume.svg'

export default {
  props: [
    'episode', 'episode_index', 'series',
    'playToggle', 'isPlaying'
  ],

  data() {
    return {
      volume_icon: require('./volume.svg').default
    }
  },

  components: {
    VolumeIcon
  },

  computed: {
    main_class() {
      const classes = []
      if (this.isPlaying(this.series, this.episode_index)) { classes.push('is-playing') }
      return classes
    },

    card_image() {
      return `background-image: url(${this.episode.image})`
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './AudioEpisodeCard'
</style>
