import apiClient from 'api'
import { useLessonStore } from 'lesson/stores/lesson'
import { useUserResultsStore } from 'lesson/stores/user-results'
import { defineStore } from 'pinia'

export const useRootStore = defineStore('root', {
  state: () => {
    return {
      jwt:                        null,
      api_url:                    null,
      rootPath:                   null,
      partner_name:               null,
      lessonPath:                 null,
      isDataLoaded:               false,
      isStarted:                  false,
      isLeftMenuOpened:           false,
      isContentsMenuOpened:       false,
      isResourcesOpened:          false,
      isOutlineOpened:            false,
      isOutlineBusy:              false,
      currentResourcesPartIndex:  null,
      offline:                    false,

      isActivityOverlayOpened: false,
      isAssessmentResultsOpened: false,
      currentArticleActivity: null,
      currentResource: null,

      lpAttribution: null,

      user: null,
      user_load_ts: null,

      lesson: null
    }
  },

  getters: {
    api_client: state => {
      if (state.jwt) apiClient.setToken(state.jwt)
      if (state.api_url) apiClient.setUrl(state.api_url)

      return apiClient
    }
  },

  actions: {
    // set of actions that were mutations in vuex
    setApiUrl(url) {
      if (url) this.api_url = url
    },

    setApiJWT(jwt) {
      if (jwt) this.jwt = jwt
    },

    setOffline(true_or_false) {
      this.offline = true_or_false
    },

    loadLesson(payload) {
      payload.part_index = payload.part_index || 0
      payload.slide_index = payload.slide_index || 0

      const lesson_store = useLessonStore()
      this.lesson = payload

      lesson_store.setParts(payload.parts)
      lesson_store.setPartIndex(payload.part_index)
      lesson_store.setSlideIndex(payload.slide_index)

      this.isDataLoaded  = true
    },

    setRootPath(path) {
      this.rootPath = path
    },

    setUser(user) {
      if (user) {
        this.user = user
        this.user_load_ts = Date.now()
      }
    },

    setPartnerName(partner_name) {
      if (partner_name) this.partner_name = partner_name
    },

    setLessonPath(path) {
      this.lessonPath = `${this.rootPath}/${path}`
    },

    setLpAttribution(data) {
      this.lpAttribution = data
    },

    startLesson() {
      this.isStarted = true
    },

    stopLesson() {
      this.isStarted = false
    },

    toggleLeftMenu() {
      this.isLeftMenuOpened = !this.isLeftMenuOpened
    },

    toggleContentsMenu() {
      this.isContentsMenuOpened = !this.isContentsMenuOpened
    },

    toggleOutlineMenu() {
      this.isOutlineOpened = !this.isOutlineOpened
    },

    toggleOutlineBusy() {
      this.isOutlineBusy = !this.isOutlineBusy
    },

    hideOutlineMenu() {
      this.isOutlineOpened = false
    },

    getHome() {
      this.isStarted = false
      this.currentPartIndex = null
      this.currentSlideIndex = null
      this.isContentsMenuOpened = false
      this.isLeftMenuOpened = false
      this.isOutlineOpened = false

      /* global vueRouter */
      vueRouter.push({
        name: 'home'
      })
    },

    goToShortResources(part_index = null) {
      if (part_index >= 0) {
        this.currentResourcesPartIndex = part_index
      }
      this.isResourcesOpened = true
    },

    closeShortResources() {
      this.isResourcesOpened = false
      this.currentResourcesPartIndex = null
    },

    toggleActivityOverlay(options = {}) {
      // Some workarounds for opening assessment results
      if (options && options.hasOwnProperty('showAssessmentResults')) {
        this.isAssessmentResultsOpened = options.showAssessmentResults
      }

      if (this.isActivityOverlayOpened &&
          this.isAssessmentResultsOpened &&
          !options.hasOwnProperty('showAssessmentResults')) {
        this.isAssessmentResultsOpened = false
      }

      if (this.isAssessmentResultsOpened &&
         options.hasOwnProperty('showAssessmentResults') &&
         options.showAssessmentResults) {
        this.isActivityOverlayOpened = true
        this.isAssessmentResultsOpened = true
      } else {
        this.isActivityOverlayOpened = !this.isActivityOverlayOpened
        if (this.currentArticleActivity) this.currentArticleActivity = null
        if (this.currentResource) this.currentResource = null
      }

      // Article
      if (options && options.hasOwnProperty('article')) {
        this.currentArticleActivity = options.article
      }

      // Audio (and other resource types)
      if (options && options.hasOwnProperty('resource')) {
        this.currentResource = options.resource
      }
    },

    //
    // Actions
    loadLessonData({ lesson_id, i18n, part_index, slide_index, opts = {} }) {
      this.api_client.find('lessons', lesson_id, opts)
        .then(({ data }) => {
          const lesson_store = useLessonStore()
          const slide_ids = []

          data.parts.forEach((p) => {
            p.slides.forEach((s) => {
              slide_ids.push(s.id)
            })
          })

          const triggered_slide_ids = []
          lesson_store.setTriggeredSlides(data.triggered_slides)
          data.triggered_slides.forEach((s) => {
            triggered_slide_ids.push(s.id)
          })

          const total_slides_length = slide_ids.length + triggered_slide_ids.length

          // Setting indicies for slide navigation
          if (isNaN(part_index)) {    // Looks like 'Back Home' or 'Get Started' button was pressed
            this.stopLesson()
            data.part_index = 0
            data.slide_index = 0
          } else {                    // Looks like we're just changing slides
            this.startLesson()
            data.part_index = part_index
            if (isNaN(slide_index)) { // Looks like we're on Outro slide
              data.slide_index = total_slides_length
            } else {
              data.slide_index = slide_index
            }
          }

          // Default options for Intro and Outro slides
          if (!data.intro) data.intro = {}
          if (!data.outro) data.outro = {}
          if (!data.left_menu) data.left_menu = {}

          // Dispatch!
          const user_results_store = useUserResultsStore()
          // load slide results
          user_results_store.loadUserResults({
            slide_ids,
            slide_type: 'slides'
          })
          // load triggered slide results
          user_results_store.loadUserResults({
            slide_ids: triggered_slide_ids,
            slide_type: 'triggered_slides'
          })

          this.loadLesson(data)
          this.setLessonPath(data.identifier)

          // Setting locales
          i18n.locale = data.locale

          // Support for navigation with Back button in browser
          // eslint-disable-next-line
          /* global vueRouter */
          vueRouter.beforeEach((to, from, next) => {
            const part_id = to.params.part_id
            const slide_id = to.params.slide_id

            if (part_id) {
              lesson_store.setPartIndex(part_id - 1)
            }

            if (slide_id) {
              if (slide_id === 'outro') { // Looks like we're on Outro slide
                const length = lesson_store.currentPartSlides.length
                lesson_store.setSlideIndex(length)
              } else {
                lesson_store.setSlideIndex(slide_id - 1)
              }
              lesson_store.setSlide()
            }
            next()
          })
        }) // End loading lessons
    },

    loadUser() {
      this.api_client.find('user')
        .then(({ data }) => {
          this.setUser(data)
        })
    },

    updateUserData(userData) {
      return new Promise((resolve) => {
        this.api_client.update('user', userData)
          .then(({ data }) => {
            this.setUser(data)
            resolve(data)
          })
          .catch((error) => {
            resolve({ error })
          })
      })
    },

    toggleLeftMenuAction() {
      this.toggleLeftMenu()
    },

    toggleOutlineMenuAction() {
      if (this.isOutlineBusy) return false
      this.toggleOutlineMenu()
      this.toggleOutlineBusy()
      setTimeout(() => {
        this.toggleOutlineBusy()
      }, 600)
    },

    getHomeAction() {
      this.getHome()
      const lesson_store = useLessonStore()
      lesson_store.setPartIndex(0)
      lesson_store.setSlideIndex(0)
    },

    goToPartResources(part_index) {
      this.goToShortResources(part_index)
    },

    setLocale({ locale, refresh }) {
    },

    updateOfflineStatus(true_or_false) {
      if (this.offline !== true_or_false) this.setOffline(true_or_false)
    },

    getBadgeData(payload) {
      this.api_client.find('badge', payload.id)
        .then(({ data }) => {
          payload.cb(data)
        })
        .catch((error) => {
          payload.cb(error)
        })
    }
  }
})
