import { defineStore } from 'pinia'
import { useHomeStore } from './home'

export const useUserResultsStore = defineStore('user-results', {
  state: () => {
    return {
      global_slide_result: {},
      slide_result: {},
      last_passed_slide_id: null,
      loaded: false,
      result_queue: {}
    }
  },

  getters: {
    getSlideResult: (state) => (slide_id, slide_type) => {
      const key = state.getResourceState(slide_type)
      return (state[key] && state[key][slide_id]) || {}
    },

    getResourceState: () => (resource) => {
      return (resource === 'global_slides') ? 'global_slide_result' : 'slide_result'
    },

    getResourceSetter: () => (resource) => {
      return (resource === 'global_slides') ? 'setGlobalSlideResultAction' : 'setSlideResultAction'
    },

    getResourceURLKey: () => (resource) => {
      return (resource === 'global_slides') ? 'global_slide_activity_result' : 'slide_activity_result'
    },

    getQueueKey: () => (slide_type, slide_id) => {
      return `${slide_type}_${slide_id}`
    },

    results_loaded(state) {
      return state.loaded
    }
  },

  actions: {
    // set of actions that were mutations in vuex
    setGlobalSlideResultAction({ slide_id, slide_result }) {
      if (this.global_slide_result[slide_id]) {
        const current_result = Object.assign({}, this.global_slide_result[slide_id])
        this.global_slide_result[slide_id] = {
          ...current_result,
          id: slide_result.id,
          event: slide_result.event,
          status: slide_result.status,
          results: Object.assign({}, this.global_slide_result[slide_id].results, slide_result.results)
        }
      } else {
        this.global_slide_result[slide_id] = slide_result
      }

      if (slide_result.status === 'passed') {
        this.last_passed_slide_id = slide_id
      }
    },

    setSlideResultAction({ slide_id, slide_result }) {
      if (this.slide_result[slide_id]) {
        const current_result = Object.assign({}, this.slide_result[slide_id])
        this.slide_result[slide_id] = {
          ...current_result,
          id: slide_result.id,
          event: slide_result.event,
          status: slide_result.status,
          results: Object.assign({}, this.slide_result[slide_id].results, slide_result.results)
        }
      } else {
        this.slide_result[slide_id] = slide_result
      }

      if (slide_result.status === 'passed') {
        this.last_passed_slide_id = slide_id
      }
    },

    enqueueSlideResults(payload) {
      const key = `${payload.slide_type}_${payload.slide_id}`
      if (this.result_queue[key]) {
        const current_result_queue = Object.assign({}, this.result_queue[key])
        this.result_queue[key] = {
          ...current_result_queue,
          queue: this.result_queue[key].queue.concat(payload)
        }
      } else {
        this.result_queue[key] = {
          running: false,
          queue: new Array(payload)
        }
      }
    },

    dequeueSlideResults({ slide_type, slide_id }) {
      const key = `${slide_type}_${slide_id}`
      if (this.result_queue[key]) {
        this.result_queue[key].queue.splice(0, 1)
      }
    },

    setRunningForQueue({ slide_type, slide_id }) {
      const key = `${slide_type}_${slide_id}`
      this.result_queue[key].running = true
    },

    setNotRunningForQueue({ slide_type, slide_id }) {
      const key = `${slide_type}_${slide_id}`
      this.result_queue[key].running = false
    },

    // actions
    loadUserResults({ slide_ids, slide_type }) {
      if (!slide_ids.length) { return }

      const home_store = useHomeStore()
      home_store.api_client.findAll(this.getResourceURLKey(slide_type), { 'filter[slides]': slide_ids.join(',') })
        .then(({ data }) => {
          data.forEach((result) => {
            if (result.slide) {
              this[this.getResourceSetter(slide_type)]({ slide_id: result.slide.id, slide_result: result })
            }
          })
        })
    },

    updateSlideResult(payload) {
      this.enqueueSlideResults(payload)

      const temp_results = {
        event:   payload.event,
        status:  'passed',
        results: payload.payload
      }
      const cur_key = this.getResourceState(payload.slide_type)
      const cur_res = this[cur_key][payload.slide_id]
      if (cur_res && cur_res.id) temp_results.id = cur_res.id

      this[this.getResourceSetter(payload.slide_type)]({ slide_id: payload.slide_id, slide_result: temp_results })
      if (!this.result_queue[this.getQueueKey(payload.slide_type, payload.slide_id)].running) {
        this.setRunningForQueue(payload)
        this._runUpdateSlideResult(payload)
      }
    },

    setSlideResult({ slide_type, slide_id, slide_result }) {
      this[this.getResourceSetter(slide_type)]({ slide_id, slide_result })
    },

    _keepUpdatingSlideResultsIfNecessary({ slide_type, slide_id }) {
      if (this.result_queue[this.getQueueKey(slide_type, slide_id)].queue.length > 0) {
        this._runUpdateSlideResult({ slide_type, slide_id })
      } else {
        this.setNotRunningForQueue({ slide_type, slide_id })
      }
    },

    _runUpdateSlideResult({ slide_type, slide_id }) {
      // unfortunately we cannot return values from a mutation so we have to get the value then shift it in the mutation
      const payload = this.result_queue[this.getQueueKey(slide_type, slide_id)].queue[0]
      this.dequeueSlideResults({ slide_type, slide_id })

      const key = this.getResourceState(payload.slide_type)
      if (this[key].hasOwnProperty(payload.slide_id) && this[key][payload.slide_id].hasOwnProperty('id')) {
        this._updateSlideResult(payload)
      } else {
        this._createSlideResult(payload)
      }
    },

    _createSlideResult({ slide_type, slide_id, event, payload, callback }) {
      const post_data = {
        event,
        results:  payload
      }
      const http_method = 'create'
      const query_params = { slide_id }
      this._sendSlideResultRequest({ http_method, slide_type, slide_id, post_data, query_params, callback })
    },

    _updateSlideResult({ slide_type, slide_id, event, payload, files, callback }) {
      const key = this.getResourceState(slide_type)
      const original = this[key][slide_id]
      if (Object.keys(original.results).length === 0 && Object.keys(payload).length === 0 && original.event === event) {
        this._keepUpdatingSlideResultsIfNecessary({ slide_type, slide_id })
        return
      }
      const post_data = {
        id:       original.id,
        event,
        results:  payload
      }
      if (files) {
        post_data.files = files
      }

      const http_method = 'update'
      this._sendSlideResultRequest({ http_method, slide_type, slide_id, post_data, callback })
    },

    _sendSlideResultRequest({ http_method, slide_type, slide_id, post_data, query_params, callback }) {
      const home_store = useHomeStore()
      home_store.api_client[http_method](this.getResourceURLKey(slide_type), post_data, query_params)
        .then(({ data }) => {
          home_store.updateOfflineStatus(false, { root: true })
          // If nothing is updated the result will be a 204 status with no body
          if (data) {
            this[this.getResourceSetter(slide_type)]({ slide_id, slide_result: data })

            if (typeof (callback) === 'function') {
              callback(data)
            }

            this._keepUpdatingSlideResultsIfNecessary({ slide_type, slide_id })
          }
        })
        .catch((error) => {
          // "Network Error" is what Axios returns when it cannot reach the network.
          if (error.message === 'Network Error') {
            setTimeout(() => {
              home_store.updateOfflineStatus(true, { root: true })
              this._sendSlideResultRequest({ http_method, slide_type, slide_id, post_data, query_params, callback })
            }, 5000)
          } else {
            this.setNotRunningForQueue({ slide_type, slide_id })
            throw (error)
          }
        })
    }

  }

})
