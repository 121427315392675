// model based on API::V1::Activity::V2::SelfAssessmentSerializer
export default {

  // Images
  background_image: null,
  gradient_start: null,
  gradient_end: null,

  // Attributes
  identifier: null,
  branching: null,
  label: null,
  headline: null,
  instructions: null,
  cta: null,
  consent_collection: null,
  auto_advance: null,
  questions: null,
  categories: null,
  results: null,
  triggers: null
}
