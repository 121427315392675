<template>
<div class='program-driver'>
  <div class='program-driver_image-container'>
    <div
      class='program-driver_image'
      :style='driverImage'
    />
  </div>

  <div class='program-driver_info'>
    <a
      v-for='(link, index) in driver_links'
      :key='index'
      :href='link.href'
      class='program-driver_name heading-six'
      target='_blank'
      @click='sendAnalytics'
    >
      {{ link.text }}
    </a>
  </div>

  <div class='program-driver_link-container'>
    <a
      :href='driver.link.href'
      class='program-driver_link'
      :title='driver.cta'
      target='_blank'
      @click='sendAnalytics'
    >
      <ArrowIcon />
    </a>
  </div>
</div>
</template>

<script>
import ArrowIcon from './arrow.svg'

export default {
  props: ['driver'],

  components: {
    ArrowIcon
  },

  computed: {
    driverImage() {
      return `background-image: url(${this.driver.image})`
    },

    from() {
      return this.$t('attribution.from', { value: this.driver.lesson_name })
    },

    driver_links() {
      const phone_regex = /(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}/
      const result = phone_regex.exec(this.driver.cta)
      const links = []

      this.cta.split(phone_regex).forEach((item) => {
        if (item === undefined && result && result[0] !== undefined) {
          links.push({
            text: result[0],
            href: 'tel:+' + (result[0][0] === 1 ? '' : '1') + result[0].replace(/\./g, '')
          })
        } else {
          links.push({
            text: item,
            href: this.driver.link.href
          })
        }
      })

      return links
    },

    cta() {
      return this.driver.cta
    }
  },

  methods: {
    sendAnalytics() {
      const category = this.$route.name

      const gtm_event = {
        event:                'resourceClick',
        pageCategory:         category,
        resourceTitle:        this.driver.headline,
        resourceSubTitle:     this.driver.cta,
        resourceID:           this.driver.id,
        resourceURL:          this.driver.link.href,
        resourceDestination:  'benefit'
      }

      dataLayer.push(gtm_event)
    }
  }

}
</script>

<style lang='sass' scoped>
  @import './ProgramDriver'
</style>

<style lang='sass'>
  .program-driver_link
    &:hover
      .arrow_space
        transition: fill 0.1s ease-in
        fill: #4A4AD5

</style>
