<template>
  <footer
    id='footer'
    :class="{'has-attribution-logo': attribution_logo_visible}"
    class='footer'
  >
    <ul class='footer_links'>
      <li>
        <router-link
          v-if="showFooterLinkType('terms_of_use')"
          :to="{ path: '/terms'}"
          class='footer_link main-nav-link'
        >
          {{ $t('terms_of_use') }}
        </router-link>
      </li>

      <li>
        <router-link
          v-if="showFooterLinkType('help')"
          :to="{ path: '/help'}"
          class='footer_link main-nav-link'
        >
          {{ $t('help') }}
        </router-link>
      </li>

      <li>
        <router-link
          v-if="showFooterLinkType('privacy_policy')"
          :to="{ path: '/privacy'}"
          class='footer_link main-nav-link'
        >
          {{ $t('privacy_policy') }}
        </router-link>
      </li>
    </ul>

    <template v-if='has_router && logo'>
      <router-link
        :to="{ name: 'home' }"
        class='footer_logo'
        @click='scrollToTop'
      >
        <img
          :src='logo'
          alt='Logo'
        >
      </router-link>
    </template>

    <template v-else>
      <a
        v-if='logo'
        class='footer_logo'
        href='/home'
      >
        <img
          :src='logo'
          alt='Logo'
        >
      </a>
    </template>

    <div
      v-if="slogan_visible"
      class='footer_slogan copy-two'
    >
      {{ $t('employee_education_that_elevates') }}
    </div>

    <img
      v-if="attribution_logo_visible"
      :src="attribution_logo"
      class="footer_attribution_logo"
    >
  </footer>
</template>

<script>

export default {
  props: ['logo', 'attribution_logo'],

  computed: {
    has_router() {
      return this.$router
    },

    slogan_visible() {
      return this.getConfigurationValue('show_footer_slogan')
    },

    attribution_logo_visible() {
      return this.attribution_logo && this.getConfigurationValue('show_footer_attribution_logo')
    }
  },

  methods: {
    showFooterLinkType(type) {
      return this.getConfigurationValue(`show_${type}`)
    },

    getConfigurationValue(configName) {
      if (this.$root.configuration && this.$root.configuration.hasOwnProperty(configName)) {
        return this.$root.configuration[configName]
      } else {
        return true
      }
    },

    scrollToTop() {
      this.$scrollTo(this.$parent, 1, { offset: 0 })
    }
  }
}

</script>

<style lang='sass' scoped>
  @import './Footer'
</style>
