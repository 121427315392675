<template>
  <div
    id="panel-tab-learning-plans"
    class='learning-plan'
    aria-labelledby="learning-plans"
    role="tabpanel"
  >
    <PageLoader v-if='loading' />

    <template v-if='!loading'>
      <PageHeader
        :learning_plan='learning_plan'
        :expandToggle='expandToggle'
        :expanded='expanded'
      />

      <ul
        v-if='expanded'
        class='learning-plan_courses-list'
      >
        <li
          v-for='(lesson, lesson_index) in learning_plan.lessons'
          :key='lesson_index'
          class='learning-plan_courses-list-item'
        >
          <Lesson
            :learning_plan='learning_plan'
            :lesson='lesson'
            :index='lesson_index'
          />
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import PageHeader from './Header/ActivityLearningPlanHeader'
import PageLoader from 'components/PageLoader/PageLoader'
import Lesson from './Lesson/ActivityLearningPlanLesson'
import LearningPlan from 'home/models/learning_plan'

export default {
  props: {
    identifier: { type: [String, Number], required: true },
    index: { type: Number, required: true },
    total_learning_plans: { type: Number, required: true }
  },

  components: {
    PageHeader,
    PageLoader,
    Lesson
  },

  created() {
    this.loadLearningPlan()

    if (this.total_learning_plans === 1) this.expanded = true
    if (this.total_learning_plans > 1 && this.index === 0) this.expanded = true
  },

  data() {
    return {
      loading: false,
      learning_plan: null,
      expanded: false
    }
  },

  methods: {
    loadLearningPlan() {
      this.loading = true
      this.learning_plan = LearningPlan.load(this.identifier, () => {
        this.loading = false
      })
    },

    expandToggle(event) {
      if (!event.target.className.match(/action-one-branded/g)) {
        this.expanded = !this.expanded
      }
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './LearningPlan'
</style>
