<template>
<div class='quiz-multiple-select'>
  <h2 class='body-four'>
    {{ label }}
  </h2>

  <form class='quiz-question'>
    <fieldset
      class='quiz-question_fieldset'
      role='group'
      :aria-labelledby="'quiz-question-text-' + question.id"
    >
      <legend
        :id="'quiz-question-text-' + question.id"
        class='quiz-question_text h-four'
      >
        {{ question.text }}
      </legend>

      <div
        v-if="question.kind === 'multiple_select'"
        class='quiz-question_description'
      >
        {{ $t('select_all_that_apply') }}
      </div>

      <div
        class='quiz-question_container'
        :class="[question_was_submitted ? 'quiz-question_submitted' : 'quiz-question_not-submitted', animation ? 'quiz-question_animated' : '']"
      >
        <quiz-question-option
          v-for='(option, index) in options'
          :key='option.id'
          :option='option'
          :question='question'
          :select='select'
          :isOptionSelected='isOptionSelected'
          :index='index'
        />

        <question-response
          v-if='question_was_submitted'
          :key='question.id'
          :question='question'
          :correct_answers='correct_answers'
          :is_correct='isCorrect()'
        />
      </div>
    </fieldset>

    <button
      v-if='!question_was_submitted'
      class='quiz-question_next button-two'
      :disabled="!is_answered"
      @click.prevent='submitButtonAction'
    >
      {{ $t('submit') }}
    </button>

    <button
      v-else
      class='quiz-question_next button-two'
      @click.prevent='toNextQuestion'
      v-html='is_question_last ? $t("get_your_results") : $t("next")'
    />
  </form>
</div>
</template>

<script>
import QuizCommon from './quiz-common'
import QuizQuestionOption from './quiz-v1-question-option'
import QuestionResponse from './quiz-v1-question-response'

export default {
  mixins: [QuizCommon],

  props: [
    'question',
    'options',
    'nextQuestion',
    'is_question_last'
  ],

  components: {
    QuizQuestionOption,
    QuestionResponse
  },

  data() {
    return {
      animation: false,
      question_result:  {
        selection: [],
        submitted: false
      }
    }
  },

  computed: {

    is_answered() {
      return this.question_result.selection.length > 0
    },

    question_was_submitted() {
      return this.question_result.submitted
    },

    correct_answers() {
      return this.options.filter((o) => o.is_correct)
    }

  },

  methods: {

    submitButtonAction() {
      if (this.question_was_submitted) {
        this.nextQuestion()
      } else {
        this.animation = true
        this.question_result.submitted = true
        this.question_result.is_correct = this.isCorrect()
        this.setQuestionResult(this.question_result)
      }
    },

    select(option_id) {
      if (!this.question_was_submitted) {
        const index = this.question_result.selection.indexOf(option_id)

        if (index === -1) {
          this.question_result.selection.push(option_id)
        } else {
          this.question_result.selection.splice(index, 1)
        }
      }
    },

    isOptionSelected(id) {
      return this.question_result.selection.indexOf(id) > -1
    },

    toNextQuestion() {
      this.animation = false
      this._initQuestionResult()
      this.nextQuestion()
    },

    isCorrect() {
      if (this.question_result.selection.length !== this.correct_answers.length) { return false }

      const matches = this.correct_answers.filter((a) => {
        return this.question_result.selection.indexOf(a.id) > -1
      })
      return this.correct_answers.length === matches.length
    },

    _initQuestionResult() {
      this.question_result = {
        selection: [],
        submitted: false
      }
    }

  }
}
</script>

<style lang="sass" scoped>
//@import 'lesson/styles/components/quiz/question'
</style>
