import apiClient from 'api'

import { defineStore } from 'pinia'
import { useUserResultsStore } from './user-results'

export const useHomeStore = defineStore('home', {
  state: () => {
    return {
      signed_in:                  false,
      jwt:                        null,
      subdomain:                  null,
      partner_name:               null,
      api_url:                    null,
      root_path:                  null,
      homepage_load_ts:           null,
      header_logo:                null,
      footer_logo:                null,
      attribution_logo:           null,
      supported_languages:        {},
      audio_series:               {},
      audio_series_load_ts:       null,
      audio_series_full_load_ts:  null,
      courses:                    {},
      course_load_ts:             null,
      instructors:                {},
      instructor_load_ts:         null,
      resources:                  null,
      resource_load_ts:           null,
      featured_resources:         null,
      featured_resource_load_ts:  null,
      sections:                   [],
      section_load_ts:            null,
      user:                       null,
      user_load_ts:               null,
      programs:                   [],
      program_load_ts:            null,
      intentions:                 [],
      intention_load_ts:          null,
      learning_plans:             {},
      learning_plan_load_ts:      null,
      offline:                    false,

      isAccountOverlayOpened: false,
      accountOverlayContext:  null,
      accountOverlayRedirect: null,
      modalAuthEnabled:       false,

      isActivityOverlayOpened: false,
      currentActivityId:       null,

      isNotificationActivityOverlayOpened: false,

      isResourceOverlayOpened: false,
      currentResource: null,

      isWelcomeOverlayOpened: false,
      welcome_image: null,

      currentAudio: {},
      currentAudioStats: {},
      currentEpisodeIndex: null,
      isAudioPlaying: false
    }
  },

  getters: {
    api_client: state => {
      if (state.jwt) apiClient.setToken(state.jwt)
      if (state.api_url) apiClient.setUrl(state.api_url)
      if (state.subdomain) apiClient.setSubdomain(state.subdomain)
      if (state.guest_token) apiClient.setGuestToken(state.guest_token)

      return apiClient
    },

    is_homepage_data_loaded: state => {
      const obj_data_loaded = state.section_load_ts

      return state.signed_in ? (obj_data_loaded && state.user_load_ts) : obj_data_loaded
    },

    is_data_loaded: state => {
      const obj_data_loaded = state.course_load_ts &&
        state.instructor_load_ts &&
        state.section_load_ts &&
        state.learning_plan_load_ts

      return state.signed_in ? (obj_data_loaded && state.user_load_ts) : obj_data_loaded
    },

    is_locale_loaded: state => {
      return Object.keys(state.supported_languages).length > 0
    },

    welcome_survey: state => {
      return state.section_load_ts &&
        state.sections.filter((section) => section.type === 'interactive_sections' && section.global_slide.global_slide_type === 'welcome_survey')[0]
    }

  },

  actions: {
    // set of actions that were mutations in vuex
    setSignedIn(value) {
      this.signed_in = value
    },

    setApiJWT(jwt) {
      if (jwt) this.jwt = jwt
    },

    setApiUrl(url) {
      if (url) this.api_url = url
    },

    setOffline(true_or_false) {
      this.offline = true_or_false
    },

    setHomepage() {
      this.homepage_load_ts = Date.now()
    },

    setHeaderLogo(logo) {
      if (logo) this.header_logo = logo
    },

    setFooterLogo(logo) {
      if (logo) this.footer_logo = logo
    },

    setAttributionLogo(logo) {
      if (logo) this.attribution_logo = logo
    },

    setWelcomeImage(image) {
      if (image) this.welcome_image = image
    },

    setSupportedLanguages(supported_languages) {
      if (supported_languages) this.supported_languages = supported_languages
    },

    setSubdomain(subdomain) {
      if (subdomain) this.subdomain = subdomain
    },

    setPartnerName(partner_name) {
      if (partner_name) this.partner_name = partner_name
    },

    setRootPath(path) {
      if (path) this.root_path = path
    },

    setGuestToken(guest_token) {
      if (guest_token) this.guest_token = guest_token
    },

    setAudioSeries(series) {
      if (series && series.length) {
        // full array
        this.audio_series = series.reduce((acc, val) => {
          acc[val.identifier] = val
          return acc
        }, {})
        this.audio_series_load_ts = Date.now()
        this.audio_series_full_load_ts = Date.now()
      } else if (series) {
        // one series
        this.audio_series[series.identifier] = series
        this.audio_series_load_ts = Date.now()
      }
    },

    setCourses(courses) {
      if (courses) {
        this.courses = courses
        this.course_load_ts = Date.now()
      }
    },

    setCourse(course) {
      this.courses[course.identifier] = course
    },

    setInstructors(instructors) {
      if (instructors) {
        this.instructors = instructors
        this.instructor_load_ts = Date.now()
      }
    },

    setResources(resources) {
      if (resources) {
        this.resources = resources
        this.resource_load_ts = Date.now()
      }
    },

    setFeaturedResources(resources) {
      if (resources) {
        this.featured_resources = resources
        this.featured_resource_load_ts = Date.now()
      }
    },

    setCurrentResource(options = {}) {
      if (options && options.resource) {
        this.currentResource = options.resource
      } else {
        this.currentResource = null
      }
    },

    setSections(sections) {
      if (sections) {
        this.sections = sections
        this.section_load_ts = Date.now()
      }
    },

    setUser(user) {
      if (user) {
        this.user = user
        this.user_load_ts = Date.now()
      }
    },

    setPrograms(programs) {
      if (programs) {
        this.programs = programs
        this.program_load_ts = Date.now()
      }
    },

    setLearningPlans(learning_plans) {
      if (learning_plans) {
        this.learning_plans = learning_plans
        this.learning_plan_load_ts = Date.now()
      }
    },

    setIntentions(intentions) {
      if (intentions) {
        this.intentions = intentions
        this.intention_load_ts = Date.now()
      }
    },

    setIntention(intention) {
      const index = this.intentions.findIndex((i) => i.id ===  intention.id)
      this.intentions[index] = intention
    },

    setModalAuth(enabled) {
      this.modalAuthEnabled = enabled
    },

    setLearningPlan(learning_plan) {
      this.learning_plans[learning_plan.identifier] = learning_plan
    },

    toggleActivityOverlay(options = {}) {
      this.isActivityOverlayOpened = !this.isActivityOverlayOpened
      if (options && options.activity_id) {
        this.currentActivityId = options.activity_id
      } else {
        this.currentActivityId = null
      }
    },

    toggleAccountOverlay(options = {}) {
      if (options.context) {
        this.accountOverlayContext = options.context
        this.isAccountOverlayOpened = true
      } else {
        this.isAccountOverlayOpened = false
        this.accountOverlayContext = null
        this.accountOverlayRedirect = null
      }

      if (options.redirect) {
        this.accountOverlayRedirect = options.redirect
      }
    },

    toggleNotificationActivityOverlay(options = {}) {
      this.isNotificationActivityOverlayOpened = !this.isNotificationActivityOverlayOpened
      if (options && options.topic_id) {
        this.currentDiscussionTopicId = options.topic_id
      } else {
        this.currentDiscussionTopicId = null
      }
    },

    toggleResourceOverlay(options = {}) {
      this.isResourceOverlayOpened = !this.isResourceOverlayOpened
      if (options && options.resource) {
        this.currentResource = options.resource
      } else {
        this.currentResource = null
      }
    },

    toggleWelcomeOverlay(options = {}) {
      this.isWelcomeOverlayOpened = !this.isWelcomeOverlayOpened
    },

    toggleAudioPlayback(options = {}) {
      this.isAudioPlaying = !this.isAudioPlaying
    },

    playEpisode(options = {}) {
      if (options && options.audio) {
        if (!options.audio.public && !this.signed_in) {
          if (this.modalAuthEnabled) {
            this.toggleAccountOverlay({
              context: 'auto',
              redirect: window.location.href
            })
          } else {
            window.location.href = `/account/sign-up?redirect_to=${window.location.href}`
          }
          return
        } else if (this.currentAudio.identifier !== options.audio.identifier) {
          this.isAudioPlaying = false
          this.currentAudio = {}
          this.currentEpisodeIndex = null
          this.currentAudio = options.audio
        }
      }

      if (options && options.episode !== null) {
        if (this.currentEpisodeIndex === options.episode) {
          this.isAudioPlaying = !this.isAudioPlaying
        } else {
          if (this.currentAudio && this.currentEpisodeIndex !== null) {
            this.sendStopAnalytics()
          }

          this.currentEpisodeIndex = options.episode
          this.sendStartAnalytics()
          this.isAudioPlaying = true
        }
      }
    },

    startPlayback() {
      if (this.currentAudio) {
        this.isAudioPlaying = true
      }
    },

    pausePlayback() {
      this.isAudioPlaying = false
    },

    sendStartAnalytics() {
      dataLayer.push({
        event:           'episodeStart',
        episodeType:     'series',
        episodeTitle:    this.currentAudio.episodes[this.currentEpisodeIndex].title,
        episodeID:       this.currentAudio.episodes[this.currentEpisodeIndex].id
      })
    },

    sendStopAnalytics() {
      dataLayer.push({
        event:           'episodeStop',
        episodeType:     'series',
        episodeTitle:    this.currentAudio.episodes[this.currentEpisodeIndex].title,
        episodeID:       this.currentAudio.episodes[this.currentEpisodeIndex].id,
        audioProgress:   this.currentAudioStats.progress || 0,
        audioCompletion: this.currentAudioStats.duration ? Math.round(this.currentAudioStats.progress) / Math.round(this.currentAudioStats.duration) : 0
      })
    },

    updateCurrentAudioStats(options = {}) {
      if (options) {
        if (options.progress) this.currentAudioStats.progress = options.progress
        if (options.duration) this.currentAudioStats.duration = options.duration
      }
    },

    resetPlayer() {
      this.isAudioPlaying = false
      this.currentAudio = {}
      this.currentEpisodeIndex = null
    },

    //
    // Actions
    loadHomepageData({ i18n }) {
      return new Promise((resolve) => {
        this.api_client.find('home_page')
          .then(({ data }) => {
            i18n.locale = data.locale
            Object.keys(data.translations).forEach((key) => {
              i18n.setLocaleMessage(key, data.translations[key])
            })

            this.setHomepage()
            this.setHeaderLogo(data.header_logo)
            this.setFooterLogo(data.footer_logo)
            this.setAttributionLogo(data.attribution_logo)
            this.setWelcomeImage(data.welcome_image)
            this.setSupportedLanguages(data.supported_languages)

            resolve(data)
          })
      })
    },

    loadSectionData() {
      return new Promise((resolve) => {
        this.api_client.findAll('sections', { filter: 'in_progress,heroes,interactive,courses,instructors,resources,lessons,learning_plans,audio_series' })
          .then(({ data }) => {
            this.setSections(data)
            resolve(data)
          })
      })
    },

    loadCourseData() {
      this.api_client.findAll('courses', { include: 'instructor' })
        .then(({ data }) => {
          const courses = data.reduce((acc, val) => {
            acc[val.identifier] = val
            return acc
          }
          , {})
          this.setCourses(courses)
        })
    },

    loadLearningPlanData() {
      this.api_client.findAll('learning_plans')
        .then(({ data }) => {
          const learning_plans = data.reduce((acc, val) => {
            acc[val.identifier] = val
            return acc
          }
          , {})
          this.setLearningPlans(learning_plans)
        })
    },

    loadInstructorData() {
      this.api_client.findAll('instructors', {
        include: 'lessons,tags,audio_series.episodes,audio_series.resources.activity,learning_plans',
        fields: {
          lessons: ['identifier', 'title', 'featured_image', 'progress'],
          audio_series: ['identifier', 'title', 'episodes', 'public', 'resources', 'configuration'],
          learning_plans: ['identifier', 'title', 'card_intro', 'card_image']
        }
      })
        .then(({ data }) => {
          const instructors = data.reduce((acc, val) => {
            acc[val.identifier] = val
            return acc
          }
          , {})
          this.setInstructors(instructors)
        })
    },

    loadUserData() {
      this.api_client.find('user')
        .then(({ data }) => {
          this.setUser(data)
        })
    },

    updateUserData(userData) {
      return new Promise((resolve) => {
        this.api_client.update('user', userData)
          .then(({ data }) => {
            this.setUser(data)
            resolve(data)
          })
          .catch((error) => {
            resolve({ error })
          })
      })
    },

    getSearchResults(query, cb) {
      return new Promise((resolve) => {
        this.api_client.findAll('search_results', { tag: query })
          .then(({ data }) => {
            resolve(data)
          })
      })
    },

    loadPrograms() {
      return this.api_client.findAll('programs', { include: 'drivers' })
        .then(({ data }) => {
          this.setPrograms(data)
        })
    },

    loadIntentions(cb) {
      return this.api_client.findAll('intentions')
        .then(({ data }) => {
          this.setIntentions(data)
          data.forEach((intention, _index) => {
            const user_results_store = useUserResultsStore()
            user_results_store.setSlideResult({
              slide_id: intention.result.slide.id,
              slide_type: intention.result.slide.type,
              slide_result: intention.result
            })
          })
          if (typeof (cb) === 'function') {
            cb()
          }
        })
    },

    loadTextPage(page_type) {
      return this.api_client.find('text_pages', page_type)
    },

    updateOfflineStatus(true_or_false) {
      if (this.offline !== true_or_false) this.setOffline(true_or_false)
    },

    getBadgeData(payload) {
      this.api_client.find('badge', payload.id)
        .then(({ data }) => {
          payload.cb(data)
        })
        .catch((error) => {
          payload.cb(error)
        })
    },

    authGateway(event) {
      if (this.modalAuthEnabled && !this.user) {
        if (event) event.preventDefault()

        this.toggleAccountOverlay({
          context: 'auto',
          redirect: event ? event.currentTarget.href : null
        })
      }
    }

  }
})
