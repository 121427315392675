<template>
<a
  :href='driver.link.href'
  class='driver-card'
  target='_blank'
  @click='sendAnalytics'
>
  <div
    class='driver-card_image'
    :style='driverImage'
  />
  <div class='driver-card_name copy-three'>{{ driver.headline }}</div>
  <div class='driver-card_cta copy-four'>{{ driver.cta }}</div>
</a>
</template>

<script>
export default {
  props: ['driver'],

  computed: {
    driverImage() {
      return `background-image: url(${this.driver.image})`
    }
  },

  methods: {
    sendAnalytics() {
      const category = this.$route.name

      const gtm_event = {
        event:                'resourceClick',
        pageCategory:         category,
        resourceTitle:        this.driver.headline,
        resourceSubTitle:     this.driver.cta,
        resourceID:           this.driver.id,
        resourceURL:          this.driver.link.href,
        resourceDestination:  'benefit'
      }

      dataLayer.push(gtm_event)
    }

  }
}
</script>

<style lang='sass' scoped>
  @import './DriverCard'
</style>
