<template>
<div class='free-form-calendar'>
  <date-picker
    v-model:value='selected_date'
    :input-class='input_class'
    :name='option.id'
    type='date'
    format='MM/DD/YYYY'
    valueType='YYYY-MM-DD'
    lang='en'
    :required='option.required'
    placeholder="MM/DD/YYYY"
    :disabled-date='disabledDate'
    @blur='onBlur'
    @change='onChange'
  />
  <label
    v-if="option.text && option.text.length"
    :for='option.id'
  >
    {{ option.text }}
  </label>
</div>
</template>

<script>
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import { isAfter, isBefore, isWeekend, isSameDay } from 'date-fns'
import { isString } from 'lodash'

export default {
  props: [
    'calendar',
    'option',
    'saved_value'
  ],

  emits: ['change'],

  components: {
    DatePicker
  },

  data() {
    return {
      is_error: false,
      selected_date: null
    }
  },

  mounted() {
    this.selected_date = this.saved_value || null
  },

  computed: {
    input_class() {
      return `free-form_input free-form-calendar_input \
        ${this.option.required ? 'required' : ''} \
        ${this.selected_date ? 'not-empty' : ''} \
        ${this.is_error ? 'error' : ''} \
      `
    }
  },

  methods: {
    disabledDate(value) {
      let disabled = false

      // disable weekends
      if (this.calendar.gray_out_weekends && isWeekend(value)) {
        disabled = true
      }

      // disable past dates
      // can be true/false
      // but also can be date string in format YYYY-MM-DD (2023-05-13)
      if (this.calendar.gray_out_past_dates && this.calendar.gray_out_past_dates === true &&
          !isSameDay(Date.parse(value), Date.now()) &&
          isBefore(Date.parse(value), Date.now())
      ) {
        disabled = true
      } else if (this.calendar.gray_out_past_dates && isString(this.calendar.gray_out_past_dates)) {
        const past_date = this.calendar.gray_out_past_dates.replace(/-/g, '/')

        if (isBefore(Date.parse(value), new Date(past_date))) disabled = true
        if (isSameDay(Date.parse(value), new Date(past_date))) disabled = true
      }

      // disable future dates
      // can be true/false
      // but also can be date string in format YYYY-MM-DD (2023-05-17)
      if (this.calendar.gray_out_future_dates && this.calendar.gray_out_future_dates === true &&
          isAfter(Date.parse(value), Date.now())
      ) {
        disabled = true
      } else if (this.calendar.gray_out_future_dates && isString(this.calendar.gray_out_future_dates)) {
        const future_date = this.calendar.gray_out_future_dates.replace(/-/g, '/')

        if (isAfter(Date.parse(value), new Date(future_date))) disabled = true
        if (isSameDay(Date.parse(value), new Date(future_date))) disabled = true
      }

      // restrict year to 2099
      if (isAfter(Date.parse(value), new Date('2099'))) {
        disabled = true
      }

      // disable OR enable custom dates (based on key value, eg "2023_02_04" : true/false)
      if (this.calendar.gray_out_custom_dates && Object.keys(this.calendar.gray_out_custom_dates).length) {
        Object.keys(this.calendar.gray_out_custom_dates).forEach((date) => {
          if (isSameDay(Date.parse(value), Date.parse(date.replace(/_/g, '-')))) {
            disabled = this.calendar.gray_out_custom_dates[date]
          }
        })
      }

      // disable Today
      if (this.calendar.gray_out_today && isSameDay(Date.parse(value), Date.now())) {
        disabled = true
      }

      return disabled
    },

    onBlur() {
      if (this.option.required && !this.selected_date) {
        this.is_error = true
        this.$emit('change', null)
      }
    },

    onChange(value) {
      this.is_error = this.option.required && !value
      this.$emit('change', value)
    }
  }
}
</script>
