<template>
<div class='intention-setter_navigation'>
  <button
    class='intention-setter_navigation_back copy-three'
    :disabled='back_disabled'
    @click.prevent='back'
  >
    {{ $t('back') }}
  </button>

  <div class='intention-setter_navigation_pages'>
    <span class='intention-setter_navigation_current copy-three'>
      {{ current_step }}
    </span>

    <span class='intention-setter_navigation_of copy-three'>/</span>

    <span class='intention-setter_navigation_total copy-three'>
      {{ total_steps }}
    </span>
  </div>

  <button
    class='intention-setter_navigation_next copy-three'
    :disabled='next_disabled'
    @click.prevent='next'
  >
    {{ $t('next') }}
  </button>
</div>
</template>

<script>
export default {
  props: [
    'total_steps',
    'current_step',
    'is_current_step_passed',
    'back',
    'next',
    'can_advance'
  ],

  data() {
    return {
    }
  },

  computed: {

    back_disabled() {
      return !(this.current_step > 1)
    },

    next_disabled() {
      return !this.can_advance || (!this.is_current_step_passed)
    }
  }
}
</script>

<style lang='sass' scoped>
  @import 'Navigation'
</style>
