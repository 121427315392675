<template>
  <div class='search-navigation'>
    <template
      v-for='category in filtered_results'
      :key='category.id'
    >
      <div class='search-navigation_item'>
        <a
          href='#'
          @click.prevent='scrollSection(category.id)'
        >
          {{ category.name }}
          ({{ category.count }})
        </a>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ['results', 'show_program_drivers'],

  computed: {
    filtered_results() {
      const sections = [
        { id: 'courses', name: this.$t('courses'), count: 0 },
        { id: 'instructors', name: this.$t('instructors'), count: 0 },
        { id: 'lessons', name: this.$t('lessons'), count: 0 },
        { id: 'resources', name: this.$t('resources'), count: 0 }
      ]

      if (this.show_program_drivers) {
        sections.push({
          id: 'program_drivers', name: this.$t('related_benefits'), count: 0
        })
      }

      sections.forEach((s) => {
        if (this.results[s.id].length) {
          s.count = this.results[s.id].length
        }
      })

      return sections
    }
  },

  methods: {
    scrollSection(section_id) {
      const $el = $(`.search-results_section[rel="${section_id}"]`)

      if ($el.length) {
        this.$scrollTo($el[0], 500, { offset: -30 })
      }
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './Navigation'
</style>
