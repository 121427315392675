<template>
<div class='instructor-card'>
  <router-link
    :to="link_path"
    class='instructor-card_link'
  >
    <div
      class='instructor-card_image'
      :style='instructorImage'
    >
      <div class='instructor-card_image-overlay'>
        <ArrowIcon />
      </div>
    </div>

    <div class='instructor-card_name heading-five'>
      {{ instructor.full_name }}
    </div>
  </router-link>

  <div class='instructor-card_title copy-three'>
    {{ title }}
  </div>
</div>
</template>

<script>
import ArrowIcon from './more.svg'

export default {
  props: ['instructor'],

  components: {
    ArrowIcon
  },

  computed: {
    instructorImage() {
      return `background-image: url(${this.instructor.detail_image})`
    },

    title() {
      return this.instructor.title
    },

    link_path() {
      return { name: 'instructor', params: { identifier: this.instructor.identifier } }
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './InstructorCard'
</style>
