// Search Result
export default {

  // Attributes
  keyword: null,

  // Relationships
  courses: {
    jsonApi: 'hasMany',
    type: 'courses'
  },

  instructors: {
    jsonApi: 'hasMany',
    type: 'instructors'
  },

  lessons: {
    jsonApi: 'hasMany',
    type: 'lessons'
  },

  resources: {
    jsonApi: 'hasMany',
    type: 'resources'
  },

  program_drivers: {
    jsonApi: 'hasMany',
    type: 'program_drivers'
  }
}
