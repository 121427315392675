<template>
  <div class='quiz-results'>
    <h2 class='quiz-results_title body-four'>
      {{ $t('results') }}
    </h2>

    <div class='quiz-results_progress'>
      <span class='quiz-results_correct-answers'>{{ results_data.correct_answers }}</span>
      <span class='quiz-results_separator'>{{ results_text }}</span>
      <span class='quiz-results_total-questions'>{{ results_data.total_questions }}</span>
    </div>

    <p class='body-one'>
      {{ results_data.result_message }}
    </p>
    <span class='body-two'>{{ results_data.secondary_result_message }}</span>

    <button
      class='quiz-results_continue button-two'
      @click.prevent='finishQuiz'
    >
      {{ $t('continue') }}
    </button>

    <button
      class='quiz-results_retake button-two'
      @click.prevent='retakeQuiz'
      v-html='retake_button_text'
    />
  </div>
</template>

<script>
export default {
  props: [
    'activity_results',
    'quiz',
    'finishQuiz',
    'retakeQuiz'
  ],

  computed: {
    retake_button_text() {
      return this.$t('retake_quiz')
    },

    results_data() {
      const correctAnswers = Object.keys(this.activity_results).reduce((sum, k) => {
        return sum + (this.activity_results[k].is_correct ? 1 : 0)
      }, 0)

      return {
        correct_answers:           correctAnswers,
        total_questions:           this.quiz.questions.length,
        result_message:            this.quiz.result_message,
        secondary_result_message:  this.quiz.secondary_result_message
      }
    },

    results_text() {
      return `${this.$t('correct')} ${this.$t('out_of')}`
    }

  }
}
</script>

<style lang="sass" scoped>
//@import 'lesson/styles/components/quiz/results'
</style>
