// Lesson Section model based on API::V1::Section::LessonSerializer
export default {

  // Relationships
  lessons: {
    jsonApi: 'hasMany',
    type: 'lessons'
  },

  // Images
  gradient_start: null,
  gradient_end: null,

  // Attributes
  headline: null,
  subheadline: null,
  link: null
}
