// model based on API::V1::Activity::V1::PairedComparisonSerializer
export default {

  // Images
  background_image: null,
  gradient_start: null,
  gradient_end: null,

  // Attributes
  identifier: null,
  label: null,
  headline: null,
  subheadline: null,
  cta: null,
  screen_1_description: null,
  screen_1_directions: null,
  screen_2_description: null,
  screen_2_directions: null,
  compare_screen_description: null,
  result_screen_description: null,
  options_type: null,
  step_1_selections: null,
  step_2_selections: null,
  options: null
}
