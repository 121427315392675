<template>
<div
  aria-modal='true'
  role='dialog'
  aria-labelledby='audio-player-fullscreen_headline'
>
  <div class='audio-player-fullscreen'>
    <div
      class='audio-player-fullscreen_bg'
      :style='background_style'
    />

    <button
      ref='close-button'
      class='audio-player-fullscreen_close'
      title='Close Fullscreen'
      aria-label='Close Fullscreen'
      @blur='checkFocus'
      @click.prevent='closeFullscreen'
    >
      <IconClose />
    </button>

    <div class='audio-player-fullscreen_label body-four-light'>
      {{ label }}
    </div>

    <div class='audio-player-fullscreen_content'>
      <div class='audio-player-fullscreen_content-left'>
        <div class='audio-player-fullscreen_cover'>
          <img
            :src='cover'
            alt=''
          >
        </div>
      </div>

      <div class='audio-player-fullscreen_content-right'>
        <h2
          id='audio-player-fullscreen_headline'
          class='audio-player-fullscreen_headline heading-three'
        >
          {{ title }}
        </h2>

        <div
          class='audio-player-fullscreen_description copy-two'
          v-html='description'
        />

        <div
          v-if='transcript_url'
          class='audio-player-fullscreen_transcript'
        >
          <a
            :href='transcript_url'
            class='info-action info-action--transcript'
            target='_blank'
          >
            <IconTranscript class="info-action_icon" />
            <span class="info-action_title">{{ $t('view_transcript') }}</span>
          </a>
        </div>

        <div class='audio-player-fullscreen_player'>
          <!-- Controls -->
          <div class='audio-player_main-controls'>
            <!-- Back -->
            <button
              v-if='player'
              class='audio-player_back'
              :title='$t("audio_player.back")'
              @click.prevent='_seek(local_current_time - 15)'
            >
              <IconBack />
            </button>

            <!-- Play Pause -->
            <button
              v-if='player'
              class='audio-player_play'
              :class="{'is-playing': is_playing}"
              :title='is_playing ? $t("audio_player.pause") : $t("audio_player.play")'
              :aria-label='is_playing ? $t("audio_player.pause") : $t("audio_player.play")'
              @click.prevent='playToggle'
            >
              <IconPause
                class='pause-icon'
              />
              <IconPlay
                class='play-icon'
              />
            </button>

            <!-- Forward -->
            <button
              v-if='player'
              class='audio-player_forward'
              :title='$t("audio_player.forward")'
              @click.prevent='_seek(local_current_time + 30)'
            >
              <IconForward />
            </button>
          </div>

          <div class='audio-player_progress-controls'>
            <!-- Timing Current -->
            <time class='audio-player_timing-current copy-four'>
              {{ timing_current }}
            </time>

            <!-- Timeline -->
            <div class='audio-player_timeline-container'>
              <div class='audio-player_buffer'>
                <div
                  class='audio-player_buffer-progress'
                  :style="{width: loading_progress + '%'}"
                />
              </div>
              <vue-slider
                v-if='duration'
                ref='timeline'
                v-model='local_current_time'
                class='audio-player_timeline'
                tabindex='0'
                role='slider'
                :aria-label="timing_current"
                :aria-valuemin="0"
                :aria-valuemax="Math.round(duration)"
                :aria-valuenow="current_time"
                :aria-valuetext="timing_current"
                :height="10"
                :width="'auto'"
                :max="Math.round(duration)"
                :interval="1"
                :drag-on-click="true"
                :tooltip="'hover'"
                :duration="0"
                :tooltipFormatter="timing_current"
                :lazy="true"
                :contained="false"
                :dot-size="16"
                :use-keyboard="false"
                @change="_seek"
              />
            </div>

            <!-- Timing Total -->
            <time class='audio-player_timing-total copy-four'>
              {{ timing_total }}
            </time>
          </div>

          <div class='audio-player_right-controls'>
            <!-- Volume -->
            <div
              class='audio-player_volume'
              :class="{
                'audio-player_volume--muted': is_muted,
                'audio-player_volume--hi': volume > 80,
                'audio-player_volume--mid': volume > 50,
                'audio-player_volume--low': volume > 0
              }"
              @mouseover="is_volume_control_visible = true"
              @mouseleave="is_volume_control_visible = false"
              @focusin="is_volume_control_visible = true"
              @focusout="is_volume_control_visible = false"
            >
              <button
                class='audio-player_volume-button'
                :title="$t('audio_player.volume') + ': ' + volume"
                :aria-label="$t('audio_player.volume') + ': ' + volume + ', ' + $t('audio_player.volume_a11')"
                @click.prevent="toggleMute()"
              >
                <IconVolume />
              </button>

              <transition name='fast-fade'>
                <div
                  v-if="is_volume_control_visible"
                  class='audio-player_popup audio-player_popup--volume'
                >
                  <vue-slider
                    ref='volume_control'
                    class='audio-player_volume-control'
                    :modelValue="volume"
                    :width="10"
                    :height="120"
                    :direction="'btt'"
                    :drag-on-click="true"
                    :tooltip="'hover'"
                    :duration=".2"
                    :contained="true"
                    :use-keyboard="false"
                    @change="setVolume"
                  />
                </div>
              </transition>
            </div>

            <!-- Playlist -->
            <div class='audio-player_playlist'>
              <button
                class='audio-player_playlist-toggle'
                :title='$t("audio_player.open_playlist")'
                :aria-label='$t("audio_player.open_playlist")'
                :aria-expanded='is_playlist_visible || false'
                aria-controls='audio-player_playlist-popup'
                aria-haspopup='true'
                @click.stop.prevent="togglePlaylist"
              >
                <IconPlaylist />
              </button>

              <transition name='fast-fade'>
                <div
                  v-if="is_playlist_visible"
                  id='audio-player_playlist-popup'
                  class='audio-player_popup audio-player_popup--playlist copy-four'
                >
                  <div class='audio-player_playlist-header'>
                    <router-link
                      :to="{ name: 'audio-overview', params: {identifier: playlist.identifier}}"
                      class='audio-player_playlist-title'
                      @click='playlistHeaderClick'
                    >
                      {{ playlist.title }}
                    </router-link>

                    <router-link
                      :to="{ name: 'audio-overview', params: {identifier: playlist.identifier}}"
                      class='audio-player_playlist-link'
                      @click='playlistHeaderClick'
                    >
                      {{ $t('learn_more') }}
                    </router-link>
                  </div>

                  <ul class='audio-player_playlist-list'>
                    <li
                      v-for='(playlist_item, index) in playlist.episodes'
                      :key='index'
                      class='playlist-item'
                      :class="{
                        'is-active': isEpisodeActive(index)
                      }"
                    >
                      <div
                        class='playlist-item_cover'
                        :style="{backgroundImage: 'url('+ playlist_item.image + ')'}"
                      />

                      <div class='playlist-item_info'>
                        <div class='playlist-item_subtitle'>
                          <span
                            v-if="playlist_item.published_date"
                            class='playlist-item_date'
                          >
                            {{ format_date(playlist_item.published_date) }}
                          </span>

                          <span
                            v-if="playlist_item.duration"
                            class='playlist-item_duration'
                          >
                            {{ playlist_item.duration }}
                          </span>
                        </div>

                        <div class='playlist-item_title'>
                          {{ playlist_item.title }}
                        </div>
                      </div>

                      <div class="playlist-item_buttons">
                        <button
                          v-if='player'
                          class='audio-player_play'
                          :class="{'is-playing': isEpisodePlaying(index)}"
                          :title='$t("audio_player.play") + playlist_item.title'
                          @click.prevent='playEpisode({
                            audio: playlist,
                            episode: index
                          })'
                        >
                          <IconPause
                            class='pause-icon'
                          />
                          <IconPlay
                            class='play-icon'
                          />
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>

            <!-- Settings -->
            <div
              class='audio-player_settings'
              @mouseover="is_speed_control_visible = true"
              @mouseleave="is_speed_control_visible = false"
              @focusin="is_speed_control_visible = true"
              @focusout="is_speed_control_visible = false"
            >
              <button
                class='audio-player_settings-toggle'
                :title='$t("audio_player.change_speed")'
                aria-haspopup='menu'
                aria-controls='audio-player_popup--speed'
                :aria-label="speed + 'x. ' + $t('audio_player.change_speed_a11')"
                :aria-expanded='is_speed_control_visible || "false"'
                @click.prevent="is_speed_control_visible = !is_speed_control_visible"
              >
                <IconGear />
              </button>

              <transition name='fast-fade'>
                <div
                  v-if="is_speed_control_visible"
                  id='audio-player_popup--speed'
                  class='audio-player_popup audio-player_popup--speed'
                  role='menu'
                  aria-labelledby='audio-player_popup--speed__label'
                >
                  <div
                    id='audio-player_popup--speed__label'
                    class='copy-four'
                  >
                    Playback Speed
                  </div>

                  <div
                    v-for='rate in speed_rates'
                    :key='rate'
                    class='audio-player_speed-item'
                  >
                    <button
                      :class="{'active': speed === rate}"
                      class='copy-four'
                      tabindex='-1'
                      role='menuitemradio'
                      :aria-checked='speed === rate'
                      @click.prevent='setRate(rate)'
                    >
                      {{ rate }}x
                    </button>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if='is_resource_carousel_visible'
      class='audio-player-fullscreen_resources'
    >
      <h2
        v-if='is_resource_carousel_headline_visible'
        class='audio-player-fullscreen_resources-headline body-one-light'
      >
        {{ $t('related_resources') }}
      </h2>

      <CardSlider
        :autoplay='false'
        :cards='resources_as_cards'
        :cardClick='resourceClick'
        :centerIfLessItems='true'
        :infinite='false'
        :miniMode='true'
        :speed='4000'
      />
    </div>
  </div>

  <div
    class='audio-player-fullscreen_restricter'
    tabindex='0'
    @focus="setFocus()"
  />
</div>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapActions } from 'pinia'

import CardSlider from 'components/Shared/CardSlider/CardSlider'

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { format, parseISO } from 'date-fns'

import IconClose from    './icons/fullscreen-off.svg'
import IconBack from     './icons/back.svg'
import IconForward from  './icons/forward.svg'
import IconPlay from     './icons/play.svg'
import IconPause from    './icons/pause.svg'
import IconPlaylist from './icons/playlist.svg'
import IconTranscript from './icons/transcript.svg'
import IconVolume from   './icons/volume.svg'
import IconGear from     './icons/gear.svg'

export default {
  props: [
    'label', 'cover', 'title', 'description', 'close',
    'player', 'is_playing', 'playToggle', '_seek', 'transcript_url',
    'current_time', 'duration', 'loading_progress',
    'timing_current', 'timing_total', 'speed', 'speed_rates',
    'setRate', 'volume', 'setVolume', 'toggleMute', 'is_muted',
    'playlist', 'isEpisodePlaying', 'isEpisodeActive', 'playEpisode'
  ],

  components: {
    CardSlider,
    VueSlider,
    IconClose,
    IconBack,
    IconForward,
    IconPlay,
    IconPause,
    IconPlaylist,
    IconTranscript,
    IconVolume,
    IconGear
  },

  data() {
    return {
      is_playlist_visible: false,
      is_volume_control_visible: false,
      is_speed_control_visible: false,
      shift_is_pressed: false
    }
  },

  mounted() {
    $('body').addClass('popup-opened')

    document.body.addEventListener('keyup', this.detectKeyup)
    document.body.addEventListener('keydown', this.detectKeydown)

    this.setFocus()
  },

  beforeUnmount() {
    $('body').removeClass('popup-opened')
    document.body.removeEventListener('keyup', this.detectKeyup)
    document.body.removeEventListener('keydown', this.detectKeydown)
  },

  computed: {
    resources_as_cards() {
      const cards = this.playlist.resources.map((resource) => {
        return {
          id:           resource.id,
          image:        resource.image,
          type:         this._getResourceType(resource),
          headline:     resource.headline,
          activity:     resource.activity
        }
      })
      return cards
    },

    is_resource_carousel_visible() {
      return !!this.playlist && !!this.playlist.configuration && !!this.playlist.configuration.sticky_player_resource_carousel_visible && this.playlist.resources && this.playlist.resources.length
    },

    is_resource_carousel_headline_visible() {
      return !!this.playlist.configuration.sticky_player_resource_carousel_headline_visible
    },

    background_style() {
      if (this.cover) {
        return `background-image: url(${this.cover})`
      }
      return null
    },

    local_current_time: {
      get() {
        return this.current_time
      },

      set(val) {
        this._seek(this.local_current_time)
        /* this.$parent.current_time = val */
      }
    }
  },

  methods: {
    ...mapActions(useHomeStore, [
      'toggleResourceOverlay'
    ]),

    closeFullscreen() {
      this.close()
    },

    setFocus() {
      setTimeout(() => {
        this.$refs['close-button'].focus()
      }, 100)
    },

    checkFocus(e) {
      if (this.shift_is_pressed) {
        const $firstElement = $(this.$el).find('a, input, button').last()
        $firstElement.focus()
      }
    },

    togglePlaylist() {
      this.is_playlist_visible = !this.is_playlist_visible

      if (this.is_playlist_visible) {
        document.body.addEventListener('click', this.playlistListener)
      } else {
        document.body.removeEventListener('click', this.playlistListener)
      }
    },

    playlistListener(e) {
      const playlist_class = '.audio-player_playlist'
      if ($(e.target).parents(playlist_class).length) {
        return false
      } else {
        this.is_playlist_visible = false
        document.body.removeEventListener('click', this.playlistListener)
      }
    },

    playlistHeaderClick(e) {
      this.close()
    },

    resourceClick(resource) {
      this.toggleResourceOverlay({ resource })
    },

    _getResourceType(resource) {
      switch (resource.activity.type) {
        case 'activity_v1_videos': return 'video'
        case 'activity_v1_audios': return 'audio'
        case 'activity_v1_articles': return 'article'
        case 'activity_v1_resources':
          if (resource.activity.link.href.match(/pdf/) !== null) return 'PDF'
          if (resource.activity.link.href.match(/file/) !== null) return 'download'
          break
        default: return 'link'
      }
    },

    _format_date(date) {
      return format(parseISO(date), 'MMMM do, yyyy')
    },

    detectKeyup(e) {
      if ((e.key !== undefined && e.key === 'Escape') ||
        (e.keyCode !== undefined && e.keyCode === 27)) {
        this.close()
      }

      if (e.key === 'Shift') {
        this.shift_is_pressed = false
      }

      switch (e.keyCode) {
        case 38:  // up arrow
          e.preventDefault()
          if (this.is_speed_control_visible) {
            this.setRate(this.speed - 0.25)
          } else {
            this.is_volume_control_visible = true
            this.setVolume(this.volume + 5)
          }
          break
        case 40:  // down arrow
          e.preventDefault()
          if (this.is_speed_control_visible) {
            this.setRate(this.speed + 0.25)
          } else {
            this.is_volume_control_visible = true
            this.setVolume(this.volume - 5)
          }
          break
      }
    },

    detectKeydown(e) {
      if (e.key === 'Shift') {
        this.shift_is_pressed = true
      }
    }

  }
}
</script>

<style lang='sass' scoped>
  @import './AudioPlayer'
  @import './FullscreenDesktop'
</style>
