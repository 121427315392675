<template>
<div class='learning-plans-app'>
  <router-view
    :learning_plans='learning_plans'
    :api_url='api_url'
    :jwt='jwt'
  />
</div>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapState } from 'pinia'

export default {
  computed: {
    ...mapState(useHomeStore, [
      'api_url',
      'learning_plans',
      'jwt'
    ])
  }
}
</script>
