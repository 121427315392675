<template>
<div
  class='card'
  :class="`card--${card.type} card--${card.id} ${ miniMode ? 'card--mini' : '' }`"
  role='group'
  :aria-label="`Card ${ index } of ${ length }.`"
>
  <a
    class='card_overlay-link'
    :class='`card_overlay-link--${card.id}`'
    :href="card.href ? card.href : '#'"
    role='button'
    :aria-label="(card.preheadline ? `${card.preheadline}. ` : '') + (card.headline ? card.headline : '')"
    @click.prevent='cardClick(card, index)'
  />

  <div
    class='card_image'
    :style='background_style'
  >
    <template v-if='card.type === "lessons" && !miniMode'>
      <div class='card_overlay' />
      <span
        role='button'
        aria-hidden='true'
        class='card_resume action-two'
        @click.prevent='cardClick(card)'
      >{{ $t('resume') }}</span>
    </template>

    <span
      v-if='card.checkmark'
      class='card_completion-mark'
      :aria-label="$t('completed')"
      :title="$t('completed')"
    />
  </div>

  <div
    v-if='card.progress !== null'
    class='card_progress-bar'
  >
    <div
      class='card_current-progress'
      :style="{width: '' + card.progress + '%'}"
    >
      <span class='ada-hidden'>
        {{ $t('completed') }} {{ Math.trunc(card.progress) }}%
      </span>
    </div>
  </div>

  <h3
    v-if='card.preheadline'
    aria-hidden='true'
    class='card_preheadline copy-three'
  >
    {{ card.preheadline }}
  </h3>

  <div
    v-if='card.headline'
    aria-hidden='true'
    class='card_headline copy-three'
  >
    {{ card.headline }}
  </div>

  <div
    v-if='card.subheadline'
    aria-hidden='true'
    class='card_subheadline copy-three'
  >
    {{ card.subheadline }}
  </div>

  <!-- Course Info (optional) -->
  <div
    v-if='card.course_info && card.course_info.length > 0'
    class='card_subheadline copy-three'
  >
    <router-link
      :to="{ name: 'courses', params: { identifier: card.course_info[0].identifier } }"
      class='card_subheadline-first_link'
    >
      {{ card.course_info[0].title }}
    </router-link>

    <span
      v-if="card.course_info.length > 1"
      class='card_subheadline-all'
      :title="course_names.join(' + ')"
    >
      +&nbsp;{{ card.course_info.length - 1 }}&nbsp;{{ $tc('resources_page.other_courses', card.course_info.length - 1 ) }}
    </span>
  </div>

  <!-- Card Type Icon -->
  <div
    v-if='!hideType && card.type'
    class='card_type'
    :class='"card_type--" + card.type'
  />
</div>
</template>

<script>
import { RouterLink } from 'vue-router'

export default {
  props: ['card', 'cardClick', 'index', 'length', 'miniMode', 'hideType'],

  components: {
    RouterLink
  },

  computed: {
    background_style() {
      // Background image
      if (this.card.image) {
        return `background-image: url(${this.card.image})`
      }

      if (this.card.activity.background_image) {
        return `background-image: url(${this.card.activity.background_image})`
      }

      // Gradient
      if (!this.card.image &&
        !this.card.activity.background_image &&
        this.card.activity.gradient_start) {
        const start = this.card.activity.gradient_start
        const end = this.card.activity.gradient_end || this.card.activity.gradient_start
        const gradient = `linear-gradient(137deg, ${start} 0%, ${end} 92%)`
        return 'background-image: ' +  gradient
      }

      return null
    },

    course_names() {
      if (this.card.course_info) {
        return this.card.course_info.map(({ title }) => title).sort()
      }

      return null
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './CardSliderCard'
</style>
