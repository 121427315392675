<template>
<div class='course-drawer-popup'>
  <a
    href='#'
    class='course-drawer-popup_close'
    :title="$t('close')"
    @click.prevent='close'
  >
    <CloseIcon />
  </a>

  <template v-if='loading'>
    <div class='course-drawer-popup_loading'>
      <div class='page-loader'>
        <div class='loader' />
      </div>
    </div>
  </template>

  <template v-if='!loading'>
    <div class='course-card course-card--course active'>
      <div
        class='course-card_image'
        :style='courseImage'
      />

      <div
        v-if='course.duration'
        class='course-card_duration'
      >
        {{ course.duration }} {{ $t('minutes') }}
      </div>

      <div class='course-card_inner'>
        <div class='course-card_inner-wrapper'>
          <div class='course-card_title heading-five'>
            {{ course.title }}
          </div>

          <div
            v-if='instructor_attribution'
            class='course-card_attribution copy-three'
          >
            {{ instructor_attribution }}
          </div>
        </div>
      </div>
    </div>

    <Overview
      :course='course'
      is_mobile='true'
    />

    <div class='course-drawer-popup_content'>
      <div class='course-drawer-popup_nav'>
        <a
          href='#'
          class='main-nav-link'
          :class='tabClass("lessons")'
          @click.prevent='changeTab("lessons")'
        >
          {{ $t('lessons') }}
        </a>

        <a
          v-if='course.resources && course.resources.length > 0'
          href='#'
          class='main-nav-link'
          :class='tabClass("resources")'
          @click.prevent='changeTab("resources")'
        >
          {{ $t('resources') }}
        </a>

        <a
          v-if='course.program_drivers && course.program_drivers.length > 0'
          href='#'
          class='main-nav-link'
          :class='tabClass("drivers")'
          @click.prevent='changeTab("drivers")'
        >
          {{ $t('related_benefits') }}
        </a>
      </div>

      <div
        v-if='activeTab === "lessons"'
        :key='"lessons"'
        class='course-drawer-popup_list'
      >
        <LessonCard
          v-for='(lesson, index) in course.lessons'
          :key='index'
          :lesson='lesson'
          :index='index'
        />
      </div>

      <div
        v-if='activeTab === "resources"'
        :key='"resources"'
        class='course-drawer-popup_list'
      >
        <ResourceCardSmall
          v-for='(resource, index) in course.resources'
          :key='index'
          :resource='resource'
        />
      </div>

      <div
        v-if='activeTab === "drivers"'
        :key='"benefits"'
        class='course-drawer-popup_list'
      >
        <DriverCard
          v-for='(driver, index) in course.program_drivers'
          :key='index'
          :driver='driver'
        />
      </div>
    </div>
  </template>
</div>
</template>

<script>
import Overview from '../Overview/CourseDrawerOverview'
import LessonCard from '../LessonCard/LessonCard'
import DriverCard from '../DriverCard/DriverCard'
import ResourceCardSmall from 'home/components/shared/ResourceCardSmall/ResourceCardSmall'
import Course from 'home/models/course'
import CloseIcon from './close.svg'

export default {
  props: [
    'identifier',
    'setOpenedDrawer',
    'setOpenedCourse'
  ],

  components: {
    Overview,
    LessonCard,
    DriverCard,
    ResourceCardSmall,
    CloseIcon
  },

  data() {
    return {
      loading: false,
      course: null,
      activeTab: 'lessons'
    }
  },

  created() {
    this.loadCourse()
    $('body').addClass('popup-opened')
  },

  beforeUnmount() {
    $('body').removeClass('popup-opened')
  },

  computed: {
    courseImage() {
      return `background-image: url(${this.course.card_image})`
    },

    instructor_attribution() {
      return (this.course.instructor && this.$t('attribution.with', { value: this.course.instructor.full_name })) || null
    }

  },

  methods: {
    close() {
      this.setOpenedDrawer(false)
      this.setOpenedCourse(null)
      $('body').removeClass('popup-opened')
    },

    loadCourse() {
      if (this.identifier === null) return false

      this.loading = true
      this.course = Course.load(this.identifier, () => {
        this.loading = false
      })
    },

    tabClass(tab) {
      return tab === this.activeTab ? 'active' : ''
    },

    changeTab(tab) {
      this.activeTab = tab
    }
  }
}
</script>

<style lang='sass' scoped>
  @import 'home/styles/shared/CourseCard'
</style>

<style lang='sass' scoped>
  @import 'home/styles/shared/CourseDrawerPopup'
</style>
