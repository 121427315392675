<template>
  <transition name='fast-fade'>
    <div
      v-if='ready'
      ref='activity-overlay'
      class="slide-article_overlay"
    >
      <div
        class='slide-article_top'
        :style='articleOverlayStyle'
      >
        <div class='slide-article_top_inner'>
          <div class='slide-article_top_subheadline body-four-light'>
            {{ activity.subheadline }}
          </div>

          <h1 class='slide-article_top_headline h-one-light'>
            {{ activity.headline }}
          </h1>
        </div>
      </div>

      <div
        class='slide-article_body'
        v-html='activity.content'
      />

      <div class='slide-article_bottom'>
        <button
          class='slide-article_keep button-one-branded'
          @click.prevent='skip'
        >
          {{ $t('keep_learning') }}
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import HypeDetectorMixin from 'components/activities/hype-detector-mixin'

export default {
  mixins: [HypeDetectorMixin],

  props: [
    'activity',
    'toggle',
    'goToNextSlide',
    'slide'
  ],

  data() {
    return {
      ready: false
    }
  },

  created() {
    this.ready = true
  },

  methods: {
    skip() {
      // we should scroll to the next slide if only we're in the context of article slide
      if (this.slide.activity.type === 'activity_v1_articles') {
        this.goToNextSlide()
      }
      this.toggleOverlay()
    },

    toggleOverlay() {
      this.hypeBecameHidden()
      this.toggle()
    }
  },

  computed: {
    articleOverlayStyle() {
      if (this.activity.background_image) {
        return `background-image: url(${this.activity.background_image})`
      }

      // Gradient Background
      if (!this.activity.background_image && this.activity.gradient_start) {
        const start = this.activity.gradient_start
        const end = this.activity.gradient_end || this.activity.gradient_start
        const gradient = `linear-gradient(137deg, ${start} 0%, ${end} 92%)`
        return {
          backgroundImage: gradient
        }
      }
      return null
    },

    label() {
      return this.activity.label || this.$t('article')
    }
  }

}
</script>

<style lang='sass'>
@import 'overlay'
</style>
