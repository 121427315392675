// Course Section model based on API::V1::Section::CourseSerializer
export default {

  // Relationships
  courses: {
    jsonApi: 'hasMany',
    type: 'courses'
  },

  // Images
  gradient_start: null,
  gradient_end: null,

  // Attributes
  headline: null,
  subheadline: null,
  link: null,
  position: 0,
  configuration: {}
}
