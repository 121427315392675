// Course Section model based on API::V1::Section::HeroSerializer
export default {

  // Relationships
  pages: {
    jsonApi: 'hasMany',
    type: 'hero_section_pages'
  },

  // Attributes
  headline: null,
  subheadline: null,
  link: null
}
