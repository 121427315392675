<template>
<div
  :class='main_class'
  class='notification-item'
>
  <a
    :href='link_href'
    class='notification-item_overlay-link'
    @click='linkAction(notification, $event)'
  />

  <div class='notification-item_inner'>
    <!-- Avatar -->
    <Avatar
      v-if="notification.avatar"
      :className="'notification-item_avatar'"
      :image="notification.avatar !== 'default' ? notification.avatar : ''"
    />

    <!-- Message -->
    <div class='notification-item_message copy-four'>
      <div
        ref='headline'
        class='notification-item_headline'
        v-html='notification.headline'
      />

      <blockquote
        v-if='notification.teaser && notification.teaser !== "<p></p>"'
        class='notification-item_teaser'
        v-html='notification.teaser'
      />

      <div class='notification-item_footer'>
        <span
          :title="notification_time"
          class='notification-item_time'
        >
          {{ notification_age }}
        </span>

        <a
          :href='link_href'
          class='notification-item_link'
          @click='linkAction(notification, $event)'
        >
          {{ notification.target.link.title }}
        </a>
      </div>
    </div>

    <!-- Featured Image -->
    <a
      v-if="notification.featured_image"
      :href='notification.featured_image.href'
      :style="{backgroundImage: 'url(' + notification.featured_image.image + ')'}"
      class='notification-item_image'
      title=''
      @click.prevent='featuredLinkClick(notification, $event)'
    />
  </div>
</div>
</template>

<script>
import { format, parseISO, formatDistanceToNow } from 'date-fns'
import { enUS, es, pt } from 'date-fns/locale'
import Avatar from 'components/Avatar/UserAvatar'

export default {
  props: ['notification', 'clickAction', 'featuredClickAction'],

  data() {
    return {
      locales: {
        en : enUS,
        es,
        pt
      }
    }
  },

  components: {
    Avatar
  },

  mounted() {
    // add listener for a link in the headline
    this.$refs.headline
      .getElementsByTagName('a')[0]
      .addEventListener('click', (event) => {
        event.preventDefault()
        this.featuredClickAction(event.target.getAttribute('href'))
      })
  },

  computed: {

    notification_time() {
      return format(parseISO(this.notification.timestamp), 'PPpp', { locale: this.locales[this.$i18n.locale] })
    },

    notification_age() {
      return formatDistanceToNow(parseISO(this.notification.timestamp), { locale: this.locales[this.$i18n.locale], addSuffix: true })
    },

    link_href() {
      if (this.notification.target.type === 'activity') {
        return '#'
      } else if (this.notification.target.type === 'course') {
        return '/home/courses/' + this.notification.target.link.identifier  + '?tab=lessons'
      } else if (this.notification.target.type === 'learning_plan') {
        return '/home/learning-plans/' + this.notification.target.link.identifier
      } else {
        return this.notification.target.link.href
      }
    },

    main_class() {
      const classes = []
      classes.push('notification-item--' + this.notification.target.type)
      if (!this.notification.read) classes.push('notification-item--unread')

      return classes
    }
  },

  methods: {
    linkAction(notification, event) {
      this.clickAction(notification)

      if (notification.target.type === 'activity') {
        event.preventDefault()
      }
    },

    featuredLinkClick(notification, event) {
      event.preventDefault()
      this.featuredClickAction(notification.featured_image.href)
    }
  }
}
</script>

<style lang='sass' scoped>
@import './Notification'
</style>
