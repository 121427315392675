<template>
  <div class='paired-comparison-select'>
    <h2 class='paired-comparison_title body-four'>
      {{ step_header_title }}
    </h2>

    <div class='paired-comparison_text h-four'>
      {{ compare_screen_description }}
    </div>

    <h3 class='paired-comparison_info-text body-four-branded'>
      {{ $t('this_or_that') }}
    </h3>

    <div class='paired-comparison-select_pairs'>
      <transition
        name='fast-fade'
        mode="out-in"
      >
        <select-card
          :option='pair(1)'
          :selectOption='selectOption'
          :optionSelected='isOptionSelected(pair(1))'
          :optionKey='currentPair'
        />
      </transition>

      <transition
        name='fast-fade'
        mode="out-in"
      >
        <select-card
          :option='pair(2)'
          :selectOption='selectOption'
          :optionSelected='isOptionSelected(pair(2))'
          :optionKey='currentPair'
        />
      </transition>
    </div>

    <div class='paired-comparison-select_counter'>
      <span> {{ currentPair }} </span>
      <span>{{ $t('of') }}</span>
      <span>{{ pairs.length }}</span>
    </div>
  </div>
</template>

<script>
import SelectCard from './compare/paired-comparison-v1-compare-card'

export default {
  props: [
    'total_steps',
    'pairs',
    'setStep',
    'submit',
    'activity_results',
    'activity'
  ],

  components: {
    SelectCard
  },

  data() {
    return {
      currentPair: 1,
      values: {},
      selected: {}
    }
  },

  computed: {
    compare_screen_description() {
      return this.activity.compare_screen_description
    },

    step_header_title() {
      return `${this.$t('step')} ${this.total_steps + 1} ${this.$t('of')} ${this.total_steps + 1}`
    }
  },

  methods: {
    selectOption(option) {
      this.selected[this.currentPair] = option.id

      // Count sum
      this.values[option.id] = this.values[option.id] + 1 || 1

      if (this.currentPair < this.pairs.length) {
        // Delay before go to next step
        setTimeout(() => {
          this.currentPair++
        }, 500)
      } else {
        this.finishComparison(this.values)
      }
    },

    finishComparison(values) {
      this.activity_results.results = values
      this.submit()
      this.setStep('results')
    },

    pair(step) {
      const id = this.pairs[this.currentPair - 1][step - 1]

      if (this.activity.options_type === 'author_defined') {
        return this.activity.options.find(o => o.id === id)
      } else { // 'user_defined'
        return this.activity_results.select_1.find(o => o.id === id)
      }
    },

    isOptionSelected(option) {
      return this.selected[this.currentPair] === option.id
    }
  }
}
</script>
