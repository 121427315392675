<template>
  <div class="course-drawer-popup">
    <a
      href='#'
      class='course-drawer-popup_close'
      :title="$t('close')"
      @click.prevent='close'
    >
      <CloseIcon />
    </a>

    <template v-if="loading">
      <div class='course-drawer-popup_loading'>
        <div class='page-loader'>
          <div class='loader' />
        </div>
      </div>
    </template>

    <template v-else>
      <div class='course-card course-card--learning-plan active'>
        <div
          v-if='learning_plan.duration'
          class='course-card_duration'
        >
          {{ learning_plan.duration }} {{ $t('minutes') }}
        </div>

        <div
          class='course-card_image'
          :style='learning_plan_image'
        />

        <div class='course-card_inner'>
          <div class='course-card_inner-wrapper'>
            <div class='course-card_title heading-four'>
              {{ learning_plan.title }}
            </div>
            <div class='course-card_attribution copy-three'>
              {{ learning_plan.card_intro }}
            </div>
          </div>
        </div>
      </div>

      <Overview
        :learning_plan='learning_plan'
        is_mobile
      />

      <div class='course-drawer-popup_content'>
        <div class='course-drawer-popup_nav'>
          <a
            href='#'
            :class="tabClass('lessons')"
            class='main-nav-link'
            @click.prevent="changeTab('lessons')"
          >
            {{ $tc('learning_plan_lesson', 2) }}
          </a>
        </div>

        <div
          v-if="activeTab === 'lessons'"
          :key="'lessons'"
          class='course-drawer-popup_list'
        >
          <LessonCard
            v-for='(lesson, index) in learning_plan.lessons'
            :key='index'
            :learning_plan='learning_plan'
            :lesson='lesson'
            :index='index'
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Overview from './LearningPlanDrawerOverview'
import LessonCard from './LessonCard/LessonCard'
import LearningPlan from 'home/models/learning_plan'
import CloseIcon from './close.svg'

export default {
  props: {
    identifier: { type: [Number, String], required: true },
    setOpenedDrawer: { type: Function, required: true },
    setOpenedLearningPlan: { type: Function, required: true }
  },

  components: {
    Overview,
    LessonCard,
    CloseIcon
  },

  data() {
    return {
      loading: false,
      activeTab: 'lessons',
      learning_plan: null
    }
  },

  created() {
    $('body').addClass('popup-opened')

    this.loadLearningPlan()
  },

  beforeUnmount() {
    $('body').removeClass('popup-opened')
  },

  computed: {
    learning_plan_image() {
      return `background-image: url(${this.learning_plan.card_image})`
    }
  },

  methods: {
    close() {
      this.setOpenedDrawer(false)
      this.setOpenedLearningPlan(null)

      $('body').removeClass('popup-opened')
    },

    loadLearningPlan() {
      this.loading = true
      this.learning_plan = LearningPlan.load(
        this.identifier,
        () => {
          this.loading = false
        }
      )
    },

    tabClass(tab) {
      return tab === this.activeTab ? 'active' : ''
    },

    changeTab(tab) {
      this.activeTab = tab
    }
  }
}
</script>

<style lang='sass' scoped>
  @import 'home/styles/shared/CourseCard'
</style>

<style lang='sass' scoped>
  @import 'home/styles/shared/CourseDrawerPopup'
</style>
