<template>
<div class='resourses-filters'>
  <h3 class='resourses-filters_heading copy-two'>
    {{ $tc("resources_page.filter", 2) }}
  </h3>

  <template
    v-for='category in categories'
    :key='category.type'
  >
    <div class='resourses-filters_section'>
      <a
        href='#'
        class='resourses-filters_section-heading copy-two'
        @click.prevent='toggleSection(category.type)'
      >
        {{ category.name }}
        <ArrowUpIcon v-if='sectionExpanded(category.type)' />
        <ArrowDownIcon v-if='!sectionExpanded(category.type)' />
      </a>

      <ul v-if='sectionExpanded(category.type)'>
        <li
          v-for='(item, index) in category.items'
          :key='index'
        >
          <label class='copy-four'>
            <input
              type='checkbox'
              :name='category.type'
              :value='item.title'
              :checked='isChecked(item.title, category.type)'
              @change='applyFilter(category.type, item.title, item.identifier)'
            >
            <CheckedIcon v-if='isChecked(item.title, category.type)' />
            <UnCheckedIcon v-if='!isChecked(item.title, category.type)' />
            <span>{{ item.title }}</span>
          </label>
        </li>
      </ul>
    </div>
  </template>
</div>
</template>

<script>
import FiltersMixin from './FiltersMixin'

export default {
  mixins: [FiltersMixin]
}
</script>

<style lang='sass' scoped>
  @import './Filters'
</style>
