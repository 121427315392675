<template>
<div
  class='intention-setter'
  :class="main_class"
>
  <button
    v-if='should_show_close'
    ref='close-button'
    class='intention-setter_close'
    @click.prevent='toggleOverlay'
    @blur='checkFocus'
  >
    {{ $t('close') }}
  </button>

  <h1
    v-if='!submit_in_progress'
    class='intention-setter_label body-four'
  >
    {{ activity.label || $t('intentions.intention_setter') }}
  </h1>

  <!-- Preloader -->
  <PageLoader v-if='submit_in_progress' />

  <transition name="fast-fade">
  <!-- Intention is set, show results -->
  <template v-if='has_result && !submit_in_progress'>
    <div class="intention-setter_step intention-setter_step--results">
      <h2
        v-if="is_just_submitted"
        class='intention-setter_step-title copy-one'
      >
        {{ $t('intentions.submitted_title') }}
      </h2>

      <h2
        v-if="show_congrats"
        class='intention-setter_step-title copy-one'
      >
        {{ $t('intentions.congratulations') }}
      </h2>

      <div class="intention-setter_results copy-three">
        <div class="intention-setter_results-card">
          <span class='intention-setter_results-icon'>
            <template v-if="is_finished">
              <CompletedIcon />
            </template>
          </span>

          <img
            ref="resultsImage"
            class="intention-setter_results-image"
            alt=""
            :src="activity.result_image"
          >

          <div class="intention-setter_results-heading">
            <div class="intention-setter_results-subtitle copy-three">
              {{ $t('intentions.my_intention') }}
            </div>

            <h2 class='intention-setter_step-title intention-setter_results-title copy-one'>
              {{ selected_intention ? selected_intention.text : '' }}
            </h2>

            <div class="intention-setter_results-date">
              {{ is_finished ? $t('intentions.completed') : $t('intentions.due') }}
              {{ formatted_date }}
            </div>
          </div>
        </div>

        <template v-if="!is_just_submitted && !show_congrats && !is_finished">
          <template v-if="notifications_enabled">
            <CustomSwitch
              :value='is_notifications_selected'
              :label_on="$t('intentions.notification_on')"
              :label_off="$t('intentions.notification_off')"
              @input='is_notifications_selected = $event'
              @change='toggleNotifications'
            />

            <div
              class='intention-setter_notification-description copy-three'
              v-html="$t('intentions.notification_description')"
            />

            <template v-if='should_show_sms_email_toggle && is_editing_notifications'>
              <TextEmailSwitch
                :notification_type='selected_notifications_type'
                :user='user'
                :mobile_number_error='mobile_number_error'
                @validation='updateValidation'
                @notification-type-change='updateNotificationType'
                @mobile-number-change='updatePhoneNumber'
              />

              <button
                class="intention-setter_close-confirm_continue action-one-branded"
                :disabled='!is_form_valid'
                @click.prevent='submit'
              >
                {{ $t('intentions.save') }}
              </button>

              <button
                class="intention-setter_close-confirm_leave copy-three"
                @click.prevent='cancelEditingNotifications'
              >
                {{ $t('cancel') }}
              </button>
            </template>
          </template>

          <button
            v-if='!should_show_sms_email_toggle || !is_editing_notifications'
            class="intention-setter_close-confirm_continue action-one-branded"
            @click.prevent='complete'
          >
            {{ $t('intentions.complete') }}
          </button>
        </template>

        <slot v-if="is_just_submitted || show_congrats || is_finished" />
      </div>
    </div>
  </template>
  </transition>

  <!-- Intention is not yet set -->
  <template v-if='!has_result && !submit_in_progress'>
    <!-- Step 1. Intention -->
    <div
      v-if='current_step_index === 1'
      class="intention-setter_step intention-setter_step--intention"
    >
      <h2 class='intention-setter_step-title copy-one'>
        {{ intention_step.headline }}
      </h2>

      <TextOptions
        :options='intentions'
        :selectOption='selectIntention'
        :isOptionSelected='isIntentionSelected'
      />
    </div>

    <!-- Step 2. Date -->
    <div
      v-if='current_step_index === 2'
      class="intention-setter_step intention-setter_step--date"
    >
      <h2 class='intention-setter_step-title copy-one'>
        {{ goal_date_step.headline }}
      </h2>

      <TextOptions
        :options='goal_dates'
        :selectOption='selectDateOption'
        :isOptionSelected='isDateSelected'
      />

      <form
        v-if="activity.configuration.datepicker_enabled"
        class="intention-setter_date-form"
      >
        <h2 class='intention-setter_step-title copy-one'>
          {{ goal_date_step.secondary_headline }}
        </h2>

        <date-picker
          v-model:value='selected_goal_date'
          input-class='intention-setter_date-input'
          name='goal_date'
          :disabled-date='disabledDate'
          type='date'
          format='MM/DD/YYYY'
          lang='en'
          valueType='YYYY-MM-DD'
          :placeholder="'MM/DD/YYYY'"
          @change='selectDatepicker'
        />
      </form>
    </div>

    <!-- Step 3. Confirmation -->
    <div
      v-if='current_step_index === 3'
      class="intention-setter_step intention-setter_step--confirmation"
    >
      <div class="intention-setter_results copy-three">
        <div class="intention-setter_results-card">
          <img
            class="intention-setter_results-image"
            alt=""
            :src="activity.result_image"
          >

          <div class="intention-setter_results-heading">
            <div class="intention-setter_results-subtitle copy-three">
              {{ $t('intentions.my_intention') }}
            </div>

            <h2 class='intention-setter_step-title intention-setter_results-title copy-one'>
              {{ selected_intention.text }}
            </h2>

            <div class="intention-setter_results-date">
              {{ $t('intentions.due') }} {{ formatted_date }}
            </div>
          </div>
        </div>

        <template v-if="notifications_enabled">
          <CustomSwitch
            :value='is_notifications_selected'
            :label_on="$t('intentions.notification_on')"
            :label_off="$t('intentions.notification_off')"
            @input='is_notifications_selected = $event'
          />

          <div
            class='intention-setter_notification-description copy-three'
            v-html="$t('intentions.notification_description')"
          />

          <TextEmailSwitch
            v-if='should_show_sms_email_toggle'
            :notification_type='selected_notifications_type'
            :user='user'
            :mobile_number_error='mobile_number_error'
            @validation='updateValidation'
            @notification-type-change='updateNotificationType'
            @mobile-number-change='updatePhoneNumber'
          />
        </template>

        <button
          class="intention-setter_close-confirm_continue action-one-branded"
          :disabled='should_show_sms_email_toggle && !is_form_valid'
          @click.prevent="submit"
        >
          {{ $t('intentions.set') }}
        </button>

        <button
          class="intention-setter_close-confirm_leave copy-three"
          @click.prevent="restart"
        >
          {{ $t('intentions.restart') }}
        </button>
      </div>
    </div>

    <Navigation
      v-if='!show_close_confirm && current_step_index !== 3'
      :total_steps='total_steps'
      :current_step='current_step_index'
      :is_current_step_passed='is_current_step_passed'
      :next='next'
      :back='back'
      :can_advance='can_advance'
    />

    <CloseConfirm
      v-if='has_close_confirm && show_close_confirm'
      :trigger='early_close_trigger'
      :toggle='toggle'
    />
  </template>

  <div
    class='intention-setter_restricter'
    tabindex='0'
    @focus="setFocus()"
  />
</div>
</template>

<script>
import { useRootStore } from 'lesson/stores/root'
import { useHomeStore } from 'home/stores/home'
import { mapActions } from 'pinia'

import Navigation from './IntentionSetterV1Navigation'
import TextOptions from './options/IntentionSetterV1Text'
import CloseConfirm from './IntentionSetterV1CloseConfirm'
import CustomSwitch from './IntentionSetterV1Switch'
import TextEmailSwitch from './IntentionSetterV1TextEmailSwitch'
import PageLoader from 'components/PageLoader/PageLoader'
import { format, isBefore, isAfter, endOfDay, add } from 'date-fns'
import { enUS, es, pt } from 'date-fns/locale'
import Confetti from 'canvas-confetti'
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import CompletedIcon from './options/completed.svg'

export default {

  props: [
    'slide',
    'slide_result',
    'activity',
    'toggle',
    'updateSlideResult',
    'api_client'
  ],

  emits: ['update'],

  data() {
    return {
      activity_results: {},
      selected_intention: null,
      selected_goal_date: null,
      is_notifications_selected: true,
      selected_notifications_type: null, // sms/email/none
      is_just_submitted: false,
      is_form_valid: false,
      is_editing_notifications: false,
      show_congrats: false,
      total_steps: 3,
      current_step_index: 1,
      show_close_confirm: false,
      submit_user_in_progress: false,
      submit_results_in_progress: false,
      can_advance: true,
      user: null,
      mobile_number_error: null,
      locales: {
        en : enUS,
        es,
        pt
      },
      shift_is_pressed: false
    }
  },

  mounted() {
    this.user = JSON.parse(JSON.stringify(this.state_user))

    if (Object.keys(this.slide_result.results).length && this.slide_result.results.current_intention) {
      this.activity_results = JSON.parse(JSON.stringify(this.slide_result.results))

      this.selected_intention = this.intentions.find(obj => obj.id === this.activity_results.current_intention)
      this.selected_goal_date = this.current_intention_results.completion_date || this.current_intention_results.goal_date
      this.is_notifications_selected =
        this.current_intention_results.notifications === 'email' ||
        (this.current_intention_results.notifications === 'sms' && this.is_user_subscribed)
      this.selected_notifications_type = this.current_intention_results.notifications || this.default_notification_type
    } else {
      // Set is_notifications_selectedto false in case notifications are disabled / notifications switch is not shown
      this.is_notifications_selected = this.notifications_enabled
      this.selected_notifications_type = this.default_notification_type
    }

    if (this.has_single_intention) {
      this.selected_intention = this.intentions[0]
      this.current_step_index = 2
    }

    document.body.addEventListener('keyup', this.detectKeyup)
    document.body.addEventListener('keydown', this.detectKeydown)

    this.setFocus()
  },

  watch: {
    current_step_index: function(index) {
      setTimeout(() => {
        $('.intention-setter_step button').first().focus()
      }, 300)
    }
  },

  beforeUnmount() {
    document.body.removeEventListener('keyup', this.detectKeyup)
    document.body.removeEventListener('keydown', this.detectKeydown)
  },

  components: {
    DatePicker,
    Navigation,
    TextOptions,
    CloseConfirm,
    CustomSwitch,
    TextEmailSwitch,
    CompletedIcon,
    PageLoader
  },

  computed: {
    state_user() {
      // Unfortunately we need to access different stores
      // in case we need to use component in different state contexts.
      if (this.$router.currentRoute.value.name === 'my_activity') {
        const home_store = useHomeStore()
        return home_store.user
      } else {
        const root_store = useRootStore()
        return root_store.user
      }
    },

    main_class() {
      const classes = []
      if (this.has_result || this.current_step_index === 3) classes.push('has-result')
      return classes
    },

    intentions() {
      return this.activity.intentions
    },

    brand_color() {
      const default_color = '#5BE7E0'
      const element = this.$refs.resultsImage
      return element ? this.rgbToHex(window.getComputedStyle(element).backgroundColor) : default_color
    },

    current_intention_results() {
      return this.activity_results[this.activity_results.current_intention]
    },

    has_single_intention() {
      return this.intentions.length === 1
    },

    notifications_enabled() {
      return this.activity.configuration.notifications_enabled
    },

    goal_dates() {
      return this.activity.goal_dates.map((d) => {
        const goal_date = endOfDay(add(new Date(), { [d.units] : d.amount_of_units }))
        return {
          text: d.text || this.$tc(`in.${d.units}`, d.amount_of_units),
          dateUTC: goal_date.toISOString()
        }
      })
    },

    formatted_date() {
      if (this.selected_goal_date) {
        const utcDate = new Date(this.selected_goal_date)
        return format(utcDate, 'PP', { locale: this.locales[this.$i18n.locale] })
      }
      return null
    },

    is_current_step_passed() {
      return (this.current_step_index === 1 && this.selected_intention) ||
        (this.current_step_index === 2 && this.selected_goal_date)
    },

    is_finished() {
      return this.slide_result && this.slide_result.event === 'finished'
    },

    is_user_subscribed() {
      return this.user.sms_subscribed
    },

    has_result() {
      return !!this.slide_result && !!this.slide_result.results &&
        Object.keys(this.slide_result.results).length &&
        this.slide_result.results.current_intention
    },

    has_close_confirm() {
      return !!this.early_close_trigger
    },

    early_close_trigger() {
      return this.activity.early_close_trigger
    },

    default_notification_type() {
      // set default notification type based on the configuration
      return this.state_user.sms_enabled ? 'sms' : 'email'
    },

    should_show_sms_email_toggle() {
      return this.is_notifications_selected &&
             this.state_user.sms_enabled
    },

    should_show_close() {
      return !this.show_close_confirm &&
          !this.submit_in_progress
    },

    submit_in_progress() {
      return this.submit_results_in_progress || this.submit_user_in_progress
    },

    intention_step() {
      return this.activity.intention_step
    },

    goal_date_step() {
      return this.activity.goal_date_step
    }

  },

  methods: {
    ...mapActions(useRootStore, [
      'updateUserData'
    ]),

    selectIntention(option) {
      // Prevent user's fastclicking
      if (!this.can_advance) return false
      this.can_advance = false

      this.selected_intention = option

      // Auto Advance to next step
      setTimeout(() => {
        this.next()
        this.can_advance = true
      }, 800)
    },

    isIntentionSelected(option) {
      return this.selected_intention === option
    },

    selectDatepicker(value) {
      // Prevent user's fastclicking
      if (!this.can_advance) return false
      this.can_advance = false

      if (value) {
        this.selected_goal_date = endOfDay(new Date(value)).toISOString()

        // Auto Advance to next step
        setTimeout(() => {
          this.next()
          this.can_advance = true
        }, 800)
      } else {
        this.can_advance = true
      }
    },

    selectDateOption(option) {
      // Prevent user's fastclicking
      if (!this.can_advance) return false
      this.can_advance = false

      this.selected_goal_date = option.dateUTC

      // Auto Advance to next step
      setTimeout(() => {
        this.next()
        this.can_advance = true
      }, 800)
    },

    isDateSelected(option) {
      return this.selected_goal_date === option.dateUTC
    },

    disabledDate(value) {
      return this.activity.configuration.datepicker_limit
        ? isBefore(value, new Date()) || isAfter(value,
          add(
            new Date(),
            {
              [this.activity.configuration.datepicker_limit.units] : this.activity.configuration.datepicker_limit.amount_of_units
            }
          )
        )
        : isBefore(value, new Date())
    },

    toggleNotifications() {
      if (this.is_notifications_selected) {
        this.is_editing_notifications = true
        if (this.current_intention_results.notifications === 'none') {
          this.selected_notifications_type = this.default_notification_type
        }
        if (!this.should_show_sms_email_toggle) this.submit()
      } else {
        this.is_editing_notifications = false
        this.submit()
      }
    },

    cancelEditingNotifications() {
      this.is_editing_notifications = this.is_notifications_selected = false
    },

    submit() {
      this.submit_results_in_progress = true
      this.show_close_confirm = false
      this.is_editing_notifications = false

      // form the activity results object
      this.activity_results.current_intention = this.selected_intention.id
      this.activity_results[this.selected_intention.id] = {
        created_at:     new Date().toISOString(),
        goal_date:      this.selected_goal_date,
        notifications:  this.is_notifications_selected ? this.selected_notifications_type : 'none'
      }

      // if sms notifications turned on - update user before updating slide results
      if (this.is_notifications_selected && this.selected_notifications_type === 'sms') {
        this.submit_user_in_progress = true

        this.updateUserData(this.user).then((data) => {
          if (data.error) {
            Object.keys(data.error).forEach((key) => {
              if (key === 'mobile_number') {
                this.is_form_valid = false
                this.is_editing_notifications = true
                this.submit_user_in_progress = this.submit_results_in_progress = false
                this.mobile_number_error = data.error[key].detail
                console.log('Mobile number error: ', this.mobile_number_error)
              }
            })
          } else {
            this.user = JSON.parse(JSON.stringify(this.state_user))
            this.submit_user_in_progress = false

            // update activity results only if user was updated sucessfully
            this.updateSlideResult({
              slide_id:    this.slide.id,
              slide_type:  this.slide.type,
              event:       'submitted',
              payload:     this.activity_results,
              callback: (updated_results) => {
                // emit parent's update event (to refresh data if needed)
                this.$emit('update', updated_results)
                this.submit_results_in_progress = false
                if (this.current_step_index !== 1) {
                  this.is_just_submitted = true
                }
              }
            })
          }
        })
      } else {
        // if email notifications or notifications turned off

        this.updateSlideResult({
          slide_id:    this.slide.id,
          slide_type:  this.slide.type,
          event:       'submitted',
          payload:     this.activity_results,
          callback: (updated_results) => {
            // emit parent's update event (to refresh data if needed)
            this.$emit('update', updated_results)
            this.submit_results_in_progress = false
            if (this.current_step_index !== 1) {
              this.is_just_submitted = true
            }
          }
        })
      }
    },

    restart() {
      this.activity_results = {}
      this.selected_intention = null
      this.selected_goal_date = null
      // Set is_notifications_selectedto false in case notifications are disabled / notifications switch is not shown
      this.is_notifications_selected = this.notifications_enabled
      this.selected_notifications_type = this.default_notification_type
      if (this.has_single_intention) {
        this.selected_intention = this.intentions[0]
        this.current_step_index = 2
      } else {
        this.current_step_index = 1
      }
    },

    complete() {
      this.submit_results_in_progress = true
      this.show_close_confirm = false

      this.updateSlideResult({
        slide_id:    this.slide.id,
        slide_type:  this.slide.type,
        event:       'finished',
        callback: (updated_results) => {
          // emit parent's update event (to refresh data if needed)
          this.$emit('update', updated_results)
          this.activity_results = updated_results.results
          this.selected_goal_date = this.current_intention_results.completion_date
          this.submit_results_in_progress = false
          this.show_congrats = true
          this.$nextTick(() => {
            this.showConfetti()
          })
        }
      })
    },

    next() {
      if (this.current_step_index < this.total_steps) {
        this.current_step_index++
      }
    },

    back() {
      this.current_step_index--
    },

    updateNotificationType(type) {
      this.selected_notifications_type = type
    },

    updatePhoneNumber(number) {
      this.user.mobile_number = number
    },

    updateValidation(value) {
      this.is_form_valid = value
    },

    toggleOverlay() {
      // this.show_close_confirm = !this.show_close_confirm
      if (!this.has_result && this.has_close_confirm) {
        this.show_close_confirm = !this.show_close_confirm
      } else {
        this.toggle()
      }
    },

    showConfetti() {
      const end = Date.now() + (3 * 1000)
      const colors = [this.brand_color, '#ffffff'];

      (function frame() {
        Confetti({
          particleCount: 2,
          angle: 60,
          spread: 55,
          origin: { x: 0 },
          colors
        })
        Confetti({
          particleCount: 2,
          angle: 120,
          spread: 55,
          origin: { x: 1 },
          colors
        })

        if (Date.now() < end) {
          requestAnimationFrame(frame)
        }
      }())
    },

    detectKeyup(e) {
      if ((e.key !== undefined && e.key === 'Escape') ||
        (e.keyCode !== undefined && e.keyCode === 27)) {
        this.toggleOverlay()
      }

      if (e.key === 'Shift') {
        this.shift_is_pressed = false
      }
    },

    detectKeydown(e) {
      if (e.key === 'Shift') {
        this.shift_is_pressed = true
      }
    },

    rgbToHex(rgb) {
      // Choose correct separator
      const sep = rgb.indexOf(',') > -1 ? ',' : ' '
      // Turn "rgb(r,g,b)" into [r,g,b]
      rgb = rgb.substr(4).split(')')[0].split(sep)

      let r = (+rgb[0]).toString(16)
      let g = (+rgb[1]).toString(16)
      let b = (+rgb[2]).toString(16)

      if (r.length === 1) r = '0' + r
      if (g.length === 1) g = '0' + g
      if (b.length === 1) b = '0' + b

      return '#' + r + g + b
    },

    setFocus() {
      setTimeout(() => {
        this.$refs['close-button'] && this.$refs['close-button'].focus()
      }, 100)
    },

    checkFocus(e) {
      if (this.shift_is_pressed) {
        const $firstElement = $(this.$el).find('.intention-setter_step').find('a, input, button').last()
        $firstElement.focus()
      }
    }

  }
}
</script>

<style lang='sass' scoped>
  @import 'main'
</style>
