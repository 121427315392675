<template>
<VeeForm
  v-slot="{ meta, errors }"
  class='sign-up-page_form'
  name='signup'
  @submit='submitForm'
>
  <div class='sign-up-form_field-group'>
    <!-- First Name -->
    <div class='sign-up-form_field'>
      <Field
        v-slot="{ field }"
        v-model='first_name'
        rules='required|first_name'
        name='user_first_name'
      >
        <input
          id='first_name'
          type='text'
          v-bind='field'
          :class="{'not-empty': first_name }"
          aria-describedby="err_fn"
        >
      </Field>
      <label for='first_name'>{{ $t('first_name') }}</label>
      <span
        v-if="errors.user_first_name"
        id='err_fn'
        class='form-error mobile'
      >
        {{ errors.user_first_name }}
      </span>
    </div>

    <!-- Last Name -->
    <div class='sign-up-form_field'>
      <Field
        v-slot="{ field }"
        v-model='last_name'
        rules='required|last_name'
        name='user_last_name'
      >
        <input
          id='last_name'
          type='text'
          v-bind='field'
          :class="{'not-empty': last_name }"
          aria-describedby="err_ln"
        >
      </Field>
      <label for='last_name'>{{ $t('last_name') }}</label>
      <span
        v-if="errors.user_last_name"
        id='err_ln'
        class='form-error mobile'
      >
        {{ errors.user_last_name }}
      </span>
    </div>

    <span
      v-if="errors.user_first_name"
      id='err_fn'
      class='form-error not-mobile'
    >
      {{ errors.user_first_name }}
    </span>

    <span
      v-if="errors.user_last_name"
      id='err_ln'
      class='form-error not-mobile'
    >
      {{ errors.user_last_name }}
    </span>
  </div>

  <div
    v-if='demographicsEnabledField("birthday") || demographicsEnabledField("sex")'
    class='sign-up-form_field-group'
  >
    <!-- Birthday -->
    <div
      v-if='demographicsEnabledField("birthday")'
      class='sign-up-form_field sign-up-form_field--birthday'
    >
      <Field
        v-slot="{ field }"
        v-model='birthday'
        :rules='birthday_validation_rules'
        name='user_birthday'
      >
        <date-picker
          v-model:value='birthday'
          input-class='sign-up-form_birthday-input'
          name='user_birthday'
          v-bind='field'
          :disabled-date='disabledDate'
          type='date'
          format='MM/DD/YYYY'
          lang='en'
          valueType='YYYY-MM-DD'
          :placeholder="$t('birthday_placeholder')"
        />
      </Field>
      <label for='user_birthday'>{{ $t('birthday_title') }}</label>
      <span
        v-if="errors.user_birthday"
        id='err_dob'
        class='form-error mobile'
      >
        {{ $t('birthday_error_message') }}
      </span>
    </div>

    <!-- Sex -->
    <div
      v-if='demographicsEnabledField("sex")'
      class='sign-up-form_field sign-up-form_field--sex'
    >
      <Field
        v-model='user_sex'
        class='sign-up-form_sex-select'
        :class="user_sex ? 'selected': ''"
        as='select'
        :rules='sex_validation_rules'
        name='user_sex'
      >
        <option
          v-if="!user_sex"
          value=''
        >
          {{ $t('select_sex') }}
        </option>

        <option
          v-for="(gender, index) in genders"
          :key="index"
          :value="gender"
        >
          {{ $t(gender) }}
        </option>
      </Field>

      <label for='user_sex'>{{ $t('sex') }}</label>
      <span
        v-if="errors.user_sex"
        id='err_sex'
        class='form-error mobile'
      >
        {{ $t('please_enter_correct_sex') }}
      </span>
    </div>

    <span
      v-if="errors.user_birthday"
      id='err_dob'
      class='form-error not-mobile'
    >
      {{ $t('birthday_error_message') }}
    </span>

    <span
      v-if="errors.user_sex"
      id='err_sex'
      class='form-error not-mobile'
    >
      {{ $t('please_enter_correct_sex') }}
    </span>
  </div>

  <div class='sign-up-form_field'>
    <Field
      v-slot="{ field }"
      v-model='user_email'
      rules='required|email|email_invalid'
      name='user_email'
    >
      <input
        id='user_email'
        type='text'
        v-bind='field'
        :class="{'not-empty': user_email }"
        aria-describedby="err_email"
        @input='resetWrongState("email")'
        @change='resetWrongState("email")'
      >
    </Field>

    <label for='user_email'>{{ $t('email') }}</label>

    <span
      v-if="!email_invalid && errors.user_email"
      id='err_email'
      class='form-error'
    >
      {{ $t('email_error_message') }}
    </span>

    <span
      v-if="email_invalid"
      id='err_email'
      class='form-error'
    >
      {{ serverMessage.email }}
    </span>
  </div>

  <div
    v-if='!with_otp'
    class='sign-up-form_field'
  >
    <Field
      v-slot="{ field }"
      v-model='password'
      rules='required|password'
      name='user_password'
    >
      <input
        id='user_password'
        type='password'
        autocomplete='new-password'
        v-bind='field'
        :class="{'not-empty': password }"
        aria-describedby="err_pw"
      >
    </Field>

    <label for='user_password'>{{ $t('password') }}</label>
    <span
      v-if="errors.user_password"
      id='err_pw'
      class='form-error'
    >
      {{ $t('password_error_message') }}
    </span>
  </div>

  <!-- Phone -->
  <div
    v-if='demographicsEnabledField("phone")'
    class='sign-up-form_field'
  >
    <Field
      v-slot="{ field }"
      v-model='user_phone'
      rules='phone'
      name='user_phone'
    >
      <input
        id='user_phone'
        type='tel'
        v-bind='field'
        :class="{'not-empty': user_phone }"
        aria-describedby="err_phone"
      >
    </Field>

    <label for='user_phone'>{{ $t('phone_number') }}</label>
    <span
      v-if="errors.user_phone"
      id='err_phone'
      class='form-error'
    >
      {{ errors.user_phone }}
    </span>
  </div>

  <!-- Organization Name -->
  <div
    v-if='demographicsEnabledField("organization")'
    class='sign-up-form_field'
  >
    <Field
      v-slot="{ field }"
      v-model='user_organization'
      rules='organization'
      name='user_organization'
    >
      <input
        id='user_organization'
        type='text'
        v-bind='field'
        :class="{'not-empty': user_organization }"
      >
    </Field>

    <label for='user_organization'>{{ $t('organization_name') }}</label>
  </div>

  <!-- Zip -->
  <div
    v-if='demographicsEnabledField("zip")'
    class='sign-up-form_field'
  >
    <Field
      v-slot="{ field }"
      v-model='user_zipcode'
      :rules='zip_validation_rules'
      name='user_zipcode'
    >
      <input
        id='user_zipcode'
        type='text'
        v-bind='field'
        :class="{'not-empty': user_zipcode }"
        aria-describedby="err_zip"
      >
    </Field>

    <label for='user_zipcode'>{{ $t('zip_code') }}</label>
    <span
      v-if="errors.user_zipcode"
      id='err_zip'
      class='form-error mobile'
    >
      {{ $t('please_enter_correct_zipcode') }}
    </span>

    <span
      v-if="errors.user_zipcode"
      id='err_zip'
      class='form-error not-mobile'
    >
      {{ $t('please_enter_correct_zipcode') }}
    </span>
  </div>

  <div
    v-if='!company_code_hidden'
    class='sign-up-form_field company-code'
  >
    <Field
      v-slot="{ field }"
      v-model='company_code'
      rules='required|company_code'
      name='company_code'
    >
      <input
        id='company_code'
        type='text'
        v-bind='field'
        :class="{'not-empty': company_code }"
        aria-describedby="err_comp_code"
        @input='resetWrongState("company_code")'
        @change='resetWrongState("company_code")'
      >
    </Field>

    <label for='company_code'>{{ $t('your_company_code') }}</label>
    <span
      v-if="wrong_company_code"
      id='err_comp_code'
      class='form-error'
    >
      {{ serverMessage.company_code }}
    </span>
  </div>

  <div
    v-else
    class='sign-up-form_field ada-hidden'
  >
    <input
      v-if='user'
      id='session_company_code'
      v-model='user.company_code'
      name='session_company_code'
      type='text'
      :class="{'not-empty': user.company_code }"
    >

    <label for='session_company_code'>{{ $t('your_company_code') }}</label>
  </div>

  <div class='sign-up-form_field'>
    <input
      type='submit'
      :value="$t('sign_up')"
      class='sign-up-form_submit-button action-one-branded'
      :disabled='!meta.valid'
      @click.prevent='submitForm'
    >
  </div>
</VeeForm>
</template>

<script>
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import { createUser } from '../../api'
import { format, isAfter, isBefore, parseISO } from 'date-fns'

import { Form as VeeForm, Field } from 'vee-validate'
import { defineRule } from 'vee-validate'
import {
  required,
  email
} from '@vee-validate/rules'

export default {
  props: ['user', 'api_url', 'subdomain'],

  data() {
    return {
      wrong_company_code: false,
      email_invalid: false,
      serverMessage: {},

      first_name: null,
      last_name: null,
      user_email: null,
      user_phone: null,
      user_organization: null,
      password: null,
      company_code: null,

      birthday: null,
      user_sex: null,
      user_zipcode: null
    }
  },

  components: {
    DatePicker,
    VeeForm,
    Field
  },

  created() {
    this._extendValidators()
    this._extendAdditionalValidators()

    // Defaults:
    this._setAutoPopulateFields()
  },

  computed: {
    company_code_hidden() {
      return this.configurationValue('academy_partner_open') || (this.user && !!this.user.company_code)
    },

    with_otp() {
      return this.configurationValue('sign_up_type') === 'with_otp'
    },

    genders() {
      return this.demographicsEnabledField('sex')
        ? this.configurationValue('demographics_sex').range
        : false
    },

    birthday_before() {
      let limit = 120
      if (this.configurationValue('demographics_birthday') &&
        this.configurationValue('demographics_birthday').range[1] !== null
      ) {
        limit = this.configurationValue('demographics_birthday').range[1]
      }
      return this.dateLimit(limit)
    },

    birthday_after() {
      let limit = 0
      if (this.configurationValue('demographics_birthday') &&
          this.configurationValue('demographics_birthday').range[0] !== 0
      ) {
        limit = this.configurationValue('demographics_birthday').range[0]
      }
      return this.dateLimit(limit)
    },

    zip_validation_rules() {
      return this.demographicsRequiredField('zip') ? 'required|zip' : 'zip'
    },

    sex_validation_rules() {
      return this.demographicsRequiredField('sex') ? 'required|sex' : 'sex'
    },

    birthday_validation_rules() {
      return this.demographicsRequiredField('birthday') ? 'required|birthday' : 'birthday'
    }


  },

  methods: {
    submitForm() {
      const user_data = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.user_email,
        password: this.password,
        password_confirmation: this.password,
        birthday: this.birthday,
        sex: this.user_sex,
        zip: this.user_zipcode,
        phone: this.user_phone,
        organization: this.user_organization
      }

      if (!this.user || !this.user.company_code) {
        user_data.company_code = this.company_code
      }

      createUser(this.api_url, this.subdomain, user_data, (data) => {
        if (data.error) {
          this.showErrors(data.error)
        } else {
          this.afterSignUpCallback()
        }
      })
    },

    showErrors(errors) {
      let targetElement

      Object.keys(errors).forEach((key) => {
        if (key === 'email') {
          this.serverMessage[key] = errors[key].detail
          this.email_invalid = true
          targetElement = '#user_email'
          document.signup.user_email.focus()
        }

        if (key === 'company_code') {
          this.serverMessage[key] = errors[key].detail
          this.wrong_company_code = true
          document.signup.company_code.focus()

          if (!this.user || !this.user.company_code) {
            targetElement = '#company_code'
          } else {
            Tbk.alerts.new(this.serverMessage[key], 'error')
          }
        }
      })

      this._validateFields(targetElement)
    },

    resetWrongState(field) {
      if (field == 'company_code' && this.wrong_company_code) {
        this.wrong_company_code = false
      }
      if (field == 'email' && this.email_invalid) {
        this.email_invalid = false
      }
    },

    afterSignUpCallback() {
      if (this.with_otp) {
        window.location.reload()
      } else {
        window.location = this.$root.redirect_to_location || window.location.origin
      }
    },

    configurationValue(key) {
      return this.$root.configuration &&
             this.$root.configuration.hasOwnProperty(key) &&
             this.$root.configuration[key]
    },

    dateLimit(limit) {
      const date = new Date()
      if (limit) {
        const limitMilliseconds = 1000 * 60 * 60 * 24 * 365.25 * limit
        date.setTime(date.getTime() - limitMilliseconds)
      }
      return date
    },

    disabledDate(value) {
      return isBefore(Date.parse(value), this.birthday_before) ||
        isAfter(Date.parse(value), this.birthday_after)
    },

    demographicsEnabledField(field) {
      const enabled_fields = this.configurationValue('sign_up_demographics_enabled')
      return enabled_fields && enabled_fields.indexOf(field) !== -1
    },

    demographicsRequiredField(field) {
      return this.configurationValue('demographics_' + field).required
    },

    _extendValidators() {
      // validation rules
      defineRule('required', required);

      defineRule('first_name', (value) => {
        const regexp = /^[a-zA-Z\s\u0080-\u024F][a-zA-Z-.\s'\u0080-\u024F]*$/
        return regexp.test(value) || this.$t('your_first_name_is_not_valid')
      })

      defineRule('last_name', (value) => {
        const regexp = /^[a-zA-Z\s\u0080-\u024F][a-zA-Z-.\s'\u0080-\u024F]*$/
        return regexp.test(value) || this.$t('your_last_name_is_not_valid')
      })

      defineRule('email', email);

      defineRule('email_invalid', (value) => {
        return !this.email_invalid || this.serverMessage.email
      })

      defineRule('password', (value) => {
        return value?.length >= 8
      })

      defineRule('company_code', (value) => {
        return !this.wrong_company_code || this.serverMessage.company_code
      })
    },

    _extendAdditionalValidators() {
      defineRule('zip', (value) => {
        let regexp = /^[A-Za-z0-9'\-\u0080-\u024F]*$/
        if (this.configurationValue('demographics_zip') && this.configurationValue('demographics_zip').match) {
          let match_value = this.configurationValue('demographics_zip').match
          match_value = match_value.replace('\\A', '^').replace('\\z', '$')
          regexp = new RegExp(match_value)
        }
        return regexp.test(value) || this.$t('please_enter_correct_zipcode')
      })

      defineRule('sex', (value) => {
        return this._isSexValid(value)
      })

      defineRule('birthday', (value) => {
        return new Date(value).toString() !== 'Invalid Date'
      })

      defineRule('phone', (value) => {
        if (value && value !== '') {
          const valid_regexp = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g
          return valid_regexp.test(value) || 'Be sure to enter a valid phone number.'
        } else {
          return true
        }
      })

      defineRule('organization', (value) => {
        return true // No validations for this field for now
      })
    },

    _setAutoPopulateFields() {
      if (this.user && this.user.first_name) { this.first_name = this.user.first_name }
      if (this.user && this.user.last_name) { this.last_name = this.user.last_name }
      if (this.user && this.user.email) { this.user_email = this.user.email }
      if (this.user && this.user.phone) { this.user_phone = this.user.phone }
      if (this.user && this.user.organization) { this.user_organization = this.user.organization }
      if (this.user && this.user.zip) { this.user_zipcode = this.user.zip }
      if (this.user && this.user.sex) { this.user_sex = this._isSexValid(this.user.sex) ? this.user.sex : null }

      if (this.user && this.user.birthday) {
        if (this._isDateValid(this.user.birthday)) {
          this.birthday = format(parseISO(this.user.birthday), 'yyyy-MM-dd')
        }
      }

      if (this.first_name || this.last_name ||
        this.user_email || this.user_zipcode ||
        this.user_phone || this.user_organization ||
        this.user_sex || this.birthday
      ) {
        setTimeout(() => {
          this._validateFields()
        }, 1000)
      }
    },

    _validateFields(targetElement) {
      if (targetElement) {
        this.$scrollTo(targetElement, 200, { offset: -30 })
      }
    },

    _isDateValid(value) {
      return new Date(value) !== 'Invalid Date'
    },

    _isSexValid(value) {
      if (this.configurationValue('demographics_sex') &&
          this.configurationValue('demographics_sex').range &&
          this.configurationValue('demographics_sex').range.indexOf(value) !== -1
      ) {
        return true
      }
      return false
    }
  }
}

</script>

<style lang='sass' scoped>
  @import './SignUpForm.sass'
</style>
