<template>
<div class='audio-section'>
  <div
    class='audio-section_bg'
    :style='main_background'
  />

  <div class='audio-section_inner'>
    <div class='audio-section_cover'>
      <img
        class='audio-section_cover-image'
        :src='section.image'
        alt=''
       >
     </div>

    <div class='audio-section_content'>
      <h2 class='audio-section_headline heading-four'>
        {{ section.headline }}
      </h2>

      <p class='audio-section_description copy-one'>
        {{ section.subheadline }}
      </p>

      <div class='audio-section_controls'>
        <span
          v-if='section.configuration.cta_1_visible && !is_audio_playing'
          class='ada-hidden'
        >
          {{ $t('audio_player.play_a11_pretext') }}
        </span>

        <!-- Play Pause -->
        <button
          v-if='section.configuration.cta_1_visible'
          class='audio-section_play action-one-branded'
          role='alert'
          @click.prevent='playToggle'
        >
          {{ play_button_text }}
        </button>

        <!-- Learn More -->
        <router-link
          v-if='section.configuration.cta_2_visible'
          :to="{ name: 'audio-overview', params: {identifier: section.audio_series.identifier} }"
          class='audio-section_more action-one-branded'
        >
          {{ $t('learn_more') }}
        </router-link>
      </div>

      <!-- External Links -->
      <div
        v-if='spotify_link || apple_link'
        class="audio-section_links copy-one"
      >
        <a
          v-if='spotify_link'
          class='copy-two audio-section_link-spotify'
          :href='spotify_link.url'
          target='_blank'
          rel="noreferrer"
        >
          <SpotifyIcon />
          {{ $t('audio_overview.listen_on') }} Spotify
        </a>

        <a
          v-if='apple_link'
          class='copy-two audio-section_link-apple'
          :href='apple_link.url'
          target='_blank'
          rel="noreferrer"
        >
          <AppleIcon />
          {{ $t('audio_overview.listen_on') }} Apple Podcasts
        </a>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapState, mapActions } from 'pinia'

import SpotifyIcon from './spotify.svg'
import AppleIcon from './apple.svg'

export default {
  props: ['section'],

  data() {
    return {
      spotify_icon: require('./spotify.svg').default,
      apple_icon: require('./apple.svg').default
    }
  },

  components: {
    SpotifyIcon,
    AppleIcon
  },

  computed: {
    ...mapState(useHomeStore, {
      is_audio_playing: 'isAudioPlaying',
      currentAudio: 'currentAudio'
    }),

    play_button_text() {
      if (this.section.audio_series.identifier === this.currentAudio.identifier) {
        return this.is_audio_playing
          ? this.$t('audio_player.pause')
          : this.$t('audio_player.resume')
      }
      return this.$t('audio_player.play')
    },

    main_background() {
      // Image
      if (this.section.background_image) {
        return `background-image: url(${this.section.background_image})`
      }

      // Gradient Background
      if (!this.section.background_image && this.section.gradient_start) {
        const start = this.section.gradient_start
        const end = this.section.gradient_end || this.section.gradient_start
        const gradient = `linear-gradient(137deg, ${start} 0%, ${end} 92%)`
        return {
          backgroundImage: gradient
        }
      }

      return null
    },

    spotify_link() {
      return this.section.audio_series.external_links.filter((l) => l.type === 'spotify')[0]
    },

    apple_link() {
      return this.section.audio_series.external_links.filter((l) => l.type === 'apple_podcasts')[0]
    }

  },

  methods: {
    ...mapActions(useHomeStore, [
      'playEpisode',
      'startPlayback',
      'pausePlayback'
    ]),

    playToggle(episodeIndex) {
      if (this.section.audio_series.identifier === this.currentAudio.identifier) {
        if (this.is_audio_playing) {
          this.pausePlayback()
        } else {
          this.startPlayback()
        }
      } else {
        this.playEpisode({
          audio: this.section.audio_series,
          episode: 0
        })
      }
    }
  }
}
</script>

<style lang='sass'>
  @import 'AudioSection'
</style>
