<template>
<a
  :href='`/lessons/${lesson.id}/continue`'
  class='lesson-card-small'
>
  <div
    class='lesson-card-small_image'
    :style='lessonImage'
  />

  <div class='lesson-card-small_content'>
    <div class='lesson-card-small_title'>
      {{ lesson.title }}
    </div>

    <div class='lesson-card-small_description'>
      {{ description }}
    </div>
  </div>

</a>
</template>

<script>
export default {
  props: ['lesson'],

  computed: {
    lessonImage() {
      return `background-image: url(${this.lesson.featured_image})`
    },

    description() {
      return this.$t('attribution.from', { value: this.lesson.course.title })
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './LessonCardSmall'
</style>
