import BaseClient from './base-client'

export default class YoutubeClient extends BaseClient {
  constructor(videoCode) {
    super(videoCode)
    this.baseURL = 'https://www.youtube.com/embed'
  }

  getEmbedCode(callback) {
    callback(`<iframe src="${this._embedURL}" width="560" height="315" frameborder="0" allowfullscreen></iframe>`)
  }

  pause(element) {
    $(element).find('iframe').each(function() {
      $(this)[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
    })
  }

  get _embedURL() {
    return `${this.baseURL}/${this.videoCode}?enablejsapi=1`
  }
}
