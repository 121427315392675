<template>
  <div class='quiz-results'>
    <h2 class='quiz-results_title body-four'>
      {{ $t('results') }}
    </h2>

    <div class='quiz-results_image'>
      <img
        :src='results_data.result_image'
        alt=''
      >
    </div>

    <p
      class='body-one'
      v-html='results_data.headline'
    />

    <span
      class='body-two'
      v-html='results_data.subheadline'
    />

    <button
      class='quiz-results_continue button-two'
      @click.prevent='finishQuiz'
    >
      {{ $t('continue') }}
    </button>

    <button
      class='quiz-results_retake button-two'
      @click.prevent='retakeQuiz'
      v-html='retake_button_text'
    />
  </div>
</template>

<script>
export default {
  props: [
    'activity_results',
    'quiz',
    'finishQuiz',
    'retakeQuiz'
  ],

  computed: {
    retake_button_text() {
      return this.$t('retake_self_assessment')
    },

    results_data() {
      let total = 0

      total = this.quiz.questions.reduce((accum, question) => {
        const selected = question.options.find((opt) => {
          return this.activity_results[question.id] === opt.id
        })
        return accum + selected.weight
      }, total)

      return this.quiz.results.find((result) => {
        return result.min_threshold <= total && result.max_threshold >= total
      })
    }
  }
}
</script>

<style lang="sass" scoped>
//@import 'lesson/styles/components/quiz/results'
</style>
