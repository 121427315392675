<template>
<div
  id='discussion-app'
  class='discussion-app'
>
  <PageLoader
    v-if='is_topic_loading'
    theme='white'
  />

  <transition name='fast-fade'>
    <div
      v-if='!is_topic_loading'
      class='discussion-app_inner'
    >
      <h1 class='discussion-app_label copy-three'>
        {{ label || $t('discussion') }}
      </h1>

      <h2 class='discussion-app_headline heading-four'>
        {{ discussion_topic.headline }}
      </h2>

      <p
        class='discussion-app_description copy-one'
        v-html='discussion_topic.introduction'
      />

      <div class='discussion-app_comment-form'>
        <DiscussionCommentForm
          :createComment='createComment'
          :supported_file_types='supported_file_types'
          parent_id='null'
          thread_id='null'
        />
      </div>

      <div
        ref='switcher'
        class='discussion-app_posts-switcher'
        role='tablist'
      >
        <button
          id='panel-switch-all'
          :class="postsSwitcherClass('all')"
          class='discussion-app_posts-switch copy-two'
          :aria-selected='posts_type === "all"'
          aria-controls="panel-tab-all"
          role='tab'
          @click.prevent="switchPosts('all')"
        >
          {{ $t('discussions.all_posts') }}
        </button>

        <button
          id='panel-switch-my_activity'
          :class="postsSwitcherClass('my_activity')"
          class='discussion-app_posts-switch copy-two'
          :aria-selected='posts_type === "my_activity"'
          aria-controls="panel-tab-my_activity"
          role='tab'
          @click.prevent="switchPosts('my_activity')"
        >
          {{ $t('discussions.my_activity') }}
        </button>
      </div>

      <div
        :id='"discussion-comments panel-tab-" + posts_type'
        class='discussion-app_comments'
        :class="{'loading': is_comments_loading}"
        :aria-labelledby='"panel-switch-" + posts_type'
        role="tabpanel"
      >
        <h3 class='discussion-app_comments-title heading-six'>
          {{ $t('recent_discussion_activity') }}
        </h3>

        <div class='discussion-app_comments-list'>
          <template v-if='comments && comments.length'>
            <transition-group name='fast-fade-in'>
              <DiscussionComment
                v-for='comment in comments'
                :key='comment.id'
                :createComment='createComment'
                :editComment='editComment'
                :deleteComment='deleteComment'
                :thread_id='comment.id'
                :comment='comment'
                :api_client='api_client'
              />
            </transition-group>
          </template>

          <p
            v-if='!is_comments_loading && !comments.length'
            class='no-comments'
          >
            {{ $t('no_discussion_activity') }}
          </p>
        </div>

        <Pagination
          v-if='total_pages > 1'
          :total_pages='total_pages'
          :goToPage='fetchComments'
          :current_page='page_number'
          :setPage='setPage'
        />
      </div>

      <div class='discussion-app_bottom'>
        <slot />
      </div>
    </div>
  </transition>
</div>
</template>

<script>
import DiscussionComment from './DiscussionComment'
import DiscussionCommentForm from './DiscussionCommentForm'
import Pagination from './DiscussionPagination'
import PageLoader from 'components/PageLoader/PageLoader'

export default {
  props: [
    'discussion_topic_id',
    'api_client',
    'slide',
    'label',
    'filter',
    'updateSlideResult'
  ],

  components: {
    PageLoader,
    Pagination,
    DiscussionComment,
    DiscussionCommentForm
  },

  data() {
    return {
      is_topic_loading: true,
      is_comments_loading: true,

      last_updated_comment: null,
      posts_type: 'all',
      current_page: 1,

      discussion_topic: null,
      comments: null,
      total_pages: null,
      page_number: 1
    }
  },

  mounted() {
    if (this.filter) this.posts_type = this.filter

    this.fetchDiscussionTopic()
    this.fetchComments()
  },

  watch: {
    last_updated_comment: function(value) {
      setTimeout(() => {
        const el = document.getElementById(value)

        const containerId = document.getElementById('activity-overlay-content') ? '#activity-overlay-content' : '#discussion-app'
        this.$scrollTo(el, {
          container: containerId,
          duration: 200,
          offset: -15
        })
      }, 300)
    }
  },

  computed: {
    supported_file_types() {
      return this.$root.configuration.discussions_supported_uploads
    }
  },

  methods: {
    scrollToSwitcher() {
      const el = this.$refs.switcher
      const containerId = document.getElementById('activity-overlay-content') ? '#activity-overlay-content' : '#discussion-app'

      this.$scrollTo(el, {
        container: containerId,
        duration: 200,
        offset: 10
      })
    },

    switchPosts(type) {
      this.posts_type = type
      this.is_comments_loading = true
      this.setPage(1)
      this.fetchComments()
    },

    postsSwitcherClass(type) {
      return this.posts_type === type ? 'active' : ''
    },

    setPage(page) {
      this.page_number = page
    },

    fetchDiscussionTopic() {
      this.api_client.find('discussion_topic', this.discussion_topic_id)
        .then((data) => {
          this.discussion_topic = data.data
          this.is_topic_loading = false
        })
    },

    fetchComments() {
      this.is_comments_loading = true

      const params = {
        topic_id: this.discussion_topic_id,
        'page[number]': this.page_number,
        type: this.posts_type
      }

      this.api_client.findAll('discussion_comments', params)
        .then((data) => {
          // Case when next page has no comments after deletion on current page
          if (this.page_number > 1 && data.data.length === 0) {
            this.setPage(this.page_number - 1)
            this.fetchComments()
            return false
          }

          if (this.page_number > 1 || this.posts_type === 'my_activity') {
            this.scrollToSwitcher()
          }

          this.comments = data.data
          this.total_pages = data.meta.total_pages
          this.is_comments_loading = false
        })
    },

    createComment(comment, callback) {
      const post_data = comment

      this.api_client.create('discussion_comment', post_data, { topic_id: this.discussion_topic_id })
        .then(({ data }) => {
          const gtmEvent = { pageCategory: 'discussion' }

          // first level comment
          if (comment.thread_id === 'null') {
            this.comments.unshift(data)
            gtmEvent.event = 'discussionComment'
          }

          // first level reply
          if (comment.thread_id !== 'null' &&
              comment.thread_id === comment.parent_id) {
            const index = _.findIndex(this.comments, function(o) { return o.id === comment.parent_id })
            this.comments[index].replies.push(data)
            gtmEvent.event = 'discussionReply'
          }

          // second level reply
          if (comment.thread_id !== 'null' &&
              comment.thread_id !== comment.parent_id) {
            const index = _.findIndex(this.comments, function(o) { return o.id === comment.thread_id })
            this.comments[index].replies.push(data)
            gtmEvent.event = 'discussionReply'
          }

          this.last_updated_comment = data.id

          callback(data)
          dataLayer.push(gtmEvent)

          // update slide result
          this._updateSlideResult()
        })
    },

    editComment(comment, files, thread_id, _parent_id, _callback) {
      const post_data = { ...comment, files }

      this.api_client.update('discussion_comment', post_data)
        .then(({ data }) => {
          if (thread_id === comment.id) {
            // First level comment
            const index = _.findIndex(this.comments, function(o) { return o.id === comment.id })
            this.comments[index].formatted_content = data.formatted_content
            this.comments[index].updated_at = data.updated_at
            this.comments[index].files = data.files
          } else {
            // Reply
            const index = _.findIndex(this.comments, function(o) { return o.id === thread_id })
            const c_index = _.findIndex(this.comments[index].replies, function(o) { return o.id === comment.id })
            this.comments[index].replies[c_index].formatted_content = data.formatted_content
            this.comments[index].replies[c_index].updated_at = data.updated_at
            this.comments[index].replies[c_index].files = data.files
          }

          this.last_updated_comment = data.id
        })
    },

    deleteComment(comment_id, callback) {
      this.api_client.destroy('discussion_comment', comment_id)
        .then(() => {
          callback()
        })
    },

    _updateSlideResult() {
      if (typeof (this.slide) !== 'undefined') {
        this.updateSlideResult({
          slide_id:    this.slide.id,
          slide_type:  this.slide.type,
          event:       'commented',
          payload:     {}
        })
      }
    }

  }
}

</script>

<style lang='sass' scoped>
  @import './Discussion'
</style>

<style lang='sass' scoped>
.discussion-app
  .page-loader
    top: 60px
    height: calc(100vh - 160px)
</style>
