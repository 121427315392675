import apiClient from 'api'
import BaseCourse from 'api/models/course'
import { useHomeStore } from 'home/stores/home'

const Course = class {
  _home_store
  _object
  _identifier

  constructor(identifier) {
    this._home_store = useHomeStore()
    this._identifier = identifier
  }

  static load(identifier, callback = null) {
    const course = new Course(identifier)
    course._fetchCourse(callback)
    return course
  }

  get recent_lesson() {
    if (this._recent_lesson) {
      return this._recent_lesson
    } else {
      this._recent_lesson = this._object.lessons.reduce((accum, curr) => {
        if (accum && curr && curr.last_update && accum.last_update > curr.last_update) {
          return accum
        } else if (curr.last_update) {
          return curr
        } else {
          return accum
        }
      }, null)
      return this._recent_lesson
    }
  }

  _fetchCourse(callback) {
    if (this._home_store.courses[this._identifier] && this._home_store.courses[this._identifier].fully_loaded) {
      this._object = this._home_store.courses[this._identifier]
      if (callback) callback()
    } else {
      apiClient.find('courses', this._identifier, { include: 'instructor,lessons,resources,program_drivers' })
        .then(({ data }) => {
          data.fully_loaded = true
          this._home_store.setCourse(data)
          this._object = data
          if (callback) callback()
        })
    }
  }
}

Object.keys(BaseCourse).forEach((key) => {
  // const prop = BaseCourse[key]
  if (!Course.prototype.hasOwnProperty(key)) {
    Object.defineProperty(Course.prototype, key, {
      get: function() {
        return this._object[key]
      }
    })
  }
})

export default Course
