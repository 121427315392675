<template>
<div class='interactive-section'>
  <!-- Outro -->
  <template v-if='has_result && section_has_outro'>
    <!-- here we render default section outro -->
    <Outro
      :toggle='ctaToggle'
      :activity='activity'
      :slide_result='slide_result'
      :toggleAccountOverlay='toggleAccountOverlay'
    />
  </template>

  <!-- Intro -->
  <template v-else>
    <template v-if='section_has_intro'>
      <!-- here we render default section intro -->
      <Intro
        :activity='activity'
        :toggle='ctaToggle'
      />
    </template>

    <template v-else>
      <!-- here we render activity specific intro -->
      <template v-if='activity.type === "activity_v1_videos"'>
        <IntroVideo
          :activity='activity'
        />
      </template>

      <template v-if='activity.type === "activity_v1_basic_contents"'>
        <IntroBasicContent
          :activity='activity'
        />
      </template>
    </template>
  </template>

  <transition
    name="fast-fade"
    mode='out-in'
  >
    <ActivitiesOverlay
      :slide='slide'
      :submitWelcomeSurveyCallback='submitWelcomeSurveyCallback'
    />
  </transition>
</div>
</template>

<script>
import Intro from './InteractiveSectionIntro'
import IntroVideo from './InteractiveSectionIntroVideo'
import IntroBasicContent from './InteractiveSectionIntroBasicContent'

import Outro from './InteractiveSectionOutro'

import ActivitiesOverlay  from 'components/activities/ActivitiesOverlay'

import { useHomeStore } from 'home/stores/home'
import { useUserResultsStore } from 'home/stores/user-results'
import { mapState, mapActions, mapGetters } from 'pinia'

export default {
  props: ['section', 'submitWelcomeSurveyCallback'],

  components: {
    Intro,
    IntroVideo,
    IntroBasicContent,
    Outro,
    ActivitiesOverlay
  },

  mounted() {
    if (this.slide.result) {
      this.setSlideResult({
        slide_id: this.slide.id,
        slide_type: this.slide.type,
        slide_result: this.slide.result
      })
    } else {
      this._setViewed()
    }
  },

  computed: {
    ...mapState(useHomeStore, [
      'user'
    ]),

    ...mapGetters(useUserResultsStore, [
      'getSlideResult'
    ]),

    slide() {
      return this.section.global_slide
    },

    slide_result() {
      const result = this.getSlideResult(this.slide.id, this.slide.type)

      if (Object.keys(result) === 0) {
        return {
          status: 'started'
        }
      } else return result
    },

    activity() {
      return this.slide.activity
    },

    section_has_intro() {
      // if section has authored intro
      if (this.activity.type !== 'activity_v1_videos' &&
      this.activity.type !== 'activity_v1_basic_contents' &&
      this.activity.type !== 'activity_v1_audios') {
        return true
      }

      return false
    },

    section_has_outro() {
      // if section has authored outro
      if (this.activity.type === 'activity_v2_self_assessments') {
        return true
      }

      return false
    },

    has_result() {
      // if user already passed activity
      return this.slide_result.status === 'passed' &&
        Object.keys(this.slide_result.results).length
    }
  },

  methods: {
    ...mapActions(useHomeStore, [
      'toggleActivityOverlay',
      'toggleAccountOverlay'
    ]),

    ...mapActions(useUserResultsStore, [
      'updateSlideResult',
      'setSlideResult'
    ]),

    _setViewed() {
      if (!this.user) return false
      this.updateSlideResult({
        slide_id:    this.slide.id,
        slide_type:  this.slide.type,
        event:       'viewed',
        payload: {}
      })
    },

    ctaToggle() {
      if (this.activity.type === 'activity_v2_self_assessments') {
        if (this.has_result) {
          dataLayer.push({
            event:        'viewSelfAssessmentResult',
            pageCategory: 'interactive-section'
          })
        } else {
          dataLayer.push({
            event:        'startSelfAssessment',
            pageCategory: 'interactive-section'
          })
        }
      }

      this.toggleActivityOverlay({ activity_id: this.activity.id })
    },

    toggle() {
      this.toggleActivityOverlay()
    }
  }
}
</script>

<style lang='sass'>
  @import 'InteractiveSection'
</style>
