export default {

  data() {
    return {
      // Add or substract depending on key pressed
      direction: {
        37: -1,
        38: -1,
        39: 1,
        40: 1
      },
      // For easy reference
      keys: {
        end: 35,
        home: 36,
        left: 37,
        up: 38,
        right: 39,
        down: 40,
        delete: 46
      }
    }
  },

  computed: {
    domTabs() {
      return document.querySelectorAll('[role="tab"]')
    }
  },

  methods: {
    // Either focus the next, previous, first, or last tab depening on key pressed
    switchTabOnArrowPress(event) {
      const pressed = event.keyCode

      for (let i = 0; i < this.domTabs.length; i++) {
        this.domTabs[i].index = i
      }

      if (this.direction[pressed]) {
        const target = event.target
        if (target.index !== undefined) {
          if (this.domTabs[target.index + this.direction[pressed]]) {
            this.domTabs[target.index + this.direction[pressed]].focus()
          } else if (pressed === this.keys.left || pressed === this.keys.up) {
            this.focusLastTab()
          } else if (pressed === this.keys.right || pressed === this.keys.down) {
            this.focusFirstTab()
          }
        }
      }
    },

    focusFirstTab() {
      this.domTabs[0].focus()
    },

    focusLastTab() {
      this.domTabs[this.domTabs.length - 1].focus()
    },

    toggleTabsAria(e) {
      for (let t = 0; t < this.domTabs.length; t++) {
        this.domTabs[t].setAttribute('aria-selected', 'false')
      }
      e.target.setAttribute('aria-selected', 'true')
    }
  }
}
