<template>
<div class='resourses-filters-mobile'>
  <div class='resourses-filters-mobile_heading'>
    <h3 class='copy-two'>
      {{ $tc('resources_page.filter', 1) }}
    </h3>
    <button
      title='Close'
      @click.prevent='close'
    >
      <CloseIcon />
    </button>
  </div>

  <div class='resourses-filters-mobile_sections'>
    <div
      v-for='category in categories'
      :key='category.type'
      class='resourses-filters-mobile_section'
    >
      <a
        href='#'
        class='resourses-filters-mobile_section-heading copy-two'
        @click.prevent='toggleSection(category.type)'
      >
        {{ category.name }}
        <ArrowUpIcon v-if='sectionExpanded(category.type)' />
        <ArrowDownIcon v-if='!sectionExpanded(category.type)' />
      </a>

      <ul v-if='sectionExpanded(category.type)'>
        <li
          v-for='(item, index) in category.items'
          :key='index'
        >
          <label class='copy-three'>
            <input
              type='checkbox'
              :name='category.type'
              :value='item.title'
              :checked='isChecked(item.title, category.type)'
              @change='applyFilter(category.type, item.title, item.identifier)'
            >
            <CheckedIcon v-if='isChecked(item.title, category.type)' />
            <UnCheckedIcon v-if='!isChecked(item.title, category.type)' />
            <span>{{ item.title }}</span>
          </label>
        </li>
      </ul>
    </div>
  </div>

  <div class='resourses-filters-mobile_buttons'>
    <button
      class='resourses-filters-mobile_buttons-clear action-two'
      :disabled='buttons_disabled'
      @click.prevent='clearFilters'
    >
      {{ $t('resources_page.clear_filters') }}
    </button>

    <button
      :disabled='buttons_disabled'
      class='resourses-filters-mobile_buttons-apply action-two'
      @click.prevent='close'
    >
      {{ $t('resources_page.apply_filters') }}
    </button>
  </div>
</div>
</template>

<script>
import FiltersMixin from './FiltersMixin'
import CloseIcon from './icons/close.svg?inilne'

export default {
  mixins: [FiltersMixin],

  props: ['close', 'clear'],

  mounted() {
    $('body').addClass('popup-opened')
  },

  beforeUnmount() {
    $('body').removeClass('popup-opened')
  },

  components: {
    CloseIcon
  },

  computed: {
    buttons_disabled() {
      return !this.selected_filters.length
    }
  },

  methods: {
    clearFilters() {
      this.clear()
      this.close()
    }

  }
}
</script>

<style lang='sass' scoped>
  @import './FiltersMobile'
</style>
