<template>
<div
  v-if='selected_filters.length'
  class='resources_applied-filters'
>
  <div class='resources_applied-filters_top'>
    <span class='resources_applied-filters_results copy-four'>
      {{ results_count }} {{ $tc('resources_page.result', results_count) }}
    </span>

    <a
      href='#'
      class='resources_applied-filters_clear copy-four'
      @click.prevent='clearFilters'
    >
      {{ $t('resources_page.clear_filters') }}
    </a>
  </div>

  <div class='resources_applied-filters_list'>
    <div
      v-for='(filter, index) in selected_filters'
      :key='index'
      class='resources_applied-filters_filter copy-four'
    >
      {{ filter.name }}
      <a
        href='#'
        @click.prevent='removeFilter(filter.type, filter.name)'
      >
        <svg
          width="7"
          height="5"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            class='icon'
            transform="translate(1.25 0)"
            stroke="#fff"
            fill="none"
          >
            <line
              x2="4.987"
              y2="4.987"
            />
            <line
              x2="4.987"
              y2="4.987"
              transform="matrix(-1 0 0 1 4.988 0)"
            />
          </g>
        </svg>
      </a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: ['selected_filters', 'results_count',
    'clearFilters', 'removeFilter'
  ],

  computed: {
  }
}
</script>

<style lang='sass' scoped>
  @import './AppliedFilters'
</style>
