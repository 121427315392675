<template>
<div class='audios-app'>
  <router-view />
</div>
</template>

<script>
export default {
  computed: {}
}
</script>
