<template>
  <div class='activity-section'>
    <div
      role="heading"
      aria-level="2"
      class='activity-section_header heading-five'
    >
      {{ title }}
    </div>

    <div class='activity-section_list'>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: ['title']
}
</script>

<style lang='sass' scoped>
  @import './Section'
</style>
