// AudioSeries model based on API::V1::AudioSeriesSerializer
export default {
  // Relationships
  episodes: {
    jsonApi: 'hasMany',
    type: 'audio_series_episodes'
  },

  instructor: {
    jsonApi: 'hasOne',
    type: 'instructors'
  },

  resources: {
    jsonApi: 'hasMany',
    type: 'resource_cards'
  },

  academy: {
    jsonApi: 'hasOne',
    type: 'academy'
  },

  // Images
  card_image: null,

  // Attributes
  identifier: null,
  position: null,
  title: null,
  overview_intro: null,
  external_links: [],
  overview_page: {},
  public: false,
  trailer_url: null,
  configuration: {},
  author_description: null,
  published: null,
  published_date: null
}
