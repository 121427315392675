// VideoWithInstructions model based on API::V1::Activity::V1::VideoWithInstructionsSerializer
export default {

  // Images
  background_image: null,
  gradient_start: null,
  gradient_end: null,

  // Attributes
  identifier: null,
  label: null,
  headline: null,
  subheadline: null,
  instructions: null,
  cta: null,
  provider: null,
  code: null,
  complete_threshold: 0
}
