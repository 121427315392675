<template>
  <div class="text-email-switch">
    <div class="text-email-switch_tabs">
      <button
        class="text-email-switch_tab"
        :class="{'active': local_notification_type === 'sms'}"
        @click.prevent="changeNotificationType('sms')"
      >
        {{ $t('intentions.sms_tab') }}
      </button>

      <button
        class="text-email-switch_tab"
        :class="{'active': local_notification_type === 'email'}"
        @click.prevent="changeNotificationType('email')"
      >
        {{ $t('intentions.email_tab') }}
      </button>
    </div>

    <div
      v-if='local_notification_type === "sms"'
      class="text-email-switch_panel text-email-switch_panel--mobile"
    >
      <template v-if='saved_phone'>
        <div class="text-email-switch_title">
          {{ $t('intentions.sms_title') }}
        </div>

        <div class="text-email-switch_value">
          {{ saved_phone }}
        </div>
      </template>

      <template v-else>
        <div class="text-email-switch_field">
          <VeeForm
            v-slot="{ meta, errors }"
            validateOnMount
          >
            <span class='ada-hidden'>
              {{ validatePhone(meta) }}
            </span>

            <Field
              v-slot="{ field, meta }"
              v-model='local_user.mobile_number'
              rules='required|phone'
              name='user_phone'
              :validateOnInput='true'
              :validateOnChange='true'
            >
              <input
                id="user_phone"
                :class='validationClasses(meta, "user_phone", local_user.mobile_number)'
                type="tel"
                autocomplete='phone'
                v-bind='field'
                @input="changePhoneNumber"
                @change="changePhoneNumber"
                @beforeinput="has_input_started = true"
              >
            </Field>

            <label for="user_phone">{{ $t('phone_number') }}</label>
            <div
              v-if="errors.user_phone"
              class='form-error'
            >
              {{ errors.user_phone }}
            </div>

            <div
              v-if="mobile_number_error && !has_input_started"
              class='form-error'
            >
              {{ mobile_number_error }}
            </div>
          </VeeForm>
        </div>
      </template>

      <label class="text-email-switch_terms">
        <input
          v-model="is_terms_accepted"
          type="checkbox"
        >
        <span v-html="$t('intentions.sms_terms')" />
      </label>
    </div>

    <div
      v-if='local_notification_type === "email"'
      class="text-email-switch_panel text-email-switch_panel--email"
    >
      <div class="text-email-switch_title">
        {{ $t('intentions.email_title') }}
      </div>
      <div class="text-email-switch_value">
        {{ local_user.email }}
      </div>
    </div>
  </div>
</template>

<script>
import { useRootStore } from 'lesson/stores/root'
import { useHomeStore } from 'home/stores/home'

import { Form as VeeForm, Field } from 'vee-validate'
import { defineRule } from 'vee-validate'

export default {

  props: [
    'notification_type',
    'mobile_number_error',
    'user'
  ],

  emits: [
    'validation',
    'notification-type-change',
    'mobile-number-change'
  ],

  data() {
    return {
      local_user: null,
      local_notification_type: null,
      is_terms_accepted: false,
      has_input_started: false,
      phone_valid: null
    }
  },

  components: {
    VeeForm,
    Field
  },

  watch:  {
    // emit validation event to the parent every time validation state changes
    // this is needed to update submit button state in the parent component
    'is_form_valid'(value) {
      this.$emit('validation', value)
    }
  },

  computed: {
    is_form_valid() {
      return this.local_notification_type === 'email'  ||
        (this.is_terms_accepted && this.phone_valid && !this.mobile_number_error)  ||
        (this.is_terms_accepted && this.phone_valid && this.has_input_started)
    },

    saved_phone() {
      // Unfortunately we need to access different stores
      // in case we need to use component in different state contexts.
      if (this.$router.currentRoute.value.name === 'my_activity') {
        const home_store = useHomeStore()
        return home_store.user.mobile_number
      } else {
        const root_store = useRootStore()
        return root_store.user.mobile_number
      }
    }
  },

  created() {
    defineRule('required', (value) => {
      return value !== '' && value !== null || this.$t('intentions.sms_phone_required')
    });

    defineRule('phone', (value) => {
      const test1 = /^([+]?1[-\s.]?)?\(?[2-9][0-9]{2}\)?[-\s./0-9]*$/g  // check the format +1 (234) 567-8901
      const test2 = /^[01]?[2-9]\d{9}$/g                                 // check the number of digits: 10 or 11

      return test1.test(value) && test2.test(value.replaceAll(/[- .+()/]/g, '')) ||this.$t('intentions.sms_phone_error')
    })
  },

  mounted() {
    this.local_user = this.user

    // Set notification type from the prop
    this.local_notification_type = this.notification_type || 'sms'

    if (this.saved_phone) {
      this.validateSavedPhone(this.saved_phone)
    }
  },

  methods: {
    changeNotificationType(type) {
      this.local_notification_type = type

      // send new notification type to parent component
      this.$emit('notification-type-change', this.local_notification_type)
    },

    changePhoneNumber() {
      // send mobile number to parent component if it is valid
      if (this.is_form_valid) {
        this.$emit('mobile-number-change', this.local_user.mobile_number)
      }
    },

    validationClasses(meta, fieldName, value) {
      return {
        'has-error': meta.dirty && meta.validated && !meta.valid,
        valid: meta.dirty && meta.validated && meta.valid,
        'not-empty': !!value,
        empty: !value
      }
    },

    validatePhone(meta) {
      this.phone_valid = meta.valid
    },

    validateSavedPhone(phone) {
      const value = '' + phone
      const test1 = /^([+]?1[-\s.]?)?\(?[2-9][0-9]{2}\)?[-\s./0-9]*$/g  // check the format +1 (234) 567-8901
      const test2 = /^[01]?[2-9]\d{9}$/g                                 // check the number of digits: 10 or 11

      const valid = test1.test(value) && test2.test(value.replaceAll(/[- .+()/]/g, ''))
      this.phone_valid = valid

      return valid
    }

  }
}
</script>

<style lang='sass' scoped>
  .text-email-switch
    margin-bottom: 30px
    width: 100%
    max-width: 300px

  .text-email-switch_tabs
    margin-bottom: 10px
    display: flex
    justify-content: center
    width: 100%

  .text-email-switch_tab
    font-weight: 400
    font-size: 16px
    box-sizing: border-box
    letter-spacing: 1px
    text-align: center
    text-decoration: none
    text-transform: uppercase
    padding: 13px 40px
    border: solid 1px rgba(0,0,0,.2)
    border-radius: 5px 0 0 5px
    color: #000
    background: #fff
    transition: background 0.1s ease-in, color 0.1s ease-in
    cursor: pointer
    flex-basis: 50%
    appearance: none
    -webkit-appearance: none
    &.active
      background: #5BE7E0
      border-color: #5BE7E0

    & + .text-email-switch_tab
      border-radius: 0 5px 5px 0

  .text-email-switch_title
    font-size: .8em
    color: #4F4FE3

  .text-email-switch_terms
    margin-top: 10px
    display: block
    font-size: .8em
    position: relative
    text-align: left

    input[type="checkbox"]
      appearance: none
      width: 20px
      height: 20px
      margin: -1px 3px 0 0
      padding: 0
      background: transparent
      border: 2px solid #4F4FE3
      display: inline-block
      vertical-align: middle
      cursor: pointer
      &:checked
        background: #4F4FE3
        & ~ span:before
          content: ""
          display: block
          position: absolute
          left: 5px
          top: 7px
          width: 10px
          height: 5px
          border: solid #fff
          border-width: 0 0 2px 2px
          transform: rotate(-45deg)
          cursor: pointer

  .text-email-switch_field
    margin-bottom: 12px
    max-width: 340px
    position: relative

    &:last-child
      margin-bottom: 0

    input[type="tel"]
      padding: 25px 20px 10px
      border: 1px solid #CED0D3
      width: 100%
      min-width: 280px
      box-sizing: border-box
      line-height: 1.2
      display: block
      appearance: none
      -webkit-appearance: none

      &:focus
        outline: none
        border: 1px solid #4A4AD6
        box-shadow: none
        & + label
          color: #4A4AD6

      &.has-error
        border-color: #E62B3D
        box-shadow: 0 0 0 1px #E62B3D
        & + label
          color: #E62B3D

      &.not-empty,
      &:focus
        & + label
          top: 7px
          transform: none
          font-size: 13px

      &:-webkit-autofill
        & + label
          top: 7px
          transform: none
          font-size: 13px

      & + label
        color: #7b7b7b
        position: absolute
        left: 20px
        top: 15px
        transition: all .3s ease
        cursor: text

    .form-error
      color: #E62B3D
      font-size: 12px
      font-weight: 300
      font-style: normal
      line-height: 1.3
      margin: 5px 0 10px 0
      text-align: left
      display: block

</style>
