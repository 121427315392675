<template>
<div
  class='paired-comparison-list_list'
  :class='listgrid'
>
  <template
    v-for='(option) in options'
    :key='option.id'
  >
    <button
      class='paired-comparison-list_list-item'
      :class='optionClass(option)'
      role='checkbox'
      :aria-checked='isOptionSelected(option) ? "true" : "false"'
      @click.prevent='selectOption(option)'
    >
      <img
        v-if='option.option_image'
        class='paired-comparison-list_list-item_image'
        :src='option.option_image'
        :alt='option.title'
      >

      <span class='paired-comparison-list_list-item_text body-three'>
        {{ option.title }}
      </span>
    </button>
  </template>
</div>
</template>

<script>
export default {
  props: [
    'options',
    'selectOption',
    'isOptionSelected'
  ],

  computed: {
    listgrid() {
      const optionsLength = this.options.length

      if (optionsLength <= 10) {
        return 'grid-2 list-' + optionsLength
      }

      if (optionsLength <= 15) {
        return 'grid-3 list-' + optionsLength
      }

      if (optionsLength > 15) {
        return 'grid-4 list-' + optionsLength
      }

      return null
    }
  },

  methods: {
    optionClass(option) {
      const classes = []
      if (!option.option_image) classes.push('paired-comparison-list_list-item--no-image')
      if (this.isOptionSelected(option)) classes.push('paired-comparison-list_list-item--selected')
      return classes.join(' ')
    }
  }

}
</script>
