<template>
<div class='discussion-comment-form'>
  <form class='discussion-comment-form_form'>
    <div class='discussion-comment-form_top'>
      <!-- Attachments -->
      <div
        v-if='local_files.length'
        class='discussion-comment-form_files'
      >
        <div
          v-for='(file, index) in local_files'
          :key='index'
          class='discussion-comment-form_file-container'
        >
          <template v-if='isImage(file)'>
            <div class='discussion-comment-form_image'>
              <div
                class='discussion-comment-form_image-bg'
                :style='bgImage(index)'
                :data-index='index'
              />

              <!-- Image Actions -->
              <div class='discussion-comment-form_image-actions'>
                <a
                  class='discussion-comment-form_image-actions_zoom'
                  href='#zoom'
                  :title='$t("zoom")'
                  @click.prevent='zoomImage(index)'
                >
                  <ZoomIcon />
                </a>

                <a
                  class='discussion-comment-form_image-actions_delete'
                  href='#delete'
                  :title='$t("delete")'
                  @click.prevent='deleteFile(index)'
                >
                  <DeleteIcon />
                </a>
              </div>
            </div>
          </template>

          <template v-else>
            <div class='discussion-comment-form_file'>
              <span class='discussion-comment-form_file-icon'><FileIcon /></span>
              <span class='discussion-comment-form_file-name'>{{ file.name || file.file_name }}</span>

              <!-- Actions -->
              <div class='discussion-comment-form_image-actions'>
                <a
                  class='discussion-comment-form_image-actions_delete'
                  href='#delete'
                  :title='$t("delete")'
                  @click.prevent='deleteFile(index)'
                >
                  <DeleteIcon />
                </a>
              </div>
            </div>
          </template>
        </div>

        <transition name='fast-fade'>
          <DiscussionModal
            v-if='zoomed_image'
            :zoomed_image='zoomed_image'
            :zoomed_image_orientation='zoomed_image_orientation'
            :closeModal='closeImage'
          />
        </transition>
      </div>

      <!-- Comment Field -->
      <div class='discussion_comment-field'>
        <textarea
          :id='textarea_id'
          ref='textArea'
          :value='comment_content'
          :class="{'not-empty': comment_content }"
          :title="$t('add_comment')"
          @input="comment_content = $event.target.value"
        />
        <label :for='textarea_id'>{{ $t('add_comment') }}</label>
      </div>
    </div>

    <div class='discussion-comment-form_bottom'>
      <!-- Upload -->
      <div class='discussion-comment-form_upload'>
        <label
          :for='input_file_id'
          tabindex='0'
          class='discussion-comment-form_upload-label'
        >
          <AddIcon />
          {{ $t('add_file') }}
        </label>

        <input
          :id='input_file_id'
          class='discussion-comment-form_upload-uploader'
          type='file'
          name='filer'
          :accept='supported_extensions'
          @change='attachFile'
        >
      </div>

      <div class='discussion-comment-form_submit'>
        <button
          class='discussion-comment-form_submit-button'
          :disabled='submit_disabled'
          type='submit'
          @click.prevent='submit'
        >
          {{ submit_button_text }}
        </button>

        <button
          v-if='show_cancel'
          class='discussion-comment-form_cancel'
          @click.prevent='cancel'
        >
          {{ $t('cancel') }}
        </button>
      </div>
    </div>
  </form>
</div>
</template>

<script>
import DiscussionModal from './DiscussionModal'
import { DirectUpload } from '@rails/activestorage'
import mime from 'mime-types'

import FileIcon from './file-icon.svg'
import ZoomIcon from './zoom.svg'
import DeleteIcon from './image_delete.svg'
import AddIcon from './add_image.svg'

export default {
  props: [
    'comment',
    'topic_id',
    'thread_id',
    'parent_id',
    'is_editing',

    'cancel',
    'createComment',
    'editComment',

    'supported_file_types'
  ],

  components: {
    DiscussionModal,
    FileIcon,
    ZoomIcon,
    DeleteIcon,
    AddIcon
  },

  data() {
    return {
      comment_content: '',

      maxFiles: 5,
      max_file_size_in_mb: 2,
      local_files: [], // used for preview block
      files: [], // sent on create/edit
      images: [],
      zoomed_image: null,
      zoomed_image_orientation: null,
      is_posting: false
    }
  },

  mounted() {
    if (this.is_editing) {
      this.comment_content = this.comment.content || ''

      // Scroll to comment form for better usability
      this._scrollToCommentForm()

      // Focus textarea for better accessibility
      setTimeout(() => {
        this.$refs.textArea.focus()
      }, 400)

      // Copy files to local state
      if (this.comment.files && this.comment.files.length) {
        this.comment.files.forEach((file, index) => {
          file.old = true
          this.local_files.push(file)
          this.files.push(file)

          if (this.isImage(file)) {
            this.images[index] = {
              src: file.thumbnail,
              original: file.original
            }
          }
        })
      }
    }
  },

  computed: {
    submit_disabled() {
      return (this.comment_content.length === 0 && (this.local_files.length === 0)) ||
        this.is_posting
    },

    submit_button_text() {
      return this.is_editing ? this.$t('update') : this.$t('post')
    },

    show_cancel() {
      return typeof (this.cancel) === 'function'
    },

    input_file_id() {
      return 'file_id_' + this.$.uid
    },

    textarea_id() {
      return 'textarea_' + this.$.uid
    },

    supported_extensions() {
      const supported_extensions = []
      this.supported_file_types.forEach((type) => {
        if (type === 'image/jpg') {
          supported_extensions.push('.jpg')
        } else {
          supported_extensions.push('.' + mime.extension(type))
        }
      })

      return supported_extensions.join(', ')
    },

    max_file_size_in_bytes() {
      return this.max_file_size_in_mb * 1024 ** 2
    }
  },

  methods: {
    submit() {
      let files_to_upload = this.local_files ? this.local_files.length : null
      this.is_posting = true

      if (files_to_upload) {
        Array.from(this.local_files).forEach((file, index) => {
          if (!file.old) {
            this._uploadFile(file, (blob) => {
              files_to_upload--
              // Here we need to save the order files were added to the form
              this.files[index] = { signed_id: blob.signed_id }
            })
          } else {
            files_to_upload--
          }
        })
      }

      const interval = setInterval(() => {
        if (files_to_upload === 0) {
          clearInterval(interval)

          if (this.is_editing) {
            this._updateComment()
          } else {
            this._createComment()
          }

          // Clear
          this.comment_content = ''
          this.local_files = []
          this.files = []
          this.images = []
          this.is_posting = false

          if (typeof (this.cancel) === 'function') {
            this.cancel()
          }
        }
      }, 100)
    },

    _createComment() {
      // Send to API
      this.createComment({
        thread_id: this.thread_id,
        parent_id: this.parent_id,
        content:   this.comment_content,
        files:     this.files.map(f => f.signed_id)
      }, (data) => {
        // first level reply
        // here we need to show replies if they're hidden
        if (this.thread_id !== 'null' &&
            this.thread_id === this.parent_id) {
          this.$parent.show_replies = true
        }
      })
    },

    _updateComment() {
      this.comment.content = this.comment_content
      this.editComment(this.comment, this.files.map(f => f.signed_id), this.thread_id, this.parent_id)
    },

    attachFile(event) {
      const file = event.target.files[0]

      if (this.supported_file_types.indexOf(file.type) < 0) {
        Tbk.alerts.new(this.$t('upload_wrong_format', { value: this.supported_extensions }), 'error')
      } else if (this.local_files.length >= this.maxFiles) {
        Tbk.alerts.new(this.$t('upload_max_files_added'), 'error')
      } else if (file.size > this.max_file_size_in_bytes) {
        Tbk.alerts.new(this.$t('upload_max_filesize', { size_in_mb: this.max_file_size_in_mb }), 'error')
      } else {
        this.local_files.push(file)

        if (this.isImage(file)) {
          this._previewImg(file, this.local_files.length - 1)
        }
      }

      // Here we need to clear the input field:
      if (event.target.value) {
        if (navigator.userAgent.match(/Trident.*rv:11\./)) {
          // this fixes strange case in IE11
          // when onchange event fires twice on input
          event.target.type = 'text'
          event.target.type = 'file'
        } else {
          event.target.value = '' // <-- !!! this line causes double event fire in IE11
        }
      }
    },

    _uploadFile(file, cb) {
      const url = '/rails/active_storage/direct_uploads'
      const upload = new DirectUpload(file, url, this)

      upload.create((error, blob) => {
        if (error) {
          // Handle the error
          console.log('error', error)
        } else {
          cb(blob)
        }
      })
    },

    directUploadWillStoreFileWithXHR(request) {
      request.upload.addEventListener('progress',
        event => this.directUploadDidProgress(event))
    },

    directUploadDidProgress(e) {
      // TODO check if progress is used
      // eslint-disable-next-line no-unused-vars
      const progress = ((e.loaded / e.total) * 100)
    },

    _previewImg(file, insert_index) {
      const img = document.createElement('img')
      img.file = file

      const reader = new FileReader()
      const images_array = this.images
      reader.onload = ((aImg) => {
        const that = this
        return function(e) {
          aImg.src = e.target.result

          that._getOrientation(file, function(result) {

            images_array[insert_index] = {
              src: e.target.result
            }
            images_array[insert_index].orientation = result
          })
        }
      })(img)

      reader.readAsDataURL(file)
    },

    _getOrientation(file, callback) {
      // https://stackoverflow.com/questions/7584794/accessing-jpeg-exif-rotation-data-in-javascript-on-the-client-side/32490603#32490603
      const reader = new FileReader()

      reader.onload = function(e) {
        const view = new DataView(e.target.result)
        if (view.getUint16(0, false) !== 0xFFD8) { return callback(-2) }
        const length = view.byteLength; let offset = 2
        while (offset < length) {
          if (view.getUint16(offset + 2, false) <= 8) return callback(-1)
          const marker = view.getUint16(offset, false)
          offset += 2
          if (marker === 0xFFE1) {
            if (view.getUint32(offset += 2, false) !== 0x45786966) { return callback(-1) }
            const little = view.getUint16(offset += 6, false) === 0x4949
            offset += view.getUint32(offset + 4, little)
            const tags = view.getUint16(offset, little)
            offset += 2
            for (let i = 0; i < tags; i++) {
              if (view.getUint16(offset + (i * 12), little) === 0x0112) {
                return callback(view.getUint16(offset + (i * 12) + 8, little))
              }
            }
          } else if ((marker & 0xFF00) !== 0xFF00) {
            break
          } else {
            offset += view.getUint16(offset, false)
          }
        }
        return callback(-1)
      }
      reader.readAsArrayBuffer(file)
    },

    zoomImage(index) {
      this.zoomed_image = this.images[index].original || this.images[index].src
      // this.zoomed_image_orientation = this.imageOrientationClass(index)
      this.zoomed_image_orientation = null
    },

    closeImage() {
      this.zoomed_image = null
      this.zoomed_image_orientation = null
    },

    deleteFile(index) {
      // Remove from local state
      this.local_files.splice(index, 1)
      this.files.splice(index, 1)
      this.images.splice(index, 1)
    },

    isImage(file) {
      const type = file.type || file.content_type
      const supported = ((this.$root.configuration || {}).discussions_supported_uploads || [])
      return supported.indexOf(type) >= 0 && !!type.match(/^image/)
    },

    bgImage(index) {
      if (this.images[index] && this.images[index].src) {
        return `background-image: url('${this.images[index].src}')`
      } else return false
    },

    imageOrientationClass(index) {
      if (this.images[index]) {
        let klass = ''
        switch (this.images[index].orientation) {
          case 1:
            break
          case 2:
            break
          case 3:
            klass = 'rotate180'
            break
          case 4:
            klass = 'rotate180'
            break
          case 5:
            klass = 'rotate90'
            break
          case 6:
            klass = 'rotate90'
            break
          case 7:
            klass = 'rotate270'
            break
          case 8:
            klass = 'rotate270'
            break
        }
        return klass
      }
    },

    _scrollToCommentForm() {
      let containerId = null
      const ids = ['activity-overlay-content', 'discussion-app', 'journal-app']
      ids.map(id => (containerId = document.getElementById(id) ? '#' + id : containerId))

      if (containerId) {
        this.$scrollTo(this.$el, {
          container: containerId,
          duration: 200,
          offset: -40
        })
      }
    }

  }
}
</script>

<style lang='sass' scoped>
  @import './DiscussionCommentForm'
</style>
