<template>
<div
  class='course-drawer'
  :class='main_class'
>
  <button
    ref='close-button'
    class='course-drawer_close'
    :title="$t('close')"
    @click.prevent='close'
  >
    <CloseImage />
  </button>

  <!-- Preloader -->
  <div
    v-if='loading'
    class='course-drawer_loading page-loader'
  >
    <div class='loader' />
  </div>

  <template v-if='!loading && !is_mobile'>
    <!-- Navigation -->
    <div class='course-drawer_nav'>
      <template
        v-for='(link, index) in links'
        :key='index'
      >
        <a
          :id="link.name"
          :aria-controls="'panel-tab' + link.name"
          :aria-selected=" (link.name === 'overview') ? 'true' : 'false' "
          :class='tabClass(link.name)'
          class='main-nav-link'
          href='#'
          role='tab'
          @click.prevent='changeTab(link.name, $event)'
          @keyup='switchTabOnArrowPress($event)'
        >
          {{ link.text }}
        </a>
      </template>
    </div>

    <div class="course-drawer_content">
      <transition name="tab-change">
        <template v-if="active_tab === 'overview'">
          <div
            id="panel-tab-overview"
            aria-labelledby="overview"
            role="tabpanel"
          >
            <Overview
              :audio_series="series"
              :playToggle='playToggle'
            />
          </div>
        </template>
      </transition>

      <transition name="tab-change">
        <template v-if="active_tab === 'episodes'">
          <div
            id="panel-tab-episodes"
            :key="'episodes'"
            aria-labelledby="episodes"
            class="course-drawer_list"
            role="tabpanel"
          >
            <Episode
              v-for="(episode, index) in series.episodes"
              :key="index"
              :audio_series="series"
              :episode='episode'
              :index="index"
              :playToggle='playToggle'
            />
          </div>
        </template>
      </transition>
    </div>
  </template>
</div>
</template>

<script>
import WindowSizeMixin from 'home/components/shared/WindowSizeMixin'
import TabAccessibilityMixin from 'home/components/shared/TabAccessibilityMixin'
import Overview from './AudioSeriesDrawerOverview'
import Episode from './AudioSeriesDrawerEpisode'

import { useHomeStore } from 'home/stores/home'
import { mapState } from 'pinia'

import CloseImage from './close.svg'

export default {
  mixins: [WindowSizeMixin, TabAccessibilityMixin],

  components: {
    Overview,
    Episode,
    CloseImage
  },

  props: {
    identifier: { type: [String, Number], required: true },
    playToggle: { type: Function, required: true },
    setOpenedDrawer: { type: Function, required: true },
    setOpenedAudioSeries: { type: Function, required: true }
  },

  data() {
    return {
      links: [
        { name: 'overview', text: this.$t('overview') },
        { name: 'episodes', text: this.$t('audio_overview.episodes') }
      ],
      valid_tabs: ['overview', 'episodes'],
      loading: false,
      activeTab: null
    }
  },

  created() {
    // Setting default opened tab
    window.activeDrawerTabs = window.activeDrawerTabs || {}
    if (
      this.use_router &&
      this.$router.currentRoute.value.params.identifier === this.identifier
    ) {
      this.activeTab = this.$router.currentRoute.value.query.tab || 'overview'
    } else if (window.activeDrawerTabs[this.identifier]) {
      this.activeTab = window.activeDrawerTabs[this.identifier]
    } else {
      this.activeTab = 'overview'
    }
    window.activeDrawerTabs[this.identifier] = this.activeTab
  },

  mounted() {
    setTimeout(() => {
      this.$refs['close-button'].focus()
    }, 200)
  },

  computed: {
    ...mapState(useHomeStore, ['audio_series']),

    series() {
      return this.audio_series[this.identifier]
    },

    main_class() {
      return this.is_mobile ? 'course-drawer--mobile' : 'course-drawer--desktop'
    },

    active_tab() {
      return this.activeTab
    },

    use_router() {
      return this.$route.name.match(/^audio-series/)
    }
  },

  methods: {
    tabClass(tab) {
      return tab === this.active_tab ? 'active' : ''
    },

    changeTab(tab, e) {
      if (this._isValidTab(tab)) {
        window.activeDrawerTabs[this.identifier] = tab
        this.activeTab = window.activeDrawerTabs[this.identifier]

        this.toggleTabsAria(e)

        if (this.use_router) {
          const q = tab === 'overview' ? {} : { tab }
          this.$router.push({
            name: 'audio-series',
            params: { identifier: this.identifier },
            query: q
          })
        }
      }
    },

    _isValidTab(tab) {
      return this.valid_tabs.indexOf(tab) >= 0
    },

    close() {
      this.setOpenedDrawer(false)
      this.setOpenedAudioSeries(null)

      if (this.use_router) {
        // clear page url without page re-render
        window.history.pushState({}, null, '/home/audio-series/')
      }
    }
  }
}
</script>

<style lang='sass' scoped>
  @import 'home/styles/shared/CourseDrawer'
</style>
