<template>
<div
  class='interactive-section-intro-video'
  :class="'interactive-section-intro-video--' + provider"
>
  <div
    class='interactive-section-intro-video_bg'
    :style='videoOverlayStyle'
  />

  <div
    class='interactive-section-intro-video_container'
    :class="containerClasses"
    @click='setPlayingStatus'
  >
    <template v-if='provider === "wistia"'>
      <h2
        v-if='activity.headline && !isPlaying'
        class='interactive-section-intro-video_headline h-three-light'
        @click='play'
      >
        {{ activity.headline }}
        <i />
      </h2>
    </template>

    <div class='interactive-section-intro-video_embed' />
  </div>
</div>
</template>

<script>
import TbkVideo from 'tbk-utils/video/tbk-video'

export default {
  props: ['activity'],

  data() {
    return {
      vClient: null,
      isPlaying: false
    }
  },

  mounted() {
    this.initVideo()
  },

  computed: {
    provider() {
      return this.activity.provider
    },

    videoOverlayStyle() {
      if (this.activity.background_image) {
        return `background-image: url(${this.activity.background_image})`
      }
      return null
    },

    containerClasses() {
      const classes = []

      if (this.activity.headline) classes.push('has-headline')

      return classes
    }
  },

  methods: {
    setPlayingStatus(e) {
      if (this.isPlaying || e.target.className.match(/video-overlay/g)) return false
      this.isPlaying = true
    },

    initVideo() {
      this.getEmbedCode()
    },

    play() {
      this.vClient && this.vClient.play($(this.$el).find('.interactive-section-intro-video_embed')[0])
    },

    getEmbedCode() {
      this.vClient = TbkVideo.new(this.activity.provider, this.activity.code)

      this.vClient.getEmbedCode(function(code, video) {
        if (this.activity.provider === 'wistia') {
          this.loadWistiaPlugins(video, this.activity.complete_threshold)

          // this._resizeIframeIfNeeded()
        }

        if (this.activity.provider !== 'tbk') {
          $(this.$el).find('.interactive-section-intro-video_embed').html(code)
        }
      }.bind(this))
    },

    loadWistiaPlugins(video, percent_complete) {
      this.initWistiaCaptions()
      // this.vClient.loadCrossTime(percent_complete, this.onComplete)
    },

    initWistiaCaptions() {
      if (this.locale !== 'en') {
        this.vClient.loadCaptions(this.locale)
      }
    }
  }

}
</script>

<style lang='sass'>
  @import 'IntroVideo'
</style>
