<template>
<div class='resourses-search'>
  <input
    type='text'
    name='search'
    :placeholder="$t('resources_page.search')"
    class='resourses-search_input copy-three'
    @keyup.enter='applySearch'
  >
  <SearchIcon />
</div>
</template>

<script>
import SearchIcon from './icons/search.svg'

export default {
  props: ['applySearch'],

  components: {
    SearchIcon
  }
}
</script>

<style lang='sass' scoped>
  @import './SearchBar'
</style>
