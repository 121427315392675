<template>
<transition name='fast-fade'>
  <div
    v-if='ready'
    class='paired-comparison'
    :class='paired_comparison_type'
  >
    <!-- Step 1 and Step 2 -->
    <transition
      name='fast-fade'
      mode='out-in'
    >
      <template v-if='current_step === "select_1"'>
        <paired-comparison-select
          :current_step="current_step"
          :setStep="setStep"
          :total_steps="total_steps"
          :activity="activity"
          :step_title="activity.screen_1_description"
          :step_directions="activity.screen_1_directions"
          :activity_results="activity_results"
          :scrollTop=scrollTop
        />
      </template>

      <template v-else-if='current_step === "select_2"'>
        <paired-comparison-select
          :current_step="current_step"
          :setStep="setStep"
          :total_steps="total_steps"
          :activity="activity"
          :step_title="activity.screen_2_description"
          :step_directions="activity.screen_2_directions"
          :activity_results="activity_results"
          :scrollTop=scrollTop
        />
      </template>

      <!-- Paired Comparison -->
      <template v-else-if='current_step === "compare"'>
        <paired-comparison-compare
          :total_steps="total_steps"
          :pairs="generatePairs()"
          :setStep="setStep"
          :submit="submit"
          :activity_results="activity_results"
          :activity="activity"
        />
      </template>

      <!-- Results -->
      <template v-else-if='current_step === "results"'>
        <paired-comparison-results
          :options="activity_options"
          :activity_results="activity_results"
          :step_title="activity.result_screen_description"
          :retake="retakeActivity"
          :finish="finish"
        />
      </template>
    </transition>
  </div>
</transition>
</template>

<script>
import PairedComparisonSelect from './paired-comparison-v1-select'
import PairedComparisonCompare from './paired-comparison-v1-compare'
import PairedComparisonResults from './paired-comparison-v1-results'

export default {
  props: [
    'slide',
    'slide_result',
    'activity',
    'toggle',
    'finish',
    'updateSlideResult'
  ],

  components: {
    PairedComparisonSelect,
    PairedComparisonCompare,
    PairedComparisonResults
  },

  data() {
    return {
      ready: false,
      current_step: null,
      activity_results: {}
    }
  },

  created() {
    this.ready = true
  },

  mounted() {
    // Set current step
    if (this.slide_result.status === 'passed' && Object.keys(this.slide_result.results).length) {
      this.activity_results = this.slide_result.results
      this.setStep('results')
    } else {
      if      (this.activity.step_1_selections) this.setStep('select_1')
      else if (this.activity.step_2_selections) this.setStep('select_2')
      else                                      this.setStep('compare')
    }
  },

  computed: {
    scrollTop() {
      return this.$parent.scrollTop
    },

    activity_options() {
      if (this.activity.options_type === 'user_defined') {
        return this.slide_result.results.select_1 || []
      } else {
        return this.activity.options
      }
    },

    paired_comparison_type() {
      return 'paired-comparison--' + this.activity.options_type
    },

    total_steps() {
      let steps = 0
      if (this.activity.step_1_selections || this.activity.step_2_selections) steps = 1
      if (this.activity.step_1_selections && this.activity.step_2_selections) steps = 2
      return steps
    },

    label() {
      return this.activity.label || this.$t('paired_comparison')
    }
  },

  methods: {
    generatePairs() {
      const pairs = []; let options = []

      let selected_options

      if (this.total_steps > 0) {
        const id_arr = Object.keys(this.activity_results)
        const last_item_id  = id_arr[id_arr.length - 1]

        selected_options = this.activity_results[last_item_id]

        if (this.activity.options_type === 'user_defined' && !this.activity.step_2_selections) {
          selected_options = selected_options.map((item) => item.id)
        }
      } else {
        options = this.activity.options.map((item) => item.id)
      }

      if (selected_options) {
        selected_options.forEach((e) => {
          options.push(e)
        })
      }

      // Make pairs
      for (let i = 0; i < options.length; i++) {
        for (let j = i + 1; j < options.length; j++) {
          pairs.push([options[i], options[j]])
        }
      }

      // Shuffle array
      return pairs.slice(0, pairs.length).sort(function() {
        return 0.5 - Math.random()
      })
    },

    retakeActivity() {
      if (this.activity.step_1_selections) {
        this.retake('select_1')
      } else if (this.activity.step_2_selections) {
        this.retake('select_2')
      } else {
        this.retake('compare')
      }
    },

    submit() {
      this.updateSlideResult({
        slide_id:    this.slide.id,
        slide_type:  this.slide.type,
        event:       'finished',
        payload:     this.activity_results
      })
    },

    setStep(step) {
      this.current_step = step
    },

    retake(step) {
      this.setStep(step)
      this.activity_results = {}
    }

  }

}
</script>

<style lang="sass">
@import 'lesson/styles/components/paired-comparison/results'
@import 'lesson/styles/components/paired-comparison/main'
@import 'lesson/styles/components/paired-comparison/select'
@import 'lesson/styles/components/paired-comparison/list'
</style>
