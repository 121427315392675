<template>
<div
  ref='activity-overlay'
  class='welcome-overlay'
>
  <div class='welcome-overlay_inner'>
    <button
      ref='close-button'
      class='welcome-overlay_close'
      @click.prevent='toggleOverlay'
    >
      {{ $t('close') }}
    </button>

    <img
      v-if='welcome_image'
      class='welcome-overlay_image'
      :src='welcome_image'
      alt=''
      aria-hidden='true'
    >

    <h1
      id='welcome-overlay_heading'
      class='heading-four'
    >
      {{ $t('welcome_username', {username: username}) }}
    </h1>

    <div
      class='welcome-overlay_text copy-two'
      v-html='$t("welcome_text")'
    />

    <button
      class='welcome-overlay_continue action-one-branded'
      @click.prevent='startSurvey'
    >
      {{ $t('continue') }}
    </button>

    <a
      class='welcome-overlay_skip copy-four'
      href='#'
      @click.prevent='toggleOverlay'
    >
      {{ $t('skip_personalization') }}
    </a>
  </div>
</div>
</template>

<script>
import OverlayAccessibilityMixin from 'lesson/components/slide/overlay-accessibility-mixin'

import { useHomeStore } from 'home/stores/home'
import { mapState } from 'pinia'

export default {
  mixins: [OverlayAccessibilityMixin],

  mounted() {
    this.closeAction = this.toggleOverlay
  },

  props: {
    toggleOverlay: Function,
    startSurvey: Function
  },

  created() {
    $('body').addClass('popup-opened')
  },

  beforeUnmount() {
    $('body').removeClass('popup-opened')
  },

  computed: {
    ...mapState(useHomeStore, [
      'user',
      'welcome_image'
    ]),

    label() {
      return  this.$t('welcome_username', { username: this.username })
    },

    username() {
      return this.user ? this.user.first_name : null
    }
  }

}
</script>

<style lang='sass' scoped>
  @import './WelcomeOverlay'
</style>
