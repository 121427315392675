<template>
<div
  id='overview-hero'
  class='overview-hero'
>
  <div class='overview-hero_back'>
    <button
      class='overview-hero_back-link'
      @click.prevent='returnBack'
    >
      {{ $t('back') }}
      <BackIcon />
    </button>
  </div>

  <div
    class='overview-hero_inner'
    :style='heroImage'
  >
    <h1
      v-if='title'
      class='overview-hero_title heading-one'
    >
      {{ title }}
    </h1>

    <div
      v-if='subtitle'
      class='overview-hero_subtitle heading-six'
    >
      {{ subtitle }}
    </div>

    <VideoTrailer
      v-if='trailer_video'
      :text='trailer_text'
      :video='trailer_video'
    />
  </div>
</div>
</template>

<script>
import VideoTrailer from 'home/components/shared/VideoTrailer/VideoTrailer'
import BackIcon from './back.svg'

export default {
  props: ['image', 'title', 'subtitle', 'trailer_text', 'trailer_video'],

  components: {
    VideoTrailer,
    BackIcon
  },

  computed: {
    heroImage() {
      return `background-image: url(${this.image})`
    }
  },

  methods: {
    returnBack() {
      if (window.history.length > 2) {
        this.$router.back()
      } else {
        this.$router.push({ name: 'home' })
      }
    }
  }
}

</script>

<style lang='sass' scoped>
  @import './Hero'
</style>
