// Course model based on API::V1::ResourceCardSerializer
export default {
  // Relationships
  activity: {
    jsonApi: 'hasOne',
    type: 'activities'
  },

  // Images
  image: null,

  // Attributes
  headline: null,
  tags: [],
  course_ids: [],
  created_at: null
}
