<template>
  <header
    class='header'
    :class="{'header--tablet' : is_tablet}"
  >
  <template v-if='!is_tablet'>
    <div id="skiptocontent">
      <a
        href="#main"
        @click.prevent='skipToMainContent'
      >
        Skip to main content
      </a>
    </div>

    <template v-if='has_router && !in_account_app'>
      <router-link
        v-if='logo'
        :to="{ name: 'home' }"
        class='header_logo'
      >
        <img
          :src='logo'
          :alt='subdomain'
        >
      </router-link>
    </template>

    <template v-else>
      <a
        v-if='logo'
        href='/home'
        class='header_logo'
      >
        <img
          :src='logo'
          :alt='subdomain'
        >
      </a>
    </template>

    <nav
      v-if='!hide_links'
      class='header_links'
    >
      <template v-if='has_router'>
        <router-link
          :to="{ name: 'home' }"
          class='main-nav-link'
          :aria-current="ariaCurrentCheck('home')"
          active-class='active'
        >
          {{ $t('home') }}
        </router-link>

        <template v-if="showLinkType('catalog')">
          <!-- eslint-disable-next-line vue/no-unused-vars -->
          <router-link
            v-slot="{ href, navigate }"
            :to="{ name: 'courses'}"
          >
            <a
              :class='catalogLinkActiveClass()'
              :href='href'
              class='main-nav-link'
              :aria-current="ariaCurrentCheck('catalog')"
              @click='navigate'
            >
              {{ $t('catalog') }}
            </a>
          </router-link>
        </template>

        <template v-else>
          <router-link
            v-if="showLinkType('learning_plans')"
            :to="{ name: 'learning-plans' }"
            class='main-nav-link'
            :aria-current="ariaCurrentCheck('learning-plans')"
            active-class='active'
          >
            {{ $t('learning_plans') }}
          </router-link>

          <router-link
            v-if="showLinkType('courses')"
            :to="{ name: 'courses' }"
            class='main-nav-link'
            :aria-current="ariaCurrentCheck('courses')"
            active-class='active'
          >
            {{ $t('courses') }}
          </router-link>

          <router-link
            v-if="showLinkType('audio_series')"
            :to="{ name: 'audio-series' }"
            class='main-nav-link'
            :aria-current="ariaCurrentCheck('audio-series')"
            active-class='active'
          >
            {{ $t('podcasts') }}
          </router-link>
        </template>

        <router-link
          v-if="showLinkType('resources')"
          :to="{ name: 'resources' }"
          class='main-nav-link'
          :aria-current="ariaCurrentCheck('resources')"
          active-class='active'
        >
          {{ $t('resources') }}
        </router-link>

        <router-link
          v-if="showLinkType('instructors')"
          :to="{ name: 'instructors' }"
          class='main-nav-link'
          :aria-current="ariaCurrentCheck('instructors')"
          active-class='active'
        >
          {{ $t('instructors') }}
        </router-link>
      </template>

      <template v-else>
        <a
          href='/home'
          class='main-nav-link'
        >
          {{ $t('home') }}
        </a>

        <template v-if="showLinkType('catalog')">
          <a
            href='/home/courses'
            class='main-nav-link'
          >
            {{ $t('catalog') }}
          </a>
        </template>

        <template v-else>
          <a
            v-if="showLinkType('learning_plans')"
            href='/home/learning-plans'
            class='main-nav-link'
          >
            {{ $t('learning_plans') }}
          </a>

          <a
            v-if="showLinkType('courses')"
            href='/home/courses'
            class='main-nav-link'
          >
            {{ $t('courses') }}
          </a>

          <a
            v-if="showLinkType('audio_series')"
            href='/home/audio-series'
            class='main-nav-link'
          >
            {{ $t('podcasts') }}
          </a>
        </template>

        <a
          v-if="showLinkType('resources')"
          href='/home/resources'
          class='main-nav-link'
        >
          {{ $t('resources') }}
        </a>

        <a
          v-if="showLinkType('instructors')"
          href='/home/instructors'
          class='main-nav-link'
        >
          {{ $t('instructors') }}
        </a>
        <!--<a href='/search' class='main-nav-link' @click.prevent='openSearch'><i class='search-icon'></i>Search</a>-->
      </template>
    </nav>

    <div
      v-if='hide_links'
      class='header_slogan copy-three'
    >
      {{ $t('education_that_elevates') }}
    </div>

    <div class='header_menu'>
      <template v-if='signed_in'>
        <router-link
          v-if='has_router'
          :to="{ name: 'my_activity' }"
          class='header-menu_my-activity secondary-nav-link'
          active-class='active'
        >
          {{ $t('my_activity') }}
        </router-link>

        <a
          v-else
          href='/home/activity'
          class='header-menu_my-activity secondary-nav-link'
        >
          {{ $t('my_activity') }}
        </a>

        <!-- Notifications Button -->
        <NotificationsInformer v-if="notifications_enabled" />

        <UserMenu :user='user' />
      </template>

      <template v-else>
        <!-- Log In -->
        <a
          v-if='!hide_log_in'
          href='/account/sign-in'
          class='header_menu_log-in action-two-transparent'
          :class="{'button-view': hide_sign_up}"
          @click='signIn'
        >
          {{ $t('log_in') }}
        </a>

        <!-- Sign Up -->
        <a
          v-if='!hide_sign_up'
          href='/account/sign-up'
          class='header_menu_sign-up action-two'
          @click='signUp'
        >
          {{ $t('sign_up') }}
        </a>
      </template>
    </div>
  </template>

  <template v-if='is_tablet'>
    <button
      class='header_mobile-menu'
      :class="{'header_mobile-menu--active': is_menu_opened}"
      :aria-expanded='is_menu_opened || "false"'
      aria-controls='header_mobile-menu_menu'
      :title="$t('open_menu')"
      @click.prevent='openMobileMenu'
    >
      <div class='icon' />
    </button>

    <transition name='menu-appear'>
      <div
        v-if='is_menu_opened'
        id='header_mobile-menu_menu'
        class='header_mobile-menu_menu'
      >
        <img
          class="header_mobile-menu_logo"
          :src='logo'
          :alt='subdomain'
        >

        <template v-if='has_router && !hide_links'>
          <router-link
            :to="{ name: 'home' }"
            class='main-nav-link'
            active-class='active'
          >
            {{ $t('home') }}
          </router-link>

          <template v-if="showLinkType('catalog')">
            <!-- eslint-disable-next-line vue/no-unused-vars -->
            <router-link
              v-slot="{ href, navigate }"
              :to="{ name: 'courses'}"
            >
              <a
                :class='catalogLinkActiveClass()'
                :href='href'
                class='main-nav-link'
                :aria-current="ariaCurrentCheck('catalog')"
                @click='navigate'
              >
                {{ $t('catalog') }}
              </a>
            </router-link>
          </template>

          <template v-else>
            <router-link
              v-if="showLinkType('learning_plans')"
              :to="{ name: 'learning-plans' }"
              class='main-nav-link'
              active-class='active'
            >
              {{ $t('learning_plans') }}
            </router-link>

            <router-link
              v-if="showLinkType('courses')"
              :to="{ name: 'courses' }"
              class='main-nav-link'
              active-class='active'
            >
              {{ $t('courses') }}
            </router-link>

            <router-link
              v-if="showLinkType('audio_series')"
              :to="{ name: 'audio-series' }"
              class='main-nav-link'
              active-class='active'
            >
              {{ $t('podcasts') }}
            </router-link>
          </template>

          <router-link
            v-if="showLinkType('resources')"
            :to="{ name: 'resources' }"
            class='main-nav-link'
            active-class='active'
          >
            {{ $t('resources') }}
          </router-link>

          <router-link
            v-if="showLinkType('instructors')"
            :to="{ name: 'instructors' }"
            class='main-nav-link'
            active-class='active'
          >
            {{ $t('instructors') }}
          </router-link>
        </template>

        <template v-else>
          <a
            href='/home'
            class='main-nav-link'
          >
            {{ $t('home') }}
          </a>

          <template v-if="showLinkType('catalog')">
            <a
              href='/home/courses'
              class='main-nav-link'
            >
              {{ $t('catalog') }}
            </a>
          </template>

          <template v-else>
            <a
              v-if="showLinkType('learning_plans')"
              href='/home/learning-plans'
              class='main-nav-link'
            >
              {{ $t('learning_plans') }}
            </a>

            <a
              v-if="showLinkType('courses')"
              href='/home/courses'
              class='main-nav-link'
            >
              {{ $t('courses') }}
            </a>

            <a
              v-if="showLinkType('audio_series')"
              href='/home/audio-series'
              class='main-nav-link'
            >
              {{ $t('podcasts') }}
            </a>
          </template>

          <a
            v-if="showLinkType('resources')"
            href='/home/resources'
            class='main-nav-link'
          >
            {{ $t('resources') }}
          </a>

          <a
            v-if="showLinkType('instructors')"
            href='/home/instructors'
            class='main-nav-link'
          >
            {{ $t('instructors') }}
          </a>
          <!-- <a href='/search' class='main-nav-link' @click.prevent='openSearch'><i class='search-icon'></i>Search</a> -->
        </template>

        <hr class='header_mobile-menu_separator'>

        <template v-if='signed_in'>
          <router-link
            :to="{ name: 'profile' }"
            class='user-menu_avatar'
            :title="$t('my_account')"
          >
            <Avatar
              :image='avatar_url'
              :className="'user_avatar'"
            />
          </router-link>

          <router-link
            v-if='has_router'
            :to="{ name: 'my_activity' }"
            class='secondary-nav-link'
            active-class='active'
          >
            {{ $t('my_activity') }}
          </router-link>

          <a
            v-else
            href='/home/activity'
            class='secondary-nav-link'
          >
            {{ $t('my_activity') }}
          </a>

          <a
            class='action-one-branded'
            href='#sign-out'
            @click.prevent='signOut'
          >
            {{ $t('sign_out') }}
          </a>
        </template>

        <template v-else>
          <!-- Log In -->
          <a
            v-if='!hide_log_in'
            href='/account/sign-in'
            class='header_mobile-menu_log-in action-one-branded'
            @click='signIn'
          >
            {{ $t('log_in') }}
          </a>

          <!-- Sign Up -->
          <a
            v-if='!hide_sign_up'
            href='/account/sign-up'
            class='header_mobile-menu_sign-in action-two-transparent'
            @click='signUp'
          >
            {{ $t('sign_up') }}
          </a>
        </template>
      </div>
    </transition>

    <a
      v-if='logo'
      href='/home'
      class='header_logo'
    >
      <img
        :src='logo'
        :alt='subdomain'
      >
    </a>

    <div class='header_menu'>
      <template v-if='signed_in'>
        <!-- Notifications Button -->
        <NotificationsInformer v-if="notifications_enabled" />

        <UserMenu :user='user' />
      </template>

      <template v-else>
        <!-- Log In -->
        <a
          v-if='!hide_log_in'
          href='/account/sign-in'
          class='header_menu_log-in--mobile action-two'
          @click='signIn'
        >
          {{ $t('log_in') }}
        </a>
      </template>
    </div>
  </template>
</header>
</template>

<script>
import UserMenu from './UserMenu/UserMenu'
import Avatar from 'components/Avatar/UserAvatar'
import whatInput from 'what-input' // eslint-disable-line no-unused-vars
import NotificationsInformer from 'components/NotificationsInformer/NotificationsInformer'

import { useHomeStore } from 'home/stores/home'
import { mapState, mapActions } from 'pinia'

export default {
  props: [
    'hide_log_in',
    'hide_sign_up',
    'hide_links',
    'logo',
    'subdomain'
  ],

  components: {
    Avatar,
    UserMenu,
    NotificationsInformer
  },

  data() {
    return {
      window_width: 0,
      is_tablet: false,
      is_menu_opened: false,
      current_route_name: null
    }
  },

  watch: {
    'window_width'(newWidth, oldWidth) {
      if (newWidth <= 768) {
        this.is_tablet = true
      } else {
        this.is_tablet = false
      }
    }
  },

  mounted() {
    this.window_width = window.innerWidth

    this.$nextTick(function() {
      const that = this
      window.addEventListener('resize', () => {
        that.window_width = window.innerWidth
      })
    })

    this.current_route_name = this.$router.currentRoute.value.name
    this.$router.beforeEach((to, from, next) => {
      this.current_route_name = to.name
      next()
    })
  },

  methods: {
    ...mapActions(useHomeStore, [
      'toggleAccountOverlay'
    ]),

    openSearch() {
      this.$parent.searchOpened = true
    },

    openMobileMenu() {
      this.is_menu_opened = !this.is_menu_opened

      if (this.is_menu_opened) {
        document.body.addEventListener('click', this.menuListener)
        $('body').addClass('mobile-menu-opened')
      } else {
        document.body.removeEventListener('click', this.menuListener)
        $('body').removeClass('mobile-menu-opened')
      }
    },

    menuListener(e) {
      const patt = /header_mobile-menu/g

      if (patt.test(e.target.className) || $(e.target).parents('.header_mobile-menu').length) {
        return false
      }
      this.is_menu_opened = false
      document.body.removeEventListener('click', this.menuListener)
      $('body').removeClass('mobile-menu-opened')
    },

    showLinkType(type) {
      return this.configurationValue(`header_menu_${type}_visible`, true)
    },

    ariaCurrentCheck(name) {
      if (this.current_route_name === name) {
        return 'page'
      } else if (name === 'catalog') {
        if (this.current_route_name === 'audio-series' ||
          this.current_route_name === 'learning-plans' ||
          this.current_route_name === 'courses'
        ) {
          return 'page'
        } else return null
      } else {
        return null
      }
    },

    catalogLinkActiveClass() {
      if (this.current_route_name === 'audio-series' ||
        this.current_route_name === 'learning-plans' ||
        this.current_route_name === 'courses'
      ) {
        return 'active'
      }
    },

    skipToMainContent() {
      const focusableElements = $('.home-container').find('a, button, input, textarea')
      focusableElements.first().focus()
    },

    configurationValue(key, fallback) {
      return this.$root.configuration && this.$root.configuration.hasOwnProperty(key) ? this.$root.configuration[key] : fallback
    },

    signIn(event) {
      if (this.modalAuthEnabled) {
        event.preventDefault()
        this.toggleAccountOverlay({
          context: 'sign-in',
          redirect: window.location.href
        })
      }
    },

    signUp(event) {
      if (this.modalAuthEnabled) {
        event.preventDefault()
        this.toggleAccountOverlay({
          context: 'auto',
          redirect: window.location.href
        })
      }
    }
  },

  computed: {
    ...mapState(useHomeStore, [
      'modalAuthEnabled'
    ]),

    avatar_url() {
      return this.user && (this.user.avatar || this.user.external_avatar)
    },

    has_router() {
      return this.$router
    },

    in_account_app() {
      return this.$router && window.location.href.match(/account/)
    },

    user() {
      return this.$parent.user
    },

    signed_in() {
      return this.$parent.signed_in
    },

    notifications_enabled() {
      return this.configurationValue('notifications_in_app_enabled')
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './Header'
</style>
