<template>
<div class='overview-page_section'>
  <div class='overview-page_section-title'>
    <slot name='title' />
  </div>

  <div class='overview-page_section-content'>
    <slot name='content' />
  </div>
</div>
</template>

<style lang='sass' scoped>
  @import './Section'
</style>
