<template>
<div class='self-assessment_navigation'>
  <button
    class='self-assessment_navigation_back copy-three'
    :disabled='back_disabled'
    @click.prevent='back'
  >
    {{ $t('back') }}
  </button>

  <div
    v-if='!hide_progress'
    class='self-assessment_navigation_pages'
  >
    <span class='self-assessment_navigation_current copy-three'>
      {{ current_question }}
    </span>

    <span class='self-assessment_navigation_of copy-three'>/</span>

    <span class='self-assessment_navigation_total copy-three'>
      {{ total_pages }}
    </span>
  </div>

  <button
    class='self-assessment_navigation_next copy-three'
    :disabled='next_disabled'
    @click.prevent='next'
  >
    {{ $t('next') }}
  </button>
</div>
</template>

<script>
export default {
  props: [
    'questions',
    'current_question',
    'is_current_question_passed',
    'back',
    'next',
    'can_advance',
    'hide_progress'
  ],

  data() {
    return {
    }
  },

  computed: {
    total_pages() { return this.questions.length },

    back_disabled() {
      return !(this.current_question > 1)
    },

    next_disabled() {
      return !this.can_advance || (!this.is_current_question_passed && !this.$parent.show_encouragement)
    }
  }
}
</script>

<style lang='sass' scoped>
  @import 'Navigation'
</style>
