import BaseClient from './base-client'

export default class TbkClient extends BaseClient {
  getEmbedCode(callback) {
    callback(this.videoCode)
  }

  pause(element) {
    const id = $(element).find('.wistia_embed').attr('id')
    /* global Wistia */
    if (Wistia) {
      const player = Wistia.api(id)
      if (player) player.pause()
    }
  }
}
