// LearningPlan model based on API::V1::LearningPlanSerializer
export default {

  // Relationships
  overview_page: {
    jsonApi: 'hasOne',
    type: 'learning_plan_overview_pages'
  },

  lessons: {
    jsonApi: 'hasMany',
    type: 'lessons'
  },

  instructors: {
    jsonApi: 'hasMany',
    type: 'instructors'
  },

  instructor: {
    jsonApi: 'hasOne',
    type: 'instructors'
  },

  academy_partner: {
    jsonApi: 'hasOne',
    type: 'academy_partners'
  },

  // Images and Files
  card_image: null,
  syllabus: null,

  // Attributes
  id: null,
  identifier: null,
  title: null,
  position: null,
  card_intro: null,
  overview_intro: null,
  trailer_cta: null,
  trailer: null,
  completion_criteria: null,
  duration: null,
  enrolled: null,
  enrollment_date: null,
  completion_date: null,
  badge_url: null,
  sticker_image: null,
  badging_enabled: null,
  required_lessons: 0,
  completed_required_lessons: 0,
  progress: 0,
  author_description: null,
  published: null,
  published_date: null
}
