<template>
  <div
    class='discussion-modal_overlay discussion-modal_close'
    @click='close()'
    @keyup='detectKeyup'
    @keydown='detectKeydown'
  >
    <div
      class='discussion-modal_popup'
      :class="{'zoomed-image': zoomed_image}"
    >
      <button
        ref='close_button'
        href='#'
        class='discussion-modal_close-button discussion-modal_close'
      >
        {{ $t('close') }}
      </button>

      <template v-if='zoomed_image_orientation'>
        <div class="rotation-wrapper-outer">
          <div class="rotation-wrapper-inner">
            <img
              :src='zoomed_image'
              :alt="$t('image')"
              :class='zoomed_image_class'
            >
          </div>
        </div>
      </template>

      <template v-if='zoomed_image && !zoomed_image_orientation'>
        <img
          :src='zoomed_image'
          :alt="$t('image')"
        >
      </template>

      <div
        v-if='modal_description'
        class='discussion-modal_content'
      >
        <h1 class='discussion-modal_title heading-four'>
          {{ modal_title }}
        </h1>

        <p class='discussion-modal_description copy-four'>
          {{ modal_description }}
        </p>
      </div>

      <div
        v-if='modalAction'
        class='discussion-modal_actions'
      >
        <button
          ref='action_button'
          class='discussion-modal_button action-one-branded'
          @click='modalAction()'
        >
          {{ button_text }}
        </button>

        <button
          ref='cancel_button'
          href='#'
          class='discussion-modal_exit discussion-modal_close'
        >
          {{ $t('cancel') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'closeModal',
    'modalAction', 'modal_title', 'modal_description',
    'button_text', 'zoomed_image', 'zoomed_image_orientation'],

  created() {
    this.setFocus()
  },

  data() {
    return {
      shift_is_pressed: false
    }
  },

  computed: {
    zoomed_image_class() {
      if (this.zoomed_image_orientation) {
        return this.zoomed_image_orientation
      }
      return null
    }
  },

  methods: {
    close() {
      const regexp = /\b(discussion-modal_close)\b/i
      if (regexp.test(event.target.className)) {
        event.preventDefault()
        this.closeModal()
      }
    },

    setFocus() {
      setTimeout(() => {
        if (this.$refs.action_button) {
          this.$refs.action_button.focus()
          this.restrictFocus('close_button', 'cancel_button')
        } else {
          this.$refs.close_button.focus()
        }
      }, 100)
    },

    detectKeyup(e) {
      if ((e.key !== undefined && e.key === 'Escape') ||
      (e.keyCode !== undefined && e.keyCode === 27)) {
        this.closeModal()
      }

      if (e.key === 'Shift') {
        this.shift_is_pressed = false
      }
    },

    detectKeydown(e) {
      if (e.key === 'Shift') {
        this.shift_is_pressed = true
      }
    },

    restrictFocus(firstItem, lastItem) {
      this.$refs[lastItem].onblur = (e) => {
        if (this.$refs[firstItem] && !this.shift_is_pressed) {
          this.$refs[firstItem].focus()
        }
      }

      this.$refs[firstItem].onblur = (e) => {
        if (this.$refs[lastItem] && this.shift_is_pressed) {
          this.$refs[lastItem].focus()
        }
      }
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './DiscussionModal'
</style>
