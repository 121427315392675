<template>
<div
  class='interactive-section-intro'
  :style='background_style'
>
  <h2 class='interactive-section-intro_headline heading-three'>
    {{ headline }}
  </h2>

  <p class='interactive-section-intro_content copy-one'>
    {{ desctiption }}
  </p>

  <button
    class='interactive-section-intro_cta action-one-branded'
    @click.prevent='toggle'
  >
    {{ cta }}
  </button>
</div>
</template>

<script>
export default {
  props: ['activity', 'toggle'],

  computed: {
    background_style() {
      // Background image
      if (this.activity.background_image) {
        return `backgroundImage: url(${this.activity.background_image})`
      }
      // Gradient
      if (!this.activity.background_image && this.activity.gradient_start) {
        const start = this.activity.gradient_start
        const end = this.activity.gradient_end || this.activity.gradient_start
        const gradient = `linear-gradient(137deg, ${start} 0%, ${end} 92%)`
        return 'backgroundImage: ' +  gradient
      }
      return null
    },

    headline() {
      return this.activity.headline
    },

    desctiption() {
      return this.activity.instructions ||
          this.activity.subheadline ||
          this.activity.prompt
    },

    cta() {
      if (this.activity.type === 'activity_v1_quizzes') return this.$t('take_quiz')
      if (this.activity.type === 'activity_v1_self_assessments') return this.$t('take_self_assessment')
      if (this.activity.type === 'activity_v1_polls') return this.$t('take_poll')
      if (this.activity.type === 'activity_v1_articles') {
        return this.activity.cta ? this.activity.cta : this.$t('read_the_article')
      }

      return this.activity.cta
    }
  }

}
</script>

<style lang='sass'>
  @import 'Intro'
</style>
