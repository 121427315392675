<template>
<div
  class='course-drawer'
  :class='main_class'
>
  <button
    ref='close-button'
    class='course-drawer_close'
    :title="$t('close')"
    @click.prevent='close'
  >
    <CloseImage />
  </button>

  <!-- Preloader -->
  <div
    v-if='loading'
    class='course-drawer_loading page-loader'
  >
    <div class='loader' />
  </div>

  <template v-if='!loading && !is_mobile'>
    <!-- Navigation -->
    <div
      class='course-drawer_nav'
      role="tablist"
    >
      <template
        v-for='(link, index) in links'
        :key='index'
      >
        <a
          v-if='showTabType(link.name)'
          :id="link.name"
          :ref='link.name'
          href='#'
          :aria-selected="active_tab === link.name ? 'true' : 'false'"
          role='tab'
          :aria-controls="'panel-tab-' + link.name"
          :class='tabClass(link.name)'
          class='main-nav-link'
          @click.prevent='changeTab(link.name, $event)'
          @keyup='switchTabOnArrowPress($event)'
        >
          {{ link.text }}
        </a>
      </template>

      <template
        v-if='course.resources && course.resources.length > 0 && showTabType("resources")'
      >
        <a
          :id='resources'
          href='#'
          :aria-controls='panel-tab-resources'
          :class='tabClass("resources")'
          class='main-nav-link'
          aria-selected='false'
          role='tab'
          @click.prevent='changeTab("resources", $event)'
          @keyup='switchTabOnArrowPress($event)'
        >
          {{ $tc('resources',2) }}
        </a>
      </template>

      <template
        v-if='course.program_drivers && course.program_drivers.length > 0 && showTabType("program_drivers")'
      >
        <a
          id="related_benefits"
          href='#'
          aria-controls='panel-tab-related_benefits'
          :class='tabClass("drivers")'
          class='main-nav-link'
          aria-selected='false'
          role='tab'
          @click.prevent='changeTab("drivers", $event)'
          @keyup='switchTabOnArrowPress($event)'
        >
          {{ $t('related_benefits') }}
        </a>
      </template>
    </div>

    <!-- Content -->
    <div class='course-drawer_content'>
      <transition name='tab-change'>
        <template v-if='active_tab === "overview"'>
          <div
            id="panel-tab-overview"
            aria-labelledby="overview"
            role="tabpanel"
          >
            <Overview :course='course' />
          </div>
        </template>
      </transition>

      <transition name='tab-change'>
        <template v-if='active_tab === "lessons"'>
          <ul
            id="panel-tab-lessons"
            :key='"lessons"'
            aria-labelledby="lessons"
            class='course-drawer_list'
            role="tabpanel"
          >
            <li
              v-for='(lesson, index) in course.lessons'
              :key='index'
              class='lesson-card_list-item'
            >
              <LessonCard
                :lesson='lesson'
                :index='index'
              />
            </li>
          </ul>
        </template>
      </transition>

      <transition name='tab-change'>
        <template v-if='active_tab === "resources"'>
          <ul
            id="panel-tab-resources"
            :key='"resources"'
            aria-labelledby="resources"
            class='course-drawer_list'
            role="tabpanel"
            tabindex="0"
          >
            <li
              v-for='(resource, index) in course.resources'
              :key='index'
              class='resource-card_list-item'
            >
              <ResourceCardSmall :resource='resource' />
            </li>
          </ul>
        </template>
      </transition>

      <transition name='tab-change'>
        <template v-if='active_tab === "drivers"'>
          <ul
            id="panel-tab-drivers"
            :key='"drivers"'
            aria-labelledby="drivers"
            class='course-drawer_list'
            role="tabpanel"
            tabindex="0"
          >
            <li
              v-for='(driver, index) in course.program_drivers'
              :key='index'
              class='driver-card_list-item'
            >
              <DriverCard :driver='driver' />
            </li>
          </ul>
        </template>
      </transition>
    </div>
  </template>
</div>
</template>

<script>
import LessonCard from './LessonCard/LessonCard'
import DriverCard from './DriverCard/DriverCard'
import ResourceCardSmall from 'home/components/shared/ResourceCardSmall/ResourceCardSmall'
import Overview from './Overview/CourseDrawerOverview'
import WindowSizeMixin from 'home/components/shared/WindowSizeMixin'
import TabAccessibilityMixin from 'home/components/shared/TabAccessibilityMixin'
import Course from 'home/models/course'

import CloseImage from './close.svg'

export default {
  mixins: [WindowSizeMixin, TabAccessibilityMixin],

  components: {
    Overview,
    LessonCard,
    DriverCard,
    ResourceCardSmall,
    CloseImage
  },

  props: [
    'identifier',
    'tab',
    'setOpenedDrawer',
    'setOpenedCourse'
  ],

  data() {
    return {
      links: [
        { name: 'overview', text: this.$t('overview') },
        { name: 'lessons', text: this.$t('lessons') }
      ],
      valid_tabs: ['overview', 'lessons', 'resources', 'drivers'],
      activeTab: null,
      loading: false,
      course: null
    }
  },

  created() {
    this.loadCourse()

    // Setting default opened tab
    window.activeDrawerTabs = window.activeDrawerTabs || {}
    if (this.use_router && this.$router.currentRoute.value.params.identifier === this.identifier) {
      this.activeTab = this.$router.currentRoute.value.query.tab || 'overview'
    } else if (window.activeDrawerTabs[this.identifier]) {
      this.activeTab = window.activeDrawerTabs[this.identifier]
    } else {
      this.activeTab = 'overview'
    }
    window.activeDrawerTabs[this.identifier] = this.activeTab
  },

  mounted() {
    setTimeout(() => {
      this.$refs['close-button'].focus()
    }, 500)
  },

  computed: {
    main_class() {
      return (this.is_mobile ? 'course-drawer--mobile' : 'course-drawer--desktop')
    },

    active_tab() {
      return this.activeTab
    },

    use_router() {
      return this.$route.name.match(/^course/)
    }
  },

  methods: {
    tabClass(tab) {
      return tab === this.active_tab ? 'active' : ''
    },

    changeTab(tab, e) {
      if (this._isValidTab(tab)) {
        window.activeDrawerTabs[this.identifier] = tab
        this.activeTab = window.activeDrawerTabs[this.identifier]

        this.toggleTabsAria(e)

        if (this.use_router) {
          const q = (tab === 'overview') ? {} : { tab }
          this.$router.push({ name: 'courses', params: { identifier: this.identifier }, query: q })
        }
      }
    },

    _isValidTab(tab) {
      return this.valid_tabs.indexOf(tab) >= 0
    },

    close() {
      this.setOpenedDrawer(false)
      this.setOpenedCourse(null)

      if (this.use_router) {
        // clear page url without page re-render
        window.history.pushState({}, null, '/home/courses/')
      }
    },

    showTabType(type) {
      const key = `course_drawer_${type}_visible`
      if (this.$root.configuration && this.$root.configuration.hasOwnProperty(key)) {
        return this.$root.configuration[key]
      } else {
        return true
      }
    },

    loadCourse() {
      if (this.identifier === null) return false

      this.loading = true
      this.course = Course.load(this.identifier, () => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang='sass' scoped>
  @import 'home/styles/shared/CourseDrawer'
</style>
