<template>
<VeeForm
  v-slot="{ errors, meta }"
  class='magic-link_form'
  @submit='submitForm'
>
  <div class='magic-link_field'>
    <Field
      v-slot="{ field }"
      v-model='user_email'
      rules='required|email'
      name='user_email'
    >
      <input
        id='user_email'
        type='text'
        v-bind='field'
        :class="{'not-empty': user_email }"
      >
    </Field>

    <label for='user_email'>{{ $t('email') }}</label>
    <span
      v-if="errors.user_email"
      class='form-error'
    >
      {{ errors.user_email }}
    </span>
  </div>

  <div class='magic-link_field'>
    <input
      :disabled='!meta.valid'
      :value="$t('magic_link.send')"
      class='magic-link_submit-button action-one-branded'
      type='submit'
      @click.prevent='submitForm'
    >
  </div>
</VeeForm>
</template>

<script>
import { Form as VeeForm, Field } from 'vee-validate'
import { defineRule } from 'vee-validate'
import {
  required,
  email
} from '@vee-validate/rules'

import { sendEmailRequest } from '../../../api'

export default {
  props: ['auth', 'redirect_to_location'],

  data() {
    return {
      user_email: null
    }
  },

  created() {
    this.user_email = this.$root.user_email

    // validation rules
    defineRule('required', required);
    defineRule('email', email);
  },

  components: {
    VeeForm,
    Field
  },

  methods: {
    submitForm() {
      let path = this.auth.send_email_path
      if (this.redirect_to_location) { path = path.concat(`?redirect_to=${this.redirect_to_location}`) }

      const data = {
        path,
        email: this.user_email
      }

      sendEmailRequest(data, (response) => {
        if (response.data.message) {
          this.$parent.response_message = response.data.message
        } else if (response.status === 200) {
          this.$parent.response_message = this.$t('magic_link.sent')
        }
      })
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './MagicLinkForm'
</style>
