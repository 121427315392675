// Lesson model based on API::V1::HomePageSerializer
export default {
  // Images
  header_logo: null,
  footer_logo: null,
  attribution_logo: null,
  welcome_image: null,

  // Attributes
  locale: null,
  supported_languages: {},
  translations: {}
}
