<template>
<div class='audio-series-section'>
  <SectionDrawer
    :title='section.headline'
    :description='section.subheadline'
    modifier='audio-series'
    type='audio_series'
    :context='context'
  >
    <AudioSeriesSectionRow
      v-for='(row, sectionRowIndex) in section_rows'
      :key='sectionRowIndex'
      :section='section'
      :row='row'
      :row_index='sectionRowIndex'
      :opened_audio_series='opened_audio_series'
      :opened_drawer='opened_drawer'
      :setOpenedDrawer='setOpenedDrawer'
      :setOpenedAudioSeries='setOpenedAudioSeries'
    />
  </SectionDrawer>
</div>
</template>

<script>
import WindowSizeMixin from 'home/components/shared/WindowSizeMixin'
import SectionDrawer from 'home/components/shared/SectionDrawer/SectionDrawer.vue'
import AudioSeriesSectionRow from 'home/components/shared/SectionDrawer/AudioSeriesSectionRow/AudioSeriesSectionRow.vue'

export default {
  mixins: [WindowSizeMixin],

  props: ['section', 'context'],

  data() {
    return {
      opened_drawer: false,
      opened_audio_series: null
    }
  },

  components: {
    SectionDrawer,
    AudioSeriesSectionRow
  },

  computed: {
    section_rows() {
      const rows = []; const items = this.section.audio_series.slice()

      // Here we split items into rows by 3, 2 or 1 item in a row
      // (or by 4, 2 and 1 for "catalog" pages)
      // depending on tablet or mobile screen size
      let items_in_row = this.context === 'catalog' ? 4 : 3
      if (this.is_tablet) items_in_row = 2
      if (this.is_mobile) items_in_row = 1

      while (items.length) {
        rows.push(items.splice(0, items_in_row))
      }

      return rows
    }
  },

  methods: {
    setOpenedDrawer(value) {
      this.opened_drawer = value
    },

    setOpenedAudioSeries(value) {
      this.opened_audio_series = value
    }
  }

}
</script>
