// Outro model based on API::V1::LessonPage::Outro::V2Serializer
export default {
  // Images
  background_image: null,

  // Attributes
  skipped_activities_slide: null,
  next_lesson_slide: null,
  complete_slide: null
}
