// Part model based on API::V1::PartSerializer
export default {

  // Relationships
  slides: {
    jsonApi: 'hasMany',
    type: 'slides'
  },

  lesson: {
    jsonApi: 'hasOne',
    type: 'lesson'
  },

  // Attributes
  author_description: null,
  headline: null,
  subheadline: null,
  duration: null,
  identifier: null
}
