<template>
<div class='hero-container'>
  <template v-if='sections.length > 1'>
    <carousel
      :itemsToShow='sliderSettings.itemsToShow'
      :itemsToSlide='sliderSettings.itemsToSlide'
      :initialSlide='sliderSettings.initialSlide'
      :mouseDrag='sliderSettings.mouseDrag'
      :touchDrag='sliderSettings.touchDrag'
      wrapAround
      :autoplay="sliderSettings.autoplay ? sliderSettings.playSpeed : 0"
    >
      <slide
        v-for="(section, index) in sections"
        :key="index"
        :index="index"
      >
        <HeroSection
          :section='section'
          :openVideoTrailer='openVideoTrailer'
          :openActivityOverlay='openActivityOverlay'
        />
      </slide>

      <template #addons>
        <Navigation v-if="is_navigation_shown" />
          <div
            class="carousel__paginationContainer"
            >
            <button
              class="play-pause-button"
              aria-label="Toggle Autoplay"
              :aria-pressed="sliderSettings.autoplay"
              @click="toggleAutoplay"
            >
              <CIcon
              v-if="!sliderSettings.autoplay"
                :width="20"
                :height="20"
                :icon="cilMediaPlay"
                />
              <CIcon
              v-else
                :width="20"
                :height="20"
                :icon="cilMediaPause"
                />
            </button>
            <Pagination />
          </div>
      </template>
    </carousel>
  </template>

  <template v-if='sections.length === 1'>
    <HeroSection
      :section='sections[0]'
      :openVideoTrailer='openVideoTrailer'
      :openActivityOverlay='openActivityOverlay'
    />
  </template>

  <VideoTrailer
    v-if='trailer_opened && trailer'
    :video='trailer'
    hide_link=true
    auto_init=true
    :closeCb='closeVideoTrailer'
  />

  <!-- Activities Overlay -->
  <transition
    name="fast-fade"
    mode='out-in'
  >
    <ActivitiesOverlay
      v-if='slide'
      :slide='slide'
    />
  </transition>
</div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import {
  Carousel,
  Slide,
  Pagination,
  Navigation
} from 'vue3-carousel'

import HeroSection from '../HeroSection/HeroSection'
import VideoTrailer from 'home/components/shared/VideoTrailer/VideoTrailer'
import ActivitiesOverlay from 'components/activities/ActivitiesOverlay'

import { useHomeStore } from 'home/stores/home'
import { mapActions } from 'pinia'
import { CIcon } from '@coreui/icons-vue'
import { inject } from 'vue'
import { cilMediaPause, cilMediaPlay } from '@coreui/icons'

export default {
  props: ['section'],

  data() {
    return {
      sliderSettings: {
        itemsToShow: 1,
        itemsToSlide: 1,
        initialSlide: 0,
        infiniteScroll: true,
        mouseDrag: true,
        touchDrag: true,
        hoverPause: true,
        autoplay: true,
        playSpeed: 6000,
      },

      trailer_opened: false,
      trailer: null,
      slide: null
    }
  },

  components: {
    Carousel,
    Slide,
    CIcon,
    Pagination,
    Navigation,
    HeroSection,
    VideoTrailer,
    ActivitiesOverlay
  },

  setup() {
   return { cilMediaPause, cilMediaPlay }
  },

  computed: {
    sections() {
      return this.section.pages
    },

    is_navigation_shown() {
      // TODO Just in case navigation will be needed
      // return [this.isNavigationShown]
      return false
    }
  },

  methods: {
    ...mapActions(useHomeStore, [
      'toggleActivityOverlay'
    ]),

    openVideoTrailer(trailer) {
      this.trailer_opened = true
      this.trailer = trailer
    },

    closeVideoTrailer() {
      this.trailer_opened = false
      this.trailer = null
    },

    openActivityOverlay(slide) {
      this.slide = slide
      this.toggleActivityOverlay({ activity_id: this.slide.activity.id })
    },

    toggleAutoplay() {
      this.sliderSettings.autoplay = !this.sliderSettings.autoplay
    }
  }
}
</script>

<style lang='sass'>
  @import './HeroContainer'
</style>
