<template>
<div
  class='interactive-section-outro'
  :style='background_style'
>
  <h2 class='interactive-section-outro_headline heading-three'>
    {{ activity.results.headline }}
  </h2>

  <p class='interactive-section-outro_content copy-one'>
    {{ activity.results.content }}
  </p>

  <a
    v-if='has_outcome'
    class='interactive-section-outro_cta-primary action-one-branded'
    :href='outcome_href'
    :target='outcome_target'
    @click='ctaLinkClick($event, outcome_href)'
  >
    {{ outcome_cta }}
  </a>

  <button
    class='interactive-section-outro_cta-secondary heading-six'
    @click.prevent='toggle'
  >
    {{ $t('view_self_assessment_result') }}
  </button>
</div>
</template>

<script>
import ResultsMixin from 'components/activities/v2/SelfAssessment/results-mixin'

import { useHomeStore } from 'home/stores/home'
import { mapState } from 'pinia'

export default {
  mixins: [ResultsMixin],

  props: [
    'activity',
    'toggle',
    'slide_result',
    'toggleAccountOverlay'
  ],

  mounted() {
    if (this.slide_result.status === 'passed' && Object.keys(this.slide_result.results).length) {
      // deep copy
      this.activity_results = JSON.parse(JSON.stringify(this.slide_result.results.answers))
      this.categories = JSON.parse(JSON.stringify(this.slide_result.results.categories))
    }
  },

  computed: {
    ...mapState(useHomeStore, [
      'modalAuthEnabled'
    ]),

    background_style() {
      // Background image
      if (this.activity.background_image) {
        return `backgroundImage: url(${this.activity.background_image})`
      }
      // Gradient
      if (!this.activity.background_image && this.activity.gradient_start) {
        const start = this.activity.gradient_start
        const end = this.activity.gradient_end || this.activity.gradient_start
        const gradient = `linear-gradient(137deg, ${start} 0%, ${end} 92%)`
        return 'backgroundImage: ' +  gradient
      }
      return null
    },

    has_outcome() {
      return (this.activity.results.cta_href) ||
          (this.hero_outcome && this.outcome_href)
    },

    outcome_href() {
      if (this.activity.results.cta_href) {
        return this.activity.results.cta_href
      } else if (this.hero_outcome &&
        this.hero_outcome.outcome &&
        this.hero_outcome.outcome.link.href
      ) {
        return this.hero_outcome.outcome.link.href
      }

      return false
    },

    outcome_target() {
      return (this.activity.results.cta_href &&
          !this.modalAuthEnabled &&
          this.isSignUpLink(this.activity.results.cta_href))
        ? ''
        : '_blank'
    },

    outcome_cta() {
      if (this.activity.results.cta_href || this.activity.results.cta) {
        return this.activity.results.cta
      } else if (this.has_outcome && this.hero_outcome.outcome.link.title) {
        return this.hero_outcome.outcome.link.title
      }
      return null
    }
  },

  methods: {
    isSignUpLink(path) {
      return path ? path.match(/^\/account\/sign-up/) : false
    },

    ctaLinkClick(event, path) {
      if (this.modalAuthEnabled && this.isSignUpLink(path)) {
        event.preventDefault()

        if (typeof (this.toggleAccountOverlay) === 'function') {
          this.toggleAccountOverlay({
            context: 'auto',
            redirect: window.location.href
          })
        }
      }
    }
  }
}
</script>

<style lang='sass'>
  @import 'Outro'
</style>
