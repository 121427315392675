// Course Section model based on API::V1::Section::InProgressSerializer
export default {
  lessons: [],

  // Attributes
  headline: null,
  subheadline: null,
  link: null,
  position: 0
}
