// Episode model based on API::V1::AudioSeries::EpisodeSerializer
export default {
  // Relationships
  audio_series: {
    jsonApi: 'hasOne',
    type: 'audio_series'
  },

  // Images
  image: null,

  // Attributes
  title: null,
  description: null,
  audio: {},
  duration: null,
  published_date: null // Note: published_date is in UTC format
}
