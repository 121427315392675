export default {

  data() {
    return {
      is_tablet: false,
      is_mobile: false
    }
  },

  mounted() {
    this.detectScreenType(window.innerWidth)

    window.addEventListener('resize', this._handleResizeEvent)
    window.addEventListener('orientationchange', this._handleResizeEvent)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this._handleResizeEvent)
  },

  methods: {
    detectScreenType(screenWidth) {
      if (screenWidth <= 768 && screenWidth > 450) {
        this.is_tablet = true
        this.is_mobile = false
      } else if (screenWidth <= 450) {
        this.is_tablet = false
        this.is_mobile = true
      } else {
        this.is_tablet = false
        this.is_mobile = false
      }
    },

    _handleResizeEvent(event) {
      // if .sweet-modal-overlay .is-visible are both on dom then don't adjust screen, or it kills video trailer
      const trailer_playing = !!document.querySelector('.sweet-modal-overlay.is-visible')
      if (!trailer_playing) {
        this.detectScreenType(event.currentTarget.innerWidth)
      }
    }
  }

}
