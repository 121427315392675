<template>
  <div class='search-app'>
    <transition
      v-if='!is_data_loaded'
      name='fade'
    >
      <PageLoader />
    </transition>

    <template v-else>
      <SearchHeader
        :results='results_total'
        :keyword='results.keyword'
      />

      <div class='search-content'>
        <div class='search-content_inner'>
          <Navigation
            :results='search_results'
            :show_program_drivers='logged_in'
          />
          <Results :results='search_results' />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import PageLoader from '../../../components/PageLoader/PageLoader'

import SearchHeader from './SearchHeader/SearchHeader'
import Navigation from './Navigation/SearchNavigation'
import Results from './Results/SearchResults'

import { useHomeStore } from 'home/stores/home'
import { mapActions, mapState } from 'pinia'

export default {
  props: ['has_basic_auth'],

  components: {
    PageLoader,

    SearchHeader,
    Results,
    Navigation
  },

  computed: {
    ...mapState(useHomeStore, [
      'signed_in'
    ]),

    search_results() {
      return this.results
    },

    results_total() {
      let results = 0
      const sections = ['courses', 'instructors', 'lessons', 'resources', 'program_drivers']

      sections.forEach((s) => {
        if (this.results[s]) results += this.results[s].length
      })

      return results
    },

    logged_in() {
      return this.signed_in
    }

  },

  data() {
    return {
      searchOpened: false,

      results: null,
      search_keyword: null,
      is_data_loaded: false
    }
  },

  created() {
    this.search_keyword = this.getKeyword()
    this.fetchData()
  },

  methods: {
    ...mapActions(useHomeStore, [
      'getSearchResults'
    ]),

    fetchData() {
      this.is_data_loaded = false

      if (this.search_keyword && this.search_keyword !== '') {
        this.getSearchResults(this.search_keyword).then((data) => {
          this.results = data
          this.is_data_loaded = true
        })
      }
    },

    getKeyword(variable) {
      const query = window.location.search.substring(1)
      const vars = query.split('&')
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        if (pair[0] === 'tag') {
          return decodeURIComponent(pair[1])
        }
      }
      return (false)
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './app'
</style>
