import { Howl, Howler } from 'howler' // eslint-disable-line no-unused-vars

import IconArrow from './icons/arrow-left.svg'
import IconBack from './icons/back.svg'
import IconForward from './icons/forward.svg'
import IconFull from './icons/fullscreen.svg'
import IconFullOff from './icons/fullscreen-off.svg'
import IconGear from './icons/gear.svg'
import IconKebab from './icons/kebab.svg'
import IconPause from './icons/pause.svg'
import IconPlay from './icons/play.svg'
import IconPlaylist from './icons/playlist.svg'
import IconVolume from './icons/volume.svg'

const AudioPlayerMixin = {

  // props: [
  //   'current_time',
  //   'duration',
  //   'rate'
  // ],

  components: {
    IconArrow,
    IconBack,
    IconForward,
    IconFull,
    IconFullOff,
    IconGear,
    IconKebab,
    IconPause,
    IconPlay,
    IconPlaylist,
    IconVolume
  },

  data() {
    return {
      // Player parameters
      current_time: 0,
      duration: null,
      loading_progress: 0,
      player: null,
      sound_id: null,
      speed: 1,
      speed_rates: ['0.5', '0.75', '1', '1.25', '1.5', '1.75', '2'],
      volume: 80,

      // Used in StickyPlayer only, but needed here to avoid actions when fullscreen is on
      fullscreen_mode: false,
      is_mobile_fullscreen: false,

      // TODO Inline only?
      // is_playing: false,
      // has_started: false,

      // Flags
      is_info_visible: false,
      is_muted: false,
      is_playlist_visible: false,
      is_settings_visible: false,
      is_speed_control_visible: false,
      is_volume_control_visible: false

    }
  },

  mounted() {
    this._initPlayer()
  },

  beforeUnmount() {
    this.player.stop() // needed to send analytics if audio was just paused
    this.player.unload()
    if (typeof (this.beforeUnloadListener) === 'function') {
      removeEventListener('beforeunload', this.beforeUnloadListener, { capture: true })
    }
  },

  computed: {
    cover_image() {
      return `backgroundImage: url(${this.audio_image})`
    },

    title() {
      return this.current_playlist_item ? this.current_playlist_item.title : this.audio_title
    },

    title_caption() {
      const separator_1 = ' - '
      const separator_2 = ' -- '
      let caption = this.title.split(separator_1)[0]

      if (caption.length === this.title.length) {
        caption = this.title.split(separator_2)[0]
      }

      return caption
    },

    title_author() {
      const separator_1 = ' - '
      const separator_2 = ' -- '
      const author = this.title.split(separator_1)[1] ||
                this.title.split(separator_2)[1]

      return !author ? '' : author
    },

    image_url() {
      return this.current_playlist_item ? this.current_playlist_item.image : this.audio_image
    },

    description() {
      return this.audio_description
    },

    timing_current() {
      return this._secondsToTime(this.current_time)
    },

    timing_total() {
      return this._secondsToTime(this.duration)
    },

    is_firefox() {
      return navigator.userAgent.toLowerCase().indexOf('firefox') > -1
    }
  },

  methods: {
    // ========================= Player control methods
    seekBackward() {
      this._seek(this.current_time - 15)
    },

    seekForward() {
      this._seek(this.current_time + 30)
    },

    setVolume(value) { // set volume, should be from 0.0 to 1.0
      value = value < 0 ? 0 : value
      value = value > 100 ? 100 : value

      if (!value) { // mute if zero
        this.player.mute(this.is_muted = true)
      } else {
        this.player.mute(this.is_muted = false)
      }

      this.volume = value
      this.player.volume(value / 100)
    },

    toggleMute() { // mute sound if on and unmute if off
      if (!this.volume) {    // if volume was manually set to zero and then unmute clicked
        this.setVolume(10) // ...set the volume to a small value of 10%
      } else {
        this.player.mute(this.is_muted = !this.is_muted)
      }
    },

    setRate(value) { // set playback speed
      if (value >= 0.5 && value <= 2) {
        this.speed = value
        this.player.rate(value)
      }
    },

    detectKeyup(e) {
      this._audioPlayerKeyboard(e)
    },

    // ========================= Player helpers

    _seek(seconds) {
      seconds = seconds < 0 ? 0 : seconds
      seconds = seconds > this.duration ? this.duration : seconds
      this.current_time = seconds

      if (this.sound_id) {
        this.player.seek(seconds, this.sound_id)
        this._updateNotificationsPosition()
      } else {
        // This is possible if player is shown but nothing played yet
        this.playToggle()
        setTimeout(() => {
          // wait till audio actually starts before doing seek (otherwise it will start from the beginning)
          this.player.seek(seconds, this.sound_id)
        }, 500)
      }
    },

    _step() {
      const time = this.player ? this.player.seek() || 0 : 0

      if (!isNaN(time)) {
        this.current_time = time > Math.round(this.duration) ? Math.round(this.duration) : time

        if (typeof (this.updateCurrentAudioStats) === 'function') {
          this.updateCurrentAudioStats({ progress: this.current_time })
        }

        if (typeof (this.onStep) === 'function') {
          this.onStep(this.current_time, this.duration)
        }

        this._updateNotificationsPosition()
      }

      if (this.is_playing) {
        requestAnimationFrame(this._step.bind(this))
      }
    },

    _secondsToTime(seconds) {
      if (!isNaN(seconds)) {
        seconds = Math.round(seconds)
        const m = Math.floor(seconds / 60) || 0
        const s = (seconds - m * 60) || 0

        return m + ':' + (s < 10 ? '0' : '') + s
      }
    },

    _audioPlayerKeyboard(e) {
      switch (e.keyCode) {
        case 27: // ecsape
          // this.infoListener()
          // this.playlistListener()
          // this.settingsListener()
          this.is_info_visible = false
          this.is_playlist_visible = false
          this.is_settings_visible = false
          this.is_speed_control_visible = false
          this.is_volume_control_visible = false
          break
        case 32: // spacebar
          this.playToggle()
          e.preventDefault()
          break
        case 37:  // left arrow
          // this.$refs.timeline.focus()
          this._seek(this.current_time - 5)
          e.preventDefault()
          break
        case 39:  // right arrow
          // this.$refs.timeline.focus()
          this._seek(this.current_time + 5)
          e.preventDefault()
          break
        case 38:  // up arrow
          e.preventDefault()
          // e.stopPropagation()
          if (this.is_speed_control_visible) {
            this.setRate(this.speed - 0.25)
          } else if (!this.fullscreen_mode) {
            this.is_volume_control_visible = true
            this.setVolume(this.volume + 5)
          }
          break
        case 40:  // down arrow
          e.preventDefault()
          // e.stopPropagation()
          if (this.is_speed_control_visible) {
            this.setRate(this.speed + 0.25)
          } else if (!this.fullscreen_mode) {
            this.is_volume_control_visible = true
            this.setVolume(this.volume - 5)
          }
          break
      }
    },

    // =========================  mediaSession helpers

    _setNotifications() {
      if ('mediaSession' in navigator) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: this.title_caption,
          artist: this.title_author,
          album: this.audio_playlist_title
        })

        if (this.image_url) {
          navigator.mediaSession.metadata.artwork = [
            { src: this.image_url, sizes: '512x512', type: 'image/png' }
          ]
        }

        navigator.mediaSession.setActionHandler('play',          this.playToggle)
        navigator.mediaSession.setActionHandler('pause',         this.playToggle)
        navigator.mediaSession.setActionHandler('seekbackward',  this.seekBackward)
        navigator.mediaSession.setActionHandler('seekforward',   this.seekForward)
        navigator.mediaSession.setActionHandler('previoustrack', this.hasPreviousEpisode ? this.playPreviousEpisode : null)
        navigator.mediaSession.setActionHandler('nexttrack',     this.hasNextEpisode ? this.playNextEpisode : null)
        navigator.mediaSession.setActionHandler('seekto',        this._seekFromNotifications)
      }
    },

    _updateNotificationsPosition() {
      if ('mediaSession' in navigator) {
        if ('setPositionState' in navigator.mediaSession) {
          navigator.mediaSession.setPositionState({
            duration: this.duration,
            playbackRate: this.rate,
            position: this.current_time < this.duration ? this.current_time : this.duration
          })
        }
      }
    },

    _seekFromNotifications(details) {
      if (details && details.seekTime && !details.fastSeek) {
        this._seek(details.seekTime)
      }
    }

  }

}

export default AudioPlayerMixin
