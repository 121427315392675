<template>
<div class='self-assessment_encouragement'>
  <div
    v-if='trigger.icon'
    class='self-assessment_encouragement_image'
  >
    <img
      :src='trigger.icon'
      :alt='trigger.title'
    >
  </div>

  <h2 class='self-assessment_encouragement_title copy-one'>
    {{ trigger.title }}
  </h2>

  <div class='self-assessment_encouragement_description copy-two'>
    {{ description }}
  </div>
</div>
</template>

<script>
export default {
  props: [
    'trigger',
    'questions_left'
  ],

  computed: {
    description() {
      return this.trigger.content
        .replace('%{questions_left}', this.questions_left)
    }
  }
}
</script>

<style lang='sass' scoped>
  @import 'Encouragement'
</style>
