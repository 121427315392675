<template>
  <div class='paired-comparison-results_list paired-comparison-results_list--desktop'>
    <section
      class='paired-comparison-results_list_content'
      :aria-label="$t('carousel')"
    >
      <p class='ada-hidden'>
        {{ $t('carousel_instructions') }}
      </p>

      <a
        href='#prev'
        class='paired-comparison-results_prev'
        :class='disablePrevClass'
        :tabindex='prevTabindex'
        role='button'
        @click.prevent='turnOptionsLeft'
      >
        Prev
      </a>

      <div
        class='paired-comparison-results_list-inner'
        :style='resultListDelta'
      >
        <template
          v-for='(option, index) in top_results'
          :key='index'
        >
          <div
            v-if='option'
            class='paired-comparison-results_list-option'
            :aria-current="$parent.isCurrent(index) ? 'true' : 'false'"
            :aria-hidden="!$parent.isCurrent(index) ? 'false' : 'true'"
            :class='$parent.addCurrentClass(index)'
          >
            <div
              v-if='option.option_image'
              class='paired-comparison-results_list-option_image'
              :style="{backgroundImage: 'url(' + option.option_image + ')'}"
            />

            <h3 class='paired-comparison-results_list-option_title body-three'>
              {{ option.title }}
            </h3>

            <p
              v-if='option.description'
              class='paired-comparison-results_list-option_subtitle body-five'
            >
              {{ option.description }}
            </p>

            <a
              v-if='option.link_href'
              :href='option.link_href'
              target='_blank'
              :tabindex='linkTabindex(index)'
              class='paired-comparison-results_list-option_link link-one-branded'
              @click='linkClick(option)'
            >
              {{ option.link_title }}
            </a>
          </div>
        </template>
      </div>

      <a
        href='#next'
        class='paired-comparison-results_next'
        :class='disableNextClass'
        :tabindex='nextTabindex'
        role='button'
        @click.prevent='turnOptionsRight'
      >
        {{ $t('next') }}
      </a>
    </section>

    <div class='paired-comparison-results_pages'>
      <template
        v-for='(option, index) in top_results'
        :key='index'
      >
        <a
          :href="'#' + (index + 1)"
          :aria-current="$parent.isCurrent(index) ? 'true' : 'false'"
          :class='$parent.addCurrentClass(index)'
          role='button'
          @click.prevent='changeCurrentNum(index)'
        >
          {{ index + 1 }}
        </a>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  props: [
    'top_results'
  ],

  mounted() {
    setTimeout(() => {
      $('.paired-comparison-results_list-inner').addClass('initialized')
    }, 100)
  },

  computed: {

    disablePrevClass() {
      return this.$parent.currentOptionNum === 1 ? 'disabled' : ''
    },

    disableNextClass() {
      return this.$parent.currentOptionNum === this.top_results.length ? 'disabled' : ''
    },

    prevTabindex() {
      return this.$parent.currentOptionNum === 1 ? '-1' : '0'
    },

    nextTabindex() {
      return this.$parent.currentOptionNum === this.top_results.length ? '-1' : '0'
    },

    resultListDelta() {
      return `transform: translateX(${this.delta});`
    },

    delta() {
      return (100 / (this.top_results.length || 3)) * (2 - (this.$parent.currentOptionNum || 1)) + '%'
    }

  },

  methods: {
    changeCurrentNum(index) {
      this.$parent.currentOptionNum = index + 1
    },

    turnOptionsLeft() {
      if (this.$parent.currentOptionNum === 1) { return false }
      this.$parent.currentOptionNum--
    },

    turnOptionsRight() {
      if (this.$parent.currentOptionNum === this.top_results.length) { return false }
      this.$parent.currentOptionNum++
    },

    linkClick(option) {
      // GTM
      const event = {
        event: 'resourceClick',
        pageCategory: 'thisorthat',
        resourceTitle: option.title,
        resourceSubTitle: option.link_title,
        resourceID: option.id,
        resourceURL: option.link_href,
        resourceDestination: 'external'
      }

      dataLayer.push(event)
    },

    linkTabindex(index) {
      return this.$parent.currentOptionNum === index + 1 ? '0' : '-1'
    }
  }
}
</script>
