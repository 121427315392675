<template>
<div class='privacy-overlay'>
  <sweet-modal
    ref='privacyOverlay'
    class='privacy-overlay_overlay'
    modal-theme='light'
    overlay-theme='dark'
    :hide-close-button='true'
    :title='$t("privacy_policy")'
    @open='openPopup'
    @close='closePopup'
  >
    <!-- custom close button -->
    <template #box-action>
      <!-- accessibility focus restricter -->
      <div
        class='privacy-overlay_restricter'
        tabindex='0'
        @focus='returnToClose'
      />

      <button
        ref='close'
        class='privacy-overlay_close'
        @click='$refs.privacyOverlay.close()'
      >
        {{ $t('close') }}
        <CloseIcon />
      </button>
    </template>

    <!-- privacy policy remote content -->
    <div
      v-if='is_loaded'
      class='privacy-policy_content copy-three'
      v-html='content'
    />

    <!-- accessibility focus restricter -->
    <div
      class='privacy-overlay_restricter'
      tabindex='0'
      @focus='returnToClose'
    />

    <!-- fancy preloader -->
    <PageLoader v-if='!is_loaded' />
  </sweet-modal>
</div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue-3'
import CloseIcon from './close.svg'
import apiClient from 'api'
import PageLoader from 'components/PageLoader/PageLoader'

export default {
  props: [
    'closeCb',
    'subdomain'
  ],

  data() {
    return {
      content: null,
      is_loaded: false
    }
  },

  components: {
    SweetModal,
    PageLoader,
    CloseIcon
  },

  mounted() {
    this._getPrivacyContent()
    this.$refs.privacyOverlay.open()

    setTimeout(() => {
      this.returnToClose()
    }, 1)
  },

  methods: {
    returnToClose() {
      this.$refs.close.focus()
    },

    _getPrivacyContent() {
      if (this.subdomain) { apiClient.setSubdomain(this.subdomain) }
      apiClient.find('text_pages', 'privacy_policy')
        .then(({ data }) => {
          this.content = data.content
          this.is_loaded = true
        })
    },

    closePopup() {
      if (typeof (this.closeCb) === 'function') this.closeCb()
    },

    openPopup() {
      // Accessibility Fixes
      this.$refs.privacyOverlay.$el.setAttribute('role', 'dialog')
      this.$refs.privacyOverlay.$el.setAttribute('aria-modal', 'true')
      this.$refs.privacyOverlay.$el.setAttribute('aria-label', this.$t('privacy_policy'))
    }
  }
}
</script>

<style lang='sass'>
.privacy-overlay
  .sweet-modal
    max-width: 70vw
    max-height: 90vh
    color: #2E3339

    @media (max-width: 600px)
      max-width: inherit
      max-height: inherit

    .page-loader
      position: relative
      top: 0
      height: 100px
      background-color: transparent

    .sweet-box-actions
      top: 21px
      right: 21px

.privacy-overlay_close
  cursor: pointer
  display: block
  width: 18px
  height: 18px
  color: transparent
  padding: 0
  border: none
  box-shadow: none
  background: transparent
  font-size: 0

  svg
    display: block
    max-width: 100%

    .close
      fill: #000

  &:hover
    transform: scale(1.2)

.privacy-overlay_restricter
  width: 0
  height: 0
  opacity: 0

.privacy-policy_content
  &.copy-three
    color: #2E3339
</style>
