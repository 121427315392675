// Outro model based on API::V1::LessonPage::Outro::V1Serializer
export default {
  // Images
  background_image: null,

  // Attributes
  headline: null,
  message: null,
  link: null,
  next_steps: null,
  next_lesson_path: null,
  catalog_path: null
}
