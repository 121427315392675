<template>
<div
  class='self-assessment'
  :class="main_class"
>
  <button
    v-if='should_show_close'
    ref='close-button'
    class='self-assessment_close'
    :class='close_class'
    @click.prevent='toggleOverlay'
  >
    {{ $t('close') }}
  </button>

  <!-- Preloader -->
  <PageLoader v-if='submit_in_progress' />

  <transition
    name="fast-fade"
    mode='out-in'
  >
  <template v-if='has_result && !submit_in_progress'>
    <Results
      :activity='activity'
      :outcomes_list='outcomes_list'
      :hero_outcome='hero_outcome'
      :api_client='api_client'
      :toggleAccountOverlay='toggleAccountOverlay'
    >
      <slot />
    </Results>
  </template>
  </transition>

  <template v-if='!has_result && !submit_in_progress'>
    <Question
      v-if='!show_close_confirm && !show_encouragement && !show_feedback'
      :key='question.id'
      :question='question'
      :selectOption='selectOption'
      :savedFreeFormValue='savedFreeFormValue'
      :savedOptionValues='savedOptionValues'
      :isOptionSelected='isOptionSelected'
    />

    <Encouragement
      v-if='show_encouragement && !show_close_confirm'
      :trigger='encouragement_trigger'
      :questions_left='questions_left'
    />

    <Feedback
      v-if='show_feedback && !show_close_confirm'
      :feedback='should_show_feedback'
    />

    <Navigation
      v-if='!show_close_confirm'
      :questions='questions'
      :current_question='current_question_index'
      :is_current_question_passed='is_current_question_passed'
      :next='next'
      :back='back'
      :can_advance='can_advance'
      :hide_progress='branching_enabled'
    />

    <CloseConfirm
      v-if='has_close_confirm && show_close_confirm'
      :trigger='early_close_trigger'
      :toggle='toggle'
    />
  </template>
</div>
</template>

<script>
import ResultsMixin from './results-mixin'
import Navigation from './SelfAssessmentV2Navigation'
import Results from './SelfAssessmentV2Results'
import Question from './SelfAssessmentV2Question'
import CloseConfirm from './SelfAssessmentV2CloseConfirm'
import Encouragement from './SelfAssessmentV2Encouragement'
import Feedback from './SelfAssessmentV2EncouragementFeedback'
import PageLoader from 'components/PageLoader/PageLoader'

export default {
  mixins: [ResultsMixin],

  props: [
    'slide',
    'slide_result',
    'activity',
    'toggle',
    'updateSlideResult',
    'api_client',
    'submitWelcomeSurveyCallback',
    'toggleAccountOverlay'
  ],

  data() {
    return {
      current_question_index: null,
      show_close_confirm: false,
      show_encouragement: false,
      show_feedback: false,
      submit_in_progress: false,
      can_advance: true,
      questions_queue: [],
      hide_results_content: false
    }
  },

  created() {
    this.current_question_index = 1
    this.questions_queue.push(this.current_question_index)
  },

  mounted() {
    if (this.slide_result.status === 'passed' &&
      Object.keys(this.slide_result.results).length &&
      this.slide_result.results.hasOwnProperty('answers')) {
      // deep copy
      this.activity_results = JSON.parse(JSON.stringify(this.slide_result.results.answers))
      this.categories = JSON.parse(JSON.stringify(this.slide_result.results.categories))
    }

    document.body.addEventListener('keyup', this.detectKeyup)
  },

  beforeUnmount() {
    document.body.removeEventListener('keyup', this.detectKeyup)
  },

  components: {
    Navigation,
    Question,
    CloseConfirm,
    Encouragement,
    Feedback,
    Results,
    PageLoader
  },

  computed: {
    main_class() {
      const classes = []
      if (this.has_result) classes.push('has-result')
      if (this.hide_results_content) {
        classes.push('no-content')
      } else if (this.outcomes_list.length ||
        this.activity.results.secondary_headline ||
        this.activity.results.secondary_content) {
        classes.push('has-content')
      } else {
        classes.push('no-content')
      }

      return classes
    },

    branching_enabled() {
      return this.activity.branching
    },

    questions() {
      return this.activity.questions
    },

    question() {
      return this.questions[this.current_question_index - 1]
    },

    questions_left() {
      return this.questions.length - this.current_question_index + 1
    },

    is_current_question_passed() {
      return this.activity_results[this.question.id] && this.activity_results[this.question.id].selection
    },

    has_result() {
      return this.slide_result.status === 'passed' &&
        !!Object.keys(this.slide_result.results).length &&
        this.slide_result.results.hasOwnProperty('answers')
    },

    has_close_confirm() {
      return !!this.early_close_trigger
    },

    close_class() {
      return this.has_result ? 'self-assessment_close--white' : ''
    },

    early_close_trigger() {
      if (this.activity.triggers && this.activity.triggers.length) {
        const earlyCloseTrigger = this.activity.triggers.filter((t) => t.type === 'early_close')[0]
        return earlyCloseTrigger
      } else return false
    },

    encouragement_triggers() {
      if (this.activity.triggers && this.activity.triggers.length) {
        const encouragementTriggers = this.activity.triggers.filter((t) => t.type === 'after_question')
        return encouragementTriggers
      } else return false
    },

    encouragement_trigger() {
      if (this.encouragement_triggers.length) {
        const after_question = this.current_question_index - 1
        const trigger = this.encouragement_triggers.filter((trigger) => trigger.question === after_question)
        return trigger[0]
      } else return false
    },

    should_show_encouragement() {
      if (this.encouragement_triggers.length) {
        const after_question = this.current_question_index - 1
        const trigger = this.encouragement_triggers.filter((trigger) => trigger.question === after_question)
        return trigger.length
      } else return false
    },

    should_show_feedback() {
      if (this.question.kind === 'multiple_choice') {
        const selected_option_id = this.activity_results[this.question.id] &&
                                   this.activity_results[this.question.id].selection[0]
        const selected_option = this.question.options.find((o) => o.id === selected_option_id)

        if (selected_option?.feedback_text) {
          return {
            text: selected_option.feedback_text,
            image: selected_option.feedback_image
          }
        }
      }
      return false
    },

    should_show_close() {
      return !this.show_close_confirm &&
          !this.show_encouragement &&
          !this.show_feedback &&
          !this.submit_in_progress
    }
  },

  methods: {
    selectOption(option, values = {}) {
      let selection = []

      // Prevent user's fastclicking
      if (!this.can_advance) return false
      this.can_advance = false

      // Get previously saved result
      if (this.activity_results[this.question.id] && this.activity_results[this.question.id].selection) {
        selection = this.activity_results[this.question.id].selection
      }

      switch (this.question.kind) {
        case 'multiple_select':
          // eslint-disable-next-line no-case-declarations
          const index = selection.indexOf(option.id)
          if (index === -1) {
            selection.push(option.id) // add
          } else {
            selection.splice(index, 1) // remove
          }
          break
        case 'free_form':
          selection = [option]
          break
        default:
          selection = [option.id]
      }

      // Update activity_results or remove if value is empty (to disallow next step on validation errors)
      if (selection[0] && (selection[0].length || Object.keys(selection[0]).length)) {
        this._updateResults(selection, values)
      } else {
        this._removeResults()
      }

      // Auto Advance to next question
      if (this.activity.auto_advance &&
          this.question.kind !== 'free_form' &&
          this.question.kind !== 'multiple_select') {
        setTimeout(() => {
          this.next()
          this.can_advance = true
        }, 800)
      } else {
        setTimeout(() => {
          this.can_advance = true
        }, 400)
      }
    },

    isOptionSelected(option) {
      if (this.activity_results[this.question.id] && this.activity_results[this.question.id].selection) {
        const index = this.activity_results[this.question.id].selection.indexOf(option.id)
        return index !== -1
      }

      return false
    },

    savedFreeFormValue() {
      if (this.activity_results[this.question.id] && this.activity_results[this.question.id].selection) {
        return this.activity_results[this.question.id].selection[0] || null
      }
    },

    savedOptionValues() {
      if (this.activity_results[this.question.id]) {
        return this.activity_results[this.question.id].values || null
      }
    },

    _updateResults(selection, values = null) {
      if (!values) {
        this.activity_results[this.question.id] =  {
          selection
        }
      } else {
        this.activity_results[this.question.id] =  {
          selection,
          values
        }
      }
    },

    _removeResults() {
      delete this.activity_results[this.question.id]
    },

    _updateCategories() {
      const categories = {}

      // collect all available categories
      this.activity.categories.forEach((cat) => {
        categories[cat.id] = { total: 0, earned: null }
      })

      // count total available
      this.questions.forEach((q) => {
        const max_scores = {}
        q.options.forEach((o) => {
          o.category_scores.forEach((score) => {
            if (score.score < 0) return false
            if (max_scores[score.id]) {
              if (max_scores[score.id] < score.score) {
                max_scores[score.id] = score.score
              }
            } else {
              max_scores[score.id] = score.score
            }
          })
        })

        Object.keys(max_scores).forEach((cat_id) => {
          categories[cat_id].total += max_scores[cat_id]
        })
      }) // end count total

      // count user earned scores
      Object.keys(this.activity_results).forEach((question_id) => {
        const option_ids = this.activity_results[question_id].selection
        const question = this.questions.find((q) => q.id === question_id)

        question.options.forEach((o) => {
          if (option_ids.indexOf(o.id) !== -1) {
            o.category_scores.forEach((cat) => {
              if (categories[cat.id].earned === null) {
                categories[cat.id].earned = cat.score
              } else {
                categories[cat.id].earned += cat.score
              }
            }) // end scores map
          }
        }) // end options map
      }) // end activity_results map

      // Sort categories to not have earned: null result
      const sorted_categories = {}
      for (const i in categories) {
        if (categories[i].earned !== null) {
          sorted_categories[i] = categories[i]
        }
      }

      // Set to store
      this.categories = sorted_categories
    },

    submit() {
      this._updateCategories()
      this.submit_in_progress = true
      this.show_encouragement = false
      this.show_feedback = false
      this.show_close_confirm = false

      const payload = {
        answers: this.activity_results,
        categories: this.categories
      }

      if (this.slide_result.results && this.slide_result.results.has_consented) {
        payload.has_consented = this.slide_result.results.has_consented
      }

      this.updateSlideResult({
        slide_id:    this.slide.id,
        slide_type:  this.slide.type,
        event:       'finished',
        payload,
        callback: () => {
          this.submit_in_progress = false

          // Refresh the page content instead of results
          // for case when signed in user came from welcome survey
          if (typeof (this.submitWelcomeSurveyCallback) === 'function' &&
            this.slide.global_slide_type === 'welcome_survey'
          ) {
            this.submitWelcomeSurveyCallback(() => this.toggle())
          }
        }
      })
    },

    next() {
      if (this.$refs['close-button']) {
        this.$refs['close-button'].focus()
      }

      // branching enabled
      if (this.branching_enabled) {
        // count total_score
        const option_ids = this.activity_results[this.question.id].selection
        let total_score = null
        option_ids.forEach((id) => {
          const option = this.question.options.find((o) => o.id === id)
          if (option.branching_score !== null) total_score += option.branching_score
        })
        // console.log('total_score', total_score)

        // match earned score with branch
        let matched_branch = null
        for (const branch in this.question.branches) {
          const range = branch.split('..')
          if (range.length === 1 && parseInt(range[0]) === total_score) {
            matched_branch = branch
          } else if (range.length === 2 && total_score >= range[0] && total_score <= range[1]) {
            matched_branch = branch
          }
        }

        // set default branch if not matched
        if (matched_branch === null) matched_branch = 'default'

        // get next_question id
        const next_question = this.question.branches[matched_branch]
        // console.log('matched_branch', matched_branch, 'question:', next_question)

        // submit
        if (this.should_show_feedback && !this.show_feedback) {
          this.show_feedback = true
        } else if (next_question === 'end') {
          this.submit()
        // go to next question
        } else {
          if (this.show_encouragement) {
            this.show_encouragement = false
          } else {
            if (this.show_feedback) {
              this.show_feedback = false
            }
            const next_question_index = this.questions.indexOf(this.questions.find((q) => q.id === next_question))
            this.current_question_index = next_question_index + 1
            this.questions_queue.push(this.current_question_index)
          }
          if (this.should_show_encouragement) {
            this.show_encouragement = true
          }
        }

      // regular self-assessment next question
      } else if (this.current_question_index <= this.questions.length) {
        if (this.should_show_feedback && !this.show_feedback) {
          this.show_feedback = true
        } else if (this.show_encouragement) {
          this.show_encouragement = false
        } else {
          if (this.show_feedback) {
            this.show_feedback = false
          }
          if (this.current_question_index !== this.questions.length) {
            this.current_question_index++
            this.questions_queue.push(this.current_question_index)
          } else this.submit()
          if (this.should_show_encouragement) {
            this.show_encouragement = true
          }
        }

      // regular self-assessment submit
      } else {
        this.current_question_index++
        this.questions_queue.push(this.current_question_index)
        setTimeout(() => {
          this.submit()
        }, 300)
      }
    },

    back() {
      if (this.show_encouragement) {
        this.show_encouragement = false
      }

      if (this.show_feedback) {
        this.show_feedback = false
      }

      // remove prev question from queue
      const last_question = this.questions_queue.pop() - 1

      // if branching_enabled we need to remove user's answer because he can choose another branch
      if (this.branching_enabled) {
        const last_question_id = this.questions[last_question].id
        delete this.activity_results[last_question_id]
      }

      // go to last question in queue
      this.current_question_index = this.questions_queue.at(-1)
    },

    toggleOverlay() {
      // this.show_close_confirm = !this.show_close_confirm
      if (!this.has_result && this.has_close_confirm) {
        this.show_close_confirm = !this.show_close_confirm
      } else {
        this.toggle()
      }
    },

    detectKeyup(e) {
      if ((e.key !== undefined && e.key === 'Escape') ||
        (e.keyCode !== undefined && e.keyCode === 27)) {
        this.toggleOverlay()
      }
    }

  }
}
</script>

<style lang='sass' scoped>
  @import 'main'
</style>
