<template>
  <div
    class='intention-card'
    @click='cardClick'
  >
     <div
       class='intention-card_image'
      :style="{backgroundImage: `url('${background_image}')`}"
    >
      <div
        v-if='is_completed'
        class='intention-card_completed'
      >
        <CompletedIcon />
      </div>
    </div>

    <div class='intention-card_content'>
      <div class='intention-card_icon-wrapper'>
        <img
          class='intention-card_icon'
          :src='intention.image'
          alt=''
        >
      </div>

      <div class='intention-card_title heading-five'>
        {{ intention.text }}
      </div>

      <div class='intention-card_date'>
        {{ is_completed ? $t('intentions.completed') : $t('intentions.due') }}
        {{ formatted_date }}
      </div>

      <a
        :href='`/home/activity?tab=intentions&intention_id=${intention.id}`'
        class='intention-card_link'
        :aria-label="$t('view_details') + ': ' + intention.text"
        @click.stop.prevent='cardClick'
      >
        {{ $t('view_details') }}
      </a>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { enUS, es, pt } from 'date-fns/locale'
import CompletedIcon from './completed.svg'

export default {
  props: [
    'intention',
    'index',
    'intentionClick'
  ],

  data() {
    return {
      locales: {
        en : enUS,
        es,
        pt
      }
    }
  },

  components: {
    CompletedIcon
  },

  computed: {
    background_image() {
      return this.intention.background_image
    },

    formatted_date() {
      const utcDate = new Date(this.is_completed ? this.intention.completion_date : this.intention.goal_date)
      return format(utcDate, 'PP', { locale: this.locales[this.$i18n.locale] })
    },

    is_completed() {
      return !!this.intention.completion_date
    }

  },

  methods: {
    cardClick() {
      if (typeof this.intentionClick === 'function' && this.intention) {
        this.intentionClick(this.intention.id)
      }
    }
  }

}
</script>

<style lang='sass' scoped>
  @import './IntentionCard'
</style>
