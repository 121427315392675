<template>
<div
  class='audio-episode'
  :class="{'is-active': isActive}"
>
  <a
    class='audio-episode_cover'
    :style='cover_image'
    aria-hidden='true'
    tabindex='-1'
    href='#'
    role='button'
    @click.prevent='play'
  >
    <div class='audio-episode_icon' />
  </a>

  <div class='audio-episode_info'>
    <div class='audio-episode_heading'>
      <span
        v-if='episode.published_date'
        class='audio-episode_date copy-four'
      >
        {{ published_date }}
      </span>
      <span class='audio-episode_duration copy-four'>
        {{ episode.duration }}
      </span>
    </div>

    <h3 class='audio-episode_header'>
      <a
        class='audio-episode_title heading-five'
        href='#'
        role='button'
        @click.prevent='play'
      >
        {{ episode.title }}
      </a>
    </h3>

    <div
      class='audio-episode_description copy-four'
      v-html="episode.description"
    />
  </div>

  <div class='audio-episode_buttons'>
    <button
      class='audio-episode_play'
      :class="{'is-playing': isPlaying}"
      :title='isPlaying ? $t("audio_player.pause") : $t("audio_player.play")'
      :aria-label='isPlaying ? $t("audio_player.pause") : $t("audio_player.play")'
      @click.stop.prevent='play'
    >
      <PauseIcon class='pause-icon' />
      <PlayIcon class='play-icon' />
    </button>
  </div>
</div>
</template>

<script>
import { format, parseISO } from 'date-fns'

import PlayIcon from './icons/play.svg'
import PauseIcon from './icons/pause.svg'

export default {
  props: ['series', 'episode', 'episodeIndex', 'playToggle', 'isPlaying', 'isActive'],

  data() {
    return {
      is_info_visible: false,
      is_playing: false
    }
  },

  components: {
    PlayIcon,
    PauseIcon
  },

  computed: {
    cover_image() {
      return `background-image: url(${this.episode.image})`
    },

    published_date() {
      return format(parseISO(this.episode.published_date), 'MMMM do, yyyy')
    }
  },

  methods: {
    play() {
      this.playToggle(this.episodeIndex)
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './Episode'
</style>
