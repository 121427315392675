<template>
<div
  ref='activity-overlay'
  class='interactive-section_overlay'
  :class='main_class'
>
  <div
    id='activity-overlay'
    class='interactive-section_overlay-inner'
  >
    <button
      v-if='!hideClose'
      ref='close-button'
      class='interactive-section_overlay_close'
      @click.prevent='toggle'
    >
      {{ $t('close') }}
    </button>

    <div
      id='activity-overlay-content'
      class='interactive-section_overlay_content'
    >
      <slot />
    </div>
  </div>
</div>
</template>

<script>
import OverlayAccessibilityMixin from 'lesson/components/slide/overlay-accessibility-mixin'

export default {
  mixins: [OverlayAccessibilityMixin],

  props: [
    'toggle',
    'activity',
    'slide_result',
    'view_style'
  ],

  created() {
    $('body').addClass('popup-opened')
  },

  beforeUnmount() {
    $('body').removeClass('popup-opened')
  },

  computed: {
    main_class() {
      const classes = []
      if (this.closeStyle) classes.push('interactive-section_overlay--' + this.closeStyle)
      if (this.view_style) classes.push('interactive-section_overlay--' + this.view_style)

      return classes
    },

    hideClose() {
      if (this.activity.type === 'activity_v1_assessments') return true
      if (this.activity.type === 'activity_v2_self_assessments') return true

      return false
    },

    closeStyle() {
      if (this.activity.type === 'activity_v1_articles') return 'white'
      if (this.activity.type === 'activity_v1_assessments') return 'white'

      return 'dark'
    }
  }

}
</script>

<style lang='sass' scoped>
  @import './Overlay'
</style>
