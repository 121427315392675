<template>
  <div>
    <transition
      v-if='loading'
      name='fade'
    >
      <PageLoader />
    </transition>

    <div
      v-else
      id='#text-page'
      class='text-page'
    >
      <h1 class='text-page_title heading-three'>
        {{ $t(page_title) }}
      </h1>

      <div
        class='text-page_content copy-three'
        v-html='page_content'
      />
    </div>
  </div>
</template>

<script>
import PageLoader from 'components/PageLoader/PageLoader'

import { useHomeStore } from 'home/stores/home'
import { mapActions } from 'pinia'


export default {
  data() {
    return {
      loading: true,
      page_title: null,
      page_content: null,
      titles: {
        terms_of_use: 'terms_of_use',
        privacy_policy: 'privacy_policy',
        help: 'we_are_here_to_help'
      }
    }
  },

  components: {
    PageLoader
  },

  created() {
    this.switchContent(this.$route.name)
  },

  watch:{
    $route(to, from) {
      this.switchContent(to.name)
    }
  },

  methods: {
    ...mapActions(useHomeStore, [
      'loadTextPage'
    ]),

    fetchData(route_name) {
      this.loadTextPage(route_name)
        .then(({ data }) => {
          this.page_content = data.content
          this.loading = false
        })
    },

    switchContent(route_name) {
      this.$scrollTo($('#text-page'), 1, { offset: -30 })
      this.page_title = this.titles[route_name]
      this.fetchData(route_name)
    }
  }
}

</script>

<style lang='sass'>
  @import './app.sass'
</style>
