// Intro model based on API::V1::LessonPage::LeftMenuSerializer
export default {
  // Images
  logo: null,

  // Attributes
  mode: null,
  links: null,
  avatar_visible: null,
  my_account_visible: null,
  log_out_visible: null,
  languages_visible: null,
  exit_lesson_button: null
}
