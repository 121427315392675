<template>
  <a
    class='episode-card'
    href='#play'
    @click.prevent='playToggle(audio_series, index)'
  >
    <div
      class='episode-card_image'
      :style='episode_image'
    >
      <VolumeIcon />
    </div>

    <div class='episode-card_heading'>
      <span
        v-if='episode.published_date'
        class='episode-card_date copy-four'
      >
        {{ published_date }}
      </span>

      <span class='episode-card_duration copy-four'>
        {{ episode.duration }}
      </span>
    </div>

    <div class='episode-card_title copy-three'>
      {{ episode.title }}
    </div>

    <div
      class='episode-card_description copy-four'
      v-html="episode.description"
    />
  </a>
</template>

<script>
import { format, parseISO } from 'date-fns'
import VolumeIcon from './volume.svg'

export default {
  props: ['audio_series', 'episode', 'index', 'playToggle'],

  components: {
    VolumeIcon
  },

  computed: {
    episode_image() {
      return `background-image: url(${this.episode.image})`
    },

    published_date() {
      return format(parseISO(this.episode.published_date), 'MMMM do, yyyy')
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './Episode'
</style>
