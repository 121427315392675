// Course Section model based on API::V1::Section::Hero::PageSerializer
export default {

  // Relationships
  course: {
    jsonApi: 'hasOne',
    type: 'courses'
  },

  learning_plan: {
    jsonApi: 'hasOne',
    type: 'learning_plans'
  },

  global_slide: {
    jsonApi: 'hasOne',
    type: 'global_slides'
  },

  // Images
  image: null,
  content_image: null,

  // Attributes
  headline: null,
  subheadline: null,
  cta: null,
  attribution: null,
  position: 0,
  alignment: null,
  trailer: null,
  content_image_alt: null
}
