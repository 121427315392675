<template>
<div class='journal-entry'>
  <div class='journal-entry_header'>
    <div class='journal-entry_title heading-six'>
      {{ headline || $t('my_journal_entry') }}
    </div>

    <button
      class='journal-entry_more'
      :title="$t('more')"
      @click.prevent='more'
    >
      <MoreIcon />
    </button>

    <ul
      v-if='show_more_actions'
      class='journal-entry_actions'
    >
      <li>
        <button
          class='journal-entry_additional-button journal-entry_edit action-two'
          @click.prevent='edit'
        >
          {{ $t('edit') }}
        </button>
      </li>

      <li>
        <button
          class='journal-entry_additional-button journal-entry_delete action-two'
          @click.prevent='openDelete'
        >
          {{ $t('delete') }}
        </button>
      </li>
    </ul>
  </div>

  <div class='journal-entry_content'>
    <div class='journal-entry_date copy-four'>
      {{ entry_date_value }}
    </div>

    <!-- Attachments -->
    <div
      v-if='entry.files'
      class='journal-entry_files'
    >
      <div
        v-for='file in entry.files'
        :key='file.id'
        class='journal-entry_file-container'
      >
        <!-- Images -->
        <template v-if='isImage(file) && file.content_type'>
          <div
            class='journal-entry_image'
            :data-id='file.id'
            :style="{backgroundImage: `url('${file.thumbnail}')`}"
          >
            <div class='journal-entry_image-actions'>
              <a
                class='journal-entry_image-actions_zoom'
                href='#zoom'
                :title='$t("zoom")'
                @click.prevent='zoomImage(file.original)'
              />
            </div>
          </div>
        </template>

        <!-- Files -->
        <template v-if='!isImage(file) && file.content_type'>
          <a
            :href='file.original'
            target='_blank'
            class='journal-entry_file'
          >
            <span class='journal-entry_file-icon' />
            <span class='journal-entry_file-name'>{{ file.file_name }}</span>
          </a>
        </template>
      </div>
    </div>

    <!-- Comment -->
    <div
      class='journal-entry_comment copy-three'
      v-html='entry_content'
    />
  </div>

  <!-- Delete Comment -->
  <transition name='fast-fade'>
    <DiscussionModal
      v-if='show_delete'
      :closeModal='closeDelete'
      :modalAction='deleteEntryAction'
      :button_text="$t('delete_post_button_text')"
      :modal_description="$t('delete_post_description')"
      :modal_title="$t('delete_post_title')"
    />
  </transition>

  <!-- Zoom Image -->
  <transition name='fast-fade'>
    <DiscussionModal
      v-if='zoomed_image'
      :zoomed_image='zoomed_image'
      :closeModal='closeImage'
    />
  </transition>
</div>
</template>

<script>
import { format, formatDistanceToNow } from 'date-fns'
import { enUS, es, pt } from 'date-fns/locale'
import DiscussionModal from 'components/activities/v1/Discussion/DiscussionModal'
import MoreIcon from './more.svg'

export default {
  props: ['headline', 'editEntry', 'deleteEntry', 'entry'],

  emits: ['edit'],

  components: {
    DiscussionModal,
    MoreIcon
  },

  data() {
    return {
      show_more_actions: false,
      show_delete: false,
      locales: {
        en : enUS,
        es,
        pt
      },
      zoomed_image: null
    }
  },

  computed: {
    entry_content() {
      return this.entry.formatted_content || this.entry.content
    },

    entry_date_value() {
      const comment_date = new Date(this.entry.created_at)
      const now = new Date()
      const seven_days = 24 * 60 * 60 * 1000 * 7

      if ((now - comment_date) > seven_days) {
        return format(comment_date, 'PPpp', { locale: this.locales[this.$i18n.locale] })
      } else {
        return formatDistanceToNow(comment_date, { locale: this.locales[this.$i18n.locale], addSuffix: true })
      }
    }

  },

  methods: {
    more() {
      if (!this.show_more_actions) {
        this.show_more_actions = true
        setTimeout(() => {
          document.body.addEventListener('click', this.closeMore)
        }, 100)
      }
    },

    closeMore(event) {
      this.show_more_actions = false
      document.body.removeEventListener('click', this.closeMore)
    },

    edit() {
      this.$emit('edit', true)
    },

    openDelete() {
      this.show_delete = true
    },

    closeDelete() {
      this.show_delete = false
    },

    deleteEntryAction() {
      this.closeDelete()
      this.deleteEntry()
    },

    isImage(file) {
      const type = file.type || file.content_type
      const supported = ((this.$root.configuration || {}).journals_supported_uploads || [])
      return supported.indexOf(type) >= 0 && !!type.match(/^image/)
    },

    zoomImage(image) {
      this.zoomed_image = image
    },

    closeImage() {
      this.zoomed_image = null
    }
  }

}
</script>
