<template>
<div class='activity-header'>
  <div class='activity-header_inner'>
    <div class='activity-header_info'>
      <h1 class='activity-header_greeting heading-four'>
        {{ $t('my_activity_greeting', { value: first_name }) }}
      </h1>

      <div class='activity-header_tagline copy-three'>
        {{ $t('my_activity_tagline') }}
      </div>
    </div>

    <div class='activity-header_links'>
      <template
        v-for='(tab, index) in tabs'
        :key='index'
      >
        <a
          :id="tab.name"
          :aria-controls="'panel-tab-' + tab.name"
          :aria-selected=" (index === 0) ? 'true' : 'false' "
          class='main-nav-link'
          :class='setActiveClass(tab.name)'
          href='#'
          role='tab'
          @click.prevent='$parent.changeTab(tab.name, $event)'
          @keyup='switchTabOnArrowPress($event)'
        >
          {{ tab.text }}
        </a>
      </template>
    </div>
  </div>
</div>
</template>

<script>
import TabAccessibilityMixin from 'home/components/shared/TabAccessibilityMixin'

export default {
  mixins: [TabAccessibilityMixin],

  props: ['first_name', 'tabs'],

  data() {
    return { }
  },

  methods: {
    setActiveClass(tabName) {
      return this.$parent.active_tab === tabName ? 'active' : ''
    }
  }
}

</script>

<style lang='sass' scoped>
  @import './ActivityHeader'
</style>
