<template>
<div class='instructors-page'>
  <transition name='fade'>
    <PageLoader v-if='loading' />
  </transition>

  <template v-if='!loading'>
    <div class='instructors-page_header'>
      <h1 class='heading-four'>
        {{ title }}
      </h1>

      <h2 class='copy-three'>
        {{ description }}
      </h2>
    </div>

    <ul
      v-if='!loading'
      class='instructors-page_list'
    >
      <li
        v-for='(item,index) in items_list'
        :key='index'
        class='instructors-page_list-item'
      >
        <InstructorCard :instructor='item' />
      </li>
    </ul>
  </template>
</div>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapState } from 'pinia'

import InstructorCard from '../InstructorCard/InstructorCard'
import PageLoader from 'components/PageLoader/PageLoader'

export default {
  data() {
    return {
      loading: false
    }
  },

  components: {
    PageLoader,
    InstructorCard
  },

  computed: {
    ...mapState(useHomeStore, [
      'instructors'
    ]),

    instructors_list() {
      return Object.keys(this.instructors).map(e => this.instructors[e])
    },

    items_list() {
      return this.instructors_list
    },

    title() {
      return this.$t('we_call_them_experts')
    },

    description() {
      return this.$t('instructors_description')
    }
  }

}

</script>

<style lang='sass' scoped>
  @import './InstructorsPage'
</style>
