<template>
<div class='instructors-app'>
  <router-view />
</div>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapState } from 'pinia'

export default {

  computed: {
    ...mapState(useHomeStore, [
      'instructors'
    ])
  }

}

</script>

<style lang='sass' scoped>
  @import './app.sass'
</style>
