<template>
<div class='poll-option-chooser'>
  <div class='poll-option-chooser_choose'>
    <div
      v-if='has_response && total_responses_headline'
      class='poll-option-chooser_total body-two'
    >
      {{ total_responses_headline }}
    </div>

    <div
      class='poll-option-chooser_options'
      :class="{'two-column' : two_column}"
      role='radiogroup'
      :aria-labelledby="'poll-headline-' + activity.id"
    >
      <div
        v-for='(row, index) in options_array'
        :key='index'
        class='poll-option-chooser_options-row'
      >
        <div
          v-for='option in row'
          :key='option.id'
          class='poll-option-chooser_options-cell'
        >
          <button
            class='poll-option'
            :class="optionClasses(option)"
            :disabled='has_response'
            @click.prevent='selectOption({selected: option.id}, $event)'
          >
            <span class='poll-option_circle' />

            <span class='poll-option_text body-two'>
              {{ option.text }}
            </span>

            <span
              v-if='has_response && show_stats'
              class='poll-option_percent body-two'
            >
              {{ resultPercent(option.id) }}%
            </span>

            <div
              v-if='has_response && show_stats'
              class='poll-option_progress'
            >
              <div
                class='poll-option_progress-bar'
                :style="{width: resultPercent(option.id) + '%' }"
              />
            </div>
          </button>

          <div
            v-if='!is_desktop && has_response && has_feedback && isSelected(option)'
            class='poll-option-chooser_mobile-feedback'
          >
            <div class='poll-option-chooser_mobile-feedback-headline body-four'>
              {{ response_headline }}
            </div>

            <div
              class='poll-option-chooser_mobile-feedback-description body-two'
              v-html='response_body'
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if='is_desktop && has_response && has_feedback'
    class='poll-option-chooser_feedback'
  >
    <div class='poll-option-chooser_feedback-headline body-four'>
      {{ response_headline }}
    </div>

    <div
      class='poll-option-chooser_feedback-description body-two'
      v-html='response_body'
    />
  </div>
</div>
</template>

<script>
export default {
  props: [
    'options',
    'selected_answer',
    'activity',
    'show_stats',

    'response_headline',
    'response_body',
    'total_responses',

    'is_desktop_view',

    'selectPollOption',
    'resultPercent'
  ],

  computed: {
    is_desktop() {
      return this.is_desktop_view !== 'false'
    },

    two_column() {
      if (!this.is_desktop) {
        return false
      } else {
        return this.options.length > 6
      }
    },

    has_response() {
      return !!this.selected_answer
    },

    has_feedback() {
      return this.activity.show_response
    },

    options_array() {
      const rows = []; const items = this.options.slice()

      const items_in_row = this.two_column ? 2 : 1

      while (items.length) {
        rows.push(items.splice(0, items_in_row))
      }

      return rows
    },

    total_responses_headline() {
      if (this.activity.total_responses_headline) {
        return this.activity.total_responses_headline.replace('%{total}', this.total_responses)
      } else return null
    }
  },

  methods: {
    isSelected(option) {
      return this.selected_answer === option.id
    },

    optionClasses(option) {
      const classes = []
      if (this.isSelected(option)) classes.push('selected')
      if (this.has_response) classes.push('show-percent')

      return classes
    },

    optionPercent(option) {
      return 55
    },

    selectOption(option, event) {
      this.selectPollOption(option)
    }
  }
}
</script>
