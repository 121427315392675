// Instructor model based on API::V1::IntentionSerializer
export default {
  // Relationships
  result: {
    jsonApi: 'hasOne',
    type: 'slide_activity_result'
  },

  // Images
  background_image: null,
  image: null,

  // Attributes
  intention_id: null,
  text: null,
  created_at: null,
  goal_date: null,
  completion_date: null
}
