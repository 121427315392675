<template>
<div
  class='user-menu'
  @keydown='detectKeydown'
  @keyup='detectKeyup'
>
  <button
    id='user-menu_avatar'
    class='user-menu_avatar'
    :class="{'active' : show_menu}"
    aria-controls='user-menu_menu'
    :aria-expanded='show_menu || "false"'
    @click='expandMenu'
  >
    <Avatar
      :image='avatar_url'
      :className="'user_avatar'"
    />
    {{ $t('open_user_menu') }}
  </button>

  <transition name='fast-fade'>
    <div
      v-if='show_menu'
      id='user-menu_menu'
      :aria-label="$t('menu')"
      class='user-menu_menu'
      @focusout='checkFocus'
    >
      <ul>
        <li>
          <router-link
            id='account_link'
            :to="{ name: 'profile' }"
            class='user-menu_link copy-three'
          >
            {{ $t('my_account') }}
          </router-link>
        </li>

        <li>
          <a
            class='user-menu_link copy-three my-activity'
            href='/home/activity'
          >
            {{ $t('my_activity') }}
          </a>
        </li>

        <li>
          <a
            id='sign-out_link'
            class='user-menu_link copy-three sign-out'
            href='#sign-out'
            @click.prevent='signOut'
          >
            {{ $t('sign_out') }}
          </a>
        </li>
      </ul>
    </div>
  </transition>
</div>
</template>

<script>
import Avatar from 'components/Avatar/UserAvatar'
import { signOut } from 'api/index'

export default {
  props: ['user'],

  components: {
    Avatar
  },

  data() {
    return {
      show_menu: false,
      touch_event: 'ontouchstart' in window ? 'touchstart' : 'click',
      shift_is_pressed: false
    }
  },

  watch: {
    'show_menu'(value) {
      if (value) {
        setTimeout(() => {
          this.setFocus('account_link')
        }, 1)
      }
    }
  },

  computed: {
    avatar_url() {
      return this.user && (this.user.avatar || this.user.external_avatar)
    }
  },

  methods: {
    expandMenu() {
      if (!this.show_menu) {
        setTimeout(() => {
          this.openMenu()
        }, 1)
      }
    },

    closeMenu(event) {
      if (this.touch_event === 'touchstart' && event.target.className.match(/sign-out/g)) {
        this.signOut()
      }
      this.show_menu = false
      document.body.removeEventListener(this.touch_event, this.closeMenu)
    },

    openMenu() {
      this.show_menu = true
      document.body.addEventListener(this.touch_event, this.closeMenu)
    },

    signOut(event) {
      event.preventDefault()
      signOut()
    },

    detectKeyup(e) {
      if ((e.key !== undefined && e.key === 'Escape') ||
      (e.keyCode !== undefined && e.keyCode === 27)) {
        this.closeMenu()
        this.setFocus('user-menu_avatar')
      }

      if (e.key === 'Shift') {
        this.shift_is_pressed = false
      }
    },

    detectKeydown(e) {
      if (e.key === 'Shift') {
        this.shift_is_pressed = true
      }
    },

    checkFocus(e) {
      if ((e.target.id === 'account_link' && this.shift_is_pressed) ||
      (e.target.id === 'sign-out_link' && !this.shift_is_pressed)) {
        setTimeout(() => {
          if (!this.show_menu) { return false }
          this.closeMenu()
        }, 150)
      }
    },

    setFocus(element_id) {
      document.getElementById(element_id).focus()
    }
  }
}
</script>
<style lang='sass' scoped>
  @import './UserMenu'
</style>
