<template>
<div class='learning-plan-section'>
  <SectionDrawer
    :title='section.headline'
    :description='section.subheadline'
    :link='section.link'
    modifier='learning-plans'
    type='learning_plans'
    :context='context'
  >
    <LearningPlanSectionRow
      v-for='(row, sectionRowIndex) in section_rows'
      :key='sectionRowIndex'
      :section='section'
      :row='row'
      :row_index='sectionRowIndex'
      :opened_learning_plan='opened_learning_plan'
      :opened_drawer='opened_drawer'
      :setOpenedDrawer='setOpenedDrawer'
      :setOpenedLearningPlan='setOpenedLearningPlan'
    />
  </SectionDrawer>
</div>
</template>

<script>
import WindowSizeMixin from 'home/components/shared/WindowSizeMixin'
import SectionDrawer from 'home/components/shared/SectionDrawer/SectionDrawer.vue'
import LearningPlanSectionRow from 'home/components/shared/SectionDrawer/LearningPlanSectionRow/LearningPlanSectionRow.vue'

export default {
  mixins: [WindowSizeMixin],

  props: ['section', 'context'],

  data() {
    return {
      opened_drawer: false,
      opened_learning_plan: null
    }
  },

  components: {
    SectionDrawer,
    LearningPlanSectionRow
  },

  computed: {
    section_rows() {
      const rows = []; const items = this.section.learning_plans.slice()

      // Here we split items into rows by 3, 2 or 1 item in a row
      // (or by 4, 2 and 1 for "catalog" pages)
      // depending on tablet or mobile screen size
      let items_in_row = this.context === 'catalog' ? 4 : 3
      if (this.is_tablet) items_in_row = 2
      if (this.is_mobile) items_in_row = 1

      while (items.length) {
        rows.push(items.splice(0, items_in_row))
      }

      return rows
    }
  },

  methods: {
    setOpenedDrawer(value) {
      this.opened_drawer = value
    },

    setOpenedLearningPlan(value) {
      this.opened_learning_plan = value
    }
  }

}
</script>
