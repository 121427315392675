// model based on API::V1::Activity::V1::AssessmentSerializer
export default {

  // Images
  background_image: null,
  gradient_start: null,
  gradient_end: null,

  // Attributes
  identifier: null,
  label: null,
  headline: null,
  subheadline: null,
  has_attempt: false,
  multiple_attempts: false,
  state_box: null,
  feedback: null,
  status: null,
  previous_attempt: null,
  current_attempt: null
}
