<template>
<div class='activity-app'>
  <ActivityHeader
    :first_name='user.first_name'
    :tabs='header_tabs'
  />

  <transition name='tab-change'>
    <PageLoader v-if='is_courses_loading' />
  </transition>

  <transition
    name='tab-change'
    mode='out-in'
  >
    <div v-if='has_content'>
      <div
        :key='active_tab'
        class='activity-app_content'
      >
        <!-- Learning Plans -->
        <template v-if="show_learning_plans && active_tab === 'learning-plans'">
          <LearningPlan
            v-for='(learning_plan, index) in activity_learning_plans'
            :key='index'
            :identifier='learning_plan.identifier'
            :index='index'
            :total_learning_plans='activity_learning_plans.length'
          />

          <div
            v-if='activity_learning_plans.length === 0'
            class='activity-app_no-learning-plans'
          >
            {{ $t('you_have_no_learning_plans_here_yet') }}
          </div>
        </template>
        <!-- End Learning Plans -->

        <!-- My Courses -->
        <template v-if="show_courses && !is_courses_loading && active_tab === 'courses'">
          <CourseSections :sections='course_sections' />
        </template>
        <!-- End My Courses -->

        <!-- Benefits -->
        <template v-if="show_benefits && active_tab === 'benefits'">
           <!-- wrapper div added for accesibility attributes -- cause one tab controls two panels -->
          <div
            id="panel-tab-benefits"
            class='benefits'
            role="tabpanel"
          >
            <!-- Programs -->
            <ul class='activity-app_programs'>
              <ProgramCard
                v-for='(program, index) in programs'
                :key='index'
                :program='program'
              />
            </ul>

            <!-- Program Drivers aka Benefits -->
            <div
              v-if='show_program_drivers'
              class='activity-app_program-drivers'
            >
              <PageSection
                v-for='(program, index) in programs'
                :key='index'
                :title='program.name'
              >
                <ProgramDriver
                  v-for='(driver, driver_index) in program.drivers'
                  :key='driver_index'
                  :driver='driver'
                />
              </PageSection>
            </div>
          </div>
        </template>
        <!-- End Benefits -->

        <!-- Intentions -->
        <template v-if="show_intentions && active_tab === 'intentions'">
          <Intentions
            :intentions='intentions'
            :intentionClick='toggleIntentionOverlay'
          />
        </template>
        <!-- End Intentions -->
      </div>

      <!-- No Content -->
      <div
        v-if='!has_content'
        class='activity-app_no-content'
      >
        <h2 class='heading-four'>
          {{ $t('my_activity_no_content_title') }}
        </h2>

        <p class='copy-two'>
          {{ $t('my_activity_no_content') }}
        </p>

        <router-link
          :to="{ name: 'courses' }"
          class='action-one-branded'
        >
          {{ $t('browse_all_content') }}
        </router-link>
      </div>
      <!-- No Content End -->
    </div>
  </transition>

  <!-- Intention Overlay -->
  <transition
    mode='out-in'
    name="fast-fade"
  >
    <Overlay
      v-if='is_intention_overlay_opened && current_intention'
      :toggle='toggleIntentionOverlay'
      activity=null
      slide_result=null
      view_style='full'
    >
      <IntentionActivity
        :slide_result='current_intention.result'
        :slide='current_intention.result.slide'
        :activity='current_intention.result.activity'
        :toggle='toggleIntentionOverlay'
        :updateSlideResult='updateSlideResult'
        :api_client='api_client'
        @update="updateIntention"
      >
        <a
          href="#"
          class='action-one-branded'
          @click.prevent='toggleIntentionOverlay'
        >
          {{ $t('continue') }}
        </a>
      </IntentionActivity>
    </Overlay>
  </transition>
</div>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { useUserResultsStore } from 'home/stores/user-results'
import { mapState, mapActions } from 'pinia'

import CourseSections from 'home/components/shared/SectionDrawer/CourseSections/CourseSections.vue'

import ActivityHeader from './ActivityHeader/ActivityHeader'
import PageLoader from 'components/PageLoader/PageLoader'
import PageSection from './Section/ActivitySection'
import ProgramCard from './ProgramCard/ProgramCard'
import ProgramDriver from './ProgramDriver/ProgramDriver'
import LearningPlan from './LearningPlan/LearningPlan'
import Intentions from './Intentions/ActivityIntentions'
import IntentionActivity from 'components/activities/v1/IntentionSetter/IntentionSetterV1'
import TabAccessibilityMixin from 'home/components/shared/TabAccessibilityMixin'
import Overlay from 'components/InteractiveSection/InteractiveSectionOverlay'

// import { getCoursesData, getProgramsData, getUserData } from 'activity/api'

export default {
  mixins: [TabAccessibilityMixin],

  components: {
    CourseSections,
    ActivityHeader,
    PageLoader,
    PageSection,
    ProgramCard,
    ProgramDriver,
    LearningPlan,
    Intentions,
    IntentionActivity,
    Overlay
  },

  data() {
    return {
      is_courses_loading: false,

      initial_tab: null,
      active_tab: null,

      is_intention_overlay_opened: false,
      intention_id: null,
      intention_was_opened: false,
      intentions_loaded: false
    }
  },

  created() {
    this.fetchData()
  },

  mounted() {
    this.initial_tab = this.$router.currentRoute.value.query.tab || null

    if (this.$router.currentRoute.value.query.intention_id &&
      this.$router.currentRoute.value.query.intention_id !== ''
    ) {
      this.intention_id = this.$router.currentRoute.value.query.intention_id
      this.intention_was_opened = false
    }
  },

  watch: {
    active_tab: function(tab) {
      const query = { tab }
      if (tab === 'intentions' &&
        !this.intention_was_opened &&
        this.intention_id
      ) {
        query.intention_id = this.intention_id
      }

      this.$router.push({
        query
      }).catch(err => { console.log(err) })
    },

    intentions_loaded: function(loaded) {
      if (this.initial_tab === 'intentions') {
        this.active_tab = 'intentions'
        if (this.intention_id && !this.intention_was_opened) {
          this.toggleIntentionOverlay(this.intention_id)
          this.intention_was_opened = true
        }
      }
    }
  },

  computed: {
    ...mapState(useHomeStore, {
      api_client: 'api_client',
      user: 'user',
      courses_dict: 'courses',
      programs: 'programs',
      program_load_ts: 'program_load_ts',
      intentions: 'intentions',
      intention_load_ts: 'intention_load_ts',
      learning_plans: 'learning_plans'
    }),

    has_benefits() {
      return !!this.programs.length
    },

    has_intentions() {
      return !!this.intentions.length
    },

    has_content() {
      return this.show_learning_plans ||
          this.show_courses ||
          this.show_benefits ||
          this.show_intentions
    },

    show_courses() {
      const config = this.configValue('activity_menu_my_courses_visible')
      if (config !== null) {
        return (this.in_progress_courses.length || this.completed_courses.length) && config
      } else {
        return false
      }
    },

    show_benefits() {
      const config = this.configValue('activity_menu_my_benefits_visible')
      if (config !== null) {
        return this.has_benefits && config
      } else {
        return this.has_benefits
      }
    },

    show_program_drivers() {
      const config = this.configValue('activity_menu_my_benefits_drivers_visible')
      return (config !== null) ? config : true
    },

    show_learning_plans() {
      const config = this.configValue('activity_menu_my_learning_plans_visible')
      if (config !== null) {
        return this.activity_learning_plans.length && config
      } else {
        return false
      }
    },

    show_intentions() {
      return this.has_intentions
    },

    header_tabs() {
      const tabs = []
      if (this.show_learning_plans) tabs.push({ name: 'learning-plans', text: this.$t('my_learning_plans') })
      if (this.show_courses) tabs.push({ name: 'courses', text: this.$t('my_courses') })
      if (this.show_benefits) tabs.push({ name: 'benefits', text: this.$t('my_benefits') })
      if (this.show_intentions) tabs.push({ name: 'intentions', text: this.$t('my_intentions') })

      if (tabs.length) {
        // get current tab from URL
        if (this.$router.currentRoute.value.query.tab) {
          /* eslint-disable-next-line vue/no-side-effects-in-computed-properties */
          this.active_tab = this.$router.currentRoute.value.query.tab
        } else {
          /* eslint-disable-next-line vue/no-side-effects-in-computed-properties */
          this.active_tab = tabs[0].name
        }
      }

      return tabs
    },

    courses() {
      return (this.courses_dict && Object.keys(this.courses_dict).map(e => this.courses_dict[e])) || []
    },

    course_sections() {
      const sections = [{
        headline: this.$t('in_progress_section.courses.headline'),
        subheadline: this.$t('in_progress_section.courses.subheadline'),
        is_in_progress: true,
        type: 'course_sections',
        position: '-1',
        courses: this.in_progress_courses
      }, {
        headline: this.$t('completed'),
        subheadline: this.$t('completed_subheadline'),
        is_in_progress: false,
        type: 'course_sections',
        position: '0',
        courses: this.completed_courses
      }]

      return sections
    },

    in_progress_courses()  {
      return this.courses.filter((c) => c.enrolled === true && !c.completion_date)
    },

    activity_learning_plans()  {
      const learning_plans = (this.learning_plans && Object.keys(this.learning_plans).map(e => this.learning_plans[e])) || []
      const enrolled_learning_plans = learning_plans.filter(lp => lp.enrolled === true)
      const sorted_learning_plans = enrolled_learning_plans.sort((lp1, lp2) => {
        const date1 = new Date(lp1.enrollment_date).getTime()
        const date2 = new Date(lp2.enrollment_date).getTime()
        return date2 - date1
      })

      return sorted_learning_plans
    },

    completed_courses()  {
      return this.courses.filter((c) => c.completion_date)
    },

    courses_drivers() {
      const results = []

      this.program_drivers.forEach((driver) => {
        const course_drivers = results.find((cd) => cd.name === driver.course_name)
        if (course_drivers) {
          course_drivers.program_drivers.push(driver)
        } else {
          results.push({
            name: driver.course_name,
            program_drivers: [driver]
          })
        }
      })

      return results
    },

    current_intention() {
      return this.intentions.filter((i) => i.id === this.intention_id)[0]
    }
  },

  methods: {
    ...mapActions(useHomeStore, [
      'loadPrograms',
      'loadIntentions',
      'setIntention'
    ]),

    ...mapActions(useUserResultsStore, [
      'updateSlideResult'
    ]),

    changeTab(tab, e) {
      this.active_tab = tab
      this.toggleTabsAria(e)
    },

    configValue(key) {
      if (this.$root.configuration && this.$root.configuration.hasOwnProperty(key)) {
        return this.$root.configuration[key]
      } else {
        return null
      }
    },

    fetchData() {
      if (this.programs.length === 0) {
        this.loadPrograms()
      }

      if (this.intentions.length === 0) {
        this.loadIntentions(() => {
          this.intentions_loaded = true
        })
      }
    },

    toggleIntentionOverlay(intention_id) {
      if (!this.is_intention_overlay_opened) {
        this.is_intention_overlay_opened = true
        this.intention_id = intention_id
      } else {
        this.is_intention_overlay_opened = false

        // Remove intention_id from the query (without saving to history)
        this.$router.replace({
          query: {
            tab: this.active_tab
          }
        }).catch(err => { console.log(err) })
      }
    },

    updateIntention(callback_results) {
      // create a tmp variable to compile an updated intention
      // required since the callback result has different data format
      const tmp = JSON.parse(JSON.stringify(this.current_intention))
      tmp.result.event = callback_results.event
      tmp.result.results = callback_results.results
      tmp.completion_date = callback_results.results[callback_results.results.current_intention].completion_date || null

      // set current intention in store to the updated value
      this.setIntention(tmp)
    }

  }
}

</script>

<style lang='sass' scoped>
  @import './app'
</style>
