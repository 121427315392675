<template>
<div class='pagination'>
  <button
    :title="$t('first_page')"
    class='pagination_left'
    :class="{'disabled': current_page === 1}"
    :disabled='current_page === 1'
    @click.prevent='toPage(1)'
  >
    &lt;&lt;
  </button>

  <button
    :title="$t('previous_page')"
    class='pagination_left'
    :class="{'disabled': current_page === 1}"
    :disabled='current_page === 1'
    @click.prevent='toPrevPage'
  >
    &lt;
  </button>

  <span
    v-if='first_gap_condition'
    class='pagination_link gap'
  >
    ...
  </span>

  <button
    v-for='(page, index) in pages'
    :key='index'
    :title='setPageTitle(page)'
    class='pagination_link'
    :class="{'active': page === current_page}"
    @click.prevent='toPage(page)'
  >
    {{ page }}
  </button>

  <span
    v-if='last_gap_condition'
    class='pagination_link gap'
  >
    ...
  </span>

  <button
    :title="$t('next_page')"
    class='pagination_right'
    :class="{'disabled': current_page === total_pages}"
    :disabled='current_page === total_pages'
    @click.prevent='toNextPage'
  >
    &gt;
  </button>

  <button
    :title="$t('last_page')"
    class='pagination_right'
    :class="{'disabled': current_page === total_pages}"
    :disabled='current_page === total_pages'
    @click.prevent='toPage(total_pages)'
  >
    &gt;&gt;
  </button>
</div>
</template>

<script>

export default {
  props: ['total_pages', 'goToPage', 'setPage', 'current_page'],

  data() {
    return {
      max_pages: 8
    }
  },

  created() {
    this.setMaxPages()
    window.onresize = () => {
      this.setMaxPages()
    }
  },

  computed: {
    pages() {
      const pages = []

      for (let i = 0; i < this.total_pages; i++) {
        pages.push(i + 1)
      }

      const filtered_pages = pages.filter((page) => {
        if (this.current_page < Math.floor(this.max_pages / 2) + 1) {
          return page < this.max_pages + 1
        } else {
          if (this.current_page < this.total_pages - Math.floor(this.max_pages / 2) + 1) {
            return page > this.current_page - Math.floor(this.max_pages / 2) && page <= this.current_page + Math.floor(this.max_pages / 2) - 1
          } else {
            return page > this.total_pages - this.max_pages
          }
        }
      })

      return this.total_pages <= this.max_pages ? pages : filtered_pages
    },

    first_gap_condition() {
      return this.total_pages > this.max_pages && this.current_page >= Math.floor(this.max_pages / 2) + 1
    },

    last_gap_condition() {
      return this.total_pages > this.max_pages && this.current_page < this.total_pages - Math.floor(this.max_pages / 2) + 1
    }
  },

  methods: {
    toPrevPage() {
      this.setPage(this.current_page - 1)
      this.goToPage()
    },

    toNextPage() {
      this.setPage(this.current_page + 1)
      this.goToPage()
    },

    toPage(page) {
      this.setPage(page)
      this.goToPage()
    },

    setPageTitle(page) {
      return `${this.$t('page')} ${page}`
    },

    setMaxPages() {
      if (window.innerWidth <= 450) {
        this.max_pages = 6

        if (this.total_pages >= 100) {
          this.max_pages = 4
        }
      } else {
        this.max_pages = 8
      }
    }
  }
}
</script>
<style lang='sass' scoped>
  @import './Pagination'
</style>
