<template>
<div class='self-assessment_question_options self-assessment_question_options--text'>
  <div
    v-for='(row, index) in options_array'
    :key='index'
    class='self-assessment_question_options-row'
  >
    <div
      v-for='option in row'
      :key='option.id'
      class='self-assessment_question_options-cell'
    >
      <button
        v-if='!edited_option || edited_option.id !== option.id'
        class='self-assessment_question_options_option'
        :class="{'selected' : isOptionSelected(option)}"
        :disabled='button_disabled'
        @click.prevent='onOptionClick(option)'
      >
        <span
          class='self-assessment_question_options_option-circle'
          @click.stop='onCheckmarkClick(option)'
        >
          <template v-if="isOptionSelected(option)">
            <CompletedIcon />
          </template>
        </span>

        <span
          v-if='values[option.id]'
          class='self-assessment_question_options_option-text self-assessment_question_options_option-label copy-two'
        >
          {{ option.text }}
        </span>

        <span class='self-assessment_question_options_option-text copy-two'>
          {{ values[option.id] ? values[option.id] : option.text }}
        </span>
      </button>

      <div
        v-else
        class='self-assessment_question_options_option self-assessment_question_options_option--editable'
      >
        <FreeFormInput
          :is_multiple_options='true'
          :option='option'
          :saved_value='values[option.id]'
          :should_set_focus='true'
          @update='onUpdate'
        />

        <button
          class='self-assessment_question_options_confirm'
          title='Confirm'
          @click.prevent='toggleEditing(option)'
        >
          <CompletedIcon />
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import FreeFormInput from './SelfAssessmentV2FreeFormInput'
import CompletedIcon from './completed.svg'

export default {
  props: [
    'options',
    'selectOption',
    'savedOptionValues',
    'isOptionSelected'
  ],

  components: {
    FreeFormInput,
    CompletedIcon
  },

  data() {
    return {
      button_disabled: true,
      edited_option: null, // the option being edited currently
      values: {},
      options_with_errors: {}
    }
  },

  mounted() {
    this.values = this.savedOptionValues() || {}
    setTimeout(() => {
      this.button_disabled = false
    }, 400)
  },

  computed: {
    options_array() {
      const rows = []; const items = this.options.slice()

      // let items_in_row = this.two_column ? 2 : 1
      const items_in_row = 2

      while (items.length) {
        rows.push(items.splice(0, items_in_row))
      }

      return rows
    }
  },
  methods: {
    onOptionClick(option) {
      // when user clicks on an option while editing another one
      if (this.edited_option && this.edited_option.id !== option.id) {
        this.toggleEditing(this.edited_option)
      }

      if (option.text_type) {
        this.toggleEditing(option)
      } else {
        this.selectOption(option, this.values)
      }
    },

    onCheckmarkClick(option) {
      // when user clicks on an option while editing another one
      if (this.edited_option && this.edited_option.id !== option.id) {
        this.toggleEditing(this.edited_option)
      }

      if (option.text_type && !this.values[option.id]) {
        this.toggleEditing(option)
      } else {
        this.selectOption(option, this.values)
      }
    },

    onUpdate(updated_option) {
      this.values[updated_option.id] = updated_option.is_error ? null : updated_option.value

      if (updated_option.is_error) {
        this.options_with_errors[updated_option.id] = true
      } else {
        delete this.options_with_errors[updated_option.id]
      }
    },

    toggleEditing(option) {
      // save values for currently edited option
      if (
        this.edited_option && (
          // value not empty AND options is not yet selected AND we are trigerring from the same option
          (this.values[this.edited_option.id] && !this.isOptionSelected(option) && option.id === this.edited_option.id) ||
          // OR if value empty and option is selected
          (!this.values[this.edited_option.id] && this.isOptionSelected(option))
        )
      ) {
        // toggle selection for currently edited option
        this.selectOption(this.edited_option, this.values)
      }

      if (this.edited_option && option.id === this.edited_option.id) {
        // quit editing mode
        this.edited_option = null
      } else {
        // enter editing mode for a new option
        this.edited_option = option
      }
    }
  }
}
</script>

<style lang='sass' scoped>
  @import 'Text'
</style>
