<template>
<div
  class='self-assessment_question_options self-assessment_question_options--scale'
  :class='[options.length > 8 ? "with-many-options" : ""]'
>
  <template
    v-for='option in options'
    :key='option.id'
  >
    <button
      class='self-assessment_question_options_option'
      :class='buttonClass(option)'
      @click.prevent='selectOption(option)'
    >
      <span class='self-assessment_question_options_option-circle' />
      <span class='self-assessment_question_options_option-text copy-two'>
        {{ option.text }}
      </span>
    </button>
    <span class='divider' />
  </template>
</div>
</template>

<script>
export default {
  props: [
    'options',
    'selectOption',
    'isOptionSelected'
  ],

  methods: {
    buttonClass(option) {
      const classes = []

      classes.push(option.text && option.text.length > 2 ? 'long' : 'short')
      if (this.isOptionSelected(option)) classes.push('selected')

      return classes
    }
  }
}
</script>

<style lang='sass' scoped>
  @import 'Scale'
</style>
