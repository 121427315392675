<template>
<div class='interactive-section-intro-basic-content'>
  <!-- Image Overlay -->
  <div
    :style='overlayStyle'
    class='interactive-section-intro-basic-content_bg'
  />

  <!-- Content -->
  <div
    class='interactive-section-intro-basic-content_body'
    v-html='activity.content'
  />
</div>
</template>

<script>
export default {
  props: ['activity'],

  computed: {
    overlayStyle() {
      // Image
      if (this.activity.background_image) {
        return `background-image: url(${this.activity.background_image})`
      }

      // Gradient Background
      if (!this.activity.background_image && this.activity.gradient_start) {
        const start = this.activity.gradient_start
        const end = this.activity.gradient_end || this.activity.gradient_start
        const gradient = `linear-gradient(137deg, ${start} 0%, ${end} 92%)`
        return {
          backgroundImage: gradient
        }
      }

      return null
    }
  }
}
</script>

<style lang='sass' scoped>
  @import 'IntroBasicContent'
</style>
