<template>
  <div class='course-section'>
    <SectionDrawer
      :title='section.headline'
      :description='section.subheadline'
      :link='section.link'
      type='courses'
      :modifier='courseSectionModifier(section)'
      :context='context'
    >
    <template v-if='section_rows.length'>
      <CourseSectionRow
        v-for='(row, sectionRowIndex) in section_rows'
        :key='sectionRowIndex'
        :section='section'
        :row='row'
        :row_index='sectionRowIndex'
        :opened_course='opened_course'
        :opened_drawer='opened_drawer'
        :setOpenedDrawer='setOpenedDrawer'
        :setOpenedCourse='setOpenedCourse'
      />
    </template>

    <template v-else>
      <div class='section-drawer_courses-list--no-courses copy-two'>
        {{ $t('you_have_no_courses_here_yet') }}
      </div>
    </template>
    </SectionDrawer>
  </div>
</template>

<script>
import WindowSizeMixin from 'home/components/shared/WindowSizeMixin'
import SectionDrawer from 'home/components/shared/SectionDrawer/SectionDrawer.vue'
import CourseSectionRow from 'home/components/shared/SectionDrawer/CourseSectionRow/CourseSectionRow.vue'

export default {
  mixins: [WindowSizeMixin],

  props: ['section', 'context'],

  data() {
    return {
      opened_drawer: false,
      opened_course: null
    }
  },

  components: {
    SectionDrawer,
    CourseSectionRow
  },

  computed: {
    section_rows() {
      const rows = []; const items = this.section.courses.slice()

      // Here we split courses into rows by 3, 2 or 1 courses in a row
      // (or by 4, 2 and 1 for "catalog" pages)
      // depending on tablet or mobile screen size
      let items_in_row = this.context === 'catalog' ? 4 : 3
      if (this.is_tablet) items_in_row = 2
      if (this.is_mobile) items_in_row = 1

      while (items.length) {
        rows.push(items.splice(0, items_in_row))
      }

      return rows
    }
  },

  methods: {
    courseSectionModifier(section) {
      return (section.type === 'in_progress_sections' ? 'courses-in-progress' : 'courses-normal')
    },

    setOpenedDrawer(value) {
      this.opened_drawer = value
    },

    setOpenedCourse(value) {
      this.opened_course = value
    }
  }

}
</script>


<style lang='sass' scoped>
.section-drawer_courses-list--no-courses
  display: flex
  text-align: center
  justify-content: center
  align-items: center
  min-height: 320px
</style>
