<template>
<a
  :href='`/lessons/${lesson.id}/continue`'
  class='lesson-card'
  @click='authGateway($event)'
>
  <div class='lesson-card_image--wrapper'>
    <div
      class='lesson-card_image'
      :style='lessonImage'
    />
  </div>

  <div class='lesson-card_title heading-six'>
    {{ title }}
  </div>

  <div class='lesson-card_description copy-three'>
    {{ description }}
  </div>
</a>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapActions } from 'pinia'

export default {
  props: ['lesson'],

  computed: {
    lessonImage() {
      return `background-image: url(${this.lesson.featured_image})`
    },

    title() {
      return this.lesson.title
    },

    description() {
      return this.lesson.card_description
    }
  },

  methods: {
    ...mapActions(useHomeStore, ['authGateway'])
  }
}
</script>

<style lang='sass' scoped>
  @import './LessonCard'
</style>
