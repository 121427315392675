// Intro model based on API::V1::LessonPage::IntroSerializer
export default {

  // Images
  background_image: null,
  header_logo: null,

  // Attributes
  headline: null,
  subheadline: null,
  introduction: null,
  cta: null
}
