import BaseClient from './base-client'

export default class EmbedClient extends BaseClient {
  getEmbedCode(callback) {
    callback(this.videoCode)
  }

  pause(element) {
    $(element).empty()
  }

  readyPlay(element) {
    this.getEmbedCode(function(code) {
      $(element).html(code)
    })
  }
}
