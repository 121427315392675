<template>
<a
  :href='`/lessons/${lesson.id}/continue`'
  class='instructor-lesson-card'
  @click='authGateway($event)'
>

  <div
    class='instructor-lesson-card_image'
    :style='lesson_image'
  />

  <div class='instructor-lesson-card_content'>
    <div class='instructor-lesson-card_title heading-five'> {{ lesson.title }} </div>
    <div class='instructor-lesson-card_instructor copy-four'> {{ instructor_attribution }} </div>

    <div
      v-if='progress'
      class='instructor-lesson-card_progress-bar'
    >
      <div
        class='instructor-lesson-card_current-progress'
        :style="{width: '' + progress + '%'}"
       >
        <span class='ada-hidden'>Completed: {{ progress }}%</span>
      </div>
    </div>
  </div>
</a>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapActions } from 'pinia'

export default {
  props: ['lesson', 'instructor'],

  computed: {
    lesson_image() {
      return `background-image: url(${this.lesson.featured_image})`
    },

    progress() {
      return this.lesson.progress
    },

    instructor_attribution() {
      return (this.instructor && this.$t('attribution.with', { value: this.instructor.full_name })) || null
    }
  },

  methods: {
    ...mapActions(useHomeStore, ['authGateway'])
  }
}
</script>

<style lang='sass' scoped>
  @import './LessonCard'
</style>
