<script>
import { inject, h } from 'vue'

/* global HYPE */
export default {
  render: function() {
    return h(
      'div',
      {
        class: ['tbk-hype_outer']
      },
      [

        h('div', {
          class: ['tbk-hype_container'],
          key: this.hype_key,
          onMouseover: this.mouseOver,
          onFocusin: this.mouseOver
        }, [

          h('div', {
            class: ['tbk-hype'],
            id: `${this.resource}_hype_container`
          }, this.$slots.default),

          h('script', { src: this.resource_url }, this.$slots.default),

          h('div', {
            class: ['tbk-hype_actions']
          }, [
            h('button', {
              class: ['tbk-hype_actions_fullscreen', 'tbk-hype_actions_fullscreen--enter'],
              title: 'Enter Fullscreen',
              onClick: this.toggleFullscreen
            }),

            h('button', {
              class: ['tbk-hype_actions_fullscreen', 'tbk-hype_actions_fullscreen--exit'],
              title: 'Exit Fullscreen',
              onClick: this.toggleFullscreen
            }),

            h('button', {
              class: ['tbk-hype_actions_fullscreen-mobile-on'],
              title: 'View Fullscreen',
              onClick: this.toggleFullscreen
            }),

            h('button', {
              class: ['tbk-hype_actions_fullscreen-mobile-off'],
              title: 'Exit Fullscreen',
              onClick: this.toggleFullscreen
            })
          ])

        ])

      ]
    )
  },

  data() {
    return {
      HYPE_last_buttons: [],
      HYPE_firt_scene_name: null,
      ratio: null,
      hype_name: null,
      hype_key: 1,
      is_hovered: false,
      hovered_timer: null,

      bucket: null,
      resource: null,
      namespace: null
    }
  },

  created() {
    this.init_options = inject('init_options')
    this.bucket = this.init_options.bucket
    this.resource = this.init_options.resource
    this.namespace = this.init_options.namespace
  },

  mounted() {
    window.addEventListener('orientationchange', this._handleOrientationChange)
    window.addEventListener('resize', this._handleOrientationChange)

    // Adding a listeners for HYPE
    if ('HYPE_eventListeners' in window === false) {
      window.HYPE_eventListeners = []
    }
    window.HYPE_eventListeners.push(
      { type:'HypeSceneLoad',    callback:this.onHypeSceneLoad }
    )

    if (window.innerWidth <= 820) {
      this._recalculateContainerHeihgt()
    }
  },

  watch: {
    is_hovered: function(value) {
      const container = this.$el.querySelector('.tbk-hype_container')
      if (value) container.classList.add('is-hovered')
      else container.classList.remove('is-hovered')
    }
  },

  beforeUnmount() {
    window.removeEventListener('orientationchange', this._handleOrientationChange)
    window.removeEventListener('resize', this._handleOrientationChange)
  },

  methods: {

    onHypeSceneLoad(hypeDocument, element, event) {
      // console.log("Load id: " + element.id + " name: " + hypeDocument.currentSceneName() + " id: " + hypeDocument.documentId());

      if (!element) {
        element = $('.slide-basic-content_body--hype').find('.HYPE_scene[hype_scene_index="1"]')[0]
      }

      const hype_scene_id = element.id
      const hype_scene = document.getElementById(hype_scene_id)
      let button_to_focus = null

      // Set the last clicked button tracker
      const buttons = hype_scene.querySelectorAll('.HYPE_element[role="button"], .HYPE_element[role="Button"]')

      if (!this.HYPE_firt_scene_name) {
        // If this is the first scene save it's name
        this.HYPE_firt_scene_name = hypeDocument.currentSceneName()
      }

      if (this.HYPE_firt_scene_name !== hypeDocument.currentSceneName()) {
        // Set event listener to return to the 1st scene on ESC key pressed
        hype_scene.addEventListener('keyup', (e) => {
          if (e.keyCode === 27) { // ESC
            hypeDocument.showSceneNamed(this.HYPE_firt_scene_name)
          }
        })
        if (hypeDocument.documentId().includes('test01_hype_container')) {
          hype_scene.setAttribute('role', 'dialog')
          hype_scene.setAttribute('aria-modal', 'true')
          hype_scene.setAttribute('aria-label', hypeDocument.currentSceneName())
        }
      }

      buttons.forEach((button) => {
        button.addEventListener('click', (e) => {
          this._setLastButton(e)
        })

        button.addEventListener('keyup', (e) => {
          if (e.keyCode === 13) {
            this._setLastButton(e)
          }
        })
      })

      // TBK-3438 Nasty hack to fix a11y issues for a partcular(!) HYPE module
      if (
        hypeDocument.documentId().includes('test01_hype_container') &&
        this.HYPE_firt_scene_name &&
        this.HYPE_firt_scene_name !== hypeDocument.currentSceneName()
      ) {
        buttons.forEach((button) => {
          button.addEventListener('keydown', (e) => {
            if (e.keyCode === 9) { // Tab
              // Lock the focus within the "popup" (imitate popup-like behavior)
              e.preventDefault()
            }
          })

          button.addEventListener('keyup', (e) => {
            if (e.keyCode === 13) { // Enter
              // Get to the 1st scene from the popup
              hypeDocument.showSceneNamed(this.HYPE_firt_scene_name)
            }
          })
        })
      }
      // end of hack

      // Set the focus on a button in the scene
      if (this.HYPE_last_buttons[hype_scene_id]) {
        // if a button was already clicked in this scene give it a focus
        button_to_focus = document.getElementById(this.HYPE_last_buttons[hype_scene_id])
      } else {
        // else set the focus on the first focusable element
        button_to_focus = hype_scene.querySelector('.HYPE_element[tabindex]')
      }

      if (button_to_focus) {
        setTimeout(() => {
          button_to_focus.focus()
        }, 600)
      }
    },

    _setLastButton(event) {
      const button = event.currentTarget
      if (button) {
        const scene = button.closest('.HYPE_scene')
        if (scene) {
          this.HYPE_last_buttons[scene.id] = button.id
        }
      }
    },

    becameVisible() {
      if (typeof (HYPE) !== 'undefined') {
        const hypes = Object.keys(HYPE.documents)
        hypes.forEach((h) => {
          setTimeout(() => {
            HYPE.documents[h].relayoutIfNecessary()
          }, 200)
        })
      }

      if (window.innerWidth <= 820) {
        this.hype_key += 1
        setTimeout(() => {
          this._recalculateContainerHeihgt()
        }, 200)
      }
    },

    becameHidden() {},

    _recalculateContainerHeihgt() {
      if ('HYPE_eventListeners' in window === false) { window.HYPE_eventListeners = [] }

      window.HYPE_eventListeners.push({
        type:'HypeSceneLoad',
        callback: (hypeDocument, element, event) => {
          const hype_name = this.$el.querySelector('.tbk-hype').getAttribute('hyp_dn')
          const hype_scenes = this.$el.querySelectorAll('.HYPE_scene')
          let visible_scene = null
          this.hype_name = hype_name

          // There're lots of scenes inside HYPE container
          // So we try to find the one scene that is visible and not hidden
          // The visible scene doesn't have 'aria-hidden' attribute
          // And has 'display: block' set as inline style
          Array.from(hype_scenes).forEach((scene) => {
            if (scene.getAttribute('aria-hidden') === null && scene.style.display === 'block') {
              visible_scene = scene
            }
          })

          // If the visible scene was found then we search for first element that has width and height attributes
          if (visible_scene) {
            const hype_element = visible_scene.querySelector('.HYPE_element_container > .HYPE_element')
            const ratio = (hype_element.offsetHeight / hype_element.offsetWidth) * 100

            // If we've found height to width ratio then we set it to our container div
            // So that hype fits all the width of our container
            if (!isNaN(ratio)) {
              this.ratio = ratio
              this._setRatio()
            }
          }
        }
      })
    },

    _setRatio() {
      const hype_name = this.hype_name || this.$el.querySelector('.tbk-hype').getAttribute('hyp_dn')
      if (window.orientation === 0) {
        this.$el.querySelector('.tbk-hype_container').style = 'padding-bottom: ' + this.ratio + '%'
        if (HYPE.documents[hype_name]) {
          HYPE.documents[hype_name].relayoutIfNecessary()
        }
      } else {
        this.$el.querySelector('.tbk-hype_container').style = ''
        if (this.ratio > 57 && $(this.$el).width() < 820) {
          const width = $(this.$el).height() * 0.8
          const height = width * (this.ratio / 100)
          const style = `padding-bottom: 0; width: ${width}px; height: ${height}px;`

          this.$el.querySelector('.tbk-hype_container').style = style
        }
        if (HYPE.documents[hype_name]) {
          HYPE.documents[hype_name].relayoutIfNecessary()
        }
      }
    },

    _handleOrientationChange() {
      this._setRatio()
    },

    toggleFullscreen(event) {
      event.preventDefault()

      const element = this.$el

      // Native FullscreenAPI
      if (this.can_go_fullscreen) {
        if (!document.fullscreenElement) {
          element.requestFullscreen()
          element.querySelector('.tbk-hype_actions_fullscreen').setAttribute('title', 'Exit Fullscreen')
        } else {
          document.exitFullscreen()
          element.querySelector('.tbk-hype_actions_fullscreen').setAttribute('title', 'Enter Fullscreen')
        }
      }

      // Fullscreen emulation with position: fixed
      if (!this.can_go_fullscreen) {
        if (element.classList.contains('fullscreen')) {
          element.classList.remove('fullscreen')
          document.body.classList.remove('fullscreen-on')
        } else {
          element.classList.add('fullscreen')
          document.body.classList.add('fullscreen-on')
        }
      }

      // Relayout
      const hype_name = this.$el.querySelector('.tbk-hype').getAttribute('hyp_dn')
      if (typeof (HYPE) !== 'undefined') {
        setTimeout(() => {
          HYPE.documents[hype_name].relayoutIfNecessary()
          this._setRatio()
        }, 200)
      }
    },

    mouseOver() {
      this.is_hovered = true
      clearTimeout(this.hovered_timer)
      this.hovered_timer = setTimeout(() => {
        this.is_hovered = false
      }, 3000)
    }
  },

  computed: {
    resource_url() {
      return `https://s3.amazonaws.com/${this.bucket}/${this.namespace}/${this.resource}.hyperesources/${this.resource}_hype_generated_script.js`
    },

    can_go_fullscreen() {
      const el = document.body
      const check = typeof el.requestFullscreen !== 'undefined' ||
                  typeof el.mozRequestFullScreen !== 'undefined' ||
                  typeof el.webkitRequestFullscreen !== 'undefined' ||
                  typeof el.msRequestFullscreen !== 'undefined' ||
                  typeof document.exitFullscreen !== 'undefined' ||
                  typeof document.mozCancelFullScreen !== 'undefined' ||
                  typeof document.webkitExitFullscreen !== 'undefined'

      return check
    }
  }
}
</script>

<style lang="sass" scoped>
@import './hype-component'
</style>
