<template>
<div class='self-assessment_question_options self-assessment_question_options--round-button'>
  <button
    v-for='(option, index) in options'
    :key='index'
    class='self-assessment_question_options_option'
    :class="{'selected' : isOptionSelected(option)}"
    @click.prevent='selectOption(option)'
  >
    <span class='self-assessment_question_options_option-text copy-two'>
      {{ option.text }}
    </span>
  </button>
</div>
</template>

<script>
export default {
  props: [
    'options',
    'selectOption',
    'isOptionSelected'
  ]
}
</script>

<style lang='sass' scoped>
  @import 'RoundButton'
</style>
