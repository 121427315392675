import HomePage from './home_page'
import TextPage from './text_page'
import User from './user'
import Course from './course'
import Program from './program'
import ProgramDriver from './program_driver'
import Lesson from './lesson'
import Part from './part'
import ResourceCard from './resource_card'
import Slide from './slide'
import SlideActivityResult from './slide_activity_result'
import TriggeredSlideActivityResult from './triggered_slide_activity_result'
import SearchResult from './search_result'
import TriggeredSlide from './triggered_slide'
import Instructor from './instructor'
import Tag from './tag'
import LessonIntro from './lesson_page/intro'
import LessonOutroV1 from './lesson_page/outro/v1'
import LessonOutroV2 from './lesson_page/outro/v2'
import LessonLeftMenu from './lesson_page/left_menu'
import LearningPlan from './learning_plan'
import LearningPlanOverviewPage from './learning_plan/overview_page'
import BadgeAssertion from './badge_assertion'
import DiscussionTopic from './discussion_topic'
import DiscussionComment from './discussion_comment'
import GlobalSlide from './global_slide'
import GlobalSlideActivityResult from './global_slide_activity_result'
import AudioSeries from './audio_series'
import AudioSeriesEpisode from './audio_series/episode'
import Intention from './intention'

import ActivityV1Article from './activity/v1/article'
import ActivityV1Audio from './activity/v1/audio'
import ActivityV1Assessment from './activity/v1/assessment'
import ActivityV1BasicContent from './activity/v1/basic_content'
import ActivityV1Fullscreen from './activity/v1/fullscreen'
import ActivityV1IntentionSetter from './activity/v1/intention_setter'
import ActivityV1PairedComparison from './activity/v1/paired_comparison'
import ActivityV1Poll from './activity/v1/poll'
import ActivityV1Profile from './activity/v1/profile'
import ActivityV1Quiz from './activity/v1/quiz'
import ActivityV1Resource from './activity/v1/resource'
import ActivityV1SelfAssessment from './activity/v1/self_assessment'
import ActivityV1Title from './activity/v1/title'
import ActivityV1Video from './activity/v1/video'
import ActivityV1VideoWithInstruction from './activity/v1/video_with_instruction'
import ActivityV1Discussion from './activity/v1/discussion'
import ActivityV1Journal from './activity/v1/journal'
import ActivityV2SelfAssessment from './activity/v2/self_assessment'

import CourseSection from './section/course'
import HeroSection from './section/hero'
import HeroSectionPage from './section/hero/page'
import InstructorSection from './section/instructor'
import LessonSection from './section/lesson'
import ResourceSection from './section/resource'
import LearningPlanSection from './section/learning_plan'
import InProgressSection from './section/in_progress'
import InteractiveSection from './section/interactive'
import AudioSeriesSection from './section/audio_series'

import Notification from './notification'

export default {
  load: function(apiClient) {
    apiClient.define('home_page', HomePage, { collectionPath: 'home_page' })
    apiClient.define('text_page', TextPage)
    apiClient.define('user', User, { collectionPath: 'user' })
    apiClient.define('learning_plan', LearningPlan)
    apiClient.define('learning_plan_overview_page', LearningPlanOverviewPage)
    apiClient.define('badge_assertion', BadgeAssertion)
    apiClient.define('course', Course)
    apiClient.define('lesson', Lesson)
    apiClient.define('program', Program)
    apiClient.define('program_driver', ProgramDriver)
    apiClient.define('part', Part)
    apiClient.define('resource_card', ResourceCard)
    apiClient.define('slide', Slide)
    apiClient.define('slide_activity_result', SlideActivityResult)
    apiClient.define('triggered_slide_activity_result', TriggeredSlideActivityResult)
    apiClient.define('triggered_slide', TriggeredSlide)
    apiClient.define('instructor', Instructor)
    apiClient.define('tag', Tag)
    apiClient.define('lesson_intro', LessonIntro)
    apiClient.define('lesson_v1_outro', LessonOutroV1)
    apiClient.define('lesson_v2_outro', LessonOutroV2)
    apiClient.define('lesson_left_menu', LessonLeftMenu)
    apiClient.define('discussion_topic', DiscussionTopic)
    apiClient.define('discussion_comment', DiscussionComment)
    apiClient.define('global_slide', GlobalSlide)
    apiClient.define('global_slide_activity_result', GlobalSlideActivityResult)
    apiClient.define('audio_series', AudioSeries)
    apiClient.define('audio_series_episode', AudioSeriesEpisode)
    apiClient.define('intention', Intention)
    apiClient.define('activity_v1_article', ActivityV1Article)
    apiClient.define('activity_v1_audio', ActivityV1Audio)
    apiClient.define('activity_v1_assessment', ActivityV1Assessment)
    apiClient.define('activity_v1_basic_content', ActivityV1BasicContent)
    apiClient.define('activity_v1_fullscreen', ActivityV1Fullscreen)
    apiClient.define('activity_v1_intention_setter', ActivityV1IntentionSetter)
    apiClient.define('activity_v1_paired_comparison', ActivityV1PairedComparison)
    apiClient.define('activity_v1_poll', ActivityV1Poll)
    apiClient.define('activity_v1_profile', ActivityV1Profile)
    // The double 'zz' is a devour pluralization limitation
    apiClient.define('activity_v1_quizz', ActivityV1Quiz)
    apiClient.define('activity_v1_resource', ActivityV1Resource)
    apiClient.define('activity_v1_self_assessment', ActivityV1SelfAssessment)
    apiClient.define('activity_v1_title', ActivityV1Title)
    apiClient.define('activity_v1_video', ActivityV1Video)
    apiClient.define('activity_v1_video_with_instruction', ActivityV1VideoWithInstruction)
    apiClient.define('activity_v1_discussion', ActivityV1Discussion)
    apiClient.define('activity_v1_journal', ActivityV1Journal)
    apiClient.define('activity_v2_self_assessment', ActivityV2SelfAssessment)
    apiClient.define('course_section', CourseSection)
    apiClient.define('hero_section', HeroSection)
    apiClient.define('hero_section_page', HeroSectionPage)
    apiClient.define('instructor_section', InstructorSection)
    apiClient.define('lesson_section', LessonSection)
    apiClient.define('resource_section', ResourceSection)
    apiClient.define('learning_plan_section', LearningPlanSection)
    apiClient.define('in_progress_section', InProgressSection)
    apiClient.define('interactive_section', InteractiveSection)
    apiClient.define('audio_series_section', AudioSeriesSection)
    apiClient.define('search_result', SearchResult)
    apiClient.define('notification', Notification)

    // Override default pluralize/singularize rules for correct model names handling
    apiClient.pluralize.addSingularRule('audio_series', 'audio_series')
  }
}
