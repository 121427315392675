<template>
  <div :class='`self-assessment_question_free-form self-assessment_question_free-form--${option_class_suffix}`'>
    <template v-if='!option.calendar'>
      <input
        v-if='option.text_type === "numeric"'
        :id='option_id'
        :ref='option.id'
        v-model="field_value"
        type='number'
        class='free-form_input free-form_number'
        :class="{
          'not-empty': !!field_value,
          'error': error_message.length
        }"
        :max="option.max_value"
        min='0'
        :required='option.required'
        @keypress="onKeypress($event)"
        @blur="onInput"
        @input="onInput"
        @paste="onPaste"
      >

      <input
        v-else-if='is_multiple_options'
        :id='option_id'
        :ref='option.id'
        v-model="field_value"
        :name='option.id'
        class='free-form_input free-form_text'
        :class="{
          'not-empty': !!field_value,
          'error': error_message.length
        }"
        :required='option.required'
        @keypress="onKeypress($event)"
        @blur="onInput"
        @input="onInput"
        @paste="onPaste"
      >

      <textarea
        v-else
        :id='option_id'
        :ref='option.id'
        v-model="field_value"
        class='free-form_input free-form_textarea'
        :class="{
          'not-empty': !!field_value,
          'error': error_message.length
        }"
        :required='option.required'
        @keypress="onKeypress($event)"
        @input="onInput"
        @paste="onPaste"
      />

      <label
        v-if="option.text && option.text.length"
        :for='option_id'
      >
        {{ option.text }}
      </label>
    </template>

    <template v-else>
      <!-- Calendar -->
      <FreeFormCalendar
        :calendar='option.calendar'
        :class="{
          'not-empty': !!field_value,
          'error': error_message.length
        }"
        :option='option'
        :saved_value='saved_value'
        @change='dateChanged'
      />
    </template>

    <div class='free-form_info'>
      <div class='messages'>
        <div
          v-if='warning_message.length'
          class='free-form_warning-message'
        >
          {{ warning_message }}
        </div>

        <div
          v-if='error_message.length'
          class='free-form_error-message'
        >
          {{ error_message }}
        </div>
      </div>
      <div
        v-if='option.text_type !== "numeric" && !option.calendar'
        class='free-form_counter'
      >
        {{ field_value.length }} / {{ option.response_length }}
      </div>
    </div>
</div>
</template>

<script>
import FreeFormCalendar from './SelfAssessmentV2FreeFormCalendar.vue'

export default {
  props: [
    'is_multiple_options',
    'option',
    'saved_value',
    'should_set_focus'
  ],

  emits: ['update'],

  components: {
    FreeFormCalendar
  },

  data() {
    return {
      field_value: '',
      error_message: '',
      warning_message: ''
    }
  },

  mounted() {
    this.field_value = this.saved_value || ''
    if (!this.option.required) {
      // fill not required fields with empty values
      const option_with_value = this.option
      option_with_value.is_error = false
      option_with_value.value = ''
      this.$emit('update', option_with_value)
    }
    if (this.should_set_focus) {
      this.$refs[this.option.id].focus()
    }
  },

  computed: {
    is_error() {
      return !!this.error_message.length
    },

    option_class_suffix() {
      return this.option.calendar ? 'calendar' : this.option.text_type
    },

    option_id() {
      return `free-form_input-${this.option.id}`
    }
  },

  methods: {
    dateChanged(value) {
      this.field_value = value ? value : ''
      this.onInput()
    },

    onInput(e) {
      this.error_message = ''
      this.warning_message = ''

      // Validate
      switch (this.option.text_type) {
        case 'alphabetic':
          if (/\d/.test(this.field_value)) {
            // if contains digits
            this.error_message = this.$t('no_digits_allowed')
          }
          break
        case 'numeric':
          if (Number(this.field_value) > this.option.max_value) {
            this.error_message = this.$t('value_less_or_equal', { max_value: this.option.max_value })
          }
      }

      if (this.option.required && !this.field_value.length) {
        this.error_message = this.$t('required_field')
      }

      // Save to activity_results
      const option_with_value = this.option
      // If there is an error (i.e. value is null) activity_results will be removed for this question, which will disallow next step
      option_with_value.is_error = this.is_error
      option_with_value.value = this.field_value
      this.$emit('update', option_with_value)
    },

    onPaste(e) {
      // Stop data actually being pasted
      e.stopPropagation()
      e.preventDefault()

      // Get pasted data via clipboard API
      const clipboardData = e.clipboardData || window.clipboardData
      const pastedData = clipboardData.getData('Text')
      if (pastedData.length) {
        // Cut pasted data length to fit the limit
        this.field_value += pastedData.substring(0, this.option.response_length - this.field_value.length)
      }
      this.onInput()
    },

    onKeypress(e) {
      // check length
      if (this.option.text_type !== 'numeric' && this.field_value.length >= this.option.response_length) {
        this.warning_message = this.$t('text_size_limit_reached', { max_length: this.option.response_length })
        e.preventDefault()
        return false
      }
      this.warning_message = ''

      // check characters
      e = e || window.event
      const keyCode = e.which || e.keyCode

      switch (this.option.text_type) {
        case 'alphabetic':
          if (this.isNumber(keyCode)) {
            if (!this.is_error) {
              this.warning_message = this.$t('no_digits_allowed')
            }
            e.preventDefault()
          } else return true
          break
        case 'numeric':
          if (!this.isNumber(keyCode)) {
            if (!this.is_error) {
              this.warning_message = this.$t('only_digits_allowed')
            }
            // this will disallow negatives and exponential values as well
            e.preventDefault()
          }
      }
    },

    isNumber(keyCode) {
      return keyCode > 47 && keyCode < 58
    }
  }
}
</script>

<style lang='sass' scoped>
  @import 'FreeForm'
</style>
