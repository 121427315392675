<template>
  <a
    :key='optionKey'
    href='#'
    class='paired-comparison-select_item'
    :class='buttonClass(option)'
    @click.prevent='selectOption(option)'
  >
    <div
      v-if='option.option_image'
      class='paired-comparison-select_item-image'
      :style="{backgroundImage: 'url(' + option.option_image + ')'}"
    />

    <span class='paired-comparison-select_item-text body-two'>
      {{ option.title }}
    </span>
  </a>
</template>

<script>
export default {
  props: [
    'option',
    'selectOption',
    'optionKey',
    'optionSelected'
  ],

  methods: {
    buttonClass(option) {
      const classes = []
      if (this.optionSelected) classes.push('selected')
      if (!option.option_image) classes.push('no-image')

      return classes.join(' ')
    }
  }
}
</script>
