<template>
<div
  class='audio-player'
  :class="{
    'audio-player--fullscreen': fullscreen_mode || is_mobile_fullscreen,
    'is-playing': is_playing,
  }"
  @keydown='detectKeyup'
>
  <!-- Cover Image -->
  <div
    class='audio-player_cover'
    :style='cover_image'
    @click.prevent='toggleMobileFullscreen'
  />

  <!-- Title -->
  <div
    class='audio-player_title copy-four'
    :title='title'
    @click.prevent='toggleMobileFullscreen'
  >
    <div class='audio-player_title-container'>
      <div class='audio-player_title-playlist'>
        <router-link
          :to="{ name: 'audio-overview', params: {identifier: playlist.identifier}}"
          class='audio-player_title-playlist-link'
        >
          {{ playlist.title }}
        </router-link>
      </div>

      <h2 class='audio-player_title-caption-heading copy-four'>
        <div class='audio-player_title-caption'>
          {{ title_caption }}
        </div>

        <div
          v-if="title_author.length"
          class='audio-player_title-author'
        >
          {{ title_author }}
        </div>
      </h2>
    </div>
  </div>

  <!-- Controls -->

  <!-- Info -->
  <AudioInfo
    :title="title"
    :description="current_playlist_item.description"
    :image="current_playlist_item.image"
    :playlist="playlist"
    :transcript_url="transcript_url"
    :is_popup_visible="is_info_visible"
    @toggle="is_info_visible = !is_info_visible"
  />

  <!-- Back -->
  <button
    v-if='player'
    class='audio-player_back'
    :title='$t("audio_player.back")'
    :aria-label='$t("audio_player.back")'
    @click.prevent='seekBackward'
  >
    <IconBack />
  </button>

  <!-- Play Pause -->
  <button
    v-if='player'
    class='audio-player_play'
    :class="{'is-playing': is_playing}"
    :title='is_playing ? $t("audio_player.pause") : $t("audio_player.play")'
    :aria-label='is_playing ? $t("audio_player.pause") : $t("audio_player.play")'
    @click.prevent='playToggle'
  >
    <IconPause class='pause-icon' />
    <IconPlay class='play-icon' />
  </button>

  <!-- Forward -->
  <button
    v-if='player'
    class='audio-player_forward'
    :title='$t("audio_player.forward")'
    :aria-label='$t("audio_player.forward")'
    @click.prevent='seekForward'
  >
    <IconForward />
  </button>

<div class='audio-player_timing'>
  <!-- Timing Current -->
  <time class='audio-player_timing-current copy-four'>
    {{ timing_current }}
  </time>

  <!-- Timeline -->
  <div class='audio-player_timeline-container'>
    <div class='audio-player_buffer'>
      <div
        class='audio-player_buffer-progress'
        :style="{width: loading_progress + '%'}"
      />
    </div>

    <vue-slider
      v-if='duration'
      ref='timeline'
      v-model='current_time'
      class='audio-player_timeline'
      tabindex='0'
      role='slider'
      :aria-label="timing_current"
      :aria-valuemin="0"
      :aria-valuemax="Math.round(duration)"
      :aria-valuenow="current_time"
      :aria-valuetext="timing_current"
      :height="10"
      :width="'auto'"
      :max="Math.round(duration)"
      :interval="1"
      :drag-on-click="true"
      :tooltip="'hover'"
      :duration="0"
      :tooltipFormatter="timing_current"
      :lazy="true"
      :contained="false"
      :dot-size="16"
      :use-keyboard="false"
      @change="_seek"
    />
  </div>

  <!-- Timing Total -->
  <time class='audio-player_timing-total copy-four'>
    {{ timing_total }}
  </time>
</div>

  <div class='audio-player_right-controls'>
    <!-- Volume -->
    <div
      class='audio-player_volume'
      :class="{
        'audio-player_volume--muted': is_muted,
        'audio-player_volume--hi': volume > 80,
        'audio-player_volume--mid': volume > 50,
        'audio-player_volume--low': volume > 0
      }"
      @mouseover="is_volume_control_visible = true"
      @mouseleave="is_volume_control_visible = false"
      @focusin="is_volume_control_visible = true"
      @focusout="is_volume_control_visible = false"
    >
      <button
        class='audio-player_volume-button'
        :title="$t('audio_player.volume') + ': ' + volume"
        :aria-label="$t('audio_player.volume') + ': ' + volume + ', ' + $t('audio_player.volume_a11')"
        @click.prevent="toggleMute()"
      >
        <IconVolume />
      </button>

      <transition name='fast-fade'>
        <div
          v-if="is_volume_control_visible"
          class='audio-player_popup audio-player_popup--volume'
        >
          <vue-slider
            ref='volume_control'
            v-model="volume"
            class='audio-player_volume-control'
            :width="10"
            :height="140"
            :direction="'btt'"
            :drag-on-click="true"
            :tooltip="'hover'"
            :duration=".2"
            :contained="true"
            :use-keyboard="false"
            @change="setVolume"
          />
        </div>
      </transition>
    </div>

    <!-- Playlist -->
    <div class='audio-player_playlist'>
      <button
        class='audio-player_playlist-toggle'
        :title='$t("audio_player.open_playlist")'
        :aria-label='$t("audio_player.open_playlist")'
        :aria-expanded='is_playlist_visible || "false"'
        aria-controls='audio-player_playlist-popup'
        aria-haspopup='true'
        @click.prevent="togglePlaylist"
      >
        <IconPlaylist />
      </button>

      <transition name='fast-fade'>
        <div
          v-if="is_playlist_visible"
          id='audio-player_playlist-popup'
          class='audio-player_popup audio-player_popup--playlist copy-four'
        >
          <div class='audio-player_playlist-header'>
            <h2 class='copy-four'>
              <router-link
                :to="{ name: 'audio-overview', params: {identifier: playlist.identifier}}"
                class='audio-player_playlist-title'
                @click='playlistHeaderClick'
              >
                {{ playlist.title }}
              </router-link>
            </h2>

            <router-link
              :to="{ name: 'audio-overview', params: {identifier: playlist.identifier}}"
              class='audio-player_playlist-link'
              @click='playlistHeaderClick'
            >
              {{ $t('learn_more') }}
            </router-link>
          </div>

          <ul class='audio-player_playlist-list'>
            <li
              v-for='(playlist_item, index) in playlist.episodes'
              :key='index'
              class='playlist-item'
              :class="{
                'is-active': isEpisodeActive(index)
              }"
            >
              <!-- TODO Decide if overlay button is needed here
              <button
                class="playlist-item_overlay"
                @click.prevent='playToggle'
                title='Play/Pause'
                :aria-label='playlist_item.title'
              ></button> -->
              <div
                class='playlist-item_cover'
                :style="{backgroundImage: 'url('+ playlist_item.image + ')'}"
              />

              <div class='playlist-item_info'>
                <div class='playlist-item_subtitle'>
                  <span
                    v-if="playlist_item.published_date"
                    class='playlist-item_date'
                  >
                    {{ _format_date(playlist_item.published_date) }}
                  </span>

                  <span
                    v-if="playlist_item.duration"
                    class='playlist-item_duration'
                  >
                    {{ playlist_item.duration }}
                  </span>
                </div>

                <h3 class='playlist-item_title copy-four'>
                  {{ playlist_item.title }}
                </h3>
              </div>

              <div class="playlist-item_buttons">
                <button
                  v-if='player'
                  class='audio-player_play'
                  :class="{'is-playing': isEpisodePlaying(index)}"
                  :title='is_playing ? $t("audio_player.pause") : $t("audio_player.play")'
                  :aria-label='is_playing ? $t("audio_player.pause") : $t("audio_player.play")'
                  :aria-current='isEpisodeActive(index) && isEpisodePlaying(index) ? true : null'
                  @click.prevent='playEpisode({
                    audio: playlist,
                    episode: index
                  })'
                >
                  <IconPause class='pause-icon' />
                  <IconPlay class='play-icon' />
                </button>
              </div>
            </li>
          </ul>
        </div>
      </transition>
    </div>

    <!-- Settings -->
    <div class='audio-player_settings'>
      <button
        class='audio-player_settings-toggle'
        :title='$t("audio_player.settings")'
        :aria-label='$t("audio_player.settings")'
        aria-controls='audio-player_settings-popup'
        :aria-expanded='is_settings_visible || "false"'
        @click.prevent='toggleSettings'
      >
        <IconGear />
      </button>

      <transition name='fast-fade'>
        <div
          v-if="is_settings_visible"
          id='audio-player_settings-popup'
          class='audio-player_popup audio-player_popup--settings'
        >
          <ul
            class='settings-menu'
            role='menu'
          >
            <li
              class='settings-menu_item'
              role='none'
            >
              <button
                class='settings-menu_link copy-three'
                role='menuitem'
                :class="{'active': is_speed_control_visible}"
                aria-controls='audio-player_speed-menu'
                aria-haspopup='menu'
                :aria-expanded="is_speed_control_visible || 'false'"
                :aria-label="speed + 'x. ' + $t('audio_player.change_speed_a11')"
                @click.prevent='is_speed_control_visible = !is_speed_control_visible'
              >
                <IconArrow
                  v-if='is_speed_control_visible'
                  class='icon icon-prefix'
                />

                {{ $t("audio_player.change_speed") }} <span class='current-speed'>{{ speed }}x</span>
                <IconArrow
                  v-if='!is_speed_control_visible'
                  class='icon icon-postfix'
                />
              </button>

              <ul
                v-if='is_speed_control_visible'
                id='audio-player_speed-menu'
                class="settings-menu settings-submenu"
                role='menu'
                @click.prevent='is_settings_visible=is_speed_control_visible=false'
              >
                <li
                  v-for='rate in speed_rates'
                  :key='rate'
                  class='settings-submenu_item'
                  role='none'
                >
                  <button
                    class='settings-menu_link copy-three'
                    role='menuitemradio'
                    :aria-checked='speed === rate'
                    :class="{'active': speed === rate}"
                    :aria-current="speed === rate || null"
                    @click.prevent='setRate(rate)'
                  >
                    {{ rate }}x
                  </button>
                </li>
              </ul>
            </li>

            <li
              v-if='!is_speed_control_visible'
              role='none'
              class='settings-menu_item'
            >
              <button
                role='menuitem'
                class='settings-menu_link settings-menu_link--close copy-three'
                :aria-label='$t("audio_player.close")'
                @click.prevent="closePlayer"
              >
                {{ $t("audio_player.close") }}
              </button>
            </li>
          </ul>
        </div>
      </transition>
    </div>

    <!-- Fullscreen -->
    <div class='audio-player_fullscreen'>
      <button
        ref='fullscreen'
        class='audio-player_fullscreen-toggle'
        title='Open Fullscreen'
        aria-label='Open Fullscreen'
        @click.prevent='toggleFullscreen'
      >
        <IconFull
          v-if='!is_mobile_fullscreen'
        />

        <IconFullOff
          v-if='is_mobile_fullscreen'
        />
      </button>
    </div>
  </div>

  <template v-if="fullscreen_mode">
    <transition name='fast-fade'>
    <FullscreenDesktop
      :_seek='_seek'
      :close='toggleFullscreen'
      :cover='current_playlist_item.image'
      :current_time='current_time'
      :description='current_playlist_item.description'
      :duration='duration'
      :is_muted='is_muted'
      :is_playing='is_playing'
      :isEpisodeActive='isEpisodeActive'
      :isEpisodePlaying='isEpisodePlaying'
      :label='playlist.title'
      :loading_progress='loading_progress'
      :playEpisode='playEpisode'
      :player='player'
      :playlist='playlist'
      :playToggle='playToggle'
      :setRate='setRate'
      :setVolume='setVolume'
      :speed_rates='speed_rates'
      :speed='speed'
      :timing_current='timing_current'
      :timing_total='timing_total'
      :title='current_playlist_item.title'
      :toggleMute='toggleMute'
      :transcript_url='transcript_url'
      :volume='volume'
    />
    </transition>
  </template>
</div>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapState, mapActions } from 'pinia'

/* global Howl Howler */
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { format, parseISO } from 'date-fns'
import FullscreenDesktop from './FullscreenDesktop'
import AudioPlayerMixin from './AudioPlayerMixin.js'
import AudioInfo from './AudioPlayerPopupInfo'

import { toRaw } from 'vue'

export default {
  mixins: [AudioPlayerMixin],

  props: ['is_playing', 'playlist', 'episode'],

  watch: {
    'is_playing'(value) {
      if (value) {
        if (this.sound_id) {
          this.player.play(this.sound_id)
        } else {
          this.sound_id = this.player.play()
        }
      } else {
        this.player.pause(this.sound_id)
      }

      setTimeout(() => {
        $('.audio-player_timeline .vue-slider-dot').removeAttr('tabindex')
      }, 200)
    },

    'episode'(value) {
      // re-init when source has changed
      this._reloadPlayer()
    },

    'playlist'(value) {
      // re-init when playlist has changed
      this._reloadPlayer()
    },

    'is_mobile_fullscreen'(value) {
      if (value) {
        $('body').addClass('popup-opened')
      } else {
        $('body').removeClass('popup-opened')
      }
    },

    'is_volume_control_visible'(value) {
      if (value) {
        setTimeout(() => {
          $('.audio-player_volume-control .vue-slider-dot').removeAttr('tabindex')
        }, 200)
      }
    }
  },

  components: {
    AudioInfo,
    VueSlider,
    FullscreenDesktop
  },

  computed: {
    ...mapState(useHomeStore, [
      'currentAudio',
      'currentEpisodeIndex',
      'isAudioPlaying'
    ]),

    current_playlist_item() {
      return this.playlist.episodes[this.episode]
    },

    cover_image() {
      return `background-image: url(${this.current_playlist_item.image})`
    },

    audio_playlist_title() {
      return this.playlist.title
    },

    transcript_url() {
      return this.current_playlist_item.links ? this.current_playlist_item.links.transcript : null
    },

    hasPreviousEpisode() {
      return this.episode - 1 >= 0
    },

    hasNextEpisode() {
      return this.episode + 1 < this.playlist.episodes.length
    }
  },

  methods: {
    ...mapActions(useHomeStore, [
      'playEpisode',
      'toggleAudioPlayback',
      'startPlayback',
      'pausePlayback',
      'sendStopAnalytics',
      'updateCurrentAudioStats',
      'resetPlayer'
    ]),

    isEpisodePlaying(episodeIndex) {
      if (this.currentAudio.identifier === this.playlist.identifier &&
        this.currentEpisodeIndex === episodeIndex &&
        this.isAudioPlaying) {
        return true
      }
      return false
    },

    isEpisodeActive(episodeIndex) {
      if (this.currentAudio.identifier === this.playlist.identifier &&
        this.currentEpisodeIndex === episodeIndex) {
        return true
      }
      return false
    },

    _initPlayer() {
      // init new instance
      this.player = new Howl({
        src: [this.current_playlist_item.audio.url],
        html5: true,         // required to keep a sound pitch when the rate/speed changes
        preload: this.is_firefox || 'metadata', // will load only file meta information, not the file itself
        volume: this.volume / 100,
        mute: this.is_muted,
        rate: this.speed,
        onload: () => {
          this.duration = this.player.duration()
          this.updateCurrentAudioStats({ duration: this.duration })

          // set a listener on window close
          addEventListener('beforeunload', this.beforeUnloadListener, { capture: true })

          this.current_time = 0
          this.loading_progress = 0

          if (this.is_playing) {
            this.sound_id = this.player.play()
          }

          // track loading progress
          const node = this.player._sounds[0]._node
          node.addEventListener('progress', () => {
            // https://developer.mozilla.org/en-US/Apps/Fundamentals/Audio_and_video_delivery/buffering_seeking_time_ranges#Creating_our_own_Buffering_Feedback
            if (this.duration > 0) {
              for (let i = 0; i < node.buffered.length; i++) {
                if (node.buffered.start(node.buffered.length - 1 - i) < node.currentTime) {
                  this.loading_progress = (node.buffered.end(node.buffered.length - 1 - i) / this.duration) * 100
                  break
                }
              }
            }
          })

          this._setNotifications()
        },
        onplay: (id) => {
          if (Howler._howls.length > 1) { // stop all other Howler players
            Howler._howls.forEach(other_player => {
              if (other_player.playing() && other_player !== toRaw(this.player)) {
                other_player.pause()
              }
            })
          }

          requestAnimationFrame(this._step.bind(this))

          if ('mediaSession' in navigator && navigator.mediaSession.metadata.title !== this.title_caption) {
            // update metadata only if the audio is different
            this._setNotifications()
          }
        },
        onstop: (id) => {
        },
        onpause: (id) => {
          this.pausePlayback() // update storage in case Howler was stopped externally
        },
        onend: (id) => {
          this.playNextEpisode()
          if (!this.hasNextEpisode) {
            this.sendStopAnalytics()
          }
        },
        onseek: (id) => {
          requestAnimationFrame(this._step.bind(this))
        }
      })
    },

    _reloadPlayer() {
      if (this.player) {
        this.player.unload() // unload current Howl instance
        this._initPlayer()   // and create a new one
      }
    },

    //
    // UI Events
    //
    playToggle() {
      this.toggleAudioPlayback()
    },

    playPreviousEpisode() {
      if (this.hasPreviousEpisode) {
        this.playEpisode({
          audio: this.playlist,
          episode: this.episode - 1
        })
      }
    },

    playNextEpisode() {
      if (this.hasNextEpisode) {
        this.playEpisode({
          audio: this.playlist,
          episode: this.episode + 1
        })
      }
    },

    playlistHeaderClick() {
      this.fullscreen_mode = false
      this.is_mobile_fullscreen = false
    },

    toggleFullscreen() {
      if (window.innerWidth <= 640 || window.innerHeight < 698) {
        this.is_mobile_fullscreen = !this.is_mobile_fullscreen
      } else {
        this.fullscreen_mode = !this.fullscreen_mode

        if (this.fullscreen_mode === false) {
          this.$refs.fullscreen.focus()
        }
      }
    },

    toggleMobileFullscreen() {
      if ((window.innerWidth <= 640 || window.innerHeight < 698) && !this.is_mobile_fullscreen) {
        this.is_mobile_fullscreen = !this.is_mobile_fullscreen
      }
    },

    togglePlaylist() {
      this.is_playlist_visible = !this.is_playlist_visible

      if (this.is_playlist_visible) {
        document.body.addEventListener('click', this.playlistListener)
      } else {
        document.body.removeEventListener('click', this.playlistListener)
      }
    },

    toggleSettings() {
      this.is_settings_visible = !this.is_settings_visible

      if (this.is_settings_visible) {
        document.body.addEventListener('click', this.settingsListener)
      } else {
        document.body.removeEventListener('click', this.settingsListener)
      }
    },

    closePlayer() {
      this.pausePlayback()
      this.sendStopAnalytics()
      // Give the player some time to stop, otherwise unload() throws an error
      setTimeout(() => {
        this.resetPlayer()
      }, 50
      )
      removeEventListener('beforeunload', this.beforeUnloadListener, { capture: true })
      $('body').removeClass('popup-opened')
    },

    //
    // Utility methods
    //

    playlistListener(e) {
      const playlist_class = '.audio-player_playlist'
      if (e && $(e.target).parents(playlist_class).length) {
        return false
      } else {
        this.is_playlist_visible = false
        document.body.removeEventListener('click', this.playlistListener)
      }
    },

    settingsListener(e) {
      const settings_class = '.audio-player_settings'
      if (e && $(e.target).parents(settings_class).length) {
        return false
      } else {
        this.is_settings_visible = false
        this.is_speed_control_visible = false
        document.body.removeEventListener('click', this.settingsListener)
      }
    },

    beforeUnloadListener(e) {
      this.sendStopAnalytics()
    },

    _format_date(date) {
      return format(parseISO(date), 'MMMM do, yyyy')
    }

  }
}
</script>

<style lang='sass' scoped>
  @import './AudioPlayer'
</style>
