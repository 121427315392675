<template>
  <div class='saml-auth'>
    <p
      v-if='description_visible'
      class='saml-auth_description copy-four'
    >
      {{ $t('saml_description') }}
    </p>

    <a
      v-for='(provider, index) in auth_data.identity_providers'
      :key='index'
      :href='provider.target_url'
      class='saml-auth_link action-two'
      @click='authGatewayClick($event)'
    >
      {{ provider.description }}
    </a>
  </div>
</template>

<script>

export default {
  props: ['auth_data', 'redirect_to_location'],

  computed: {
    description_visible() {
      return this.configurationValue('log_in_saml_description_visible')
    }
  },

  methods: {
    authGatewayClick(event) {
      event.preventDefault()

      let new_href = event.target.href
      if (this.redirect_to_location) {
        new_href = new_href.concat(`&redirect_to=${encodeURIComponent(this.redirect_to_location)}`)
      }

      window.location = new_href
    },

    configurationValue(key) {
      return this.$root.configuration &&
             this.$root.configuration.hasOwnProperty(key) &&
             this.$root.configuration[key]
    }
  }
}

</script>

<style lang='sass' scoped>
  @import './SamlAuth'
</style>
