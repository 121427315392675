<template>
<div class='catalog-navigation'>
  <div class='catalog-navigation_inner'>
    <h1 class='catalog-navigation_title heading-three'>
      {{ $t('catalog') }}
    </h1>

    <div class='catalog-navigation_links'>
      <template v-for='(tab, index) in tabs'>
        <router-link
          v-if="showLinkType(tab.type)"
          :key="index"
          :to="{ name: tab.name }"
          class='main-nav-link'
          active-class='active'
        >
          {{ tab.text }}
        </router-link>
      </template>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [{
        name: 'courses',
        type: 'courses',
        text: this.$t('courses')
      }, {
        name: 'audio-series',
        type: 'audio_series',
        text: this.$t('audio_series.podcasts')
      }, {
        name: 'learning-plans',
        type: 'learning_plans',
        text: this.$t('learning_plans')
      }]
    }
  },

  methods: {
    showLinkType(type) {
      return this.configurationValue(`header_menu_${type}_visible`, true)
    },

    configurationValue(key, fallback) {
      return this.$root.configuration && this.$root.configuration.hasOwnProperty(key) ? this.$root.configuration[key] : fallback
    }
  }

}
</script>

<style lang='sass' scoped>
  @import './CatalogNavigation'
</style>
