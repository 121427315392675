import apiClient from 'api'
import axios from 'axios'

apiClient.axios = axios

function _csrfToken() {
  return document.querySelector('meta[name=csrf-token]').content
}

export function signIn(data, cb) {
  axios.defaults.headers.common['X-CSRF-Token'] = _csrfToken()

  axios.post(data.path, {
    email: data.email,
    password: data.password
  })
    .then(function(response) {
      cb(response)
    })
    .catch(function(error) {
      cb(error.response)
    })
}

export function createUser(url, subdomain, user_data, cb) {
  apiClient.setUrl(url)
  apiClient.setSubdomain(subdomain)

  apiClient.create('user', user_data)
    .then((data) => {
      cb(data)
    })
    .catch((error) => {
      cb({ error })
    })
}

export function sendEmailRequest(data, cb) {
  axios.defaults.headers.common['X-CSRF-Token'] = _csrfToken()

  axios.post(data.path, {
    email: data.email
  })
    .then((response) => {
      cb(response)
    })
    .catch((error) => {
      cb(error.response)
    })
}

export function resetPassword(data, cb) {
  axios.defaults.headers.common['X-CSRF-Token'] = _csrfToken()

  axios.post(data.path, {
    password: data.password,
    password_confirmation: data.password_confirmation,
    otp: data.otp
  })
    .then((response) => {
      cb(response)
    })
    .catch((error) => {
      cb(error.response)
    })
}
