<template>
  <div class='search-results'>
    <!-- Courses -->
    <div
      v-if='results.courses && results.courses.length'
      class='search-results_section'
      rel='courses'
    >
      <div class='search-results_section-title'>
        {{ $t('courses') }}
      </div>

      <div class='search-results_courses-list'>
        <CourseCard
          v-for='(course, index) in results.courses'
          :key='index'
          :course='course'
        />
      </div>
    </div>
    <!-- End Courses -->

    <!-- Instructors -->
    <div
      v-if='results.instructors && results.instructors.length'
      rel='instructors'
      class='search-results_section'
    >
      <div class='search-results_section-title'>
        {{ $t('instructors') }}
      </div>

      <div class='search-results_instructors-list'>
        <InstructorCard
          v-for='(instructor, index) in results.instructors'
          :key='index'
          :instructor='instructor'
        />
      </div>
    </div>
    <!-- End Instructors -->

    <!-- Lessons -->
    <div
      v-if='results.lessons && results.lessons.length'
      class='search-results_section'
      rel='lessons'
    >
      <div class='search-results_section-title'>
        {{ $t('lessons') }}
      </div>

      <div class='search-results_lessons-list'>
        <LessonCardSmall
          v-for='(lesson, index) in results.lessons'
          :key='index'
          :lesson='lesson'
        />
      </div>
    </div>
    <!-- End Lessons -->

    <!-- Resources -->
    <div
      v-if='results.resources && results.resources.length'
      class='search-results_section'
      rel='resources'
    >
      <div class='search-results_section-title'>
        {{ $t('resources') }}
      </div>

      <div class='search-results_resources-list'>
        <ResourceCard
          v-for='(resource, index) in results.resources'
          :key='index'
          :resource='resource'
          modifier='search'
        />
      </div>
    </div>
    <!-- End Resources -->

    <!-- Benefits -->
    <div
      v-if='results.program_drivers && results.program_drivers.length'
      class='search-results_section'
      rel='program_drivers'
    >
      <div class='search-results_section-title'>
        {{ $t('related_benefits') }}
      </div>

      <div class='search-results_drivers-list'>
        <DriverCard
          v-for='(driver, index) in results.program_drivers'
          :key='index'
          :driver='driver'
        />
      </div>
    </div>
    <!-- End Benefits -->
  </div>
</template>

<script>
import LessonCardSmall from '../LessonCardSmall/LessonCardSmall'
import CourseCard from '../CourseCard/CourseCard'
import InstructorCard from '../InstructorCard/InstructorCard'
import ResourceCard from 'home/components/shared/ResourceCardSmall/ResourceCardSmall'
import DriverCard from '../ProgramDriverCard/ProgramDriverCard'

export default {
  components: {
    LessonCardSmall,
    CourseCard,
    InstructorCard,
    ResourceCard,
    DriverCard
  },

  props: ['results']
}
</script>

<style lang='sass' scoped>
  @import './Results'
</style>
