<template>
<div class='self-assessment-results'>
  <!-- Hero Section -->
  <div
    class='self-assessment-results_hero'
    :style='background_style'
    :class='hero_class'
  >
    <div class='self-assessment-results_hero-inner'>
      <div class='self-assessment-results_hero-info'>
        <h2 class='self-assessment-results_hero-headline heading-two'>
          {{ hero_headline }}
        </h2>

        <div
          class='self-assessment-results_hero-description copy-one'
          v-html='hero_description'
        />

        <div class='self-assessment-results_hero-cta-container'>
          <template v-if='show_hero_keep_learning'>
            <slot />
          </template>

          <a
            v-if='has_hero_cta'
            class='self-assessment-results_hero-cta action-one-branded'
            :href='hero_cta_href'
            :target='hero_cta_target'
            @click='ctaLinkClick($event, hero_cta_href)'
          >
            {{ hero_cta }}
          </a>
        </div>
      </div>

      <HeroCard
        v-if='has_card && card_loaded'
        :card='card_object'
      />
    </div>
  </div>

  <!-- Results -->
  <div
    v-if='has_results_content && !hide_outcomes'
    class='self-assessment-results_content'
  >
    <!-- Results Heading -->
    <div
      v-if='activity.results.secondary_headline || activity.results.secondary_content'
      class='self-assessment-results_content-secondary'
    >
      <div
        v-if='activity.results.secondary_headline'
        class='self-assessment-results_secondary-headline copy-one'
      >
        {{ activity.results.secondary_headline }}
      </div>

      <div
        v-if='activity.results.secondary_content'
        class='self-assessment-results_secondary-content copy-two'
        v-html='activity.results.secondary_content'
      />
    </div>

    <!-- Outcomes List -->
    <div
      v-for='(outcome, index) in outcomes_list'
      :key='index'
      class='self-assessment-results_outcome'
    >
      <h3 class='self-assessment-results_outcome-title heading-four'>
        {{ outcome.name }}
      </h3>

      <div
        v-if='activity.results.show_scores'
        class='self-assessment-results_outcome-score copy-two'
      >
        {{ earnedScore(outcome.earned) }} {{ $t('out_of') }} {{ outcome.total }}
      </div>

      <div
        class='self-assessment-results_outcome-description copy-two'
        v-html='outcome.outcome.feedback'
      />

      <a
        v-if='outcome.outcome.link.href'
        :href='outcome.outcome.link.href'
        target='_blank'
        class='self-assessment-results_outcome-cta copy-two'
      >
        {{ outcome.outcome.link.title }}
      </a>
    </div>
    <!-- Outcomes List End -->

    <div
      v-if='show_keep_learning'
      class='self-assessment-results_bottom'
    >
      <slot />
    </div>
  </div>
</div>
</template>

<script>
import HeroCard from './SelfAssessmentV2HeroCard'

import { useHomeStore } from 'home/stores/home'
import { mapState } from 'pinia'

export default {

  props: [
    'activity',
    'outcomes_list',
    'hero_outcome',
    'api_client',
    'toggleAccountOverlay'
  ],

  data() {
    return {
      card_object: { image: null, title: null, subtitle: null, badging_enabled: null },
      card_loaded: false
    }
  },

  components: {
    HeroCard
  },

  watch: {
    'hero_outcome.outcome': function(value) {
      if (value && (value.card || value.outcome_image) && !this.card_loaded) {
        this._getCardObject()
      }

      if (value.hide_outcomes) {
        this.$parent.hide_results_content = true
      }
    }
  },

  mounted() {
    // Load LP
    if (this.hero_outcome.outcome &&
        (this.hero_outcome.outcome.card || this.hero_outcome.outcome.outcome_image) &&
        !this.card_loaded
    ) {
      this._getCardObject()
    }

    if (this.hero_outcome.outcome && this.hero_outcome.outcome.hide_outcomes) {
      this.$parent.hide_results_content = true
    }
  },

  computed: {
    ...mapState(useHomeStore, [
      'modalAuthEnabled'
    ]),

    background_style() {
      // image
      if (this.activity.background_image) return `background-image: url(${this.activity.background_image})`
      // gradient
      if (this.activity.gradient_start) {
        const start = this.activity.gradient_start
        const end = this.activity.gradient_end || this.activity.gradient_start
        const gradient = `linear-gradient(137deg, ${start} 0%, ${end} 92%)`
        return 'background-image: ' +  gradient
      }
      return null
    },

    has_card() {
      return this.hero_outcome &&
        (this.hero_outcome.outcome.card || this.hero_outcome.outcome.outcome_image)
    },

    has_hero_outcome() {
      return !!this.hero_outcome
    },

    hero_class() {
      const classes = []
      if (this.has_card) classes.push('has-card')

      return classes
    },

    has_results_content() {
      return (this.activity.results.secondary_headline || this.activity.results.secondary_content) ||
        this.outcomes_list.length
    },

    has_hero_cta() {
      return (this.activity.results.cta_href) ||
          (this.has_hero_outcome && this.hero_outcome.outcome.link.href)
    },

    hero_cta() {
      if (this.activity.results.cta_href || this.activity.results.cta) {
        return this.activity.results.cta
      } else if (this.has_hero_outcome && this.hero_outcome.outcome.link.title) {
        return this.hero_outcome.outcome.link.title
      }
      return null
    },

    hero_cta_href() {
      if (this.activity.results.cta_href) {
        return this.activity.results.cta_href
      } else if (this.has_hero_outcome &&
        this.hero_outcome.outcome &&
        this.hero_outcome.outcome.link.href
      ) {
        return this.hero_outcome.outcome.link.href
      }
      return null
    },

    hero_cta_target() {
      return (this.activity.results.cta_href &&
          !this.modalAuthEnabled &&
          this.isSignUpLink(this.activity.results.cta_href))
        ? ''
        : '_blank'
    },

    hero_headline() {
      if (this.has_hero_outcome && this.hero_outcome.outcome.hero_headline) {
        return this.hero_outcome.outcome.hero_headline
      }
      return this.activity.results.headline
    },

    hero_description() {
      if (this.has_hero_outcome && this.hero_outcome.outcome.hero_description) {
        return this.hero_outcome.outcome.hero_description
      }
      return this.activity.results.content
    },

    hide_outcomes() {
      if (this.has_hero_outcome && this.hero_outcome.outcome.hide_outcomes) {
        return this.hero_outcome.outcome.hide_outcomes
      } else return false
    },

    show_keep_learning() {
      // return !!(Object.keys(this.$slots).length && this.$slots.default[0])
      return this.$slots.default && this.$slots.default()
    },

    show_hero_keep_learning() {
      return this.show_keep_learning &&
        !this.has_results_content
    }
  },

  methods: {
    earnedScore(score) {
      return score >= 0 ? score : 0
    },

    _getCardObject() {
      if (!this.hero_outcome) return false
      if (this.hero_outcome.outcome.outcome_image) {
        this.card_object.image = this.hero_outcome.outcome.outcome_image
        this.card_object.link = this.hero_outcome.outcome.link.href
        this.card_loaded = true
      } else {
        this.api_client.find(
          this.hero_outcome.outcome.card.type,
          this.hero_outcome.outcome.card.identifier
        ).then((data) => {
          this.card_loaded = true

          if (data.data) {
            this.card_object.title = data.data.title
            this.card_object.link = this.hero_outcome.outcome.link.href
            this.card_object.image = data.data.card_image
            this.card_object.subtitle = data.data.card_intro ? data.data.card_intro : null
            this.card_object.badging_enabled =
              data.data.badging_enabled
                ? data.data.badging_enabled
                : false
          }
        })
      }
    },

    ctaLinkClick(event, path) {
      if (this.modalAuthEnabled && this.isSignUpLink(path)) {
        event.preventDefault()

        if (typeof (this.toggleAccountOverlay) === 'function') {
          this.$parent.toggleOverlay()

          setTimeout(() => {
            this.toggleAccountOverlay({
              context: 'auto',
              redirect: window.location.href
            })
          }, 1)
        }
      }
    },

    isSignUpLink(path) {
      return path ? path.match(/^\/account\/sign-up/) : false
    }
  }
}
</script>

<style lang='sass'>
  @import 'Results'
</style>
