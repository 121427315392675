<template>
  <div class='quiz-pagination_wrapper'>
    <div
      class='quiz-pagination'
      role='region'
      aria-label='Quiz Progress'
    >
      <template
        v-for='(question, index) in questions'
        :key='index'
      >
        <span
          class='quiz-pagination_link body-two'
          :class='activeClass(index)'
          :aria-current="ariaCurrentCheck(index)"
        >
          <span class="ada-hidden">Question</span> {{ index + 1 }}
        </span>
      </template>
    </div>

    <div class='quiz-pagination_mobile'>
      <span class="quiz-pagination_current">{{ question_index + 1 }}</span>
      <span class="quiz-pagination_of">of</span>
      <span class="quiz-pagination_last">{{ questions.length }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'questions',
    'question_index'
  ],

  methods: {
    activeClass(index) {
      return index === this.question_index ? 'active' : false
    },

    ariaCurrentCheck(index) {
      return index === this.question_index
        ? true
        : null
    }
  }
}
</script>

<style lang="sass" scoped>
//@import 'lesson/styles/components/quiz/quiz-pagination'
</style>
