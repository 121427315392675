// Slide model based on API::V1::SlideActivityResultSerializer
export default {

  // Relationships
  activity: {
    jsonApi: 'hasOne',
    type: 'activities'
  },

  slide: {
    jsonApi: 'hasOne',
    type: 'slides'
  },

  user: {
    jsonApi: 'hasOne',
    type: 'user'
  },

  // Attributes
  status: null,
  event: null,
  results: {},
  files: [],
  updated_at: null
}
