// Audio Series Section model based on API::V1::Section::AudioSeriesSerializer
export default {
  // Relationships
  audio_series: {
    jsonApi: 'hasOne',
    type: 'audio_series'
  },

  // Images
  image: null,
  gradient_start: null,
  gradient_end: null,

  // Attributes
  headline: null,
  subheadline: null,
  position: 0,
  configuration: {},
  link: null
}
