<template>
<div
  ref='card-button'
  class='course-card course-card--audio-series'
  :class='main_class'
  @click.stop='cardClick(audio_series, $event)'
>
  <div
    class='course-card_image'
    :style='audio_series_image'
  />

  <div class='course-card_inner'>
    <div class='course-card_inner-wrapper'>
      <div
        v-if='audio_series.public'
        class='course-card_label copy-four'
      >
        {{ $t('audio_series.public_podcast') }}
      </div>

      <div class='course-card_title heading-five'>
        {{ audio_series.title }}
      </div>

      <div
        v-if='audio_series.instructor'
        class='course-card_attribution copy-three'
      >
        {{ $t('attribution.hosted_by', { value: audio_series.instructor.full_name }) }}
      </div>
    </div>

    <a
      href='#play'
      class='course-card_action action-two'
      @click.prevent='playToggle(audio_series, -1)'
    >
      {{ play_button_text }}
    </a>

    <button
      ref='more-button'
      class='course-card_button'
      @click.stop='selectAudioSeries(audio_series)'
    >
      {{ $t('more') }}
      <SelectCourseIcon />
    </button>
  </div>
</div>
</template>

<script>
import SelectCourseIcon from './down-arrow.svg'

import { useHomeStore } from 'home/stores/home'
import { mapState } from 'pinia'

export default {
  props: {
    audio_series: { type: Object, required: true },
    opened_audio_series: { type: String, required: false, default: null },
    is_selected: { type: Boolean, required: true },
    selectAudioSeries: { type: Function, required: true },
    playToggle: { type: Function, required: true }
  },

  components: {
    SelectCourseIcon
  },

  watch: {
    'is_selected'(value) {
      if (value) { return false }
      setTimeout(() => {
        if (this.opened_audio_series) { return false }
        if ($('html').attr('data-whatintent') === 'keyboard') {
          this.$refs['more-button'].focus()
        }
      }, 1)
    }
  },

  computed: {
    ...mapState(useHomeStore, [
      'currentAudio',
      'isAudioPlaying'
    ]),

    main_class() {
      const classes = []
      if (this.is_selected) classes.push('active')
      if (this.opened_audio_series && !this.is_selected) classes.push('opaque')

      return classes
    },

    audio_series_image() {
      return `background-image: url(${this.audio_series.card_image})`
    },

    play_button_text() {
      if (this.audio_series.identifier === this.currentAudio.identifier) {
        return this.isAudioPlaying
          ? this.$t('audio_player.pause')
          : this.$t('audio_player.resume')
      } else {
        return this.$t('audio_player.play')
      }
    }
  },

  methods: {
    cardClick(audio_series, event) {
      if (!event.target.className.match(/course-card_action/g) &&
          !event.target.className.match(/course-card_button/g)) {
        this.selectAudioSeries(audio_series)
      }
    }
  }

}
</script>

<style lang='sass' scoped>
  @import 'home/styles/shared/CourseCard'
</style>
