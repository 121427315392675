<template>
<div class='course-drawer_overview'>
  <!-- Desktop -->
  <div
    v-if='!is_mobile'
    class='course-drawer_overview-info'
  >
    <span
      v-if='rating'
      class='course-drawer_overview-rating'
    >
      {{ rating }}% {{ $t('liked_it') }}
    </span>

    <span
      v-if='trailer && rating'
      class='course-drawer_overview-divider'
    >
      |
    </span>

    <VideoTrailer
      v-if='trailer'
      :text='trailer_cta'
      :video='trailer'
    />

    <p class='course-drawer_overview-description copy-two'>
      {{ course.overview_intro }}
    </p>

    <!-- Progress -->
    <template v-if='enrolled'>
      <div class='course-drawer_overview-current-lesson heading-six'>
        {{ recent_lesson_title }}
      </div>

      <div class='course-drawer_overview-progress-bar'>
        <div
          class='course-drawer_overview-current-progress'
          :style="{width: '' + progress + '%'}"
        >
          <span class='ada-hidden'>Completed: {{ progress }}%</span>
        </div>
      </div>
    </template>

    <!-- Actions -->
    <div class='course-drawer_overview-actions'>
      <a
        v-if='enrolled && !course.completion_date'
        :href='`/courses/${course.identifier}/continue?skip_intro=true`'
        class='action-one course-drawer_overview-action course-drawer_overview-action--primary'
      >
        {{ $t('resume') }}
      </a>

      <a
        v-if='!enrolled'
        :href='`/courses/${course.identifier}/continue?skip_intro=true`'
        class='action-one course-drawer_overview-action course-drawer_overview-action--primary'
        @click='authGateway($event)'
      >
        {{ $t('begin') }}
      </a>
    </div>
  </div>
  <!-- End Desktop -->

  <!-- Mobile  -->
  <div
    v-if='is_mobile'
    class='course-drawer_overview-info course-drawer_overview-info--mobile'
  >
    <!-- Progress -->
    <template v-if='enrolled'>
      <div class='course-drawer_overview-current-lesson heading-six'>
        {{ recent_lesson_title }}
      </div>

      <div class='course-drawer_overview-progress-bar'>
        <div
          class='course-drawer_overview-current-progress'
          :style="{width: '' + progress + '%'}"
        >
          <span class='ada-hidden'>Completed: {{ progress }}%</span>
        </div>
      </div>
    </template>

    <!-- Actions -->
    <div class='course-drawer_overview-actions'>
      <a
        v-if='enrolled && !course.completion_date'
        :href='`/courses/${course.identifier}/continue?skip_intro=true`'
        class='action-one course-drawer_overview-action course-drawer_overview-action--primary'
      >
        {{ $t('resume') }}
      </a>

      <a
        v-if='!enrolled'
        :href='`/courses/${course.identifier}/continue?skip_intro=true`'
        class='action-one course-drawer_overview-action course-drawer_overview-action--primary'
        @click='authGateway($event)'
      >
        {{ $t('begin') }}
      </a>
    </div>

    <div
      v-if='course.lessons.length > 1'
      class='course-drawer_overview-label heading-six'
    >
      {{ $t('overview') }}
    </div>

    <span
      v-if='rating'
      class='course-drawer_overview-rating'
    >
      {{ rating }}% {{ $t('liked_it') }}
    </span>

    <span
      v-if='trailer && rating'
      class='course-drawer_overview-divider'
    >
      |
    </span>

    <VideoTrailer
      v-if='trailer'
      :text='trailer_cta'
      :video='trailer'
    />

    <p class='course-drawer_overview-description copy-two'>
      {{ course.overview_intro }}
    </p>
  </div>
  <!-- End Mobile  -->

  <template v-if="course.instructor">
    <router-link
      :to="{ name: 'instructor', params: {identifier: course.instructor.identifier} }"
      class='course-drawer_overview-instructor'
    >
      <Avatar :image='course.instructor.image' />

      <div class='course-drawer_overview-instructor-info'>
        <span class='course-drawer_overview-instructor-label copy-four'>
          {{ $t('instructor') }}
        </span>

        <div class='course-drawer_overview-instructor-name heading-six'>
          {{ course.instructor.full_name }}
        </div>

        <p class='course-drawer_overview-instructor-description copy-three'>
          {{ course.instructor.title }}
        </p>
      </div>
    </router-link>
  </template>
</div>
</template>

<script>
import Avatar from 'components/Avatar/UserAvatar'
import VideoTrailer from 'home/components/shared/VideoTrailer/VideoTrailer'

import { useHomeStore } from 'home/stores/home'
import { mapActions } from 'pinia'

export default {
  components: {
    Avatar,
    VideoTrailer
  },

  props: ['course', 'is_mobile'],

  computed: {
    progress() {
      return (this.course.recent_lesson && this.course.recent_lesson.progress) || 0
    },

    enrolled() {
      return this.course.enrolled
    },

    rating() {
      // return this.course.rating;
      return false
    },

    trailer() {
      return this.course.trailer
    },

    trailer_cta() {
      return this.course.trailer_cta || this.$t('watch_trailer')
    },

    recent_lesson_title() {
      if (this.course.recent_lesson) {
        return `${this.$t('lesson')} ${this.course.recent_lesson.position_within_context}: ${this.course.recent_lesson.title}`
      } else {
        return ''
      }
    }
  },

  methods: {
    ...mapActions(useHomeStore, ['authGateway'])
  }
}
</script>

<style lang='sass' scoped>
  @import 'home/styles/shared/CourseDrawerOverview'
</style>
