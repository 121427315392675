<template>
<div class='overview-page_badge'>
  <router-link
    v-if='image && identifier'
    :to="{ name: 'instructor', params: {identifier: identifier}}"
    class='overview-page_badge-image'
    :style="{backgroundImage: `url('${ image }')`}"
    :aria-label='aria_label'
  />

  <div
    v-else-if="image"
    class='overview-page_badge-image'
    :style="{backgroundImage: `url('${ image }')`}"
  />

  <div
    v-if='label'
    class='overview-page_badge-label copy-four'
  >
    {{ label }}
  </div>

  <router-link
    v-if='title && identifier'
    :to="{ name: 'instructor', params: {identifier: identifier}}"
    class='overview-page_badge-title copy-one'
  >
    {{ title }}
  </router-link>

  <div
    v-if='subtitle'
    class='overview-page_badge-subtitle copy-three'
  >
    {{ subtitle }}
  </div>

  <slot />
</div>
</template>

<script>
export default {
  props: [
    'label',
    'title',
    'subtitle',
    'image',
    'identifier'
  ],

  computed: {
    aria_label() {
      let ariaLabel = ''

      ariaLabel += this.label ? this.label : ''
      ariaLabel += this.title ? ' ' + this.title : ''

      return ariaLabel
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './Badge'
</style>
