<template>
  <div
    :class='lesson_status'
    class='learning-plan_course'
  >
    <div class='learning-plan_course-inner'>
      <div class='learning-plan_course-info'>
        <div
          :style='lesson_image'
          class='learning-plan_course-image'
        />

        <div class='learning-plan_course-content'>
          <span class='learning-plan_course-order copy-four'>
            {{ lesson_order }}
          </span>

          <h3 class='learning-plan_course-name copy-one'>
            {{ lesson.title }}
          </h3>

          <span class='learning-plan_course-status copy-four'>
            {{ lesson_status_text }}
          </span>
        </div>
      </div>

      <a
        v-if="lesson_status !== 'complete'"
        :href='lesson_path'
        :class='button_class'
        class='learning-plan_course-button action-two'
      >
        {{ button_text }}
      </a>

      <span
        v-else
        :class='button_class'
        class='learning-plan_course-checkmark'
      >
        <CheckmarkIcon />
        {{ button_text }}
      </span>
    </div>
  </div>
</template>

<script>
import CheckmarkIcon from './checkmark.svg'

export default {
  props: ['learning_plan', 'lesson', 'index'],

  data() {
    return {
      checkmarkImageUrl: null
    }
  },

  components: {
    CheckmarkIcon
  },

  computed: {
    lesson_image() {
      return `background-image: url(${this.lesson.featured_image})`
    },

    lesson_order() {
      return `${this.$tc('learning_plan_lesson', 1)} ${this.index + 1}`
    },

    lesson_status() {
      if (this.lesson.completion_date) {
        return 'complete'
      }

      if (this.lesson.retake) {
        return 'incomplete'
      }

      if (!this.lesson.enrolled) {
        return 'not-started'
      }

      if (this.lesson.enrolled && this.lesson.progress < 100) {
        return 'in-progress'
      }

      return null
    },

    lesson_status_text() {
      const lesson_status = this.lesson_status.replace(/-/g, '_')
      return this.$t(lesson_status)
    },

    lesson_path() {
      if (this.lesson_status !== 'complete') {
        const base_path = `/learning-plans/${this.learning_plan.identifier}/lessons/${this.lesson.identifier}`
        return `${base_path}/${this.lesson_status === 'incomplete' ? 'retake' : 'continue'}`
      } else {
        return ''
      }
    },

    button_text() {
      switch (this.lesson_status) {
        case 'not-started':
          return this.$t('begin')
        case 'complete':
          return this.$t('completed')
        case 'incomplete':
          return this.$t('retake')
        case 'in-progress':
          return this.$t('resume')
        default:
          return ''
      }
    },

    button_class() {
      return `button_${this.$i18n.locale}`
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './Lesson'
</style>
