<template>
<transition name='fast-fade'>
<div
  v-if='ready'
  ref='resource-overlay'
  class='resource-overlay'
  :class='main_class'
>
  <div
    v-if='!resource'
    class='resource-overlay_inner'
  >
    <button
      ref='close-button'
      class='resource-overlay_close'
      @click='closeResourse'
    >
      {{ $t('close') }}
    </button>

    <PageLoader class='page-loader--overlay' />
  </div>

  <!-- Generic Resource -->
  <transition
    name='fast-fade'
    mode='out-in'
  >
  <div
    v-if='resource && !is_article && !is_quote && !is_basic_content'
    class='resource-overlay_inner'
    :style='background_style'
  >
    <button
      ref='close-button'
      class='resource-overlay_close'
      @click='closeResourse'
      @blur='checkFocus'
    >
      {{ $t('close') }}
    </button>

    <div class='resource-overlay_type copy-three'>
      {{ $tc('resource', 1) }}
    </div>

    <aside
      v-if="is_audio"
      class='resource-overlay_aside'
    >
      <img
        class='resource-overlay_image'
        :src='resource.activity.card_image'
        alt=''
      >
    </aside>

    <main class='resource-overlay_main'>
      <div class='resource-overlay_headline heading-one'>
        {{ resource.headline }}
      </div>

      <div class='resource-overlay_subheadline heading-five'>
        {{ resource.activity.subheadline }}
      </div>

      <a
        v-if='resource.activity.link'
        ref='last-action'
        class='resource-overlay_link action-one-branded'
        :href='link_href'
        target="_blank"
        rel="noreferrer"
        @click.stop='resourceClick'
      >
        {{ resource.activity.link.title }}
      </a>

      <!-- Audio Resource -->
      <div
        v-if='is_audio'
        class='resource-overlay_audio-container'
      >
        <AudioPlayer
          :audio_title='resource.headline'
          :audio_playlist_title='$tc("resource", 2)'
          :audio_image='resource.activity.card_image'
          :audio_id='resource.activity.id'
          :file_url='resource.activity.provider_url'
        />
      </div>

      <!-- Video Resource -->
      <div
        v-if='is_video'
        class='resource-overlay_video-container'
      >
        <div
          ref='video'
          class='resource-overlay_video'
        />

        <div
          v-if='resource.activity.provider === "wistia"'
          ref='video-play'
          class='resource-overlay_video-play'
          @click='play'
        >
          <i />
        </div>
      </div>
    </main>
  </div>
  </transition>

  <!-- Article -->
  <transition
    name='fast-fade'
    mode='out-in'
  >
  <div
    v-if='resource && is_article'
    class="article-overlay"
  >
    <button
      ref='close-button'
      class='resource-overlay_close'
      @click='closeResourse'
    >
      {{ $t('close') }}
    </button>

    <div
      class='article-overlay_top'
      :style='background_style'
    >
      <div class='article-overlay_top_inner'>
        <span class='article-overlay_type copy-three'>
          {{ resource.activity.subheadline || $t('article') }}
        </span>

        <h2
          v-if='resource.headline'
          class='article-overlay_headline heading-one'
        >
          {{ resource.headline }}
        </h2>
      </div>
    </div>

    <div
      class='article-overlay_body'
      v-html='resource.activity.content'
    />

    <div class='article-overlay_bottom'>
      <button
        ref='last-action'
        class='article-overlay_keep action-one-branded'
        @click='closeResourse'
      >
        {{ $t('keep_learning') }}
      </button>
    </div>
  </div>
  </transition>

  <!-- Quote -->
  <transition
    name='fast-fade'
    mode='out-in'
  >
  <div
    v-if='resource && is_quote'
    class='resource-overlay_inner'
    :style='background_style'
  >
    <button
      ref='close-button'
      class='resource-overlay_close'
      @click='closeResourse'
      @blur='checkFocus'
    >
      {{ $t('close') }}
    </button>

    <div class='slide-fullscreen'>
      <blockquote class='slide-fullscreen_headline h-two-light'>
        {{ resource.activity.headline }}
      </blockquote>

      <div class='slide-fullscreen_subheadline body-four-light'>
        {{ resource.activity.subheadline }}
      </div>
    </div>
  </div>
  </transition>

  <!-- Basic Content Item -->
  <transition
    name='fast-fade'
    mode='out-in'
  >
  <div
    v-if='resource && is_basic_content'
    class='resource-overlay_inner'
    :style='background_style'
  >
    <button
      ref='close-button'
      class='resource-overlay_close'
      @click='closeResourse'
      @blur='checkFocus'
    >
      {{ $t('close') }}
    </button>

    <div
      class='slide-basic-content_body'
      v-html='resource.activity.content'
    />
  </div>
  </transition>

  <div
    v-if='resource && resource.course_info && resource.course_info.length'
    class='resource-overlay_course-announcement'
  >
    <FloatingCta
      :title='$t("resources_page.want_to_learn_more")'
      :subtitle='resource.course_info[0].title'
      link_name='courses'
      :link_identifier='resource.course_info[0].identifier'
      :click_callback='closeOverlay'
    />
  </div>

  <div
    class='resource-overlay_restricter'
    tabindex='0'
    @focus="setFocus('close-button')"
  />
</div>
</transition>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapActions } from 'pinia'

/* global Wistia */
import TbkVideo from 'tbk-utils/video/tbk-video'
// import CommonMixin from "lesson/components/slide/common-mixin.js"
import HypeDetectorMixin from 'components/activities/hype-detector-mixin'
import AudioPlayer from 'components/Shared/AudioPlayer/AudioPlayerInline'
import FloatingCta from 'components/Shared/FloatingCta/FloatingCta'
import PageLoader from 'components/PageLoader/PageLoader'

export default {
  props: ['resource', 'closeOverlay'],

  mixins: [HypeDetectorMixin],

  components: {
    AudioPlayer,
    FloatingCta,
    PageLoader
  },

  data() {
    return {
      ready: false,
      shift_is_pressed: false,
      keyUpCallback: (e) => this.detectKeyup(e),
      keyDownCallback: (e) => this.detectKeydown(e),
      lastFocusedSelector: null,

      video_width: null,
      video_height: null,
      aspect_ratio: null
    }
  },

  created() {
    this.ready = true
    $('body').addClass('popup-opened')
  },

  mounted() {
    // Store classes of the element that initiated the overlay
    let whatClasses = $('html').attr('data-whatclasses')
    let lastFocusedClasses = []
    if (whatClasses) {
      whatClasses = whatClasses.replace(',', ' ')
      lastFocusedClasses = whatClasses.split(' ')
    }

    if (lastFocusedClasses.length) {
      this.lastFocusedSelector = '.' + lastFocusedClasses.join('.')
    }

    this.setFocus('close-button')
    document.body.addEventListener('keyup', this.keyUpCallback)
    document.body.addEventListener('keydown', this.keyDownCallback)

    if (this.resource && this.is_video) {
      if (this.resource.activity.provider === 'embed') {
        this._loadAdditionalWistiaScriptsAndInit()
      } else {
        this.getEmbedCode()
      }
    }

    if (this.pausePlayback) {
      this.pausePlayback()   // pause the sticky player (if it plays)
    }
  },

  beforeUpdate() {
    // Required when overlay was opened before resource was loaded
    if (this.resource && this.is_video) {
      if (this.resource.activity.provider === 'embed') {
        this._loadAdditionalWistiaScriptsAndInit()
      } else {
        this.getEmbedCode()
      }
    }
  },

  beforeUnmount() {
    document.body.removeEventListener('keyup', this.keyUpCallback)
    document.body.removeEventListener('keydown', this.keyDownCallback)
    // some workarouds related to resource carousel (TBK-3708)
    if (this.lastFocusedSelector === '.resources-carousel_resource') {
      $('.part_next-slide').focus()
    } else {
      $(this.lastFocusedSelector).focus()
    }
    $('body').removeClass('popup-opened')

    if (this.is_basic_content) {
      this.hypeBecameHidden()
    }
  },

  watch: {
    resource(value) {
      if (value && this.is_basic_content) {
        setTimeout(() => {
          this.initHype()
          this.hypeBecameVisible()
        }, 500)
      }
    },

    video_height: function(val) {
      this._resizeVideoToAspectRatio()
    }
  },

  computed: {
    background_style() {
      // Background image
      if (this.resource.activity.background_image) {
        return `background-image: url(${this.resource.activity.background_image})`
      }

      // Gradient
      if (!this.resource.activity.background_image && this.resource.activity.gradient_start) {
        const start = this.resource.activity.gradient_start
        const end = this.resource.activity.gradient_end || this.resource.activity.gradient_start
        const gradient = `linear-gradient(137deg, ${start} 0%, ${end} 92%)`
        return 'background-image: ' +  gradient
      }
      return null
    },

    main_class() {
      const classes = []
      if (this.resource && this.is_video) classes.push(`video--${this.resource.activity.provider}`)

      return classes
    },

    is_article() {
      return this.resource.activity.type === 'activity_v1_articles'
    },

    is_audio() {
      return this.resource.activity.type === 'activity_v1_audios'
    },

    is_video() {
      return this.resource.activity.type === 'activity_v1_videos'
    },

    is_quote() {
      return this.resource.activity.type === 'activity_v1_fullscreens'
    },

    is_basic_content() {
      return this.resource.activity.type === 'activity_v1_basic_contents'
    },

    link_href() {
      const link = this.resource.activity.link.href
      if ( link.match(/pdf$/) || link.match(/docx/) ) {
        if (link.match(/^http:\/\/files.thebigknow.com/)) {
          return link.replace('http://files.thebigknow.com/', 'https://s3.amazonaws.com/files.thebigknow.com/')
        }
        if (link.match(/^http:\/\/s3.amazonaws.com\/files.thebigknow.com/)) {
          return link.replace('http://s3.amazonaws.com/files.thebigknow.com/', 'https://s3.amazonaws.com/files.thebigknow.com/')
        }
        return link
      } else {
        return link
      }
    }
  },

  methods: {
    ...mapActions(useHomeStore, [
      'pausePlayback'
    ]),

    resourceClick() {
      if (typeof (dataLayer) === 'undefined') return

      const url = this.resource.type === 'activity_v1_articles' ? window.location.href : this.resource.activity.link.href
      const destination = url.search(window.location.hostname) > 0 || /^\//.test(this.resource.activity.link.href) ? 'internal' : 'external'
      let category = this.$route ? this.$route.name : null

      if (category === 'course') {
        category = 'courses'
      }

      const gtm_event = {
        event:                'resourceClick',
        pageCategory:         category,
        resourceTitle:        this.resource.headline,
        resourceSubTitle:     this.resource.activity.link ? this.resource.activity.link.title : this.resource.cta,
        resourceID:           this.resource.id,
        resourceURL:          url,
        resourceDestination:  destination
      }

      dataLayer.push(gtm_event)
    },

    getEmbedCode() {
      const provider = this.resource.activity.provider
      const code = this.resource.activity.code

      this.vClient = TbkVideo.new(provider, code)

      const that = this
      this.vClient.getEmbedCode((code, video) => {
        $(this.$refs.video).html(code)

        if (this.resource.activity.provider === 'wistia') {
          // Hack to support vertical videos
          _wq.push({
            id: that.resource.activity.code,
            options: {
              videoFoam: true,
              playButton: false
            },
            onReady: function(video) {
              that.video_width = video.data.media.assets[0].width
              that.video_height = video.data.media.assets[0].height

              // make video container visible after video was resized
              video.bind('heightchange', () => {
                $(that.$el).find('.resource-overlay_video').css({ opacity: '1' })
                $(that.$el).find('.resource-overlay_headline').css({ opacity: '1' })
                $(that.$el).find('.resource-overlay_type').css({ opacity: '1' })
              })
            }
          })
        }
      })
    },

    closeResourse(event) {
      if (event.target.className.match(/resource-overlay_close/g) ||
          event.target.className.match(/article-overlay_keep/g)) {
        this.closeOverlay()
      }
    },

    detectKeyup(e) {
      if ((e.key !== undefined && e.key === 'Escape') ||
        (e.keyCode !== undefined && e.keyCode === 27)) {
        this.closeOverlay()
      }

      if (e.key === 'Shift') {
        this.shift_is_pressed = false
      }
    },

    detectKeydown(e) {
      if (e.key === 'Shift') {
        this.shift_is_pressed = true
      }
    },

    setFocus(ref_name) {
      this.$refs[ref_name].focus()
    },

    checkFocus(e) {
      if (this.shift_is_pressed) {
        this.setFocus('last-action')
      }
    },

    play() {
      Wistia.api(this.resource.activity.code).play()
      $(this.$refs['video-play']).hide()
    },

    _loadAdditionalWistiaScriptsAndInit() {
      // Load Wistia API script
      if (document.querySelector('#wistia_api_script') === null) {
        const wistia_api_script = document.createElement('script')
        wistia_api_script.setAttribute('src', '//fast.wistia.com/assets/external/E-v1.js')
        wistia_api_script.async = true
        wistia_api_script.setAttribute('id', 'wistia_api_script')
        document.head.appendChild(wistia_api_script)
      }

      // Load Wistia Playlist API script
      if (document.querySelector('#wistia_playlist_api_script') === null) {
        const wistia_playlist_api_script = document.createElement('script')
        wistia_playlist_api_script.setAttribute('src', '//fast.wistia.com/assets/external/playlist-v1.js')
        wistia_playlist_api_script.async = true
        wistia_playlist_api_script.setAttribute('id', 'wistia_playlist_api_script')
        document.head.appendChild(wistia_playlist_api_script)
      }

      setTimeout(() => {
        this.getEmbedCode()
      }, 1000)
    },

    _resizeVideoToAspectRatio() {
      this.aspect_ratio = this.video_height / this.video_width
      let containerHeight = null
      let containerWidth = null

      if (this.aspect_ratio > 0.5625) {
        $(this.$el).addClass('video--vertical')

        if ($(window).outerWidth() < 450) {
          // mobile
          if ($(window).outerHeight() < 680) {
            containerWidth = $(window).outerWidth() * 0.53 // this number is magic (53% of screen window)
          } else {
            containerWidth = $(window).outerWidth() * 0.6 // this number is magic (60% of screen window)
          }
          containerHeight = containerWidth * this.aspect_ratio
        } else {
          // desktop
          containerHeight = $(window).outerHeight() * 0.6 // this number is magic (60% of screen height)
          containerWidth = containerHeight / this.aspect_ratio
        }
      }

      if (containerWidth && containerHeight) {
        $(this.$el).find('.resource-overlay_video').css({
          'padding-bottom': '0',
          height: containerHeight,
          width: containerWidth
        })
      } else {
        // make wistia video container visible after video was loaded
        $(this.$el).find('.resource-overlay_video').css({ opacity: '1' })
        $(this.$el).find('.resource-overlay_headline').css({ opacity: '1' })
        $(this.$el).find('.resource-overlay_type').css({ opacity: '1' })
      }
    }
  }

}
</script>

<style lang='sass'>
  @import './ResourceOverlay'
</style>
