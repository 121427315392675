<template>
  <div class='section-row'>
    <ul
      :key='row_index'
      class='section-drawer_courses-list-row'
    >
      <li
        v-for='(course, course_index) in row'
        :key='course_index'
        class='section-drawer_courses-list-item'
      >
        <CourseCard
          :course='course'
          :is_selected='isSelected(course)'
          :opened_course='opened_course'
          :selectCourse='selectCourse'
        />
      </li>
    </ul>

    <transition name='drawer-fade'>
    <template v-if='!is_mobile && show_drawer'>
      <div
        :key='opened_course'
        class='section-drawer_drawer'
      >
        <div class='section-drawer_drawer-inner'>
          <CourseDrawer
            :identifier='opened_course'
            :setOpenedDrawer='setOpenedDrawer'
            :setOpenedCourse='setOpenedCourse'
          />
        </div>
      </div>
    </template>
    </transition>

    <transition
      name='popup-appear'
      mode='out-in'
    >
      <template v-if='is_mobile && show_drawer'>
        <CourseDrawerPopup
          :identifier='opened_course'
          :setOpenedDrawer='setOpenedDrawer'
          :setOpenedCourse='setOpenedCourse'
        />
      </template>
    </transition>
  </div>
</template>

<script>
import WindowSizeMixin from 'home/components/shared/WindowSizeMixin'
import CourseDrawer from 'home/components/shared/CourseDrawer/CourseDrawer'
import CourseDrawerPopup from 'home/components/shared/CourseDrawer/Popup/CourseDrawerPopup'
import CourseCard from 'home/components/shared/CourseCard/CourseCard'

export default {
  mixins: [WindowSizeMixin],

  props: [
    'section', 'row', 'row_index',
    'opened_course',
    'opened_drawer',
    'setOpenedDrawer',
    'setOpenedCourse'
  ],

  data() {
    return {
      drawer_opened: false,
      selected_course: null
    }
  },

  watch: {
    'drawer_opened'(value) {
      this.setOpenedDrawer(value)
    }
  },

  components: {
    CourseDrawer,
    CourseDrawerPopup,
    CourseCard
  },

  mounted() {
    if (this.$route.params.identifier &&
        this._weOwnRoutedCourse(this.$route.params.identifier)
    ) {
      this.selectCourse({ identifier: this.$route.params.identifier })

      if (this.$route.params.previous_route === 'courses') {
        setTimeout(() => {
          this.$scrollTo(this.$el, 400, { offset: -30 })
        }, 700)
      }
    }
  },

  computed: {
    show_drawer() {
      return this.opened_drawer &&
        this.row_has_opened_course &&
        this.opened_course !== null
    },

    row_has_opened_course() {
      return this.row.find((c) => c.identifier === this.opened_course)
    }
  },

  methods: {
    courseSectionModifier(section) {
      return (section.is_in_progress ? 'courses-in-progress' : 'courses-normal')
    },

    isSelected(course) {
      return course && course.identifier === this.opened_course
    },

    selectCourse(course) {
      if (this.$route.name.match(/^course/)) {
        const q = (this.$route.params.identifier === course.identifier) ? this.$route.query : {}

        if ((this.$route.params.identifier !== course.identifier ||
          (this.$route.params.previous_route === 'courses' && this.$route.params.identifier === course.identifier)) ||
          this.$route.query !== q) {
          // we need to push route if only something has changed
          // this prevents odd route push on initial page load
          this.$router.push({
            name: 'courses',
            params: {
              identifier: course.identifier
              // previous_route: this.$route.name
            },
            query: q
          })
        }
      }

      this.drawer_opened = true
      this.setOpenedDrawer(true)
      this.setOpenedCourse(course.identifier)

      setTimeout(() => {
        this.$scrollTo(this.$el, 400, { offset: -30 })
      }, 400)
    },

    _weOwnRoutedCourse(identifier) {
      return !!this.row.find((course) => {
        return course.identifier === identifier
      })
    }

  }

}
</script>

<style lang='sass' scoped>
  @import '../SectionDrawer'
</style>
