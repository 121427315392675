// Course model based on API::V1::ProgramSerializer
export default {
  // Relationships
  drivers: {
    jsonApi: 'hasMany',
    type: 'program_drivers'
  },

  // Images
  image: null,

  // Attributes
  identifier: null,
  position: null,
  name: null,
  url: null,
  author_description: null
}
