// model based on API::V1::Activity::V1::ArticleSerializer
export default {

  // Images
  background_image: null,
  card_image: null,
  carousel_image: null,
  gradient_start: null,
  gradient_end: null,

  // Attributes
  identifier: null,
  label: null,
  headline: null,
  subheadline: null,
  instructions: null,
  cta: null,
  content: null,
  lesson_title: null,
  card_subheadline: null,
  hype_translations: null
}
