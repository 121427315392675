import ArrowUpIcon from './icons/arrow_up.svg'
import ArrowDownIcon from './icons/arrow_down.svg'
import CheckedIcon from './icons/checked.svg'
import UnCheckedIcon from './icons/unchecked.svg'

export default {
  props: [
    'applyFilter',
    'selected_filters',
    'categories',
    'courses_expanded'
  ],

  data() {
    return {
      expanded_sections: []
    }
  },

  components: {
    ArrowUpIcon,
    ArrowDownIcon,
    CheckedIcon,
    UnCheckedIcon
  },

  created() {
    if (this.courses_expanded) {
      this.expanded_sections.push('courses')
    }

    // Expand sections for selected filters on page load
    if (this.selected_filters.length) {
      this.selected_filters.forEach((filter) => {
        if (this.expanded_sections.indexOf(filter.type) === -1) {
          this.toggleSection(filter.type)
        }
      })
    }
  },

  methods: {
    isChecked(name, type) {
      let index = null
      this.selected_filters.forEach((o, i) => { if (o.name === name && o.type === type) index = i })

      return index !== null && index !== -1
    },

    sectionClass(type) {
      const classes = []
      classes.push('resourses-filters_section--' + type)

      if (this.expanded_sections.indexOf(type) !== -1) {
        classes.push('resourses-filters_section--expanded')
      }

      return classes
    },

    sectionExpanded(type) {
      return this.expanded_sections.indexOf(type) !== -1
    },

    toggleSection(type) {
      const index = this.expanded_sections.indexOf(type)
      if (index !== -1) {
        this.expanded_sections.splice(index, 1)
      } else {
        this.expanded_sections.push(type)
      }
    }
  }
}
