<template>
  <div
    class='application'
    :class='application_classes'
  >
    <PageTitle :title='page_and_academy_partner' />

    <!-- Header -->
    <PageHeader
      v-if='is_locale_loaded'
      :hide_sign_up='should_hide_sign_up'
      :logo='header_logo'
      :subdomain='subdomain'
    />

    <!-- Main Content -->
    <main
      id='main'
      class='home-container'
    >
      <transition
        v-if='!is_data_loaded || is_data_reloading'
        name='fast-fade'
      >
        <PageLoader :message='loader_message' />
      </transition>

      <template v-else>
        <transition
          name='fast-fade'
          mode='out-in'
        >
          <CatalogNavigation
            v-if='should_show_catalog_nav'
            key='nav'
          />
        </transition>

        <router-view v-slot="{ Component }">
          <transition
            name='fast-fade'
            mode='out-in'
          >
            <component
              :is="Component"
              :key='$route.name'
              :has_basic_auth="has_basic_auth"
              :submitWelcomeSurveyCallback='submitWelcomeSurveyCallback'
            />
          </transition>
        </router-view>
      </template>
    </main>

    <!-- Footer -->
    <PageFooter
      v-if='is_locale_loaded'
      :attribution_logo='attribution_logo'
      :logo='footer_logo'
    />

    <!-- Account Overlay (SignIn, SignUp, ForgotPass, etc) -->
    <transition
      mode='out-in'
      name="fast-fade"
    >
      <AccountOverlay
        v-if='isAccountOverlayOpened'
        :toggleAccountOverlay='toggleAccountOverlay'
        :type='accountOverlayContext'
        :redirect='accountOverlayRedirect'
        :authentications='authentications'
        :invited_user='invited_user'
      />
    </transition>

    <!-- Welcome Overlay (recommendations) -->
    <transition
      mode='out-in'
      name="fast-fade"
    >
      <WelcomeOverlay
        v-if='should_show_welcome_popup'
        :toggleOverlay='toggleWelcomeOverlay'
        :startSurvey='startWelcomeSurvey'
      />
    </transition>

    <!-- Discussion Overlay for Notifications -->
    <transition
      mode='out-in'
      name="fast-fade"
    >
    <Overlay
      v-if='isNotificationActivityOverlayOpened'
      :toggle='toggleNotificationActivityOverlay'
      activity=null
      slide_result=null
      view_style='full'
    >
      <DiscussionActivity
        :api_client='api_client'
        :discussion_topic_id='currentDiscussionTopicId'
        filter='my_activity'
      />
    </Overlay>
    </transition>

    <!-- Sticky Audio Player -->
    <div
      v-if='should_show_audioplayer'
      class='sticky-audio-player'
      role='region'
      aria-label='Audio player'
    >
      <AudioPlayer
        :is_playing='isAudioPlaying'
        :playlist='currentAudio'
        :episode='currentEpisodeIndex'
      />
    </div>

    <!-- Resource Overlay -->
    <transition
      mode='out-in'
      name="fast-fade"
    >
      <ResourceOverlay
        v-if='isResourceOverlayOpened'
        :closeOverlay='toggleResourceOverlay'
        :resource='currentResource'
      />
    </transition>

    <!-- Cookies consent -->
    <CookiesConsent
      v-if='is_locale_loaded'
      :enabled='show_cookie_notice'
    />

    <!-- Custom Styles -->
    <CustomStyles />
  </div>
</template>

<script>
import { inject } from 'vue'
import { useHomeStore } from '../stores/home'
import { mapActions, mapGetters, mapState } from 'pinia'

import CookiesConsent from 'components/Shared/CookiesConsent/CookiesConsent'
import CustomStyles from 'components/Shared/CustomStyles/CustomStyles'
import PageHeader from 'components/Header/PageHeader'
import PageFooter from 'components/Footer/PageFooter'
import PageLoader from 'components/PageLoader/PageLoader'
import PageTitle from 'components/Header/PageTitle'
import AccountOverlay from 'components/AccountOverlay/AccountOverlay'
import WelcomeOverlay from 'components/WelcomeOverlay/WelcomeOverlay'
import Overlay from 'components/InteractiveSection/InteractiveSectionOverlay'
import DiscussionActivity from 'components/activities/v1/Discussion/DiscussionV1'
import CatalogNavigation from 'home/components/shared/CatalogNavigation/CatalogNavigation'
import AudioPlayer from 'components/Shared/AudioPlayer/AudioPlayerSticky'
import ResourceOverlay from 'home/components/shared/ResourceOverlay/ResourceOverlay'

export default {

  components: {
    CookiesConsent,
    CustomStyles,
    PageHeader,
    PageFooter,
    PageLoader,
    PageTitle,
    AccountOverlay,
    WelcomeOverlay,
    Overlay,
    DiscussionActivity,
    CatalogNavigation,
    ResourceOverlay,
    AudioPlayer
  },

  data() {
    return {
      has_basic_auth: null,
      authentications: null,
      invited_user: null,
      loading_message: null,
      show_welcome_popup: null,
      show_cookie_notice: null,
      init_options: null,
      configuration: null,

      current_route_name: null,
      is_data_reloading: false
    }
  },

  created() {
    // set options that were props previously
    this.init_options = inject('init_options')
    this.has_basic_auth = this.init_options.has_basic_auth
    this.authentications = this.init_options.authentications
    this.invited_user = this.init_options.invited_user
    this.loading_message = this.init_options.loading_message
    this.show_welcome_popup = this.init_options.show_welcome_popup
    this.show_cookie_notice = this.init_options.show_cookie_notice

    // set configuration that was in $root.configuration previously
    this.configuration = inject('configuration')

    // set up pinia store
    const home_store = useHomeStore()

    home_store.setSignedIn(this.init_options.signed_in)
    home_store.setApiJWT(this.init_options.jwt)
    home_store.setApiUrl(this.init_options.api_url)
    home_store.setSubdomain(this.init_options.subdomain)
    home_store.setRootPath(this.init_options.base_path)
    home_store.setPartnerName(this.init_options.partner_name)
    home_store.setGuestToken(this.init_options.guest_token)
  },

  mounted() {
    this.fetchData()

    this.current_route_name = this.$router.currentRoute.value.name

    this.$router.beforeEach((to, from, next) => {
      this.current_route_name = to.name

      // Scroll to top on folowing routes
      const routes_to_scroll = [
        'home', 'learning-plan-overview',
        'instructors',
        'my_activity', 'resources',
        'audio-overview'
      ]

      if (from.name === 'home' && to.name === 'courses') routes_to_scroll.push('courses')
      if (from.name === 'home' && to.name === 'learning-plans') routes_to_scroll.push('learning-plans')
      if (from.name === 'home' && to.name === 'audio-series') routes_to_scroll.push('audio-series')

      if (routes_to_scroll.indexOf(to.name) !== -1) {
        if (to.name === 'resources' && from.name === 'resources') {
          // Prevents scrolling to top every time the selected filters change
          return next()
        }

        this.$scrollTo(this.$el, 1, { offset: 0 })
      }

      // This is dirty hack to fight scrolling issues after Back button (2218)
      if (document.body.style.overflow === 'hidden') {
        document.body.style.overflow = ''
        document.body.style.height = ''
        document.body.style.overflow = ''
      }

      document.body.classList.remove('popup-opened')

      if (window.gtm) {
        dataLayer.push(window.gtm)
      }

      next()
    })

    if ('ontouchstart' in window || navigator.maxTouchPoints) {
      $('body').addClass('is-touch-device')
    }

    // IOS Detection
    let isIos = false
    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
      isIos = true
    } else if (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)) {
      isIos = true
    }

    if (isIos) {
      $('body').addClass('is-ios-device')

      window.onpageshow = function(event) {
        if (event.persisted) { // if the page is loaded from cache
          window.location.reload()
        }
      }
    }

    // Modal Auth Enable
    if (this.configuration.authentication_modal_experience) {
      this.setModalAuth(true)
    }
  },

  watch: {
    is_data_loaded: function(value) {
      if (value && this.show_welcome_popup) {
        this.toggleWelcomeOverlay()
      }
    }
  },

  methods: {
    /* TODO: This is a naive approach at the moment where we just load
    everything. I think we can make this better by detecting the route and
    loading what we need then kicking of the rest in the background.
    */
    fetchData() {
      if (Object.keys(this.sections).length === 0) {
        this.loadHomepageData({ i18n: this.$i18n })
        this.loadSectionData()
      }

      if (Object.keys(this.instructors).length === 0) {
        this.loadInstructorData()
      }

      if (Object.keys(this.courses).length === 0) {
        this.loadCourseData()
      }

      if (Object.keys(this.learning_plans).length === 0) {
        this.loadLearningPlanData()
      }

      if (!this.user && this.signed_in) {
        this.loadUserData()
      }
    },

    ...mapActions(useHomeStore, [
      'loadHomepageData',
      'loadCourseData',
      'loadLearningPlanData',
      'loadInstructorData',
      'loadSectionData',
      'loadUserData',

      'toggleAccountOverlay',
      'toggleNotificationActivityOverlay',
      'toggleActivityOverlay',
      'toggleResourceOverlay',
      'toggleWelcomeOverlay',
      'setModalAuth'
    ]),

    startWelcomeSurvey() {
      this.toggleWelcomeOverlay()

      const survey = this.welcome_survey
      if (!survey) return false

      this.toggleActivityOverlay({ activity_id: survey.global_slide.activity.id })
    },

    submitWelcomeSurveyCallback(beforeCallback) {
      if (!this.user && !this.signed_in) return false
      if (typeof (beforeCallback) === 'function') { beforeCallback() }

      // Scroll to top
      this.$scrollTo(this.$el, 1, { offset: 0 })

      // Show preloader
      this.is_data_reloading = true

      // Reload home_page + sections
      const wait_3sec = new Promise((resolve) => setTimeout(() => resolve(), 3000))
      Promise.all([
        this.loadSectionData(),
        wait_3sec
      ]).then(() => {
        this.is_data_reloading = false
      })
    }
  },

  computed: {
    ...mapState(useHomeStore, [
      'signed_in',
      'jwt',
      'header_logo',
      'footer_logo',
      'attribution_logo',
      'courses',
      'learning_plans',
      'instructors',
      'sections',
      'user',
      'subdomain',
      'partner_name',
      'isAudioPlaying',
      'currentAudio',
      'currentEpisodeIndex',

      'isWelcomeOverlayOpened',
      'isResourceOverlayOpened',
      'isAccountOverlayOpened',
      'accountOverlayContext',
      'accountOverlayRedirect',
      'isNotificationActivityOverlayOpened',
      'currentDiscussionTopicId',
      'currentResource'
    ]),

    ...mapGetters(useHomeStore, [
      'api_client',
      'is_homepage_data_loaded',
      'is_data_loaded',
      'is_locale_loaded',
      'welcome_survey'
    ]),

    page_and_academy_partner() {
      return this.page_name + ' | ' + this.partner_name
    },

    page_name() {
      return this.$route.name
        ? this.$route.name.replace(/[-_]/g, ' ').replace(/(^|\s)\w/g, char => char.toUpperCase())
        : 'Home'
    },

    should_show_audioplayer() {
      return Object.keys(this.currentAudio).length > 0 &&
        this.currentEpisodeIndex !== null
    },

    should_show_welcome_popup() {
      return this.isWelcomeOverlayOpened
    },

    should_hide_sign_up() {
      return !this.has_basic_auth &&
        this.configuration.sign_up_type !== 'with_otp'
    },

    application_classes() {
      const classes = []

      if (this.should_show_audioplayer) classes.push('sticky-player-opened')

      return classes
    },

    should_show_catalog_nav() {
      return (this.is_catalog_enabled &&
        (this.current_route_name === 'audio-series' ||
          this.current_route_name === 'learning-plans' ||
          this.current_route_name === 'learning-plan' ||
          this.current_route_name === 'courses' ||
          this.current_route_name === 'course'
        )
      )
    },

    loader_message() {
      return this.loading_message || (this.is_data_reloading && this.$t('loading_personalized_homepage'))
    },

    is_catalog_enabled() {
      return (this.configuration && this.configuration.header_menu_catalog_visible) || false
    }
  }

}

</script>

<style lang='sass' scoped>
.home-container
  display: block
  min-height: calc(100vh - 101px)

  @media (max-width: 768px)
    min-height: calc(100vh - 60px)

// transition name='fade'
.fade-enter-active,
.fade-leave-active
  transition: ease-out opacity 2s

.fade-enter-from,
.fade-leave-to
  opacity: 0

// transition name='fast-fade'
.fast-fade-enter-active,
.fast-fade-leave-active
  transition: ease-in opacity 0.2s

.fast-fade-enter-from,
.fast-fade-leave-to
  opacity: 0
</style>

<style lang="sass">
@import "lesson/styles/mixins/styleguide/links"
@import "lesson/styles/mixins/styleguide/typography"
@import "lesson/styles/mixins/styleguide/buttons"

@import "lesson/styles/vh-based-typography"

@import "lesson/styles/customizations/new/betterfly"
@import "lesson/styles/customizations/new/carebridge"
@import "lesson/styles/customizations/new/covidvax"
@import "lesson/styles/customizations/new/light"
@import "lesson/styles/customizations/new/medela"
@import "lesson/styles/customizations/new/mindfull"
@import "lesson/styles/customizations/new/mobe"
@import "lesson/styles/customizations/new/nps"
@import "lesson/styles/customizations/new/phs"
@import "lesson/styles/customizations/new/phs-optum"
@import "lesson/styles/customizations/new/phs-uhc"
@import "lesson/styles/customizations/new/optumbank"
@import "lesson/styles/customizations/new/rally"
@import "lesson/styles/customizations/new/ula"
@import "lesson/styles/customizations/new/usbank"
@import "lesson/styles/customizations/new/walmart"

@import "lesson/styles/customizations/vh-based-betterfly"
@import "lesson/styles/customizations/vh-based-carebridge"
@import "lesson/styles/customizations/vh-based-covidvax"
@import "lesson/styles/customizations/vh-based-light"
@import "lesson/styles/customizations/vh-based-medela"
@import "lesson/styles/customizations/vh-based-mindfull"
@import "lesson/styles/customizations/vh-based-mobe"
@import "lesson/styles/customizations/vh-based-nps"
@import "lesson/styles/customizations/vh-based-phs"
@import "lesson/styles/customizations/vh-based-phs-optum"
@import "lesson/styles/customizations/vh-based-phs-uhc"
@import "lesson/styles/customizations/vh-based-optumbank"
@import "lesson/styles/customizations/vh-based-rally"
@import "lesson/styles/customizations/vh-based-ula"
@import "lesson/styles/customizations/vh-based-usbank"
@import "lesson/styles/customizations/vh-based-walmart"
</style>
