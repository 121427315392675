<template>
<div
  class='carousel-section'
  :class='main_class'
>
<div class='carousel-section_inner'>
  <div class='carousel-section_header'>
    <div class='carousel-section_headlines'>
      <h2 class='carousel-section_headline heading-four'>
        {{ headline }}
      </h2>
      <div
        v-if='subheadline'
        class='carousel-section_subheadline copy-two'
      >
        {{ subheadline }}
      </div>
    </div>

    <!-- CTA link -->
    <div
      v-if='link && link.title && link.href'
      class='carousel-section_cta'
    >
      <router-link
        v-if='alignment !== "center"'
        :to="{ path: link.href }"
        class='carousel-section_cta-link copy-three'
      >
        <span>{{ link.title }}</span>
        <ArrowIcon class='icon' />
      </router-link>

      <router-link
        :to="{ path: link.href }"
        class='section-drawer_cta-link action-two'
      >
        {{ link.title }}
      </router-link>
    </div>
  </div>

  <div class='carousel-section_carousel'>
    <!-- In Progress Lessons -->
    <template v-if='type === "in-progress-lessons"'>
      <CardSlider
        :autoplay='false'
        :breakpoints='course_carousel_breakpoints'
        :cards='lessons_as_cards'
        :cardClick='lessonClick'
        :hideType='true'
        :infinite='false'
        :mouseDrag='true'
        :speed='4000'
        :title='$t("lessons")'
      />
    </template>

    <!-- Courses -->
    <template v-if='type === "courses"'>
      <CardSlider
        :cards='courses_as_cards'
        :infinite='false'
        :autoplay='false'
        :speed='4000'
        :breakpoints='course_carousel_breakpoints'
        hideType=true
        :cardClick='courseClick'
        :centerIfLessItems='false'
        type='courses'
        :title='$t("courses")'
        :isSelected='isCourseSelected'
        :slideCallback='courseSlideCallback'
      />
    </template>
  </div>

  <!-- Course Drawer -->
  <div
    v-if='!is_mobile && opened_drawer'
    :key='selected_course'
    class='carousel-section_drawer'
  >
    <div class='carousel-section_drawer-inner'>
      <CourseDrawer
        :identifier='selected_course'
        :setOpenedDrawer='setOpenedDrawer'
        :setOpenedCourse='setOpenedCourse'
      />
    </div>
  </div>

  <!-- Course Drawer Mobile -->
  <transition
    name='popup-appear'
    mode='out-in'
  >
    <template v-if='is_mobile && opened_drawer'>
      <CourseDrawerPopup
        :identifier='selected_course'
        :setOpenedDrawer='setOpenedDrawer'
        :setOpenedCourse='setOpenedCourse'
      />
    </template>
  </transition>
</div>
</div>
</template>

<script>
import WindowSizeMixin from 'home/components/shared/WindowSizeMixin'

import CardSlider from 'components/Shared/CardSlider/CardSlider'
import CourseDrawer from 'home/components/shared/CourseDrawer/CourseDrawer'
import CourseDrawerPopup from 'home/components/shared/CourseDrawer/Popup/CourseDrawerPopup'

import ArrowIcon from './arrow.svg'

export default {
  mixins: [WindowSizeMixin],

  props: [
    'headline',
    'subheadline',
    'alignment',
    'type',
    'link',
    'lessons',
    'courses'
  ],

  components: {
    CardSlider,
    CourseDrawer,
    CourseDrawerPopup,
    ArrowIcon
  },

  data() {
    return {
      opened_drawer: false,
      selected_course: null,

      course_carousel_breakpoints: {
        320: {
          itemsToShow: 1.3
        },
        640: {
          itemsToShow: 2.4
        },
        800: {
          itemsToShow: 4
        },
        1100: {
          itemsToShow: 4
        },
        1360: {
          itemsToShow: 4
        }
      }
    }
  },

  computed: {
    main_class() {
      return [`carousel-section--${this.type}`, `carousel-section--align-${this.alignment || 'default'}`]
    },

    lessons_as_cards() {
      const cards = this.lessons.map((lesson, index) => {
        return {
          id:           lesson.identifier,
          image:        lesson.featured_image,
          href:         lesson.link,
          headline:     lesson.title,
          subheadline:  this.$t(lesson.context.type) + ': ' + lesson.context.title,
          progress:     lesson.progress,
          type:         'lessons'
        }
      })

      return cards
    },

    courses_as_cards() {
      const cards = this.courses.map((course, index) => {
        return {
          id:           course.identifier,
          course,
          type:         'courses'
        }
      })

      return cards
    }

  },

  methods: {

    courseClick(course, carousel, index) {
      this.selected_course = course.id
      this.opened_drawer = true

      this.$scrollTo(this.$el, 200, { offset: 20 })
    },

    isCourseSelected(course) {
      return this.opened_drawer &&
          this.selected_course === course.id
    },

    courseSlideCallback(slide) {
      setTimeout(() => {
        this.selected_course = null
        this.opened_drawer = false
      }, 300)
    },

    lessonClick(lesson) {
      window.location.href = lesson.href
    },

    setOpenedDrawer(value) {
      this.opened_drawer = value
    },

    setOpenedCourse(value) {
      this.selected_course = value
    }
  }

}
</script>

<style lang='sass' scoped>
  @import './CarouselSection'
</style>
