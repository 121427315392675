<template>
<div>
<!-- Custom Page Title -->
<PageTitle
  v-if='is_loaded'
  :title='page_title'
/>

<!-- Preloader -->
<PageLoader v-if='!is_loaded' />

<OverviewPage v-if='is_loaded'>
  <!-- Hero -->
  <template #hero>
    <OverviewHero
      :image='series.overview_page.hero_image'
    />
  </template>

  <!-- Main Content -->
  <template #content-main>
    <!-- Overview -->
    <OverviewSection>
      <template #title>
        <h1 class='heading-three'>
          {{ series.title }}
        </h1>
      </template>

      <template #content>
        <!-- Trailer -->
        <div
          v-if='series.trailer_url'
          class='audio-page_trailer'
        >
          <AudioPlayer
            :mini_mode='true'
            :audio_title='series.title + " - Trailer"'
            :audio_playlist_title='series.title'
            :audio_image='series.card_image'
            :file_url='series.trailer_url'
            :play_title="$t('listen_to_trailer')"
          />
        </div>

        <!-- Overview Copy -->
        <div
          class='audio-page_content-overview copy-one'
          v-html='series.overview_page.introduction'
        />

        <!-- External Links -->
        <div
          v-if='spotify_link || apple_link'
          class="audio-page_content-overview_links copy-one"
        >
          <a
            v-if='spotify_link'
            class='copy-two audio-page_link-spotify'
            :href='spotify_link.url'
            target='_blank'
            rel="noreferrer"
          >
            <SpotifyIcon />
            {{ $t('audio_overview.listen_on') }} Spotify
          </a>

          <a
            v-if='apple_link'
            class='copy-two audio-page_link-apple'
            :href='apple_link.url'
            target='_blank'
            rel="noreferrer"
          >
            <AppleIcon />
            {{ $t('audio_overview.listen_on') }} Apple Podcasts
          </a>
        </div>
      </template>
    </OverviewSection>
    <!-- EndOverview -->

    <!-- Episodes -->
    <OverviewSection>
      <template #title>
        <h2 class='heading-five'>
          {{ $t('audio_overview.episodes') }}
        </h2>
      </template>

      <template #content>
        <ul class="audio-page_episodes-list">
          <li
            v-for='(episode, index) in series.episodes'
            :key='index'
            class="audio-page_episodes-list-item"
          >
            <Episode
              :series='series'
              :episode='episode'
              :episodeIndex='index'
              :playToggle='playToggle'
              :isPlaying='isEpisodePlaying(index)'
              :isActive='isEpisodeActive(index)'
            />
          </li>
        </ul>
      </template>
    </OverviewSection>
    <!-- End Episodes -->
  </template>
  <!-- Left Block End -->

  <!-- Info Block -->
  <template #content-info>
    <template v-if='instructor'>
      <OverviewBadge
        :image='instructor.featured_image'
        :identifier='instructor.identifier'
        :label='$t("attribution.hosted_by", { value: "" })'
        :title='instructor.full_name'
        :subtitle='instructor.title'
      >
        <button
          class='audio-page_info-play action-one'
          role='alert'
          @click.prevent='playToggle(-1)'
        >
          {{ play_button_text }}
          <span class='ada-hidden'>{{ play_button_a11_text }}</span>
        </button>
      </OverviewBadge>
    </template>

    <!-- if no instructor authored -->
    <template v-else>
      <button
        class='audio-page_info-play action-one'
        @click.prevent='playToggle(-1)'
      >
        {{ play_button_text }}
        <span class='ada-hidden'>{{ play_button_a11_text }}</span>
      </button>
    </template>
  </template>
  <!-- End Info Block -->

  <!-- Bottom Section -->
  <template #bottom-section>
    <!-- Resources -->
    <template v-if="resource_section">
      <SectionDrawer
        v-if="series.resources"
        :description='resource_section.subheadline'
        :link='resource_section.cta'
        :title='resource_section.headline'
        modifier='resources_carousel'
        type='one_column'
      >
        <CardSlider
          :autoplay='false'
          :cardClick='resourceClick'
          :cards='resourcesAsCards(series.resources)'
          :centerIfLessItems='true'
          :infinite='false'
          :speed='4000'
        />
      </SectionDrawer>
    </template>

    <OverviewBottom
      v-if='bottom_section'
      :title='bottom_section.headline'
      :copy='bottom_section.subheadline'
      :link_title='bottom_section.cta.title'
      :link_href='bottom_section.cta.href'
      :image='bottom_section.background_image'
      :gradient_start='bottom_section.gradient_start'
      :gradient_end='bottom_section.cta.gradient_end'
      :new_tab='bottom_section.cta.new_tab'
    />
  </template>
  <!-- Bottom Section End -->
</OverviewPage>
</div>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapState, mapActions } from 'pinia'

import Episode from './AudioEpisode'
import PageLoader from 'components/PageLoader/PageLoader'
import PageTitle from 'components/Header/PageTitle'

import AudioPlayer from 'components/Shared/AudioPlayer/AudioPlayerInline'

import SectionDrawer from 'home/components/shared/SectionDrawer/SectionDrawer.vue'
import CardSlider from 'components/Shared/CardSlider/CardSlider'

import OverviewPage from 'home/components/shared/Overview/OverviewPage'
import OverviewSection from 'home/components/shared/Overview/OverviewSection'
import OverviewBadge from 'home/components/shared/Overview/OverviewBadge'
import OverviewBottom from 'home/components/shared/Overview/OverviewBottom'
import OverviewHero from 'home/components/shared/Overview/OverviewHero'

import SpotifyIcon from './icons/spotify.svg'
import AppleIcon from './icons/apple.svg'

export default {
  props: ['identifier'],

  components: {
    Episode,
    PageLoader,
    PageTitle,
    AudioPlayer,

    SectionDrawer,
    CardSlider,

    OverviewPage,
    OverviewHero,
    OverviewSection,
    OverviewBadge,
    OverviewBottom,

    SpotifyIcon,
    AppleIcon
  },

  data() {
    return {
      is_loaded: false
    }
  },

  mounted() {
    this._fetchData()
  },

  computed: {
    ...mapState(useHomeStore, [
      'api_client',
      'audio_series',
      'partner_name',
      'currentAudio',
      'currentEpisodeIndex',
      'isAudioPlaying'
    ]),

    page_title() {
      return this.series.title +
        ' | ' +
        this.$t('audio_series.podcasts') +
        ' | ' +
        this.partner_name
    },

    series() {
      return this.audio_series[this.identifier]
    },

    sections() {
      return this.series.overview_page.sections
    },

    bottom_section() {
      return this.sections.filter((s) => s.type === 'info')[0]
    },

    resource_section() {
      return this.sections.filter((s) => s.type === 'resources')[0]
    },

    instructor() {
      return this.series.instructor
    },

    spotify_link() {
      return this.series.external_links.filter((l) => l.type === 'spotify')[0]
    },

    apple_link() {
      return this.series.external_links.filter((l) => l.type === 'apple_podcasts')[0]
    },

    play_button_text() {
      if (this.series.identifier === this.currentAudio.identifier) {
        return this.isAudioPlaying
          ? this.$t('audio_player.pause')
          : this.$t('audio_player.resume')
      } else {
        return this.$t('audio_player.play')
      }
    },

    play_button_a11_text() {
      if (this.series.identifier === this.currentAudio.identifier) {
        return this.isAudioPlaying
          ? this.$t('audio_player.pause_a11')
          : this.$t('audio_player.resume_a11')
      } else {
        return this.$t('audio_player.play_a11')
      }
    }

  },

  methods: {
    ...mapActions(useHomeStore, [
      'setAudioSeries',
      'toggleResourceOverlay',
      'playEpisode',
      'startPlayback',
      'pausePlayback'
    ]),

    _fetchData() {
      // Get from store
      if (this.audio_series && this.audio_series[this.identifier]) {
        this.is_loaded = true
        return false
      }

      // Fetch from API
      this.api_client
        .find('audio_series', this.identifier, {
          include: 'instructor,resources.activity',
          fields: {
            instructors: ['identifier', 'full_name', 'featured_image', 'image', 'title']
          }
        })
        .then(({ data }) => {
          this.setAudioSeries(data)
          this.is_loaded = true
        })
    },

    playToggle(episodeIndex) {
      if (episodeIndex < 0) { // if play/resume button
        if (this.series.identifier === this.currentAudio.identifier) {
          // if the series has already started
          if (this.isAudioPlaying) {
            this.pausePlayback()
          } else {
            this.startPlayback()
          }
        } else {
          // start the series from the very begining
          this.playEpisode({
            audio: this.series,
            episode: 0
          })
        }
      } else {
        this.playEpisode({
          audio: this.series,
          episode: episodeIndex
        })
      }
    },

    isEpisodeActive(episodeIndex) {
      if (this.currentAudio.identifier === this.series.identifier &&
        this.currentEpisodeIndex === episodeIndex) {
        return true
      }
      return false
    },

    isEpisodePlaying(episodeIndex) {
      if (this.currentAudio.identifier === this.series.identifier &&
        this.currentEpisodeIndex === episodeIndex &&
        this.isAudioPlaying) {
        return true
      }
      return false
    },

    resourcesAsCards(resources) {
      const cards = resources.map((resource) => {
        return {
          id:           resource.id,
          image:        resource.image,
          type:         this._getResourceType(resource),
          headline:     resource.headline,
          activity:     resource.activity
        }
      })
      return cards
    },

    _getResourceType(resource) {
      switch (resource.activity.type) {
        case 'activity_v1_videos': return 'video'
        case 'activity_v1_audios': return 'audio'
        case 'activity_v1_articles': return 'article'
        case 'activity_v1_resources':
          if (resource.activity.link.href.match(/pdf/) !== null) return 'PDF'
          if (resource.activity.link.href.match(/file/) !== null) return 'download'
          break
        default: return 'link'
      }
    },

    resourceClick(resource) {
      this.toggleResourceOverlay({ resource })
    }

  }
}

</script>

<style lang='sass' scoped>
  @import './AudioPage'
</style>
