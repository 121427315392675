<template>
<div
  class='self-assessment_question_options self-assessment_question_options--free-form'
  :class='{ "self-assessment_question_options--multiple": is_multiple_options }'
>
  <FreeFormInput
    v-for='option in options'
    :key='option.id'
    :is_multiple_options='is_multiple_options'
    :option='option'
    :saved_value='saved_values[option.id]'
    @update='onUpdate'
  />
</div>
</template>

<script>
import FreeFormInput from './SelfAssessmentV2FreeFormInput'

export default {
  props: [
    'options',
    'savedFreeFormValue',
    'selectOption'
  ],

  components: {
    FreeFormInput
  },

  data() {
    return {
      results: {},
      options_with_errors: {}
    }
  },

  computed: {
    is_multiple_options() {
      return this.options.length > 1
    },

    saved_values() {
      return this.savedFreeFormValue() || {}
    }
  },

  methods: {
    onUpdate(updated_option) {
      this.results[updated_option.id] = updated_option.is_error ? null : updated_option.value

      if (updated_option.is_error) {
        this.options_with_errors[updated_option.id] = true
      } else {
        delete this.options_with_errors[updated_option.id]
      }

      // check if there are errors in any field
      if (Object.keys(this.options_with_errors).length) {
        this.selectOption(null)
        return
      }

      let results_to_send = this.results

      // check if any of required fileds is empty
      this.options.every(option => {
        if (option.required && !this.results[option.id]) {
          results_to_send = null
          return false
        }
        return true
      })

      // update activity_results. If null it will be removed for this question, which will disallow next step
      this.selectOption(results_to_send)
    }
  }
}
</script>

<style lang='sass' scoped>
.self-assessment_question_options--free-form
  width: 100%
  text-align: left
  padding: 0 20px
  box-sizing: border-box
  display: flex
  flex-direction: column
  flex-wrap: nowrap
  align-items: center
  justify-content: center
  gap: 20px
</style>
