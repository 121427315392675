<template>
<div
  class='quiz-response'
  :class='mainClass'
  role='alert'
>
  <div
    class='quiz-response_text body-two'
    v-html='feedback'
  />

  <div
    v-if='!is_correct'
    class='quiz-response_correct body-five'
  >
    {{ correct_answers_label }}: {{ correct_answers_text }}
  </div>
</div>
</template>

<script>
export default {
  props: [
    'question',
    'correct_answers',
    'is_correct'
  ],

  computed: {

    correct_answers_label() {
      return this.$tc('correct_answer', this.correct_answers.length)
    },

    correct_answers_text() {
      return this.correct_answers.map((o) => o.text).join(', ')
    },

    mainClass() {
      return this.is_correct ? 'correct' : 'incorrect'
    },

    feedback() {
      return this.is_correct ? this.question.correct_feedback : this.question.incorrect_feedback
    }

  }
}
</script>

<style lang="sass" scoped>
//@import 'lesson/styles/components/quiz/response'
</style>
