// model based on API::V1::Activity::V1::IntentionSetterSerializer
export default {
  // Images
  background_image: null,
  result_image: null,
  gradient_start: null,
  gradient_end: null,

  // Attributes
  identifier: null,
  label: null,
  headline: null,
  subheadline: null,
  intentions: [],
  goal_dates: [],
  early_close_trigger: null,
  configuration: {},
  intention_step: {},
  goal_date_step: {}
}
