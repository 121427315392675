<template>
<div>
  <VueSelect
    v-model='selected'
    class='custom-select copy-four'
    :options='options'
    :data-id='uid'
    :clearable='clearable'
    :filterable='filterable'
  />

  <component
    :is='"style"'
    v-if='prefix'
    type='text/css'
  >
    .custom-select[data-id='{{ uid }}'] .resources-app_sorter-select .v-select:before { content: '{{ prefix }}' }
  </component>
</div>
</template>

<script>
import VueSelect from "vue-select"
import 'vue-select/dist/vue-select.css'

export default {
  props: [
    'value',
    'options',
    'prefix',
    'resetResourceLoader'
  ],

  emits: ['input'],

  components: {
    VueSelect
  },

  data() {
    return {
      selected: null,
      clearable: false,
      filterable: false
    }
  },

  watch: {
    selected: function(value) {
      this.$emit('input', value)
      this.resetResourceLoader(value)
    }
  },

  mounted() {
    this.selected = this.value
  },

  computed: {
    uid() {
      return this.$.uid
    },

    model: {
      get() {
        return this.value
      },
      set(new_value) {
        this.$emit('input', new_value)
      }
    }
  }

}
</script>

<style lang='sass'>
  @import './CustomSelect'
</style>
