// Slide model based on API::V1::NotificationSerializer
export default {
  // Attributes
  version: null,
  avatar: null,
  headline: null,
  teaser: null,
  featured_image: {},
  timestamp: null,
  read: false,
  target: {}
}
