<template>
  <div class='page-not-found'>
    {{ $t('oh_know') }}
    <br>
    {{ $t('this_page_does_not_exist') }}
  </div>
</template>

<style lang='sass' scoped>
.page-not-found
  font-family: 'canada-type-gibson', sans-serif
  font-size: 40px
  font-weight: 400
  min-height: 400px
  color: #fff
  display: flex
  align-items: center
  justify-content: center
  text-align: center
</style>
