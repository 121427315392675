import { defineStore } from 'pinia'
import { useRootStore } from 'lesson/stores/root'

export const useLessonStore = defineStore('lesson', {
  state: () => {
    return {
      part_index: 0,
      slide_index: 0,
      parts: [],
      // parts: [
      //   {
      //     slides: [
      //       { activity: {} }
      //     ]
      //   }
      // ],
      triggered_slides: []
    }
  },

  getters: {
    currentPart(state) {
      return state.parts[state.part_index]
    },

    currentPartSlides() {
      if (this.currentPart) {
        return this.getPartSlides(this.currentPart)
      }
      return []
    },

    getPartSlides: (state) => (part) => {
      const slides = part.slides.reduce((accum, slide, idx) => {
        accum.push(
          ...state.triggered_slides
            .filter((tslide) => tslide.trigger === 'before_slide' && tslide.slide_id === slide.id)
            .sort((a, b) => { return a.position - b.position })
        )
        accum.push(slide)
        accum.push(
          ...state.triggered_slides
            .filter((tslide) => tslide.trigger === 'after_slide' && tslide.slide_id === slide.id)
            .sort((a, b) => { return a.position - b.position })
        )
        return accum
      }, [])
      return slides
    },

    currentSlide(state) {
      if (state.slide_index >= this.currentPartSlides.length) {
        return  {
          id: 'outro',
          identifier: 'outro'
        }
      } else {
        return this.currentPartSlides[state.slide_index]
      }
    },

    nextPart(state) {
      return state.parts[state.part_index + 1]
    },

    currentActivity(state) {
      if (this.currentSlide && this.currentSlide.activity) {
        return this.currentSlide.activity
      }
      return null
    },

    onLastPart(state) {
      return ((state.part_index + 1) === state.parts.length)
    },

    onLastSlide(state) {
      return ((state.slide_index + 1) > this.currentPartSlides.length)
    },

    outroSlide(state) {
      const root_store = useRootStore()
      return root_store.lesson ? root_store.lesson.outro : null
    },

    introSlide(state) {
      const root_store = useRootStore()
      return root_store.lesson ? root_store.lesson.intro : null
    }
  },

  actions: {
    // mutations
    //
    setPartIndex(index) {
      this.part_index = index
      this.slide_index = 0
    },

    setSlideIndex(index) {
      this.slide_index = index
    },

    setParts(parts) {
      this.parts = parts
    },

    setPart() {
      // need for analytics-helper
    },

    setSlide() {
      // need for analytics-helper
    },

    setTriggeredSlides(triggered_slides) {
      this.triggered_slides = triggered_slides
    },

    updateSlideData() {
    },

    setActivity({ slide, activity }) {
      slide.activity = activity
    },

    // actions
    //
    goToNextPart() {
      if (!this.onLastPart) {
        this.goToPart({ index: this.part_index + 1 })
      }
    },

    goToPart(payload) {
      this.setPartIndex(payload.index)
      this.setPart()
      /* global vueRouter */
      vueRouter.push({
        name: 'slide',
        params: {
          part_id: payload.index + 1,
          slide_id: 1
        }
      })
    },

    goToNextSlide() {
      if (!this.onLastSlide) {
        this.goToSlide({ index: this.slide_index + 1 })
      } else {
        this.goToNextPart()
      }
    },

    goToPrevSlide() {
      if (this.slide_index !== 0) {
        this.goToSlide({ index: this.slide_index - 1 })
      }
    },

    goToSlide(payload) {
      this.setSlideIndex(payload.index)

      let slide_index = payload.index + 1
      if (slide_index > this.currentPartSlides.length) {
        slide_index = 'outro'
      }

      vueRouter.push({
        name: 'slide',
        params: {
          part_id: this.part_index + 1,
          slide_id: slide_index
        }
      })
    },

    attemptAssessment({ activity, slide, callback }) {
      const root_store = useRootStore()
      const attempt_url = root_store.api_client.resourceUrlFor('activity', activity.id) + '/attempt'
      root_store.api_client.request(attempt_url, 'GET', { slide_id: slide.id })
        .then(({ data }) => {
          this.setActivity({ slide, activity: data })
          callback(data)
        })
    },

    updateSlide({ slide, callback }) {
      const root_store = useRootStore()
      root_store.api_client.find(slide.type, slide.id)
        .then(({ data }) => {
          this.setActivity({ slide, activity: data.activity })
          if (typeof (callback) === 'function') callback(data.activity)
        })
    }
  }
})
