<template>
  <transition name='fast-fade'>
    <div
      v-if='ready'
      id='slide-journal_overlay'
      class="slide-journal_overlay"
    >
      <JournalApp
        :activity='activity'
        :slide='slide'
        :updateSlideResult='updateSlideResult'
      >
        <a
          href="#"
          class='slide-journal_next button-one-branded'
          @click.prevent='skip'
        >
          {{ $t('keep_learning') }}
        </a>
      </JournalApp>
    </div>
  </transition>
</template>

<script>
import JournalApp from './app'

export default {
  props: [
    'slide',
    'activity',
    'toggle',
    'goToNextSlide',
    'updateSlideResult'
  ],

  data() {
    return {
      ready: false
    }
  },

  components: {
    JournalApp
  },

  created() {
    this.ready = true
  },

  methods: {
    skip() {
      this.goToNextSlide()
      this.toggleOverlay()
    },

    toggleOverlay() {
      this.toggle()
    }
  }

}
</script>
