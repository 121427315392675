// Lesson model based on API::V1::LessonSerializer
export default {

  // Relationships
  course: {
    jsonApi: 'hasOne',
    type: 'courses'
  },
  instructor: {
    jsonApi: 'hasOne',
    type: 'instructors'
  },
  parts: {
    jsonApi: 'hasMany',
    type: 'parts'
  },
  intro: {
    jsonApi: 'hasOne',
    type: 'lesson_intros'
  },
  outro: {
    jsonApi: 'hasOne',
    type: 'lesson_outros'
  },
  left_menu: {
    jsonApi: 'hasOne',
    type: 'lesson_left_menus'
  },
  triggered_slides: {
    jsonApi: 'hasMany',
    type: 'triggered_slides'
  },

  // Images
  featured_image: null,
  result_image: null,

  // Attributes
  identifier: null,
  title: null,
  card_intro: null,
  overview_intro: null,
  home_text: null,
  trailer: null,
  position: 0,
  position_within_context: 0,
  progress: null,
  enrolled: null,
  locale: null,
  supported_languages: {},
  card_description: null,
  overview_description: null,
  translations: {},
  last_update: null,
  learning_plan_attribution: null,
  completion_date: null,
  retake: null,
  author_description: null,
  published: null,
  published_date: null,
  context: null // short info about lesson's context (course or learning plan)
  /*
  completion_title: null,
  completion_body: null,
  completion_button: null
  */
}
