<template>
  <div class='paired-comparison-results_list paired-comparison-results_list--mobile'>
    <template v-for='(option, index) in top_results'>
      <div
        v-if='option'
        :key='index'
        :aria-current="$parent.isCurrent(index) ? 'true' : 'false'"
        class='paired-comparison-results_list-option paired-comparison-results_list-option--mobile'
        :class='$parent.addCurrentClass(index)'
        @click='expand(index, $event)'
      >
        <div
          v-if='option.option_image'
          class='paired-comparison-results_list-option_image'
          :style="{backgroundImage: 'url(' + option.option_image + ')'}"
        />

        <h3 class='paired-comparison-results_list-option_title body-three'>
          {{ option.title }}
        </h3>

        <p
          v-if='isCurrent(index) && option.description'
          class='paired-comparison-results_list-option_subtitle body-five'
        >
          {{ option.description }}
        </p>

        <a
          v-if='isCurrent(index) && option.description'
          :href='option.link_href'
          target='_blank'
          class='paired-comparison-results_list-option_link link-one-branded'
          @click='linkClick(option)'
        >
          {{ option.link_title }}
        </a>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  props: [
    'top_results'
  ],

  methods: {
    isCurrent(index) {
      return this.$parent.currentOptionNum === index + 1
    },

    expand(index, event) {
      if (this.$parent.currentOptionNum === index + 1 && event.target.tagName !== 'A') {
        this.$parent.currentOptionNum = 0
        return
      }

      this.$parent.currentOptionNum = index + 1
    },

    linkClick(option) {
      // GTM
      const event = {
        event: 'resourceClick',
        pageCategory: 'thisorthat',
        resourceTitle: option.title,
        resourceSubTitle: option.link_title,
        resourceID: option.id,
        resourceURL: option.link_href,
        resourceDestination: 'external'
      }

      dataLayer.push(event)
    }
  }
}
</script>
