// LearningPlan model based on API::V1::LearningPlan::OverviewPageSerializer
export default {
  // Images
  overview_image: null,

  // Attributes
  introduction: null,
  lessons_introduction: null,
  instructors_introduction: null
}
