<template>
  <label
    class="switch"
    :class="classes"
  >
    <input
      v-model="model"
      type="checkbox"
      class="switch_input"
      @change="onChange"
    >

    <span class="switch_label">
      <span class="on">{{ label_on }}</span>
      <span class="off">{{ label_off }}</span>
    </span>
  </label>
</template>

<script>
export default {

  props: [
    'value',
    'classes',
    'label_on',
    'label_off'
  ],

  emits: [
    'input',
    'change'
  ],

  data() {
    return {
      content: this.value
    }
  },

  computed: {
    model: {
      get() {
        return this.value
      },
      set(new_value) {
        this.$emit('input', new_value)
      }
    }
  },

  methods: {
    onChange(e) {
      this.$emit('change', e)
    }
  }

}
</script>

<style lang='sass' scoped>
  @import 'Switch'
</style>
