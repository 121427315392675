<template>
<div class='quiz-multiple-choice'>
  <h2 class='body-four'>
    {{ label }}
  </h2>

  <form class='quiz-question'>
    <fieldset
      class='quiz-question_fieldset'
      role='radiogroup'
      :aria-labelledby="'quiz-question-text-' + question.id"
    >
      <legend
        :id="'quiz-question-text-' + question.id"
        class='quiz-question_text h-four'
      >
        {{ question.text }}
      </legend>

      <div
        v-if="question.kind === 'multiple_select'"
        class='quiz-question_description'
      >
        {{ $t('select_all_that_apply') }}
      </div>

      <div
        class='quiz-question_container'
        :class="[question_was_submitted ? 'quiz-question_submitted' : 'quiz-question_not-submitted', animation ? 'quiz-question_animated' : '']"
      >
        <template v-for='(option, index) in options'>
          <quiz-question-option
            v-if='option.id === question_result.selection && question_was_submitted'
            :key='questionOptionKey(option.id)'
            class='question-option_user-selected'
            :option='option'
            :question='question'
            :question_was_submitted='question_was_submitted'
            :select='select'
            :isOptionSelected='isOptionSelected'
            :index='index'
          />
        </template>

        <quiz-question-option
          v-for='(option, index) in options'
          :key='option.id'
          :option='option'
          :question='question'
          :question_was_submitted='question_was_submitted'
          :select='select'
          :isOptionSelected='isOptionSelected'
          :index='index'
        />

        <question-response
          v-if='question_was_submitted'
          :key='question.id'
          :question='question'
          :correct_answers='[correct_answer]'
          :is_correct='isCorrect()'
        />
      </div>
    </fieldset>

    <button
      v-if='!question_was_submitted'
      class='quiz-question_next button-two'
      :disabled="!is_answered"
      @click.prevent='submitButtonAction'
    >
      {{ $t('submit') }}
    </button>

    <button
      v-else
      class='quiz-question_next button-two'
      @click.prevent='toNextQuestion'
      v-html='is_question_last ? $t("get_your_results") : $t("next")'
    />
  </form>
</div>
</template>

<script>
import QuizCommon from './quiz-common'
import QuizQuestionOption from './quiz-v1-question-option'
import QuestionResponse from './quiz-v1-question-response'

export default {
  mixins: [QuizCommon],

  props: [
    'question',
    'options',
    'nextQuestion',
    'is_question_last'
  ],

  components: {
    QuizQuestionOption,
    QuestionResponse
  },

  data() {
    return {
      animation: false,
      question_result:  {
        selection: null,
        submitted: false
      }
    }
  },

  computed: {

    is_answered() {
      return (this.question_result.selection && true) || false
    },

    question_was_submitted() {
      return this.question_result.submitted
    },

    correct_answer() {
      return this.options.find((o) => o.is_correct)
    }

  },

  methods: {

    submitButtonAction() {
      if (this.question_was_submitted) {
        this.nextQuestion()
      } else {
        this.animation = true
        this.question_result.submitted = true
        this.question_result.is_correct = this.isCorrect()
        this.setQuestionResult(this.question_result)
      }
    },

    select(option_id) {
      if (!this.question_was_submitted) {
        if (this.question_result.selection !== option_id) {
          this.question_result.selection = option_id
        } else {
          this.question_result.selection = null
        }
      }
    },

    isOptionSelected(id) {
      return this.question_result.selection === id
    },

    toNextQuestion() {
      this.animation = false
      this._initQuestionResult()
      this.nextQuestion()
    },

    questionOptionKey(id) {
      return 'question-option_' + id
    },

    isCorrect() {
      return (this.correct_answer.id === this.question_result.selection)
    },

    _initQuestionResult() {
      this.question_result = {
        selection: null,
        submitted: false
      }
    }

  }
}
</script>

<style lang="sass" scoped>
//@import 'lesson/styles/components/quiz/question'
</style>
