export default {
  // Relationships
  program: {
    jsonApi: 'hasOne',
    type: 'programs'
  },

  // Images
  image: null,

  // Attributes
  author_description: null,
  headline: null,
  cta: null,
  resource_type: null,
  link: null,
  tags: null
}
