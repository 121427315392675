<template>
<div class='self-assessment_question'>
  <transition
    name="fast-fade"
    mode='out-in'
    appear
  >
    <h2
      :key='question.id'
      class='self-assessment_question-title copy-one'
    >
      {{ question.text }}
    </h2>
  </transition>

  <transition
    name="fast-fade"
    mode='out-in'
    appear
  >
    <template v-if='question.view_style === "text"'>
      <!-- free_form -->
      <options-free-form
        v-if='question.kind === "free_form"'
        :key='question.id'
        :options='question.options'
        :selectOption='selectOption'
        :savedFreeFormValue='savedFreeFormValue'
      />

      <!-- View Style: text -->
      <options-text
        v-else
        :key='question.id'
        :isOptionSelected='isOptionSelected'
        :options='question.options'
        :selectOption='selectOption'
        :savedOptionValues='savedOptionValues'
      />
    </template>
  </transition>

  <transition
    appear
    mode='out-in'
    name="fast-fade"
  >
    <!-- View Style: round_button -->
    <template v-if='question.view_style === "round_button"'>
      <options-round-button
        :key='question.id'
        :options='question.options'
        :selectOption='selectOption'
        :isOptionSelected='isOptionSelected'
      />
    </template>
  </transition>

  <transition
    appear
    mode='out-in'
    name="fast-fade"
  >
    <!-- View Style: scale -->
    <template v-if='question.view_style === "scale"'>
      <options-scale
        :key='question.id'
        :options='question.options'
        :selectOption='selectOption'
        :isOptionSelected='isOptionSelected'
      />
    </template>
  </transition>

  <transition
    appear
    mode='out-in'
    name="fast-fade"
  >
    <!-- View Style: round_card -->
    <template v-if='question.view_style === "round_card"'>
      <options-round-card
        :key='question.id'
        :options='question.options'
        :selectOption='selectOption'
        :isOptionSelected='isOptionSelected'
      />
    </template>
  </transition>

  <!-- View Style: card -->
  <template v-if='question.view_style === "card"' />
</div>
</template>

<script>
import OptionsFreeForm from './options/SelfAssessmentV2FreeForm'
import OptionsRoundButton from './options/RoundButton'
import OptionsRoundCard from './options/RoundCard'
import OptionsScale from './options/SelfAssessmentV2Scale'
import OptionsText from './options/SelfAssessmentV2Text'

export default {
  props: [
    'question',
    'selectOption',
    'savedFreeFormValue',
    'savedOptionValues',
    'isOptionSelected'
  ],

  components: {
    OptionsFreeForm,
    OptionsRoundButton,
    OptionsRoundCard,
    OptionsScale,
    OptionsText
  }
}
</script>

<style lang='sass' scoped>
  @import 'Question'
</style>
