<template>
<div
  id='journal-app'
  class='journal-app'
>
  <transition name='fast-fade'>
    <div
      v-if='ready'
      class='journal-app_inner'
    >
      <h1 class='journal-app_label copy-three'>
        {{ activity.label || $t('journal') }}
      </h1>

      <h2 class='journal-app_headline heading-four'>
        {{ activity.overlay_headline }}
      </h2>

      <p
        class='journal-app_description copy-one'
        v-html="activity.overlay_instructions"
      />

      <!-- Entry -->
      <template v-if='has_entry && !is_editing'>
        <Entry
          :headline='activity.entry_headline'
          :entry='entry_object'
          :editEntry='editEntry'
          :deleteEntry='deleteEntry'
          @edit='setIsEditing'
        />
      </template>

      <!-- Comment -->
      <template v-if='!has_entry || is_editing'>
        <CommentForm
          :comment='entry_object'
          :is_editing='is_editing'
          :createComment='createEntry'
          :editComment='editEntry'
          :cancel='is_editing ? cancelEdit : ""'
          :supported_file_types='supported_file_types'
          parent_id='null'
          thread_id='null'
        />
      </template>

      <div class='journal-app_bottom'>
        <slot />
      </div>
    </div>
  </transition>
</div>
</template>

<script>
import Entry from './journal-v1-entry.vue'
import CommentForm from 'components/activities/v1/Discussion/DiscussionCommentForm'

import { useUserResultsStore } from 'home/stores/user-results'
import { mapGetters } from 'pinia'

export default {
  props: ['slide', 'activity', 'updateSlideResult'],

  components: {
    Entry,
    CommentForm
  },

  data() {
    return {
      ready: false,
      entry: null,
      is_editing: false
    }
  },

  created() {
    this.ready = true
  },

  computed: {
    ...mapGetters(useUserResultsStore, [
      'getSlideResult'
    ]),

    has_entry() {
      const slideResult = this.getSlideResult(this.slide.id, this.slide.type)
      if (slideResult.results && Object.keys(slideResult.results).length) {
        return true
      } else return false
    },

    entry_object() {
      if (this.has_entry) {
        const slideResult = JSON.parse(JSON.stringify(this.getSlideResult(this.slide.id, this.slide.type))) // we need a copy of a state object here to avoid store manipulation

        const entry = {
          content: slideResult.results[Object.keys(slideResult.results)[0]].content,
          formatted_content: slideResult.results[Object.keys(slideResult.results)[0]].formatted_content,
          created_at: Object.keys(slideResult.results)[0],
          files: slideResult.files || []
        }
        return entry
      } else return {}
    },

    supported_file_types() {
      return this.$root.configuration.journals_supported_uploads
    }
  },

  methods: {
    createEntry(entry, _callback) {
      const new_entry = {}
      const created_at = new Date().toISOString()

      new_entry[created_at] = {
        content: entry.content
      }

      this._sendData(new_entry, entry.files, 'finished')
    },

    deleteEntry() {
      this._sendData({}, [], 'retake')
    },

    setIsEditing(value) {
      this.is_editing = value
    },

    editEntry(entry, files) {
      this.is_editing = false
      const updated_entry = {}

      updated_entry[entry.created_at] = {
        content: entry.content
      }

      this._sendData(updated_entry, files, 'finished')
    },

    cancelEdit() {
      this.is_editing = false
    },

    _sendData(entry, files, event) {
      // update slide result
      this.updateSlideResult({
        slide_id:    this.slide.id,
        slide_type:  this.slide.type,
        event,
        payload:     entry,
        files
      })
    }
  }
}

</script>
