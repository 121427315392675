<template>
<div class='account-overlay_forgot-pass'>
  <template v-if='!response_message'>
    <h1 class='heading-four'>
      {{ $t('reset_your_password') }}
    </h1>

    <p class='account-overlay_forgot-pass_description copy-two'>
      {{ $t('reset_pass_description') }}
    </p>

    <ForgotPassForm
      :basic_auth='basic_auth'
    />

    <a
      href='/account/sign-in'
      class='copy-four'
      @click.prevent='toggleAccountOverlay({context: "sign-in"})'
    >
      {{ $t('back_to_log_in') }}
    </a>
  </template>

  <template v-if='response_message'>
    <h1 class='heading-four'>
      {{ response_message }}
    </h1>

    <a
      href='/account/sign-in'
      class='copy-four'
      @click.prevent='toggleAccountOverlay({context: "sign-in"})'
    >
      {{ $t('back_to_log_in') }}
    </a>
  </template>
</div>
</template>

<script>
import ForgotPassForm from '../../account/components/ForgotPass/ForgotPassForm'

export default {
  props: {
    toggleAccountOverlay: Function,
    redirect_to_location: String,
    basic_auth: Object
  },

  components: {
    ForgotPassForm
  },

  data() {
    return {
      response_message: null
    }
  }
}
</script>

<style lang='sass' scoped>
.account-overlay_forgot-pass_description
  max-width: 70%
  margin: 0 auto 30px

  @media (max-width: 450px)
    max-width: 95%
</style>
