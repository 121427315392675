<template>
<div class='courses-app'>
  <CourseSection
    :section='all_courses_section'
    context='catalog'
  />
</div>
</template>

<script>
import CourseSection from 'home/components/shared/SectionDrawer/CourseSection/CourseSection.vue'

import { useHomeStore } from 'home/stores/home'
import { mapState } from 'pinia'

export default {
  components: {
    CourseSection
  },

  computed: {
    ...mapState(useHomeStore, [
      'courses'
    ]),

    all_courses_section() {
      return {
        headline:     this.$t('all_courses'),
        subheadline:  this.$t('all_courses_description'),
        courses:      Object.keys(this.courses).map(e => this.courses[e])
      }
    }
  }

}
</script>
