<template>
<div
  class='overview-page_bottom-section'
  :style='background_style'
>
  <div class='overview-page_bottom-section_inner'>
    <h2
      v-if='title'
      class="overview-page_bottom-section-title heading-five"
    >
      {{ title }}
    </h2>

    <p
      v-if='copy'
      class="overview-page_bottom-section-copy copy-one"
      v-html='copy'
    />

    <a
      v-if='link_href && link_title'
      :href='link_href'
      class='overview-page_bottom-section-link action-one-branded'
      :target='link_target'
    >
      {{ link_title }}
    </a>
  </div>
</div>
</template>

<script>
export default {
  props: [
    'title',
    'copy',
    'link_title',
    'link_href',
    'image',
    'gradient_start',
    'gradient_end',
    'new_tab'
  ],

  computed: {
    background_style() {
      // Background image
      if (this.image) {
        return `backgroundImage: url(${this.image})`
      }

      // Gradient
      if (!this.image && this.gradient_start) {
        const start = this.gradient_start
        const end = this.gradient_end || this.gradient_start
        const gradient = `linear-gradient(137deg, ${start} 0%, ${end} 92%)`
        return 'backgroundImage: ' +  gradient
      }

      return null
    },

    link_target() {
      return this.new_tab ? '_blank' : '_self'
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './Bottom'
</style>
