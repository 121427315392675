// Instructor model based on API::V1::DiscussionCommentSerializer
export default {
  // Relationships
  replies: {
    jsonApi: 'hasMany',
    type: 'discussion_comments'
  },

  // Attributes
  thread_id: null,
  parent_id: null,
  user: {},
  content: null,
  formatted_content: null,
  from_current_user: false,
  likes_amount: 0,
  liked: false,
  flagged: false,
  deleted: false,
  created_at: null,
  updated_at: null,

  files: []
}
