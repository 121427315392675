<template>
<div>
  <Overlay
    v-if='isActivityOverlayOpened && currentActivityId === activity.id'
    :key='activity.id'
    :toggle='toggle'
    :activity='activity'
    :slide_result='slide_result'
  >
    <!-- Self Assessment V2 -->
    <template v-if='activity.type === "activity_v2_self_assessments"'>
      <SelfAssessmentActivity
        :api_client='api_client'
        :toggle='toggle'
        :activity='activity'
        :slide='slide'
        :slide_result='slide_result'
        :updateSlideResult='updateSlideResult'
        :submitWelcomeSurveyCallback='submitWelcomeSurveyCallback'
        :toggleAccountOverlay='toggleAccountOverlay'
      />
    </template>

    <!-- Discussion -->
    <template v-if='activity.type === "activity_v1_discussions"'>
      <DiscussionActivity
        :discussion_topic_id='activity.topic_id'
        :api_client='api_client'
        :label='activity.label'
        :slide='slide'
        :updateSlideResult='updateSlideResult'
      />
    </template>

    <!-- Quizzes and old Self-Assessments -->
    <template v-if='activity.type === "activity_v1_quizzes" || activity.type === "activity_v1_self_assessments"'>
      <QuizActivity
        :slide='slide'
        :slide_result='slide_result'
        :updateSlideResult='updateSlideResult'
        :quiz="activity"
        :finish="toggle"
      />
    </template>

    <!-- Article -->
    <template v-if='activity.type === "activity_v1_articles"'>
      <ArticleActivity
        :slide="slide"
        :activity='activity'
        :toggle='toggle'
        :goToNextSlide='toggle'
      />
    </template>

    <!-- This or That -->
    <template v-if='activity.type === "activity_v1_paired_comparisons"'>
      <PairedComparisonActivity
        :activity="activity"
        :slide="slide"
        :slide_result='slide_result'
        :toggle="toggle"
        :updateSlideResult='updateSlideResult'
        :finish="toggle"
      />
    </template>

    <!-- Polls -->
    <template v-if='activity.type === "activity_v1_polls"'>
      <PollActivity
        :activity="activity"
        :slide="slide"
        :slide_result='slide_result'
        :goToNextSlide='toggle'
        :toggle="toggle"
        :updateSlideResult='updateSlideResult'
        view_style='textual'
        :is_desktop_view='poll_desktop_view'
      />
    </template>

    <!-- Journal -->
    <template v-if='activity.type === "activity_v1_journals"'>
      <JournalActivity
        :slide="slide"
        :activity='activity'
        :toggle='toggle'
        :goToNextSlide='toggle'
        :updateSlideResult='updateSlideResult'
      />
    </template>
  </Overlay>
</div>
</template>

<script>
import Overlay from 'components/InteractiveSection/InteractiveSectionOverlay'
// V2
import SelfAssessmentActivity from 'components/activities/v2/SelfAssessment/SelfAssessmentV2'
// V1
import ArticleActivity    from 'components/activities/v1/Article/article-overlay-v1'
import DiscussionActivity from 'components/activities/v1/Discussion/DiscussionV1'
import JournalActivity    from 'components/activities/v1/Journal/journal-v1-overlay'
import PairedComparisonActivity from 'components/activities/v1/PairedComparison/paired-comparison-v1'
import PollActivity       from 'components/activities/v1/Poll/poll-v1-overlay'
import QuizActivity       from 'components/activities/v1/Quiz/quiz-v1'

import { useHomeStore } from 'home/stores/home'
import { useUserResultsStore } from 'home/stores/user-results'
import { mapState, mapActions, mapGetters } from 'pinia'

export default {
  props: ['slide', 'submitWelcomeSurveyCallback'],

  components: {
    Overlay,

    SelfAssessmentActivity,
    ArticleActivity,
    DiscussionActivity,
    JournalActivity,
    PairedComparisonActivity,
    PollActivity,
    QuizActivity
  },

  data() {
    return {
      viewportWidth: null
    }
  },

  created() {
    this.recalculateViewportWidth()

    window.addEventListener('orientationchange', this.recalculateViewportWidth)
    window.addEventListener('resize', this.recalculateViewportWidth)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.recalculateViewportWidth)
    window.removeEventListener('orientationchange', this.recalculateViewportWidth)
  },

  mounted() {
  },

  computed: {
    ...mapGetters(useUserResultsStore, [
      'getSlideResult'
    ]),

    ...mapState(useHomeStore, [
      'api_client',
      'isActivityOverlayOpened',
      'currentActivityId'
    ]),

    slide_result() {
      const result = this.getSlideResult(this.slide.id, this.slide.type)

      if (Object.keys(result) === 0) {
        return {
          status: 'started'
        }
      } else return result
    },

    activity() {
      return this.slide.activity
    },

    poll_desktop_view() {
      if (this.viewportWidth) {
        return !(this.viewportWidth <= 768)
      }
      return null
    }
  },

  methods: {
    ...mapActions(useHomeStore, [
      'toggleActivityOverlay',
      'toggleAccountOverlay'
    ]),

    ...mapActions(useUserResultsStore, [
      'updateSlideResult'
    ]),

    toggle() {
      this.toggleActivityOverlay()
    },

    recalculateViewportWidth() {
      this.viewportWidth = $(window).width()
    }
  }

}

</script>
