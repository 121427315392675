<template>
  <div
    id="panel-tab-intentions"
    class='intentions-sections'
    aria-labelledby="intentions"
    role="tabpanel"
  >
    <!-- No intentions -->
    <SectionDrawer
      v-if='!intentions_in_progress.length && !intentions_completed.length'
      :title='$t("intentions.dashboard_empty_title")'
      :description='$t("intentions.dashboard_empty_description")'
      type='intentions'
      modifier='empty'
    />

    <!-- Intentions in progress -->
    <SectionDrawer
      v-if="intentions_in_progress.length"
      :title='$t("intentions.dashboard_in_progress")'
      :description='$t("intentions.dashboard_in_progress_description")'
      type='intentions'
      modifier='in-progress'
    >
      <ul class='intentions-list'>
        <li
          v-for='(intention, index) in intentions_in_progress'
          :key='index'
          class='intentions-list_list-item'
        >
          <IntentionCard
            :intention='intention'
            :intentionClick='intentionCardClick'
            :index='index'
          />
        </li>
      </ul>
    </SectionDrawer>

    <!-- Completed intentions -->
    <SectionDrawer
      v-if="intentions_completed.length"
      :title='$t("intentions.dashboard_completed")'
      :description='$t("intentions.dashboard_completed_description")'
      type='intentions'
      modifier='completed'
    >
      <ul class='intentions-list'>
        <li
          v-for='(intention, index) in intentions_completed'
          :key='index'
          class='intentions-list_list-item'
        >
          <IntentionCard
            :intention='intention'
            :intentionClick='intentionCardClick'
            :index='index'
          />
        </li>
      </ul>
    </SectionDrawer>
  </div>
</template>

<script>
import SectionDrawer from 'home/components/shared/SectionDrawer/SectionDrawer'
import IntentionCard from './IntentionCard'
export default {
  props: [
    'intentions',
    'intentionClick'
  ],

  components: {
    SectionDrawer,
    IntentionCard
  },

  computed: {
    intentions_in_progress() {
      return this.intentions.filter((intention) => {
        return !intention.completion_date
      })
    },

    intentions_completed() {
      return this.intentions.filter((intention) => {
        return !!intention.completion_date
      })
    }

  },

  methods: {
    intentionCardClick(intention_id) {
      if (typeof this.intentionClick === 'function') {
        this.intentionClick(intention_id)
      }
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './Intentions'
</style>
