<template>
<div class='profile-app'>
  <div class='profile-page--wrapper'>
    <transition name='fade'>
      <PageLoader v-if='loading' />
    </transition>

    <template v-if='!loading'>
      <div class='profile-page'>
        <h1 class='profile-page_title heading-six'>
          {{ $t('my_profile') }}
        </h1>

        <VeeForm
          v-slot="{ meta, errors }"
          class='profile-page_form'
          @submit='submitForm'
        >
          <!-- Required Information -->
          <fieldset class='profile-page_section profile-page_required'>
            <legend class='profile-page_label copy-four'>
              {{ $t('required_information') }}
            </legend>

            <!-- First Name -->
            <div class='profile-page_field'>
              <Field
                v-slot="{ field, meta }"
                v-model='user.first_name'
                rules='required|first_name'
                name='user_first_name'
              >
                <input
                  id='user_first_name'
                  type='text'
                  v-bind='field'
                  :disabled='user.sso_only'
                  :class='validationClasses(meta, "user_first_name", user.first_name)'
                >
              </Field>
              <label for='user_first_name'>{{ $t('first_name') }}</label>
            </div>

            <!-- Last Name -->
            <div class='profile-page_field'>
              <Field
                v-slot="{ field, meta }"
                v-model='user.last_name'
                rules='required|last_name'
                name='user_last_name'
              >
                <input
                  id='user_last_name'
                  type='text'
                  v-bind='field'
                  :disabled='user.sso_only'
                  :class='validationClasses(meta, "user_last_name", user.last_name)'
                >
              </Field>
              <label for='user_last_name'>{{ $t('last_name') }}</label>
            </div>

            <!-- Email -->
            <div class='profile-page_field'>
              <Field
                v-slot="{ field, meta }"
                v-model='user.email'
                rules='required|email|email_exist'
                name='user_email'
              >
                <input
                  id='user_email'
                  type='text'
                  v-bind='field'
                  :disabled='user.sso_only || !is_email_editable'
                  :class='validationClasses(meta, "user_email", user.email)'
                  @input='resetWrongState'
                  @change='resetWrongState'
                >
              </Field>
              <label for='user_email'>{{ $t('email') }}</label>
              <span
                v-if="email_exist"
                class='form-error'
              >
                {{ serverMessage?.email }}
              </span>
              <span
                v-if="!email_exist && errors.user_email"
                class='form-error'
              >
                {{ $t('email_error_message') }}
              </span>
            </div>
          </fieldset>

          <!-- Additional Information -->
          <fieldset
            v-if='should_show_additional_information'
            class='profile-page_section profile-page_additional'
          >
            <legend class='profile-page_label copy-four'>
              {{ $t('additional_information') }}
            </legend>

            <div
              v-if='!demographicsExtraFieldHidden("avatar")'
              class='profile-page_field profile-page_upload'
              :class="{'is-uploading': avatar_is_uploading}"
            >
              <Avatar
                :className="'profile-page_avatar'"
                :image="avatar_image || user.external_avatar"
                :orientation="avatar_orientation"
              />

              <div class='profile-page_upload-actions'>
                <div class='profile-page_upload-button'>
                  <div
                    class='profile-page_upload-button-progress'
                    :style="`width: ${ 100 - avatar_upload_progress }%`"
                  />
                  <UploadIcon />

                  <label for='user_upload_avatar'>{{ $t('upload_image') }}</label>
                  <input
                    id='user_upload_avatar'
                    :accept='supported_extensions'
                    type='file'
                    @change='attachFile'
                  >
                  <span>{{ $t('upload_image') }}</span>
                </div>

                <a
                  v-if='avatar_image'
                  class='profile-page_remove-button'
                  :aria-disabled="avatar_is_uploading"
                  :tabindex="avatar_is_uploading ? '-1' : '0'"
                  href='#'
                  @click.prevent='removeImage'
                >
                  {{ $t('remove_image') }}
                </a>
              </div>
            </div>

            <div
              v-if='!demographicsExtraFieldHidden("username")'
              class='profile-page_field profile-page_username'
            >
              <Field
                v-slot="{ field, meta }"
                v-model='user.username'
                rules='max:20'
                name='user_username'
              >
                <input
                  id='user_username'
                  type='text'
                  autocomplete='username'
                  v-bind='field'
                  :readonly='user_readonly'
                  :class='validationClasses(meta, "user_username", user.username)'
                >
              </Field>
              <label for='user_username'>{{ $t('username') }}</label>
            </div>

            <div
              v-if='demographicsExtraFieldUiEnabled("phone")'
              class='profile-page_field profile-page_phone'
            >
              <Field
                v-slot="{ field, meta }"
                v-model='user.phone'
                rules='phone'
                name='user_phone'
              >
                <input
                  id='user_phone'
                  type='tel'
                  autocomplete='phone'
                  v-bind='field'
                  :class='validationClasses(meta, "user_phone", user.phone)'
                >
              </Field>
              <label for='user_phone'>{{ $t('phone_number') }}</label>
              <span
                v-if="errors.user_phone"
                class='form-error'
              >
                {{ errors.user_phone }}
              </span>
            </div>

            <div
              v-if='demographicsExtraFieldUiEnabled("organization")'
              class='profile-page_field profile-page_organization'
            >
              <Field
                v-slot="{ field, meta }"
                v-model='user.organization'
                rules='organization'
                name='user_organization'
              >
                <input
                  id='user_organization'
                  type='text'
                  autocomplete='organization'
                  v-bind='field'
                  :class='validationClasses(meta, "user_organization", user.organization)'
                >
              </Field>
              <label for='user_organization'>{{ $t('organization_name') }}</label>
            </div>

            <div
              v-if='!demographicsFieldHidden("zip")'
              class='profile-page_field profile-page_zip'
            >
              <Field
                v-slot="{ field, meta }"
                v-model='user.zip'
                :rules='zip_validation_rules'
                name='user_zip'
              >
                <input
                  id='user_zip'
                  type='text'
                  v-bind='field'
                  :class='validationClasses(meta, "user_zip", user.zip)'
                >
              </Field>
              <label for='user_zip'>{{ $t('zip_code') }}</label>
            </div>

            <div
              v-if='!demographicsFieldHidden("birthday")'
              class='profile-page_field profile-page_birthday'
            >
              <Field
                v-slot="{ field, meta }"
                v-model='user.birthday'
                :rules='birthday_validation_rules'
                name='user_birthday'
              >
                <date-picker
                  v-model:value='user.birthday'
                  :input-class='birthdayInputClass(meta)'
                  name='user_birthday'
                  v-bind='field'
                  :disabled-date='disabledDate'
                  type='date'
                  format='MM/DD/YYYY'
                  lang='en'
                  valueType='YYYY-MM-DD'
                  :placeholder="$t('birthday_placeholder')"
                />
              </Field>
              <label for='user_birthday'>{{ $t('birthday_title') }}</label>
            </div>

            <div
              v-if='genders && !demographicsFieldHidden("sex")'
              class='profile-page_field profile-page_sex'
            >
              <Field
                v-model='user.sex'
                class='profile-page_sex-select'
                as='select'
                :rules='sex_validation_rules'
                name='user_sex'
              >
                <option
                  v-if="!user.sex"
                  value=""
                >
                  {{ $t('select_sex') }}
                </option>
                <option
                  v-for="(gender, index) in genders"
                  :key="index"
                  :value="gender"
                >
                  {{ $t(gender) }}
                </option>
              </Field>
              <label for='user_sex'>{{ $t('sex') }}</label>
            </div>
          </fieldset>

          <!-- Language Prefs -->
          <fieldset
            v-if='!demographicsExtraFieldHidden("language")'
            class='profile-page_section profile-page_additional'
          >
            <legend class='profile-page_label copy-four'>
              {{ $t('website_config') }}
            </legend>
            <div class='profile-page_field profile-page_language'>
              <Field
                v-model='user.preferred_locale'
                class='profile-page_language-select'
                as='select'
                rules='has_changes'
                name='language'
              >
                <option
                  v-if="!user.preferred_locale"
                  value=''
                >
                  {{ $t('select_language') }}
                </option>
                <option
                  v-for="(lang, code) in supported_languages"
                  :key="code"
                  :value="code"
                >
                  {{ lang }}
                </option>
              </Field>
              <label for='language'>{{ $t('language') }}</label>
            </div>
          </fieldset>

          <!-- Change Password -->
          <fieldset
            v-if='user.password_changeable && has_basic_auth'
            class='profile-page_section profile-page_update-password'
          >
            <legend class='profile-page_label copy-four'>
              {{ $t('change_your_password') }}
            </legend>

            <div class='profile-page_field profile-page_current-password'>
              <Field
                v-slot="{ field, meta }"
                v-model='user.current_password'
                :rules='current_password_rules'
                name='user_current_password'
              >
                <input
                  id='user_current_password'
                  type='password'
                  autocomplete='new-password'
                  v-bind='field'
                  :class='validationClasses(meta, "user_current_password", user.current_password)'
                  @input='resetWrongState'
                >
              </Field>
              <label for='user_current_password'>{{ $t('current_password') }}</label>
              <span
                v-if="errors.user_current_password || wrong_current_password"
                class='form-error'
              >
                {{ serverMessage?.current_password }}
              </span>
            </div>

            <div class='profile-page_field'>
              <Field
                v-slot="{ field, meta }"
                v-model='user.password'
                :rules='new_password_rules'
                name='user_password'
              >
                <input
                  id='user_new_password'
                  ref='confirmation'
                  type='password'
                  autocomplete='off'
                  v-bind='field'
                  :class='validationClasses(meta, "user_password", user.password)'
                >
              </Field>
              <label for='user_new_password'>{{ $t('new_password') }}</label>
              <span
                v-if="errors.user_password"
                class='form-error'
              >
                {{ $t('password_error_message') }}
              </span>
            </div>

            <div class='profile-page_field'>
              <Field
                v-slot="{ field, meta }"
                v-model='user.password_confirmation'
                :rules='confirm_password_rules'
                name='user_password_confirmation'
              >
                <input
                  id='user_new_password'
                  type='password'
                  autocomplete='off'
                  v-bind='field'
                  :class='validationClasses(meta, "user_password_confirmation", user.password_confirmation)'
                >
              </Field>
              <label for='user_confirm_password'>{{ $t('confirm_password') }}</label>
              <span
                v-if="errors.user_password_confirmation"
                class='form-error'
              >
                {{ $t('confirmed_error_message') }}
              </span>
            </div>
          </fieldset>

          <fieldset class='profile-page_section profile-page_submit'>
            <legend class='profile-page_label ada-hidden'>
              {{ $t('save_profile') }}
            </legend>
            <div class='profile-page_field'>
              <input
                type='submit'
                :value="$t('save_profile')"
                class='profile-page_submit-button action-one-branded'
                :disabled='_isSubmitDisabled(meta)'
                @click.prevent='submitForm'
              >
            </div>
          </fieldset>
        </VeeForm>
      </div>
    </template>
  </div>
</div>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapState, mapActions } from 'pinia'

import Avatar from '../../../components/Avatar/UserAvatar'
import PageLoader from '../../../components/PageLoader/PageLoader'

import { Form as VeeForm, Field } from 'vee-validate'
import { defineRule } from 'vee-validate'
import {
  required,
  email,
  max
} from '@vee-validate/rules'
import DatePicker from 'vue-datepicker-next'

import 'vue-datepicker-next/index.css'
import { isAfter, isBefore } from 'date-fns'
import mime from 'mime-types'
import { DirectUpload } from '@rails/activestorage'
import UploadIcon from './upload.svg'

export default {
  props: ['jwt', 'subdomain', 'api_url', 'has_basic_auth'],

  components: {
    Avatar,
    PageLoader,
    DatePicker,
    UploadIcon,
    VeeForm,
    Field
  },

  watch: {
    'user.preferred_locale': function(newLocale) {
      if (this.initial_locale !== newLocale) {
        this.locale_has_changed = true;
      }
    },

    'user.username': function(b) {
      if (this.user.username && this.user.username.length >= 20) {
        this.user.username = this.user.username.substr(0, 20)
      }
    },

    'user.birthday': function(value) {
      if (this.initial_birthday && this.initial_birthday !== this.user.birthday) {
        this.birthday_has_changed = true
      }
    }
  },

  mounted() {
    this.initial_locale = this.user.preferred_locale
    this.initial_birthday = this.user.birthday
    this.avatar_image = this.user.avatar
    this.avatar_has_changed_on_remove = this.user.avatar !== null

    setTimeout(() => {
      this.user_readonly = false
    }, 500)
  },

  data() {
    return {
      loading: false,
      user: null,
      email_exist: false,
      wrong_current_password: false,
      dictionary: null,
      initial_locale: null,
      locale_has_changed: false,
      serverMessage: {},
      user_readonly: true,
      avatar_image: null,
      avatar_orientation: null,
      avatar_has_changed: false,
      avatar_is_uploading: false,
      avatar_upload_progress: 0,
      avatar_has_changed_on_remove: false,
      max_file_size_in_mb: 3,
      supported_extensions: ['.png', '.jpeg', '.jpg'],
      initial_birthday: null,
      birthday_has_changed: false
    }
  },

  computed: {
    ...mapState(useHomeStore, {
      original_user: state => state.user,
      supported_languages: state => state.supported_languages
    }),

    is_email_editable() {
      if (this.$root.configuration && this.$root.configuration.hasOwnProperty('profile_allow_email_update')) {
        return this.$root.configuration.profile_allow_email_update
      } else {
        return true
      }
    },

    zip_validation_rules() {
      const isRequired = this.demographicsRequiredField('zip');
      const rule = isRequired ? 'required|zip' : 'zip';
      return rule;
    },

    sex_validation_rules() {
      const isRequired = this.demographicsRequiredField('sex');
      const rule = isRequired ? 'required|sex' : 'sex';
      return rule;
    },

    birthday_validation_rules() {
      const isRequired = this.demographicsRequiredField('birthday');
      const rule = isRequired ? 'required|birthday' : 'birthday';
      return rule;
    },

    current_password_rules() {
      if (!this.user.current_password) {
        return null
      }

      return this.user.password || this.user.password_confirmation ? 'required|current_password' : 'current_password'
    },

    new_password_rules() {
      if (!this.user.current_password) {
        return null
      }

      return this.user.current_password || this.user.password_confirmation ? 'required|password' : 'password'
    },

    confirm_password_rules() {
      if (!this.user.current_password) {
        return null
      }

      return this.user.password || this.user.current_password ? 'required|confirmed:confirmation' : 'confirmed:confirmation'
    },

    genders() {
      return this.configurationValue('demographics_sex').range
    },

    birthday_before() {
      let limit = 120
      if (this.configurationValue('demographics_birthday') &&
        this.configurationValue('demographics_birthday').range[1] !== null
      ) {
        limit = this.configurationValue('demographics_birthday').range[1]
      }
      return this.dateLimit(limit)
    },

    birthday_after() {
      let limit = 0
      if (this.configurationValue('demographics_birthday') &&
          this.configurationValue('demographics_birthday').range[0] !== 0
      ) {
        limit = this.configurationValue('demographics_birthday').range[0]
      }
      return this.dateLimit(limit)
    },

    max_file_size_in_bytes() {
      return this.max_file_size_in_mb * 1024 ** 2
    },

    should_show_additional_information() {
      if (
        this.demographicsExtraFieldHidden('avatar') &&
        this.demographicsExtraFieldHidden('username') &&
        !this.demographicsExtraFieldUiEnabled('phone') &&
        !this.demographicsExtraFieldUiEnabled('organization') &&
        this.demographicsFieldHidden('zip') &&
        this.demographicsFieldHidden('birthday') &&
        this.demographicsFieldHidden('sex')
      ) return false
      return true
    }
  },

  created() {
    // validation rules
    defineRule('required', required);
    defineRule('email', email);
    defineRule('max', max);

    defineRule('first_name', (value) => {
      const regexp = /^[a-zA-Z\s\u0080-\u024F][a-zA-Z-.\s'\u0080-\u024F]*$/
      return regexp.test(value) || this.$t('your_first_name_is_not_valid')
    })

    defineRule('last_name', (value) => {
      const regexp = /^[a-zA-Z\s\u0080-\u024F][a-zA-Z-.\s'\u0080-\u024F]*$/
      return regexp.test(value) || this.$t('your_last_name_is_not_valid')
    })

    defineRule('email_exist', (value) => {
      return !this.email_exist || this.serverMessage.email
    })

    defineRule('phone', (value) => {
      const valid_regexp = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g
      return !value || valid_regexp.test(value) || 'Be sure to enter a valid phone number.'
    })

    defineRule('organization', (value) => {
      return true // No validations for this field for now
    })

    defineRule('zip', (value) => {
      if (value) {
        let regexp = /^[A-Za-z0-9'\-\u0080-\u024F]*$/;
        if (this.configurationValue('demographics_zip') && this.configurationValue('demographics_zip').match) {
          let match_value = this.configurationValue('demographics_zip').match;
          match_value = match_value.replace('\\A', '^').replace('\\z', '$');
          regexp = new RegExp(match_value);
        }
        return regexp.test(value) || this.$t('please_enter_correct_zipcode');
      }
      return true;
    });

    defineRule('sex', (value) => {
      return !value || this._isSexValid(value)
    })

    defineRule('has_changes', (value) => {
      return true
    })

    defineRule('birthday', (value) => {
      return !value || this._isDateValid(value)
    })

    defineRule('password', (value) => {
      return value?.length >= 8
    })

    defineRule('current_password', (value) => {
      return !this.wrong_current_password || this.serverMessage.current_password
    })

    defineRule('confirmed', (value) => {
      return this.user.password === this.user.password_confirmation
    })

    // Copy user to avoid store mutations
    this.user = JSON.parse(JSON.stringify(this.original_user))
  },

  methods: {
    ...mapActions(useHomeStore, [
      'updateUserData'
    ]),

    validationClasses(meta, fieldName, value) {
      if (fieldName === 'user_current_password' && this.wrong_current_password) {
        return ['has-error', 'not-empty']
      } else if (fieldName === 'user_email' && this.email_exist) {
        return ['has-error', 'not-empty']
      } else {
        return {
          'has-error': meta.dirty && meta.validated && !meta.valid,
          valid: meta.dirty && meta.validated && meta.valid,
          'not-empty': !!value,
          empty: !value
        }
      }
    },

    dateLimit(limit) {
      const date = new Date()
      if (limit) {
        const limitMilliseconds = 1000 * 60 * 60 * 24 * 365.25 * limit
        date.setTime(date.getTime() - limitMilliseconds)
      }
      return date
    },

    disabledDate(value) {
      return isBefore(Date.parse(value), this.birthday_before) ||
        isAfter(Date.parse(value), this.birthday_after)
    },

    birthdayInputClass(meta) {
      const classes = ['profile-page_birthday-input']
      classes.push(this.validationClasses(meta, 'user_birthday', this.user.birthday))

      return classes
    },

    resetPasswordFields() {
      this.user.password = null
      this.user.password_confirmation = null
      this.user.current_password = null
    },

    resetWrongState() {
      if (this.wrong_current_password) {
        this.wrong_current_password = false
      }
      if (this.email_exist) {
        this.email_exist = false
      }
    },

    attachFile(event) {
      const file = event.target.files[0]
      if (this.supported_extensions.indexOf(this.fileExtension(file)) < 0) {
        Tbk.alerts.new(this.$t('upload_wrong_format', { value: this.supported_extensions.join(', ') }), 'error')
      } else if (file.size > this.max_file_size_in_bytes) {
        Tbk.alerts.new(this.$t('upload_max_filesize', { size_in_mb: this.max_file_size_in_mb }), 'error')
      } else {
        this.avatar_upload_progress = 0
        this.avatar_is_uploading = true
        this._uploadFile(file, (data) => {
          this.user.avatar = data.signed_id
          this.avatar_has_changed = true
          setTimeout(() => { // Let the progress bar animation to finish
            this.avatar_is_uploading = false
          }, 300)
        })

        this._previewImg(file)
      }

      // Here we need to clear the input field:
      if (event.target.value) {
        event.target.value = ''
      }
    },

    fileExtension(file) {
      return '.' + mime.extension(file.type)
    },

    removeImage() {
      this.user.avatar = null
      this.avatar_image = null
      this.avatar_orientation = null
      this.avatar_has_changed = this.avatar_has_changed_on_remove
    },

    _uploadFile(file, cb) {
      const url = '/rails/active_storage/direct_uploads'
      const upload = new DirectUpload(file, url, this)

      upload.create((error, blob) => {
        if (error) {
          // Handle the error
          console.log('error', error)
        } else {
          cb(blob)
        }
      })
    },

    directUploadWillStoreFileWithXHR(request) {
      // Function with this predefined name is called by DirectUpload on upload progress
      request.upload.addEventListener('progress',
        event => this.showUploadProgress(event))
    },

    showUploadProgress(event) {
      // Use event.loaded and event.total to update the progress bar
      this.avatar_upload_progress = event.loaded * 100 / event.total
    },

    _previewImg(file) {
      const img = document.createElement('img')
      img.file = file

      const reader = new FileReader()
      reader.onload = ((aImg) => {
        const that = this
        return function(e) {
          that.avatar_image = e.target.result

          that._getOrientation(file, function(result) {
            that.avatar_orientation = result
          })
        }
      })(img)

      reader.readAsDataURL(file)
    },

    _getOrientation(file, callback) {
      // https://stackoverflow.com/questions/7584794/accessing-jpeg-exif-rotation-data-in-javascript-on-the-client-side/32490603#32490603
      const reader = new FileReader()

      reader.onload = function(e) {
        const view = new DataView(e.target.result)
        if (view.getUint16(0, false) !== 0xFFD8) { return callback(-2) }
        const length = view.byteLength; let offset = 2
        while (offset < length) {
          if (view.getUint16(offset + 2, false) <= 8) return callback(-1)
          const marker = view.getUint16(offset, false)
          offset += 2
          if (marker === 0xFFE1) {
            if (view.getUint32(offset += 2, false) !== 0x45786966) { return callback(-1) }
            const little = view.getUint16(offset += 6, false) === 0x4949
            offset += view.getUint32(offset + 4, little)
            const tags = view.getUint16(offset, little)
            offset += 2
            for (let i = 0; i < tags; i++) {
              if (view.getUint16(offset + (i * 12), little) === 0x0112) {
                return callback(view.getUint16(offset + (i * 12) + 8, little))
              }
            }
          } else if ((marker & 0xFF00) !== 0xFF00) {
            break
          } else {
            offset += view.getUint16(offset, false)
          }
        }
        return callback(-1)
      }
      reader.readAsArrayBuffer(file)
    },

    submitForm() {
      if (!this.avatar_has_changed) {
        delete this.user.avatar;
      }
      this.updateUserData(this.user)
        .then((data) => {
          if (data.error) {
            this.handleFormErrors(data.error);
          } else {
            this.handleFormSuccess();
          }
        })
        .catch((error) => {
          console.error('An error occurred during form submission:', error);
          // Handle other types of errors if needed
        });
    },

    handleFormErrors(errors) {
      const targetElements = [];

      Object.keys(errors).forEach((key) => {
        if (key === 'current_password') {
          this.serverMessage[key] = errors[key].detail;
          this.wrong_current_password = true;
          targetElements.push('#user_current_password');
        }

        if (key === 'email') {
          this.serverMessage[key] = errors[key].detail;
          this.email_exist = true;
          targetElements.push('#user_email');
        }
      });

      if (targetElements.length) {
        this.$scrollTo(targetElements[0], 200, { offset: -30 });
      }
    },

    handleFormSuccess() {
      this.resetForm();
      // Copy user to avoid store mutations
      this.user = JSON.parse(JSON.stringify(this.original_user));

      if (this.locale_has_changed) {
        this.$i18n.locale = this.user.preferred_locale;
        Tbk.alerts.new(this.$t('profile_success_message'), 'success');

        // Delay the reload to give time for the success alert to show
        setTimeout(() => {
          Tbk.alerts.close($('.alert.success'));
        }, 2000);
      } else {
        Tbk.alerts.new(this.$t('profile_success_message'), 'success');
        setTimeout(() => {
          Tbk.alerts.close($('.alert.success'));
        }, 5000);
      }
    },

    resetForm() {
      this.resetPasswordFields()
      this.avatar_has_changed = false
      this.avatar_has_changed_on_remove = this.user.avatar !== null
      this.birthday_has_changed = false
    },

    configurationValue(key) {
      return this.$root.configuration &&
             this.$root.configuration.hasOwnProperty(key) &&
             this.$root.configuration[key]
    },

    demographicsRequiredField(field) {
      const requiredField = this.configurationValue('demographics_' + field).required
      return requiredField
    },

    demographicsFieldHidden(field) {
      const hiddenField =  this.configurationValue('demographics_' + field)?.hidden
      return hiddenField
    },

    demographicsExtraFieldHidden(field) {
      const extra_fields = this.configurationValue('demographics_extra')
      return extra_fields &&
          extra_fields.hasOwnProperty(field) &&
          extra_fields[field].hidden
    },

    demographicsExtraFieldUiEnabled(field) {
      const extra_fields = this.configurationValue('demographics_extra')

      return extra_fields &&
          extra_fields.hasOwnProperty(field) &&
          extra_fields[field].ui
    },

    _isDateValid(value) {
      return new Date(value).toString() !== 'Invalid Date'
    },

    _isSexValid(value) {
      if (this.configurationValue('demographics_sex') &&
          this.configurationValue('demographics_sex').range &&
          this.configurationValue('demographics_sex').range.indexOf(value) !== -1
      ) {
        return true
      }
      return false
    },

    _isEmpty(value) {
      return value === null || value === ''
    },

    _isSubmitDisabled(meta) {
      if (this.birthday_has_changed && meta.valid) return false;

      // Check if any of the required fields have non-empty values
      const hasNonEmptyFields =
        !this._isEmpty(this.user.username) ||
        !this._isEmpty(this.user.zip) ||
        !this._isEmpty(this.user.birthday) ||
        !this._isEmpty(this.user.sex) ||
        !this._isEmpty(this.user.phone) ||
        !this._isEmpty(this.user.organization) ||
        !this._isEmpty(this.user.avatar)
      // Add more conditions for other fields

      const isDisabled =
        !meta.valid ||
        !meta.touched ||
        this.avatar_is_uploading ||
        !hasNonEmptyFields ||
        !this.locale_has_changed;

        return isDisabled;;
    },
  }
}

</script>

<style lang='sass' scoped>
  @import './profile.sass'
</style>

<style lang='sass'>
  .profile-app

    input:disabled
      background-color: #AAA
      cursor: default
      color: #fff
      border-color: transparent

    .profile-page_birthday-input
      padding: 16px
      border: 1px solid #CED0D3
      box-sizing: border-box
      width: 100%
      border-radius: 0
      line-height: 1.2
      -webkit-appearance: none

      &:focus
        outline: none

    .mx-datepicker
      width: 100%

    .mx-input-append,
    .mx-icon-calendar
      display: none

    .mx-clear-wrapper
      background-color: transparent

    .mx-clear-icon
      line-height: 41px

</style>
