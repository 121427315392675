// Slide model based on API::V1::GlobalSlideActivityResultSerializer
export default {

  // Relationships
  slide: {
    jsonApi: 'hasOne',
    type: 'global_slides'
  },

  global_slide: {
    jsonApi: 'hasOne',
    type: 'global_slides'
  },

  user: {
    jsonApi: 'hasOne',
    type: 'user'
  },

  activity: {
    jsonApi: 'hasOne',
    type: 'activity'
  },

  // Attributes
  status: null,
  event: null,
  results: {},
  updated_at: null
}
