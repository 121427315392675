<template>
<div class='slide-poll_content'>
  <template v-if="view_style === 'visual'">
    <VisualView
      :options='activity.answers'
      :selected_answer='selected_answer'
      :activity='activity'
      :show_stats='show_stats'
      :response_headline='response_headline'
      :response_body='response_body'
      :total_responses="answer_selection_count['total']"
      :selectPollOption='selectPollOption'
      :resultPercent='resultPercent'
    />
  </template>

  <template v-if="view_style === 'textual'">
    <TextView
      :options='activity.answers'
      :selected_answer='selected_answer'
      :activity='activity'
      :show_stats='show_stats'
      :response_headline='response_headline'
      :response_body='response_body'
      :total_responses="answer_selection_count['total']"
      :selectPollOption='selectPollOption'
      :resultPercent='resultPercent'
      :is_desktop_view='is_desktop_view'
    />
  </template>

  <template v-if="view_style === 'scale'">
    <ScaleView
      :options='activity.answers'
      :selected_answer='selected_answer'
      :activity='activity'
      :resultPercent='resultPercent'
      :show_stats='show_stats'
      :selectPollOption='selectPollOption'
      :total_responses="answer_selection_count['total']"
    />
    <div class="scale-select-result">
      <div
        v-if='show_feedback && selected_answer'
        class='slide-poll_results'
      >
        <div class='slide-poll_results-headline h-three'>
          {{ response_headline }}
        </div>

        <p
          class='slide-poll_results-description body-two'
          v-html='response_body'
        />
      </div>
    </div>
  </template>
</div>
</template>

<script>
import TextView from './poll-v1-text'
import VisualView from './poll-v1-visual'
import ScaleView from './ScaleSelect/ScaleSelect'

import { useUserResultsStore } from 'lesson/stores/user-results'
import { useLessonStore } from 'lesson/stores/lesson'
import { mapActions } from 'pinia'

export default {
  props: [
    'activity',
    'slide',
    'slide_result',
    'view_style',
    'is_desktop_view'
  ],

  components: {
    VisualView,
    TextView,
    ScaleView
  },

  data() {
    return {
      locally_selected_answer: null
    }
  },

  computed: {
    show_stats() {
      return this.activity.show_stats
    },

    show_feedback() {
      return this.activity.show_response
    },

    response_headline() {
      const response = this.activity.answers.filter((a) => a.id === this.selected_answer)
      if (response.length) {
        return response[0].response_headline
      }
      return null
    },

    response_body() {
      const response = this.activity.answers.filter((a) => a.id === this.selected_answer)
      if (response.length) {
        return response[0].response_body
      }
      return null
    },

    selected_answer() {
      return (this.slide_result.results && this.slide_result.results.selected) || this.locally_selected_answer
    },

    answer_selection_count() {
      const selection_count = {}
      this.activity.answers.forEach((a) => {
        selection_count[a.id] = parseInt(a.selected_count, 10)
      })
      selection_count.total = Object.keys(selection_count).map(e => selection_count[e]).reduce((a, b) => a + b)

      return selection_count
    }

  },

  methods: {
    ...mapActions(useLessonStore, [
      'setActivity'
    ]),

    ...mapActions(useUserResultsStore, [
      'updateSlideResult'
    ]),

    resultPercent(id) {
      if (!this.selected_answer) return

      const orig = []; const keys = []; const target = 100
      const total_answers = this.answer_selection_count.total || 1

      Object.keys(this.answer_selection_count).sort().forEach((key) => {
        if (key !== 'total') {
          const selection_count = this.answer_selection_count[key]
          keys.push(key)
          orig.push((selection_count / total_answers * 100).toFixed(2))
        }
      })

      // Note: this algorhytm was taken fron stackoverflow comments :) (with no edits)
      // https://stackoverflow.com/questions/13483430/how-to-make-rounded-percentages-add-up-to-100

      let i = orig.length; let j = 0; let total = 0; const newVals = []; let next; let factor1; let factor2; const len = orig.length; const marginOfErrors = []
      // map original values to new array
      while (i--) {
        total += newVals[i] = Math.round(orig[i])
      }
      const change = total < target ? 1 : -1

      while (total !== target) {
        // select number that will be less affected by change determined
        // in terms of itself e.g. Incrementing 10 by 1 would mean
        // an error of 10% in relation to itself.
        for (i = 0; i < len; i++) {
          next = i === len - 1 ? 0 : i + 1
          factor2 = errorFactor(orig[next], newVals[next] + change)
          factor1 = errorFactor(orig[i], newVals[i] + change)
          if (factor1 > factor2) {
            j = next
          }
        }
        newVals[j] += change
        total += change
      }

      for (i = 0; i < len; i++) { marginOfErrors[i] = newVals[i] && Math.abs(orig[i] - newVals[i]) / orig[i] }
      for (i = 0; i < len; i++) {
        for (j = 0; j < len; j++) {
          if (j === i) continue
          const roundUpFactor = errorFactor(orig[i], newVals[i] + 1)  + errorFactor(orig[j], newVals[j] - 1)
          const roundDownFactor = errorFactor(orig[i], newVals[i] - 1) + errorFactor(orig[j], newVals[j] + 1)
          const sumMargin = marginOfErrors[i] + marginOfErrors[j]
          if (roundUpFactor < sumMargin) {
            newVals[i] = newVals[i] + 1
            newVals[j] = newVals[j] - 1
            marginOfErrors[i] = newVals[i] && Math.abs(orig[i] - newVals[i]) / orig[i]
            marginOfErrors[j] = newVals[j] && Math.abs(orig[j] - newVals[j]) / orig[j]
          }
          if (roundDownFactor < sumMargin) {
            newVals[i] = newVals[i] - 1
            newVals[j] = newVals[j] + 1
            marginOfErrors[i] = newVals[i] && Math.abs(orig[i] - newVals[i]) / orig[i]
            marginOfErrors[j] = newVals[j] && Math.abs(orig[j] - newVals[j]) / orig[j]
          }
        }
      }

      function errorFactor(oldNum, newNum) {
        return Math.abs(oldNum - newNum) / oldNum
      }

      const index = keys.indexOf(id)

      return newVals[index]
    },

    selectPollOption(data) {
      this.locally_selected_answer = data.selected
      this.updateSelection(data.selected)
      this._updateSlideResult('finished', data, {
        include: 'slide.activity'
      }, (data) => {
        this.setActivity({
          slide: this.slide,
          activity: data.activity
        })
      })
    },

    updateSelection(selectedOptionId) {
      if (!selectedOptionId) return

      this.answer_selection_count[selectedOptionId] += 1
      this.answer_selection_count.total += 1
    },

    _updateSlideResult(event, data, query_params = null, callback = null) {
      if (typeof (this.slide) !== 'undefined') {
        this.updateSlideResult({
          slide_id:    this.slide.id,
          slide_type:  this.slide.type,
          event,
          payload:     data,
          query_params,
          callback: (data) => {
            if (callback) callback(data)
          }
        })
      }
    }

  }
}
</script>

<style lang='sass'>
@import 'lesson/styles/components/poll/text'
@import 'lesson/styles/components/poll/overlay'
</style>
