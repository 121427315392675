<template>
<div class='account-overlay_sign-up'>
  <h1 class='heading-four'>
    {{ $t('sign_up') }}
  </h1>

  <p
    v-if='description_visible'
    class='account-overlay_sign-up_description copy-two'
  >
    {{ $t('sign_up_description') }}
  </p>

  <SignUpForm
    :user='invited_user'
    :api_url='api_url'
    :subdomain='subdomain'
  />

  <!-- OauthAuth -->
  <div v-if='oauth_auth'>
    <OauthAuth
      :auth_data='oauth_auth'
      :redirect_to_location='redirect_to_location'
      :sign_up=true
    />
  </div>

  <span class='copy-four'>{{ $t('already_have_account') }}</span>

  <!-- SignIn Link -->
  <a
    href="/account/sign-in"
    class='copy-four'
    @click.prevent='toggleAccountOverlay({context: "sign-in"})'
  >
    {{ $t('log_in') }}
  </a>
</div>
</template>

<script>
import SignUpForm from '../../account/components/SignUpPage/SignUpForm'
import OauthAuth  from '../../account/components/SignInPage/OauthAuth/OauthAuth'

export default {
  props: {
    toggleAccountOverlay: Function,
    redirect_to_location: String,
    api_url: String,
    subdomain: String,
    invited_user: Object
  },

  components: {
    OauthAuth,
    SignUpForm
  },

  computed: {
    description_visible() {
      return this.configurationValue('sign_up_description_visible')
    },

    oauth_auth() {
      return this.$parent.authentications.find(e => e.auth_type === 'oauth')
    }
  },

  methods: {
    configurationValue(key) {
      return this.$root.configuration &&
             this.$root.configuration.hasOwnProperty(key) &&
             this.$root.configuration[key]
    }
  }
}
</script>

<style lang='sass' scoped>
.account-overlay_sign-up_description
  max-width: 70%
  margin: 0 auto 30px

  @media (max-width: 450px)
    max-width: 95%
</style>
