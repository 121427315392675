<template>
<div class='course-drawer_overview'>
  <!-- Desktop -->
  <div
    v-if='!is_mobile'
    class='course-drawer_overview-info'
  >
    <VideoTrailer
      v-if='trailer'
      :text='trailer_cta'
      :video='trailer'
    />

    <p class='course-drawer_overview-description copy-two'>
      {{ learning_plan.overview_intro }}
    </p>

    <!-- Actions -->
    <div class='course-drawer_overview-actions'>
      <a
        v-if='learning_plan.enrolled && !learning_plan.completion_date'
        :href='`/learning-plans/${learning_plan.identifier}/continue`'
        class='action-one course-drawer_overview-action course-drawer_overview-action--primary'
      >
        {{ $t('resume') }}
      </a>

      <a
        v-if="!learning_plan.enrolled"
        :href='`/learning-plans/${learning_plan.identifier}/continue`'
        class="action-one course-drawer_overview-action course-drawer_overview-action--primary"
        @click='authGateway($event)'
      >
        {{ $t('begin') }}
      </a>

      <router-link
        :to="{
          name: 'learning-plan-overview',
          params: { identifier: learning_plan.identifier }
        }"
        class='action-one course-drawer_overview-action course-drawer_overview-action--secondary'
      >
        {{ $t('learn_more') }}
      </router-link>
    </div>
  </div>
  <!-- End Desktop -->

  <!-- Mobile  -->
  <div
    v-if='is_mobile'
    class='course-drawer_overview-info course-drawer_overview-info--mobile'
  >
    <!-- Actions -->
    <div class='course-drawer_overview-actions'>
      <a
        v-if='learning_plan.enrolled && !learning_plan.completion_date'
        :href='`/learning-plans/${learning_plan.identifier}/continue`'
        class='action-one course-drawer_overview-action course-drawer_overview-action--primary'
      >
        {{ $t('resume') }}
      </a>

      <a
        v-if="!learning_plan.enrolled"
        :href='`/learning-plans/${learning_plan.identifier}/continue`'
        class="action-one course-drawer_overview-action course-drawer_overview-action--primary"
        @click='authGateway($event)'
      >
        {{ $t('begin') }}
      </a>

      <router-link
        :to="{
          name: 'learning-plan-overview',
          params: { identifier: learning_plan.identifier }
        }"
        class='action-one course-drawer_overview-action course-drawer_overview-action--secondary'
      >
        {{ $t('learn_more') }}
      </router-link>
    </div>

    <div class='course-drawer_overview-label heading-six'>
      {{ $t('overview') }}
    </div>

    <VideoTrailer
      v-if='trailer'
      :text='trailer_cta'
      :video='trailer'
    />

    <p class='course-drawer_overview-description copy-two'>
      {{ learning_plan.overview_intro }}
    </p>
  </div>
  <!-- End Mobile  -->

  <!-- LP Instructor -->
  <template v-if='learning_plan.instructor'>
    <router-link
      :to="{
        name: 'instructor',
        params: { identifier: learning_plan.instructor.identifier }
      }"
      class='course-drawer_overview-instructor'
    >
      <Avatar :image='learning_plan.instructor.image' />

      <div class='course-drawer_overview-instructor-info'>
        <span
          class='course-drawer_overview-instructor-label copy-four'
        >
          {{ $t('attribution.with', {value: ''}) }}
        </span>

        <p class='course-drawer_overview-instructor-description copy-three'>
          <strong class="heading-six">{{ learning_plan.instructor.full_name }}</strong>
          {{ learning_plan.instructor.title }}
        </p>
      </div>
    </router-link>
  </template>
</div>
</template>

<script>
import Avatar from 'components/Avatar/UserAvatar'
import VideoTrailer from 'home/components/shared/VideoTrailer/VideoTrailer'

import { useHomeStore } from 'home/stores/home'
import { mapActions } from 'pinia'

export default {
  components: {
    Avatar,
    VideoTrailer
  },

  props: {
    learning_plan: { type: Object, required: true },
    is_mobile: Boolean
  },

  computed: {
    trailer() {
      return this.learning_plan.trailer
    },

    trailer_cta() {
      return this.learning_plan.trailer_cta || this.$t('watch_trailer')
    }
  },

  methods: {
    ...mapActions(useHomeStore, ['authGateway'])
  }
}
</script>

<style lang='sass' scoped>
  @import 'home/styles/shared/CourseDrawerOverview'
</style>
