<template>
<div class='resources-app'>
  <!-- Heading -->
  <div class='resources-app_header'>
    <div class='resources-app_header_inner'>
      <h1 class='heading-three'>
        {{ $t("resources") }}
      </h1>

      <!-- Search Bar -->
      <SearchBar
        v-if='false'
        :applySearch='applySearch'
      />
    </div>
  </div>

  <!-- Featured Resources -->
  <div
    v-if='is_featured_loaded && featured_resources.length'
    class='resources-app_featured-resources'
  >
    <h2 class='heading-four'>
      {{ $t("resources_page.featured_resources") }}
    </h2>

    <CardSlider
      :cards='featured_resources_cards'
      :cardClick='resourceClick'
      :title='$t("resources_page.featured_resources")'
    />
  </div>

  <!-- All Resources -->
  <div
    class='resources-app_all-resources'
    :class='[is_tablet ? "is_tablet" : ""]'
  >
    <PageLoader
      v-if='!is_loaded'
      class='resources-app_empty-list-loader'
    />

    <!-- Left panel -->
    <div
      v-if='is_loaded && (!is_tablet && !is_mobile)'
      class='resources-app_all-resources_left'
    >
      <!-- Filter -->
      <Filters
        v-if='is_loaded'
        :categories='filterCategories'
        :applyFilter='applyFilter'
        :selected_filters='selected_filters'
        :courses_expanded='courses_expanded'
      />
    </div>

    <!-- Right panel -->
    <div
      v-if='is_loaded'
      class='resources-app_all-resources_right'
    >
      <h2 class='heading-four'>
        {{ $t("resources_page.all_resources") }}
      </h2>

      <AppliedFilters
        v-if='is_loaded && (is_tablet || is_mobile)'
        :clearFilters='clearFilters'
        :removeFilter='removeFilter'
        :selected_filters='selected_filters'
        :results_count='filtered_resources.length'
      />

      <div class='resources-app_sorter'>
        <CustomSelect
          v-if='is_sort_visible'
          class='resources-app_sorter-select'
          :value='sorting'
          name='sort'
          :prefix="$t('resources_page.sort_by')"
          :options="sorting_options"
          :resetResourceLoader='resetResourceLoader'
        />

        <button
          v-if='is_tablet || is_mobile'
          class='resources-app_filter-button copy-four'
          @click.prevent='openMobileFilter'
        >
          {{ $tc("resources_page.filter", 1) }}
        </button>
      </div>

      <AppliedFilters
        v-if='is_loaded && (!is_tablet && !is_mobile)'
        :clearFilters='clearFilters'
        :removeFilter='removeFilter'
        :selected_filters='selected_filters'
        :results_count='filtered_resources.length'
      />

      <!-- Filtered Resources -->
      <ul class='resources-app_all-resources-list'>
        <Resource
          v-for='(resource, index) in resources_to_show'
          :key='index'
          :resource='resource'
          :course_info='_getResourceCourseInfo(resource)'
          :resource_type='_getResourceType(resource)'
          :resourceClick='resourceClick'
        />
      </ul>

      <infinite-loading
        v-if='resources_to_show.length'
        ref='resources_loader'
        class='resources-app_all-resources-list-footer'
        :identifier='loading_identifier'
        @infinite="showResources"
      >
        <template #spinner>
          <div class='spinner-container'>
            {{ $t("resources_page.loader_loading") }}
          </div>
        </template>
        <template #no-more>
          <div class='copy-four' />
        </template>
        <template #no-results>
          <div class='copy-four' />
        </template>
        <template #error>
          <div class='copy-four'>
            {{ $t("resources_page.loader_error") }}
          </div>
        </template>
      </infinite-loading>

      <div
        v-else
        class='resources-app_empty-list copy-four'
      >
        <span
          v-if='is_loaded'
          class='resources-app_empty-list-message'
        >
          {{ $t("resources_page.loader_no_results") }}
        </span>
        <PageLoader
          v-else
          class='resources-app_empty-list-loader'
        />
      </div>
    </div>
  </div>

  <!-- Mobile Filters Overlay -->
  <div
    v-if='mobile_filters_opened'
    class='resources-app_filters-overlay'
  >
    <div class='resources-app_filters-overlay_inner'>
      <FiltersMobile
        :categories='filterCategories'
        :applyFilter='applyFilter'
        :selected_filters='selected_filters'
        :courses_expanded='courses_expanded'
        :close='closeFilters'
        :clear='clearFilters'
      />
    </div>
  </div>
</div>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapState, mapActions } from 'pinia'

import SearchBar from './ResourcesSearchBar'
import Resource from './ResourcesResource'
import CardSlider from 'components/Shared/CardSlider/CardSlider'
import Filters from './ResourcesFilters'
import FiltersMobile from './ResourcesFiltersMobile'
import AppliedFilters from './ResourcesAppliedFilters'
import WindowSizeMixin from 'home/components/shared/WindowSizeMixin'
import CustomSelect from 'components/Shared/CustomSelect/CustomSelect'
import PageLoader from 'components/PageLoader/PageLoader'

import InfiniteLoading from 'v3-infinite-loading'
import 'v3-infinite-loading/lib/style.css'

export default {
  mixins: [WindowSizeMixin],

  components: {
    SearchBar,
    Resource,
    CardSlider,
    CustomSelect,
    PageLoader,
    Filters,
    FiltersMobile,
    AppliedFilters,
    InfiniteLoading
  },

  data() {
    return {
      selected_filters: [],
      sorting_options: [
        { value: 'a-z', label: 'A - Z' },
        { value: 'z-a', label: 'Z - A' },
        { value: 'new', label: this.$t('resources_page.sort_by_newest') },
        { value: 'old', label: this.$t('resources_page.sort_by_oldest') }
      ],
      sorting: {},
      mobile_filters_opened: false,
      is_loaded: false,
      is_featured_loaded: false,
      preset_filters_loaded: false,
      resources_page: 1,
      resources_page_size: 20,
      loading_identifier: null
    }
  },

  mounted() {
    this.loading_identifier = Date.now()
    this.sorting = this.sorting_options[0]

    // if we are on "resource" route then we load only one resource data
    if (this.route_query.hasOwnProperty('resource_id')) {
      this.togglePreSetResource()
    } else {
      this._fetchFeaturedResources()
      this._fetchResources()
    }
  },

  watch: {
    currentResource: {
      handler: function(newRes, oldRes) {
        if (newRes && this.route_query.resource_id !== newRes.id) {
          return this.$router.replace({ query: { ...this.route_query, resource_id: newRes.id } })
        }
      },
      deep: true
    },

    isResourceOverlayOpened(newVal, oldVal) {
      if (!newVal) {
        // resource overlay was closed
        // so we probably need to load resources
        if (!this.is_loaded) this._fetchResources()
        if (!this.is_featured_loaded) this._fetchFeaturedResources()

        // and scroll the page to the top
        if (!this.is_loaded || !this.is_featured_loaded) {
          this.$scrollTo(this.$el, 1, { offset: 0 })
        }

        const newQuery = _.omit(this.route_query, 'resource_id')
        return this.$router.replace({ query: newQuery })
      }
    }
  },

  computed: {
    ...mapState(useHomeStore, [
      'api_client',
      'courses',
      'resources',
      'featured_resources',
      'currentResource',
      'isResourceOverlayOpened'
    ]),

    featured_resources_cards() {
      const cards = this.featured_resources.map((resource) => {
        return {
          id:           resource.id,
          image:        resource.image,
          type:         this._getResourceType(resource),
          headline:     resource.headline,
          course_info:  this._getResourceCourseInfo(resource),
          activity:     resource.activity
        }
      })
      return cards
    },

    filtered_resources() {
      let filtered = []

      // Resources that don't have headline actually need headline to be sorted correctly (TBK-4560)
      this.resources.forEach((resource) => {
        if (this._getResourceType(resource) === 'content') {
          resource.headline = resource.activity.headline || this.$t('additional_content')
        }
      })

      // All resources
      if (!this.selected_filters.length) {
        filtered.push(...this.resources)
      }

      // Filter results based on Filter
      const coursesFilters = this.selected_filters.filter((f) => f.type === 'courses')
      const topicsFilters = this.selected_filters.filter((f) => f.type === 'topics')
      const typesFilters = this.selected_filters.filter((f) => f.type === 'types')

      // Filter by Course
      if (coursesFilters.length) {
        this.resources.forEach((resource) => {
          coursesFilters.forEach((filter) => {
            const course_names = this._getResourceCourseNames(resource)
            if (course_names.indexOf(filter.name) !== -1 && filtered.indexOf(resource) === -1) {
              filtered.push(resource)
            }
          })
        })
      } else {
        filtered = this.resources
      }

      // Filter by Topic
      const filteredByTopics = []
      if (topicsFilters.length) {
        filtered.forEach((resource) => {
          topicsFilters.forEach((filter) => {
            if ( // if any of resource tags is the same as the filter
              resource.tags.findIndex(tag => tag.name === filter.name) !== -1              && // and item is not filtered yet
              filteredByTopics.findIndex(filtered => filtered.id === resource.id) === -1
            ) {
              filteredByTopics.push(resource)
            }
          })
        })
        filtered = filteredByTopics
      }

      // Filter by Type
      const filteredByType = []
      if (typesFilters.length) {
        filtered.forEach((resource) => {
          typesFilters.forEach((filter) => {
            const type_name = this._getResourceType(resource)
            if (filter.name === type_name) {
              filteredByType.push(resource)
            }
          })
        })
        filtered = filteredByType
      }

      return filtered
    },

    sortered_and_filtered_resources() {
      switch (this.sorting.value) {
        case 'a-z': return _.sortBy(this.filtered_resources, 'headline')
        case 'z-a': return _.sortBy(this.filtered_resources, 'headline').reverse()
        case 'old': return _.sortBy(this.filtered_resources, 'created_at')
        case 'new': return _.sortBy(this.filtered_resources, 'created_at').reverse()
        default: return this.filtered_resources
      }
    },

    resources_to_show() {
      let shown_items = []
      const slice_from = (this.resources_page - 1) * this.resources_page_size
      const slice_to = slice_from + this.resources_page_size

      if (slice_from < this.sortered_and_filtered_resources.length) {
        shown_items = this.sortered_and_filtered_resources.slice(0, slice_to)
      }

      return shown_items
    },

    filterCategories() {
      const categories =  [{
        type: 'courses',
        name: this.$t('resources_page.filters_courses'),
        items: this.filterCourses
      }, {
        type: 'topics',
        name: this.$t('resources_page.filters_topics'),
        items: this.filterTopics
      }, {
        type: 'types',
        name: this.$t('resources_page.filters_activity_type'),
        items: this.filterTypes
      }]

      if (this.filterTopics.length === 0) {
        const index = categories.findIndex((c) => c.type === 'topics')
        index !== -1 && categories.splice(index, 1)
      }

      return categories
    },

    filterCourses() {
      const courses = []
      this.resources.forEach((r) => {
        if (r.course_ids.length === 0) return false

        this._getResourceCourseInfo(r).forEach((course_info) => {
          if (courses.filter((c) => c.title === course_info.title).length === 0) {
            courses.push({ title: course_info.title, identifier: course_info.identifier })
          }
        })
      })

      return _.sortBy(courses, 'title')
    },

    filterTopics() {
      const topics = []
      this.resources.forEach((r) => {
        r.tags.forEach((tag) => {
          if (topics.filter((t) => t.title === tag.name).length === 0) {
            topics.push({ title: tag.name, identifier: tag.identifier })
          }
        })
      })

      return _.sortBy(topics, 'title')
    },

    filterTypes() {
      const types = []
      this.resources.forEach((r) => {
        const type_name = this._getResourceType(r)
        if (types.filter((t) => t.title === type_name).length === 0) {
          types.push({ title: type_name, identifier: type_name })
        }
      })

      // this sort should be case-insensitive:
      return _.sortBy(types, function(i) { return i.title.toLowerCase() })
    },

    courses_expanded() {
      return this.resources.length >= 20
    },

    is_featured_resources_visible() {
      return !!this.$root.configuration.resource_catalog_featured_visible
    },

    route_query() {
      return this.$route.query
    },

    is_resource_overlay_loading() {
      return this.isResourceOverlayOpened && !this.currentResource
    },

    all_resources() {
      if (this.is_featured_resources_visible) {
        return this.is_featured_loaded && this.is_loaded && this.featured_resources.concat(this.resources)
      } else {
        return this.is_loaded && this.resources
      }
    },

    is_sort_visible() {
      return !!this.$root.configuration.resource_catalog_sort_visible
    }
  },

  methods: {
    ...mapActions(useHomeStore, [
      'toggleResourceOverlay',
      'setResources',
      'setFeaturedResources',
      'setCurrentResource'
    ]),

    showResources($loadingState) {
      if (this.resources_to_show.length) {
        if (this.resources_to_show.length < this.sortered_and_filtered_resources.length) {
          this.resources_page++
          $loadingState.loaded()
        } else {
          $loadingState.complete()
        }
      }
    },

    resetResourceLoader(sorting_value) {
      if (sorting_value) {
        this.sorting = sorting_value
      }
      this.resources_page = 1
      if (this.$refs.resources_loader) {
        this.loading_identifier = Date.now()
      }
    },

    applyFilter(type, name, identifier) {
      let index = null
      this.selected_filters.forEach((o, i) => { if (o.name === name && o.type === type) index = i })

      if (index !== null && index !== -1) {
        this.selected_filters.splice(index, 1)
      } else {
        this.selected_filters.push({
          type,
          name,
          identifier
        })
      }

      this.resetResourceLoader()

      this.updateQueryFilters(type)
    },

    applyPreSetFilters(queryObj) {
      if (queryObj.length === 0) return null

      return Object.keys(queryObj).map((category) => {
        // Check if the query's keys are valid filter categories
        if (this.filterCategories.filter((c) => c.type === category).length) {
          const queryFilter = queryObj[category]
          if (typeof queryFilter === 'string') {  // query only has 1 filter for the category
            return this._applyFilterByIdentifier(category, queryFilter)
          }
          if (queryFilter.length) {
            return queryFilter.map((val) => this._applyFilterByIdentifier(category, val))
          }
        }
        return null
      })
    },

    applySearch(event) {
      console.log(event.target.value)
    },

    removeFilter(category, name) {
      let index = null
      this.selected_filters.forEach((o, i) => { if (o.name === name && o.type === category) index = i })

      if (index !== null && index !== -1) {
        this.selected_filters.splice(index, 1)
      }
      this.resetResourceLoader()

      this.updateQueryFilters(category)
    },

    clearFilters() {
      this.selected_filters = []
      this.resetResourceLoader()
      return this.$router.replace({ query: {} })
    },

    closeFilters() {
      this.mobile_filters_opened = false
    },

    openMobileFilter() {
      this.mobile_filters_opened = true
    },

    resourceClick(resource) {
      this.toggleResourceOverlay({ resource })

      // GTM:
      const resourceTitle =  this._getResourceType(resource) === 'content'
        ? resource.activity.headline || this.$t('additional_content')
        : resource.headline

      const event = {
        event:        'resourceCatalogClick',
        resourceCatalogTitle: resourceTitle,
        resourceCatalogCourse: resource.course_info.length ? resource.course_info[0].title : '',
        resourceCatalogType: this._getResourceType(resource)
      }

      dataLayer.push(event)
    },

    updateQueryFilters(category) {
      if (this.preset_filters_loaded) {
        const selectedIdentifiersForType = this.selected_filters.filter((f) => f.type === category).map((s) => s.identifier)

        switch (category) {
          case 'courses': {
            return this.$router.replace({ query: { ...this.route_query, courses: this._getQueryValue(selectedIdentifiersForType) } })
          }
          case 'topics': {
            return this.$router.replace({ query: { ...this.route_query, topics: this._getQueryValue(selectedIdentifiersForType) } })
          }
          case 'types': {
            return this.$router.replace({ query: { ...this.route_query, types: this._getQueryValue(selectedIdentifiersForType) } })
          }
          default: return null
        }
      }
      return null
    },

    togglePreSetResource() {
      this.toggleResourceOverlay() // Opens ResourceOverlay before resources are loaded
      this._fetchResource(this.route_query.resource_id)
    },

    _applyFilterByIdentifier(category, filter) {
      let items = []

      // First, check if filter is valid for the given category. Then, apply the filter.
      if (category === 'courses') items = this.filterCourses.filter((c) => c.identifier === filter)

      if (category === 'topics') items = this.filterTopics.filter((tag) => tag.identifier === filter)

      if (category === 'types') items = this.filterTypes.filter((type) => type.identifier === filter)

      if (items.length) return this.applyFilter(category, items[0].title, items[0].identifier)

      return null
    },

    _fetchFeaturedResources() {
      if (!this.is_featured_resources_visible) return false

      if (this.featured_resources) {
        // here we already have the data in the store
        this.is_featured_loaded = true
        return false
      }

      this.api_client
        .runMiddleware({
          url: this.api_client.collectionUrlFor('resource') + '/featured',
          method: 'GET',
          model: 'resource'
        })
        .then(({ data }) => {
          this.setFeaturedResources(data)
          this.is_featured_loaded = true
        })
        .catch((error) => {
          console.log(error)
        })
    },

    _fetchResources() {
      if (this.resources && this.resources.length) {
        // Preset filters must be set before this.is_loaded = true
        this.applyPreSetFilters(this.route_query)
        this.preset_filters_loaded = true
        this.is_loaded = true
        return false
      }

      this.api_client
        .findAll('resource')
        .then(({ data }) => {
          this.setResources(data)
          this.applyPreSetFilters(this.route_query)
          this.preset_filters_loaded = true
          this.is_loaded = true
        })
    },

    _fetchResource(id) {
      this.api_client
        .find('resource', id)
        .then(({ data }) => {
          if (data) {
            const resource = data
            resource.course_info = this._getResourceCourseInfo(resource)
            this.setCurrentResource({ resource })
          }
        })
    },

    _getCourseNameById(course_id) {
      if (!course_id) return null

      const courses = _.values(this.courses).filter((c) => c.id === course_id)
      if (courses && courses.length) return courses[0].title

      return null
    },

    _getResourceCourseNames(resource) {
      if (!resource.course_names) {
        resource.course_names = resource.course_ids
          .map((course_id) => { return this._getCourseNameById(course_id) })
          .sort()
      }
      return resource.course_names
    },

    _getCourseInfoById(course_id) {
      if (!course_id) return null

      const courses = _.values(this.courses).filter((c) => c.id === course_id)

      if (courses && courses.length) return { title: courses[0].title, identifier: courses[0].identifier }

      return null
    },

    _getResourceCourseInfo(resource) {
      if (!resource.course_info) {
        const course_info = resource.course_ids
          .map((course_id) => { return this._getCourseInfoById(course_id) })
        resource.course_info = _.sortBy(course_info, 'title')
      }
      return _.sortBy(resource.course_info, 'title')
    },

    _getResourceType(resource) {
      switch (resource.activity.type) {
        case 'activity_v1_basic_contents': return 'content'
        case 'activity_v1_fullscreens': return 'quote'
        case 'activity_v1_videos': return 'video'
        case 'activity_v1_audios': return 'audio'
        case 'activity_v1_articles': return 'article'
        case 'activity_v1_resources':
          if (resource.activity.link.href.match(/pdf/) !== null) return 'PDF'
          if (resource.activity.link.href.match(/file/) !== null) return 'download'
          return 'link'
        default: return 'link'
      }
    },

    _getQueryValue(selectedIdentifiersForType) {
      if (!selectedIdentifiersForType.length) {
        return undefined
      }
      if (selectedIdentifiersForType.length === 1) {
        return selectedIdentifiersForType[0]
      }
      return selectedIdentifiersForType
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './app'
</style>
