<template>
<div class='styleguide'>
  <div class='styleguide_inner'>
    <h1>The new Argon Styleguide</h1>

    <!-- Headings -->
    <div class='styleguide_section'>
      <div class='styleguide_section-header'>
        Headings
      </div>

      <div class='styleguide_section-content'>
        <div class='styleguide_section-row'>
          <div class='styleguide_section-left'>
            <strong>Heading 1 - 96px</strong>
            <span>.heading-one</span>
          </div>

          <div class='styleguide_section-right'>
            <div class='heading-one'>
              Lorem Ipsum Dolor
            </div>
          </div>
        </div>

        <div class='styleguide_section-row'>
          <div class='styleguide_section-left'>
            <strong>Heading 2 - 62px</strong>
            <span>.heading-two</span>
          </div>
          <div class='styleguide_section-right'>
            <div class='heading-two'>
              Lorem Ipsum Dolor
            </div>
          </div>
        </div>

        <div class='styleguide_section-row'>
          <div class='styleguide_section-left'>
            <strong>Heading 3 - 48px</strong>
            <span>.heading-three</span>
          </div>
          <div class='styleguide_section-right'>
            <div class='heading-three'>
              Lorem Ipsum Dolor
            </div>
          </div>
        </div>

        <div class='styleguide_section-row'>
          <div class='styleguide_section-left'>
            <strong>Heading 4 - 34px</strong>
            <span>.heading-four</span>
          </div>
          <div class='styleguide_section-right'>
            <div class='heading-four'>
              Lorem Ipsum Dolor
            </div>
          </div>
        </div>

        <div class='styleguide_section-row'>
          <div class='styleguide_section-left'>
            <strong>Heading 5 - 22px</strong>
            <span>.heading-five</span>
          </div>
          <div class='styleguide_section-right'>
            <div class='heading-five'>
              Lorem Ipsum Dolor
            </div>
          </div>
        </div>

        <div class='styleguide_section-row'>
          <div class='styleguide_section-left'>
            <strong>Heading 6 - 18px</strong>
            <span>.heading-six</span>
          </div>
          <div class='styleguide_section-right'>
            <div class='heading-six'>
              Lorem Ipsum Dolor
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Headings End -->

    <!-- Body Copy -->
    <div class='styleguide_section'>
      <div class='styleguide_section-header'>
        Body Copy
      </div>
      <div class='styleguide_section-content'>
        <div class='styleguide_section-row'>
          <div class='styleguide_section-left'>
            <strong>Body 1</strong>
            <span>.copy-one</span>
          </div>
          <div class='styleguide_section-right'>
            <div class='copy-one'>
              "The quick brown fox jumps over the lazy dog" is an English-language pangram—a sentence that contains all of the letters of the alphabet. It is commonly used for touch-typing practice, testing typewriters and computer keyboards, displaying examples of fonts, and other applications involving text where the use of all letters in the alphabet is desired. Owing to its brevity and coherence, it has become widely known.
            </div>
          </div>
        </div>

        <div class='styleguide_section-row'>
          <div class='styleguide_section-left'>
            <strong>Body 2</strong>
            <span>.copy-two</span>
          </div>
          <div class='styleguide_section-right'>
            <div class='copy-two'>
              "The quick brown fox jumps over the lazy dog" is an English-language pangram—a sentence that contains all of the letters of the alphabet. It is commonly used for touch-typing practice, testing typewriters and computer keyboards, displaying examples of fonts, and other applications involving text where the use of all letters in the alphabet is desired. Owing to its brevity and coherence, it has become widely known.
            </div>
          </div>
        </div>

        <div class='styleguide_section-row'>
          <div class='styleguide_section-left'>
            <strong>Body 3</strong>
            <span>.copy-three</span>
          </div>
          <div class='styleguide_section-right'>
            <div class='copy-three'>
              "The quick brown fox jumps over the lazy dog" is an English-language pangram—a sentence that contains all of the letters of the alphabet. It is commonly used for touch-typing practice, testing typewriters and computer keyboards, displaying examples of fonts, and other applications involving text where the use of all letters in the alphabet is desired. Owing to its brevity and coherence, it has become widely known.
            </div>
          </div>
        </div>

        <div class='styleguide_section-row'>
          <div class='styleguide_section-left'>
            <strong>Body 4</strong>
            <span>.copy-four</span>
          </div>
          <div class='styleguide_section-right'>
            <div class='copy-four'>
              "The quick brown fox jumps over the lazy dog" is an English-language pangram—a sentence that contains all of the letters of the alphabet. It is commonly used for touch-typing practice, testing typewriters and computer keyboards, displaying examples of fonts, and other applications involving text where the use of all letters in the alphabet is desired. Owing to its brevity and coherence, it has become widely known.
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Body Copy End -->

    <!-- UI Elements -->
    <div class='styleguide_section'>
      <div class='styleguide_section-header'>
        UI Elements
      </div>
      <div class='styleguide_section-content'>
        <div class='styleguide_section-row'>
          <div class='styleguide_section-left'>
            <strong>Main Navigation</strong>
            <span>.main-nav-link</span>
          </div>
          <div class='styleguide_section-right'>
            <a
              href='#'
              class='main-nav-link'
            >
              Lorem Ipsum
            </a>
            <a
              href='#'
              class='main-nav-link'
            >
              Dolor Sit
            </a>
            <a
              href='#'
              class='main-nav-link'
            >
              Amet
            </a>
          </div>
        </div>

        <div class='styleguide_section-row'>
          <div class='styleguide_section-left'>
            <strong>Secondary Navigation</strong>
            <span>.secondary-nav-link</span>
          </div>
          <div class='styleguide_section-right'>
            <a
              href='#'
              class='secondary-nav-link'
            >
              Lorem Ipsum
            </a>
            <a
              href='#'
              class='secondary-nav-link'
            >
              Dolor Sit
            </a>
            <a
              href='#'
              class='secondary-nav-link'
            >
              Amet
            </a>
          </div>
        </div>

        <div class='styleguide_section-row'>
          <div class='styleguide_section-left'>
            <strong>Button 1 Branded color</strong>
            <span>.action-one-branded</span>
          </div>
          <div class='styleguide_section-right'>
            <button class='action-one-branded'>
              Learn More
            </button>
          </div>
        </div>

        <div class='styleguide_section-row'>
          <div class='styleguide_section-left'>
            <strong>Button 1 Branded</strong>
            <span>.action-one</span>
          </div>
          <div class='styleguide_section-right'>
            <button class='action-one'>
              Learn More
            </button>
          </div>
        </div>

        <div class='styleguide_section-row'>
          <div class='styleguide_section-left'>
            <strong>Button 2</strong>
            <span>.action-two</span>
          </div>
          <div class='styleguide_section-right'>
            <button class='action-two'>
              Resume
            </button>
          </div>
        </div>

        <div class='styleguide_section-row'>
          <div class='styleguide_section-left'>
            <strong>Button 2 Transparent</strong>
            <span>.action-two-transparent</span>
          </div>
          <div class='styleguide_section-right'>
            <button class='action-two-transparent'>
              Log In
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- UI Elements End -->
  </div>
</div>
</template>

<style lang='sass' scoped>
  @import 'app.sass'
</style>
