<template>
<a
  :href='`/lessons/${lesson.id}/continue?skip_intro=true`'
  class='lesson-card'
  @click='authGateway($event)'
>

  <div
    class='lesson-card_image'
    :style='lesson_image'
  >
    <span
      v-if='lesson.completion_date'
      class='lesson-card_completion-mark'
      title='Completed'
    />

    <template v-if='is_enrolled && !lesson.completion_date'>
      <div class='lesson-card_overlay' />
      <span class='lesson-card_resume action-two'>
        {{ $t('resume') }}
      </span>
    </template>

    <template v-if='!is_enrolled'>
      <div class='lesson-card_overlay' />
      <span class='lesson-card_resume action-two'>
        {{ $t('begin') }}
      </span>
    </template>
  </div>

  <div
    v-if='is_enrolled'
    class='lesson-card_progress-bar'
  >
    <div
      class='lesson-card_current-progress'
      :style="{width: '' + progress + '%'}"
    >
      <span class='ada-hidden'>Completed: {{ progress }}%</span>
    </div>
  </div>

  <div
    v-if='show_numbering'
    class='lesson-card_number copy-four'
  >
    {{ lesson_number }}
  </div>

  <div class='lesson-card_title heading-six'>
    {{ lesson.title }}
  </div>

  <div class='lesson-card_title-mobile heading-six'>
    <template v-if='show_numbering'>
      {{ lesson_number }}.
    </template>
    {{ lesson.title }}
  </div>

  <div class='lesson-card_description copy-four'> {{ lesson.card_description }} </div>

</a>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapActions } from 'pinia'

export default {
  props: ['lesson', 'index'],

  computed: {
    lesson_image() {
      return `background-image: url(${this.lesson.featured_image})`
    },

    lesson_number() {
      return this.index + 1
    },

    progress() {
      return this.lesson.progress
    },

    is_enrolled() {
      return this.lesson.enrolled
    },

    show_numbering() {
      if (this.$root.configuration && this.$root.configuration.hasOwnProperty('course_drawer_lessons_numbering_visible')) {
        return this.$root.configuration.course_drawer_lessons_numbering_visible
      } else {
        return true
      }
    }
  },

  methods: {
    ...mapActions(useHomeStore, ['authGateway'])
  }
}
</script>

<style lang='sass' scoped>
  @import './LessonCard'
</style>
