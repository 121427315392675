<template>
<li
  class='resource'
  :class="`resource--${resource.id}`"
>
  <router-link
    :to="{ query: { ...$route.query, resource_id: resource.id } }"
    :class='`resource_overlay-link resource_overlay-link--${resource.id}`'
    :title="resource.headline"
    @click='resourceClick(resource)'
  />

  <div
    class='resource_image'
    :style='background_style'
  />

  <div
    class='resource_inner'
  >
    <div
      class='resource_headline copy-three'
      :title="resource.headline"
    >
      {{ headline }}
    </div>

    <div
      v-if='course_info.length > 0'
      class='resource_course copy-three'
    >
      <router-link
        :to="{ name: 'courses', params: { identifier: course_info[0].identifier } }"
        class='resource_course-first_link'
      >
        {{ course_info[0].title }}
      </router-link>

      <span
        v-if="course_info.length > 1"
        class='resource_course-all'
        :title="course_names.join(' + ')"
      >
        +&nbsp;{{ course_info.length - 1 }}&nbsp; {{ $tc('resources_page.other_courses', course_info.length - 1 ) }}
      </span>
    </div>
  </div>

  <div
    class='resource_type'
  >
    <AudioIcon v-if='resource_type === "audio"' />
    <VideoIcon v-if='resource_type === "video"' />
    <LinkIcon v-if='resource_type === "link"' />
    <DownloadIcon v-if='resource_type === "download"' />
    <PdfIcon v-if='resource_type === "PDF"' />
    <ArticleIcon v-if='resource_type === "article"' />
    <BasicContentIcon v-if='resource_type === "content"' />
    <QuoteIcon v-if='resource_type === "quote"' />
  </div>
</li>
</template>

<script>
import AudioIcon from './icons/audio.svg'
import VideoIcon from './icons/video.svg'
import LinkIcon from './icons/link.svg'
import DownloadIcon from './icons/download.svg'
import PdfIcon from './icons/pdf.svg'
import ArticleIcon from './icons/article.svg'
import QuoteIcon from './icons/quote.svg'
import BasicContentIcon from './icons/basic_content.svg'

export default {
  props: ['resource', 'resourceClick', 'resource_type', 'course_info'],

  components: {
    AudioIcon,
    VideoIcon,
    LinkIcon,
    DownloadIcon,
    PdfIcon,
    ArticleIcon,
    QuoteIcon,
    BasicContentIcon
  },

  computed: {
    background_style() {
      // Background image
      if (this.resource.image) {
        return `background-image: url(${this.resource.image})`
      }

      if (this.resource.activity.background_image) {
        return `background-image: url(${this.resource.activity.background_image})`
      }

      // Gradient
      if (!this.resource.image &&
        !this.resource.activity.background_image &&
        this.resource.activity.gradient_start) {
        const start = this.resource.activity.gradient_start
        const end = this.resource.activity.gradient_end || this.resource.activity.gradient_start
        const gradient = `linear-gradient(137deg, ${start} 0%, ${end} 92%)`
        return 'background-image: ' +  gradient
      }

      return null
    },

    headline() {
      if (this.resource_type === 'content') {
        return this.resource.activity.headline || this.$t('additional_content')
      }

      return this.resource.headline
    },

    course_names() {
      return this.course_info.map(({ title }) => title).sort()
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './Resource'
</style>
