<template>
<router-link
  class='floating-cta'
  :class="{'is-visible': is_visible }"
  :to='link_to'
  @click='linkClick'
>
  <div class='floating-cta_info'>
    <span class='floating-cta_title copy-four'>{{ title }}</span>
    <span class='floating-cta_subtitle copy-two'>{{ subtitle }}</span>
  </div>

  <div class='floating-cta_arrow'>
    <ArrowIcon />
  </div>
</router-link>
</template>

<script>
import ArrowIcon from './more.svg'

export default {
  props: {
    appearance_delay: Number,
    title: String,
    subtitle: String,
    // link_href: String, // future needs
    link_name: String,
    link_identifier: String,
    click_callback: Function
  },

  components: {
    ArrowIcon
  },

  data() {
    return {
      is_visible: false
    }
  },

  mounted() {
    setTimeout(() => {
      this.is_visible = true
    }, this.appearance_timeout)
  },

  computed: {
    appearance_timeout() {
      return this.appearance_delay || 2000 // 2s default
    },

    link_to() {
      return {
        name: this.link_name,
        params: {
          identifier: this.link_identifier
        }
      }
    }
  },

  methods: {
    linkClick() {
      if (typeof (this.click_callback) === 'function') {
        this.click_callback()
      }
    }
  }
}
</script>

<style lang='sass'>
@import './FloatingCta'
</style>
