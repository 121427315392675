// Course model based on API::V1::CourseSerializer
export default {

  // Relationships
  lessons: {
    jsonApi: 'hasMany',
    type: 'lessons'
  },

  academy: {
    jsonApi: 'hasOne',
    type: 'academies'
  },

  instructor: {
    jsonApi: 'hasOne',
    type: 'instructors'
  },

  resources: {
    jsonApi: 'hasMany',
    type: 'resources'
  },

  program_drivers: {
    jsonApi: 'hasMany',
    type: 'program_drivers'
  },

  // Images
  card_image: null,
  result_image: null,

  // Attributes
  identifier: null,
  title: null,
  card_intro: null,
  overview_intro: null,
  trailer: null,
  trailer_cta: null,
  position: null,
  rating: null,
  progress: null,
  enrolled: null,
  completion_date: null,
  category: null,
  duration: null,
  card_attribution_visible: false,
  author_description: null,
  published: null,
  published_date: null
}
