const ResultsMixin = {
  data() {
    return {
      activity_results: {},
      categories: {}
    }
  },

  computed: {
    // Collect Outcomes
    outcomes_list() {
      const max = this.activity.results.number_of_results
      let results = []

      // Get user results, sort them and then truncate array
      Object.keys(this.categories).forEach((key) => {
        results.push({
          id: key,
          earned: this.categories[key].earned,
          total: this.categories[key].total
        })
      })

      results.sort((a, b) =>  b.earned - a.earned)
      results = results.slice(0, max)

      // Get outcomes
      results.forEach((result) => {
        this.activity.categories.forEach((category) => {
          if (category.id === result.id) {
            category.outcomes.forEach((outcome) => {
              const earned = result.earned >= 0 ? result.earned : 0
              if  (earned >= outcome.min_threshold &&
                earned <= outcome.max_threshold) {
                result.outcome = outcome
                result.name = category.name
              }
            })
          }
        })
      })

      return results
    },

    hero_outcome() {
      return this.outcomes_list.length && this.outcomes_list[0]
    }
  }
}

export default ResultsMixin
