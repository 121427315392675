<template>
<div class='paired-comparison-list_add'>
  <div>
    <template v-if='!isEditing'>
      <button
        v-if='!enough_items_added'
        class='paired-comparison-list_add-button body-three-light'
        @click.prevent='edit'
      >
        <i />
        <span>{{ add_button_text }}</span>
      </button>

      <div
        v-if='enough_items_added'
        class='paired-comparison-list_add-full body-two'
      >
        {{ full_text }}
      </div>
    </template>

    <template v-if='isEditing'>
      <input
        ref='inputField'
        v-focus
        type='text'
        class='paired-comparison-list_add-text body-three'
        name='item'
        placeholder='Type something'
        autocomplete='off'
        @blur='add'
        @keyup.enter='add'
        @keyup.esc='cancelAdd'
      >
    </template>
  </div>

  <!-- List -->
  <template
    v-for='(item, index) in user_options'
    :key='index'
  >
    <div class='paired-comparison-list_add-item'>
      <span
        :id='item.id'
        class='body-three'
      >
        {{ item.title }}
      </span>
      <!-- TODO Localize -->
      <button
        title='Delete'
        @click.prevent='deleteItem'
      >
        Delete
      </button>
    </div>
  </template>
</div>
</template>

<script>
const focus = {
  mounted: (el) => el.focus()
}

export default {
  props: [
    'user_options',
    'options_required'
  ],

  directives: { focus },

  data() {
    return {
      isEditing: false
    }
  },

  computed: {
    enough_items_added() {
      return this.options_required === this.user_options.length
    },

    // TODO Localize text
    add_button_text() {
      let text = 'Add your first item'
      if (this.user_options.length >= 1) text = 'Add item ' + (this.user_options.length + 1)
      return text
    },

    // TODO Localize text
    full_text() {
      return 'Good work, your list is full.'
    }
  },

  methods: {
    edit() {
      this.isEditing = true
    },

    cancelAdd() {
      this.isEditing = false
    },

    add(event) {
      if (!this.$refs.inputField) return false

      const value = $.trim(this.$refs.inputField.value)

      // Just basic presence validation
      if (value && value.length >= 1) {
        this.isEditing = false
        this.user_options.push({ id: this._generateUUID(), title: value })
      }
    },

    deleteItem(event) {
      const optionId = $(event.target).parent().find('span').attr('id')

      let index = -1
      this.user_options.forEach((o, i) => { if (o.id === optionId) index = i })
      if (index > -1) this.user_options.splice(index, 1)
    },

    _generateUUID() {
      return (this._UUIDPart() + this._UUIDPart() + '-' + this._UUIDPart() + '-' + this._UUIDPart() + '-' + this._UUIDPart() + '-' + this._UUIDPart() + this._UUIDPart() + this._UUIDPart())
    },

    _UUIDPart() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    }

  }
}
</script>
