import ApiClient from 'devour-client'
import ModelLoader from 'api/models'
import axios from 'axios'

let apiUrl = window.location.host.split('.')
apiUrl.splice(0, 1, 'api')
apiUrl = `${window.location.protocol}//${apiUrl.join('.')}/api/v1`

const apiClient = new ApiClient({ apiUrl })
apiClient.use_cookies = true

apiClient.setToken = (token) => {
  apiClient.headers.Authorization = `Bearer ${token}`
}

apiClient.useCookies = (flag) => {
  apiClient.use_cookies = flag
}

apiClient.setSubdomain = (subdomain) => {
  apiClient.headers['X-TBK-AcademyHost'] = subdomain
}

apiClient.setUrl = (url) => {
  apiClient.apiUrl = url
}

apiClient.setGuestToken = (token) => {
  apiClient.headers['X-TBK-GuestToken'] = token
}

apiClient.setLocale = (locale) => {
  apiClient.headers['X-TBK-Locale'] = locale
}

function _csrfToken() {
  return document.querySelector('meta[name=csrf-token]').content
}

const railsCsrfMiddleware = {
  name: 'rails-csrf-middleware',
  req: (payload) => {
    if (payload.req.method !== 'GET') {
      payload.req.headers['X-CSRF-TOKEN'] = _csrfToken()
      payload.jsonApi.axios.defaults.withCredentials = payload.jsonApi.use_cookies
    }
    return payload
  }
}
// TODO: exclude or override for admin
const sessionTimeoutErrorMiddleware = {
  name: 'session-timeout-error-middleware',
  error: (payload) => {
    if (payload.response && payload.response.status === 401) {
      return (window.location.pathname = '/home/back')
    }
    return payload
  }
}

apiClient.insertMiddlewareBefore('axios-request', railsCsrfMiddleware)
apiClient.insertMiddlewareBefore('errors', sessionTimeoutErrorMiddleware)

apiClient.axios = axios

ModelLoader.load(apiClient)

export { apiClient as default }

export function signOut() {
  window.location = '/account/sign-out'
}

export function updateUserLocale(locale) {
  return new Promise((resolve) => {
    apiClient.update('user', { preferred_locale: locale })
      .then(({ data }) => {
        resolve(data)
      })
      .catch((error) => {
        resolve({ error })
      })
  })
}

export function sendPromocode(data, responseCallback, errorCallback) {
  axios.post(data.path, {
    promocode: data.promocode
  })
    .then(function(response) {
      // eslint-disable-next-line no-undef
      responseCallback(promocode)
    })
    .catch(function(error) {
      errorCallback(error)
    })
}

export function sendStripeToken(data, cb) {
  axios.defaults.headers.common.Authorization = `Bearer ${data.jwt}`
  axios.defaults.headers.common['X-CSRF-Token'] = _csrfToken()
  axios.defaults.withCredentials = true

  axios.post(data.path, {
    stripe_token: data.token
  })
    .then(function(response) {
      cb(response)
    })
    .catch(function(error) {
      cb(error.response)
    })
}

export function getNotifications(page, callbackSuccess, callbackError) {
  apiClient.findAll('notification', { page: { number: page } })
    .then(({ data, meta }) => {
      callbackSuccess(data, meta)
    })
    .catch((error) => {
      callbackError(error)
    })
}

export function markAllNotificationsAsRead(callbackSuccess, callbackError) {
  apiClient.runMiddleware({
    url: apiClient.collectionUrlFor('notification') + '/read_all',
    method: 'POST',
    data: {},
    model: 'notification'
  })
    .then(() => {
      callbackSuccess()
    })
    .catch((error) => {
      callbackError(error)
    })
}

export function markNotificationAsRead(notification, callbackSuccess, callbackError) {
  apiClient.runMiddleware({
    url: apiClient.resourceUrlFor('notification', notification.id) + '/read',
    method: 'POST',
    data: {},
    model: 'notification'
  })
    .then(() => {
      callbackSuccess()
    })
    .catch((error) => {
      callbackError(error)
    })
}

export function updateIntention(sar_id, type, jwt) {
  const update_url = apiClient.apiUrl + '/slide_activity_results/' + sar_id
  axios.defaults.headers.common.Authorization = `Bearer ${jwt}`
  axios.defaults.headers.common['X-CSRF-Token'] = _csrfToken()
  axios.defaults.withCredentials = true

  axios.get(update_url + '/intention_' + type)
    .then(function(response) {
      Tbk.alerts.new(response.data.message, 'success')
      // eslint-disable-next-line no-undef
    })
}
