<template>
<div
  class='audio-player-inline'
  :class="{
    'audio-player-inline--mini': mini_mode,
    'is-playing': is_playing,
    'has-started': has_started
  }"
  @keydown='detectKeyup'
>
  <!-- Info -->
  <div class="audio-player_left-controls">
    <AudioInfo
      v-if="description && !mini_mode"
      :title="title"
      :description="description"
      :image="audio_image"
      :playlist="playlist"
      :is_popup_visible="is_info_visible"
      @toggle="is_info_visible = !is_info_visible"
    />
  </div>

  <!-- Controls -->
  <div class='audio-player_main-controls'>
    <!-- Back -->
    <button
      v-if='player && !mini_mode'
      class='audio-player_back'
      :title='$t("audio_player.back")'
      @click.prevent='seekBackward'
    >
      <IconBack />
    </button>

    <!-- Play Pause -->
    <button
      v-if='player'
      class='audio-player_play'
      :class="{'is-playing': is_playing}"
      :title='!play_title ? (is_playing ? $t("audio_player.pause") : $t("audio_player.play")) : null'
      :aria-label='!play_title ? (is_playing ? $t("audio_player.pause") : $t("audio_player.play")) : null'
      @click.prevent='playToggle'
    >
      <IconPause class='pause-icon' />
      <IconPlay class='play-icon' />
      <span
        v-if='play_title'
        class='audio-player_play-title copy-two'
      >
        {{ play_title }}
      </span>
    </button>

    <!-- Forward -->
    <button
      v-if='player && !mini_mode'
      class='audio-player_forward'
      :title='$t("audio_player.forward")'
      @click.prevent='seekForward'
    >
      <IconForward />
    </button>
  </div>

  <div class='audio-player_progress-controls'>
    <!-- Timing Current -->
    <time
      v-if="!mini_mode"
      class='audio-player_timing-current copy-four'
    >
      {{ timing_current }}
    </time>

    <!-- Timeline -->
    <div class='audio-player_timeline-container'>
      <div class='audio-player_buffer'>
        <div
          class='audio-player_buffer-progress'
          :style="{width: loading_progress + '%'}"
        />
      </div>

      <vue-slider
        v-if='duration'
        ref='timeline'
        v-model='current_time'
        class='audio-player_timeline'
        tabindex='0'
        role='slider'
        :aria-label="timing_current"
        :aria-valuemin="0"
        :aria-valuemax="Math.round(duration)"
        :aria-valuenow="current_time"
        :aria-valuetext="timing_current"
        :height="10"
        :width="'auto'"
        :max="Math.round(duration)"
        :interval="1"
        :drag-on-click="true"
        :tooltip="'hover'"
        :duration="0"
        :tooltipFormatter="timing_current"
        :lazy="true"
        :contained="false"
        :dot-size="16"
        :use-keyboard="false"
        @change="_seek"
      />
    </div>

    <!-- Timing Total -->
    <time
      v-if="!mini_mode"
      class='audio-player_timing-total copy-four'
    >
      {{ timing_total }}
    </time>
  </div>

  <div
    v-if="!mini_mode"
    class='audio-player_right-controls'
  >
    <!-- Volume -->
    <div
      class='audio-player_volume'
      :class="{
        'audio-player_volume--muted': is_muted,
        'audio-player_volume--hi': volume > 80,
        'audio-player_volume--mid': volume > 50,
        'audio-player_volume--low': volume > 0
      }"
      @mouseover="is_volume_control_visible = true"
      @mouseleave="is_volume_control_visible = false"
      @focusin="is_volume_control_visible = true"
      @focusout="is_volume_control_visible = false"
    >
      <button
        class='audio-player_volume-button'
        :title="$t('audio_player.volume') + ': ' + volume"
        :aria-label="$t('audio_player.volume') + ': ' + volume + ', ' + $t('audio_player.volume_a11')"
        @click.prevent="toggleMute()"
      >
        <IconVolume />
      </button>

      <transition name='fast-fade'>
        <div
          v-if="is_volume_control_visible"
          class='audio-player_popup audio-player_popup--volume'
          @mouseover="is_volume_control_visible = true"
          @mouseleave="is_volume_control_visible = false"
        >
          <vue-slider
            ref='volume_control'
            v-model="volume"
            class='audio-player_volume-control'
            :width="10"
            :height="120"
            :direction="'btt'"
            :drag-on-click="true"
            :tooltip="'hover'"
            :duration=".2"
            :contained="true"
            :use-keyboard="false"
            @change="setVolume"
          />
        </div>
      </transition>
    </div>

    <!-- Settings -->
    <div
      class='audio-player_settings'
      @mouseover="is_speed_control_visible = true"
      @mouseleave="is_speed_control_visible = false"
    >
      <button
        class='audio-player_settings-toggle'
        :title='$t("audio_player.change_speed")'
        aria-haspopup='menu'
        aria-controls='audio-player_popup--speed'
        :aria-label="speed + 'x. ' + $t('audio_player.change_speed_a11')"
        :aria-expanded='is_speed_control_visible || "false"'
        @click.prevent="is_speed_control_visible = !is_speed_control_visible"
        @focusin="is_speed_control_visible = true"
        @focusout="is_speed_control_visible = false"
      >
        <IconGear />
      </button>

      <div
        v-if="is_speed_control_visible"
        id='audio-player_popup--speed'
        class='audio-player_popup audio-player_popup--speed'
        role='menu'
        aria-labelledby='audio-player_popup--speed__label'
      >
        <div
          id='audio-player_popup--speed__label'
          class='copy-four'
        >
          Playback Speed
        </div>

        <div
          v-for='rate in speed_rates'
          :key='rate'
          class='audio-player_speed-item'
        >
          <button
            :class="{'active': speed === rate}"
            class='copy-four'
            tabindex='-1'
            role='menuitemradio'
            :aria-checked='speed === rate'
            @click.prevent='setRate(rate)'
          >
            {{ rate }}x
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
/* global Howl Howler */
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import AudioPlayerMixin from './AudioPlayerMixin.js'
import AudioInfo from './AudioPlayerPopupInfo'

import { toRaw } from 'vue'

export default {
  mixins: [AudioPlayerMixin],
  props: [
    // basic parameters
    'file_url',
    'should_pause',
    'onPlay',
    'onComplete',
    'onStep',

    // playlist parameters
    'playlist',

    // meta info for notification area
    'audio_title',
    'audio_description',
    'audio_author',
    'audio_image',
    'audio_playlist_title',

    // for analytics
    'audio_id',

    // mini player mode (trailer)
    'mini_mode', // to toggle mini/trailer mode on/off
    'play_title' // play button caption (optional)
  ],

  data() {
    return {
      is_playing: false,
      has_started: false
    }
  },

  components: {
    AudioInfo,
    VueSlider
  },

  watch: {
    should_pause: function(val) {
      if (val === true && this.is_playing) {
        this.player.pause()
        this.is_playing = false
      }
    }
  },

  methods: {
    _initPlayer() {
      this.player = new Howl({
        src: [this.file_url],
        html5: true,         // required to keep a sound pitch when the rate/speed changes
        preload: this.is_firefox || 'metadata', // will load only file meta information, not the file itself
        volume: this.volume / 100,
        rate: this.speed,
        onload: (id) => {
          this.duration = this.player.duration()
          this.current_time = 0

          // set a listener on window close
          addEventListener('beforeunload', this.beforeUnloadListener, { capture: true })

          // track loading progress
          const node = this.player._sounds[0]._node
          node.addEventListener('progress', () => {
            // https://developer.mozilla.org/en-US/Apps/Fundamentals/Audio_and_video_delivery/buffering_seeking_time_ranges#Creating_our_own_Buffering_Feedback
            if (this.duration > 0) {
              for (let i = 0; i < node.buffered.length; i++) {
                if (node.buffered.start(node.buffered.length - 1 - i) < node.currentTime) {
                  this.loading_progress = (node.buffered.end(node.buffered.length - 1 - i) / this.duration) * 100
                  break
                }
              }
            }
          })
          this._setNotifications()
        },
        onplay: (id) => {
          if (Howler._howls.length > 1) { // stop all other Howler players
            Howler._howls.forEach(other_player => {
              if (other_player.playing() && other_player !== toRaw(this.player)) {
                other_player.pause()
              }
            })
          }

          // send start analytics on first playback
          if (!this.has_started) {
            this.sendStartAnalytics()
          }

          this.has_started = this.is_playing = true

          if (typeof (this.onPlay) === 'function') {
            this.onPlay()
          }

          requestAnimationFrame(this._step.bind(this))

          this._setNotifications()
        },
        onstop: (id) => {
          if (this.has_started) {
            this.sendStopAnalytics()
          }
          this.is_playing = false // in case Howler was stopped externally
        },
        onpause: (id) => {
          this.is_playing = false // in case Howler was stopped externally
        },
        onend: (id) => {
          this.sendStopAnalytics()
          this.current_time = 0
          this.is_playing = false

          if (typeof (this.onComplete) === 'function') {
            this.onComplete()
          }
        },
        onseek: (id) => {
          requestAnimationFrame(this._step.bind(this))
        }
      })
    },

    playToggle() {
      if (!this.is_playing) {
        if (this.sound_id) {
          this.player.play(this.sound_id)
        } else { // first play
          this.sound_id = this.player.play()
        }
      } else {
        this.player.pause(this.sound_id)
        this.is_playing = false
      }
    },

    sendStartAnalytics() {
      if (this.audio_title && this.audio_id) {
        dataLayer.push({
          event:           'episodeStart',
          episodeType:     'single',
          episodeTitle:    this.audio_title,
          episodeID:       this.audio_id
        })
      }
    },

    sendStopAnalytics() {
      if (this.audio_title && this.audio_id) {
        dataLayer.push({
          event:           'episodeStop',
          episodeType:     'single',
          episodeTitle:    this.audio_title,
          episodeID:       this.audio_id,
          audioProgress:   this.current_time || 0,
          audioCompletion: this.duration ? Math.round(this.current_time) / Math.round(this.duration) : 0
        })
      }
    },

    beforeUnloadListener(e) {
      this.sendStopAnalytics()
    }

  }
}
</script>

<style lang='sass' scoped>
  @import './AudioPlayerInline'
</style>
