<template>
  <div
    class='learning-plan_header'
    :class="{'expanded' : expanded}"
    @click.stop='expandToggle'
  >
    <div
      class='learning-plan_info-block'
      :style='header_image'
    >
      <div
        v-if='show_sticker'
        class='learning-plan_badge-wrapper'
      >
        <div
          class='learning-plan_badge'
          :style='sticker_image'
        />
      </div>

      <h2 class='learning-plan_name heading-four'>
        {{ learning_plan.title }}
      </h2>

      <a
        href="#expand"
        class='learning-plan_lessons copy-one'
        @click.prevent.stop='expandToggle'
      >
        <span class='expand-icon' />
        {{ total_courses_text }}
      </a>

      <span class='learning-plan_separator copy-one'> | </span>

      <template v-if="learning_plan.syllabus">
        <a
          :href="learning_plan.syllabus"
          class='learning-plan_pdf copy-one'
          target='_blank'
        >
          <PdfIcon />
          {{ $t('download_syllabus') }}
        </a>
        <span class='learning-plan_separator copy-one'> | </span>
      </template>

      <router-link
        :to="{ name: 'learning-plan-overview', params: {identifier: learning_plan.identifier} }"
        class='learning-plan_details copy-one'
      >
        {{ $t('view_details') }}
      </router-link>
    </div>

    <div class='learning-plan_status-block'>
      <div
        v-if="!learning_plan.completion_date"
        class='learning-plan_progress-block'
      >
        <div class='learning-plan_progress'>
          <p class='learning-plan_progress-text copy-three'>
            {{ progress_text }}
          </p>

          <div
            v-if="learning_plan.completion_criteria"
            class='learning-plan_info-button'
          >
            <InfoIcon />
            <div class='learning-plan_info-popup'>
              <h3 class='learning-plan_info-header copy-three'>
                {{ $t('completion') }} {{ $t('criteria') }}
              </h3>

              <p class='learning-plan_info-text copy-four'>
                {{ learning_plan.completion_criteria }}
              </p>
            </div>
          </div>
        </div>

        <div class='learning-plan_progress-bar'>
          <div
            class='learning-plan_progress-line'
            :style='progress_line'
            :title='progress_line_title'
           />
        </div>
      </div>

      <div
        v-else
        class='learning-plan_achievement-block'
      >
        <div class='learning-plan_achievement-date copy-three'>
          {{ $t('achieved') }} {{ formatted_completion_date }}
        </div>

        <a
          v-if='learning_plan.badge_url'
          :href='badge_sso_url'
          class='learning-plan_share-button action-one-branded'
          :class="{'button_loading': !badge_sso_url}"
          target='_blank'
        >
          {{ $t('view_my_badge') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'

import { useHomeStore } from 'home/stores/home'
import { mapActions } from 'pinia'

import PdfIcon from './pdf-icon.svg'
import InfoIcon from './info-icon.svg'

export default {
  props: ['learning_plan', 'expandToggle', 'expanded'],

  data() {
    return {
      badge_sso_url: null,
      pdfIconUrl: null,
      infoIconUrl: null
    }
  },

  components: {
    PdfIcon,
    InfoIcon
  },

  created() {
    if (this.learning_plan.completion_date) {
      this.getBadgeData({
        id: this.learning_plan.id,
        cb: (data) => {
          if (data.type === 'badge_assertions') {
            this.badge_sso_url = data.badge_assertion_sso_url
          }
        }
      })
    }

    this.pdfIconUrl = require('./pdf-icon.svg').default
    this.infoIconUrl = require('./info-icon.svg').default
  },

  computed: {
    sticker_image() {
      return `background-image: url(${this.learning_plan.sticker_image})`
    },

    show_sticker() {
      return this.learning_plan.badging_enabled
    },

    header_image() {
      return `background-image: url(${this.learning_plan.overview_page.overview_image})`
    },

    required_lessons() {
      return this.learning_plan.required_lessons
    },

    required_and_completed_lessons() {
      return this.learning_plan.completed_required_lessons
    },

    progress_line() {
      return `width: ${this.learning_plan.progress}%`
    },

    progress_line_title() {
      return `Completed: ${this.learning_plan.progress}%`
    },

    total_courses_text() {
      return `${this.learning_plan.lessons.length} ${this.$t('lessons')}`
    },

    progress_text() {
      return `${this.required_and_completed_lessons} ${this.$t('of')} ${this.required_lessons} ${this.$tc('learning_plan_lesson', this.required_lessons)} ${this.$t('complete')}`
    },

    formatted_completion_date() {
      return this.learning_plan.completion_date && format(new Date(this.learning_plan.completion_date), 'MM/dd/yyyy')
    }
  },

  methods: {
    ...mapActions(useHomeStore, [
      'getBadgeData'
    ])
  }
}
</script>

<style lang='sass' scoped>
  @import './Header'
</style>
