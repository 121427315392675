<template>
<router-link
  :to="{ name: 'learning-plan-overview', params: {identifier: learning_plan.identifier} }"
  class='instructor-learning-plan-card'
>
  <div
    class='instructor-learning-plan-card_image'
    :style='card_image'
  />

  <div class='instructor-learning-plan-card_content'>
    <div class='instructor-learning-plan-card_title heading-five'>
      {{ learning_plan.title }}
    </div>

    <div class='instructor-learning-plan-card_intro copy-three'>
      <CertIcon
        v-if="learning_plan.badging_enabled"
      />
      {{ learning_plan.card_intro }}
    </div>
  </div>
</router-link>
</template>

<script>
import CertIcon from './cert.svg'

export default {
  props: ['learning_plan'],

  components: {
    CertIcon
  },

  computed: {
    card_image() {
      return `background-image: url(${this.learning_plan.card_image})`
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './LearningPlanCard'
</style>
