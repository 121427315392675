<template>
<div class='intention-setter_question_options intention-setter_question_options--text'>
  <div
    v-for='(row, index) in options_array'
    :key='index'
    class='intention-setter_question_options-row'
  >
    <div
      v-for='(option, option_index) in row'
      :key='option_index'
      class='intention-setter_question_options-cell'
    >
      <button
        class='intention-setter_question_options_option'
        :class="{'selected' : isOptionSelected(option)}"
        @click.prevent='selectOption(option)'
      >
        <span class='intention-setter_question_options_option-circle'>
          <template v-if="isOptionSelected(option)">
            <CompletedIcon />
          </template>
        </span>

        <span class='intention-setter_question_options_option-text copy-two'>
          {{ option.text }}
        </span>
      </button>
    </div>
  </div>
</div>
</template>

<script>
import CompletedIcon from './completed.svg'

export default {
  props: [
    'options',
    'selectOption',
    'isOptionSelected'
  ],

  components: {
    CompletedIcon
  },

  computed: {
    options_array() {
      const rows = []; const items = this.options.slice()

      // let items_in_row = this.two_column ? 2 : 1
      const items_in_row = 1

      while (items.length) {
        rows.push(items.splice(0, items_in_row))
      }

      return rows
    }

  }
}
</script>

<style lang='sass' scoped>
  @import 'Text'
</style>
