<template>
<div class='learning-plans-page'>
  <!-- Custom Page Title -->
  <PageTitle :title='page_title' />

  <LearningPlanSection
    :section='all_plans_section'
    context='catalog'
  />
</div>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapState } from 'pinia'

import LearningPlanSection from 'home/components/shared/SectionDrawer/LearningPlanSection/LearningPlanSection.vue'
import PageTitle from 'components/Header/PageTitle'

export default {
  props: ['learning_plans'],

  components: {
    LearningPlanSection,
    PageTitle
  },

  computed: {
    ...mapState(useHomeStore, [
      'partner_name'
    ]),

    all_plans_section() {
      return {
        headline:     this.$t('learning_plans_page_headline'),
        subheadline:  this.$t('learning_plans_page_subheadline'),
        learning_plans: Object.keys(this.learning_plans).map(e => this.learning_plans[e])
      }
    },

    page_title() {
      return this.$t('learning_plans') +
        ' | ' +
        this.partner_name
    }
  }
}

</script>

<style lang='sass'>
  .learning-plans-page
    .section-drawer.learning_plans-section.section-drawer--learning-plans
      background-color: #0A021D
      background-image: none
</style>
