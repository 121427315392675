<template>
<li class='program-card'>
  <a
    :href='program.url'
    target='_blank'
  >
    <div
      class='program-card_image'
      :style='programImage'
    />

    <div class='program-card_name copy-four'>
      {{ program.name }}
    </div>
  </a>
</li>
</template>

<script>
export default {
  props: ['program'],

  computed: {
    programImage() {
      return `background-image: url(${this.program.image})`
    }
  }
}
</script>

<style lang='sass' scoped>
  @import './ProgramCard'
</style>
