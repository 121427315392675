<template>
<div class="scale-select">
  <div
    v-if='has_response && show_stats && total_responses_headline'
    class='scale-select_total copy-two'
  >
    {{ total_responses_headline }}
  </div>

  <div
    :class='[options.length > 8 ? "with-many-options" : ""]'
    class='scale-select_options'
  >
    <template
      v-for='option in options'
      :key='option.id'
    >
      <button
        class='scale-select_option'
        :class='optionClasses(option)'
        :disabled='has_response'
        @click.prevent='selectPollOption({selected: option.id})'
      >
        <span class='scale-select_option-circle' />
        <span
          ref='option_text'
          :style='option_min_height'
          class='scale-select_option-text copy-two'
        >
          {{ option.text }}
        </span>

        <span
          v-if='has_response && show_stats'
          class='scale-select_option-percent copy-four'
        >
          {{ resultPercent(option.id) }}%
        </span>
      </button>

      <span class='divider' />
    </template>
  </div>
</div>
</template>

<script>
export default {
  props: [
    'options',
    'selected_answer',
    'selectPollOption',
    'resultPercent',
    'show_stats',
    'total_responses',
    'activity'
  ],

  data() {
    return {
      option_height: null
    }
  },

  computed: {
    has_response() {
      return !!this.selected_answer
    },

    total_responses_headline() {
      if (this.activity.total_responses_headline) {
        return this.activity.total_responses_headline.replace('%{total}', this.total_responses)
      } else return null
    },

    option_min_height() {
      return 'min-height: ' + this.option_height + 'px'
    }
  },

  mounted() {
    this._calculateOptionHeights()
    setTimeout(() => {
      this._calculateOptionHeights()
    }, 300)

    window.addEventListener('resize', this._calculateOptionHeights)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this._calculateOptionHeights)
  },

  methods: {
    optionClasses(option) {
      const classes = []

      classes.push(option.text && option.text.length > 2 ? 'long' : 'short')

      if (this.selected_answer === option.id) { classes.push('selected') }

      if (this.has_response && this.show_stats) { classes.push('show-percent') }

      return classes
    },

    _calculateOptionHeights() {
      this.$refs.option_text.forEach((o) => {
        if (this.option_height < $(o).outerHeight()) {
          this.option_height = $(o).outerHeight()
        }
      })
    }

  }
}
</script>

<style lang='sass' scoped>
  @import 'ScaleSelect'
</style>
