// model based on API::V1::Activity::V1::PollSerializer
export default {

  // Images
  background_image: null,
  gradient_start: null,
  gradient_end: null,

  // Attributes
  identifier: null,
  label: null,
  headline: null,
  prompt: null,
  answers: null,
  show_stats: null,
  show_response: null,
  view_style: null,
  total_responses_headline: null
}
