<template>
  <div class='sign-in-form'>
    <VeeForm
      v-slot="{ meta }"
      class='sign-in-form_form'
      name='login'
      @submit='submitForm'
    >
      <div class='sign-in-form_field'>
        <Field
          id='user_email'
          v-model='user_email'
          name='user_email'
          type='text'
          :class="{'not-empty': user_email }"
          aria-describedby="err_account"
          rules='required|email'
        />

        <label for='user_email'>{{ $t('email') }}</label>
      </div>

      <div class='sign-in-form_field'>
        <Field
          id='user_password'
          v-model='user_password'
          name='user_password'
          type='password'
          :class="{'not-empty': user_password }"
          aria-describedby="err_account"
          rules='required'
        />

        <label for='user_password'>{{ $t('password') }}</label>
      </div>

      <div class='sign-in-form_field'>
        <input
          type='submit'
          :value="$t('log_in')"
          class='sign-in-form_submit-button action-one-branded'
          :disabled='submitDisabled(meta)'
          @click.prevent='submitForm'
        >
      </div>

      <template v-if='toggleAccountOverlay'>
        <a
          href="/account/forgot-password"
          class='sign-in-form_link copy-four'
          @click.prevent='toggleAccountOverlay({context: "forgot-password"})'
        >
          {{ $t('forgot_password') }}
        </a>
      </template>

      <template v-else>
        <router-link
          :to="{ path: '/forgot-password'}"
          class='sign-in-form_link copy-four'
        >
          {{ $t('forgot_password') }}
        </router-link>
      </template>
    </VeeForm>
  </div>
</template>

<script>
import { Form as VeeForm, Field } from 'vee-validate'
import { defineRule } from 'vee-validate'
import { required, email } from '@vee-validate/rules'

import { signIn } from '../../../api'

export default {
  props: [
    'auth_data',
    'redirect_to_location',
    'toggleAccountOverlay'
  ],

  data() {
    return {
      timer: null,
      user_email: null,
      user_password: null
    }
  },

  components: {
    VeeForm,
    Field
  },

  created() {
    // validation rules
    defineRule('required', required);
    defineRule('email', email);
  },

  beforeUnmount() {
    if (this.timer) clearInterval(this.timer)
  },

  computed: {
    is_ios() {
      const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
      if (iOS) { return true }
      return false
    }
  },

  methods: {
    submitForm() {
      const data = {
        path: this.auth_data.auth_path,
        email: this.user_email,
        password: this.user_password
      }

      signIn(data, (response) => {
        if (response.status === 200) {
          window.location = this.redirect_to_location || window.location.origin
        } else {
          this.clearForm()
        }

        if (response.status === 401) {
          document.login.user_email.focus()
          Tbk.alerts.new(response.data.message, 'error')
        }
      })
    },

    clearForm() {
      this.user_password = null
    },

    submitDisabled(meta) {
      const has_input = this.user_email && this.user_password
      if (has_input && this.is_ios) return false

      return !meta.valid
    }
  }
}

</script>

<style lang='sass' scoped>
  @import './SignInForm'
</style>
