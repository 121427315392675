<template>
  <div
    class='avatar'
    :class='classes'
  >
    <div
      v-if='!as_image'
      :style='avatar_image'
      class='avatar_main'
    />

    <img
      v-if='as_image && image_path'
      :src='image_path'
      :alt='alt || "Avatar Image"'
      class='avatar_main avatar_main--image'
    >

    <div class='avatar_default' />
  </div>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapState } from 'pinia'

export default {
  props: [
    'image',
    'alt',
    'placeholder',
    'className',
    'orientation',
    'as_image'
  ],

  computed: {
    ...mapState(useHomeStore, [
      'user_load_ts'
    ]),

    avatar_image() {
      if (this.image_path) {
        return `background-image: url(${this.image_path})`
      }
      return null
    },

    image_path() {
      if (this.image) {
        return this.image
      } else if (this.placeholder) {
        return this.placeholder
      } else if (this.user_load_ts && !this.image) {
        return ''
      }
      return null
    },

    classes() {
      const classes = [this.className]
      if (this.image_path) classes.push('has-image')

      classes.push(this._imageOrientationClass(this.orientation))

      return classes
    }
  },

  methods: {
    _imageOrientationClass(orientation) {
      if (!orientation) return false

      let klass = ''
      switch (orientation) {
        case 1:
          break
        case 2:
          break
        case 3:
          klass = 'rotate180'
          break
        case 4:
          klass = 'rotate180'
          break
        case 5:
          klass = 'rotate90'
          break
        case 6:
          klass = 'rotate90'
          break
        case 7:
          klass = 'rotate270'
          break
        case 8:
          klass = 'rotate270'
          break
      }
      return klass
    }
  }
}

</script>

<style lang='sass' scoped>
  @import './Avatar'
</style>
