<template>
  <!-- Info -->
  <div class='audio-player_info'>
    <button
      class='audio-player_info-toggle'
      aria-controls='audio-player_popup'
      :aria-expanded='is_info_visible ? "true" : "false"'
      :title='$t("audio_player.info")'
      :aria-label='$t("audio_player.info")'
      @click.prevent="toggleInfo"
    >
      <IconKebab />
    </button>

    <transition name='fast-fade'>
      <div
        v-if="is_info_visible"
        id='audio-player_popup'
        class='audio-player_popup audio-player_popup--info'
        :class='{"audio-player_popup--gray-arrow": is_resource_carousel_visible}'
      >
        <div class='audio-player_info-container'>
          <div class='audio-player_info-inner'>
            <div class='audio-player_info-sidebar'>
              <div
                class='audio-player_info-cover'
                :style='cover_image'
              />

              <div class='audio-player_info-actions'>
                <a
                  v-if='transcript_url'
                  :href='transcript_url'
                  class='info-action info-action--transcript'
                  target='_blank'
                >
                  <IconTranscript class="info-action_icon" />
                  <span class="info-action_title">{{ $t('view_transcript') }}</span>
                </a>
                <!-- Placeholder for buttons -->
              </div>
            </div>

            <div class='audio-player_info-description copy-four'>
              <div
                v-if="playlist"
                class='audio-player_info-subtitle'
              >
                <router-link
                  v-if="playlist.identifier && playlist.title"
                  class='audio-player_info-subtitle-link'
                  :to="{ name: 'audio-overview', params: {identifier: playlist.identifier}}"
                  @click="toggleInfo"
                >
                  {{ playlist.title }}
                </router-link>
              </div>

              <h2 class='audio-player_info-title copy-four'>
                {{ title }}
              </h2>

              <div
                class='audio-player_info-text'
                v-html="description"
              />
            </div>
          </div>

          <div
            v-if='is_resource_carousel_visible'
            class='audio-player_info-resources'
          >
            <h3
              v-if='is_resource_carousel_headline_visible'
              class='audio-player_info-resources-headline copy-four'
            >
              {{ $t('related_resources') }}
            </h3>

            <CardSlider
              :autoplay='false'
              :cards='resources_as_cards'
              :cardClick='resourceClick'
              :centerIfLessItems='true'
              :infinite='false'
              :miniMode='true'
              :hideNavigation='!is_resource_carousel_headline_visible'
              :mouseDrag='true'
              :speed='4000'
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapActions } from 'pinia'

import CardSlider from 'components/Shared/CardSlider/CardSlider'
import IconKebab from './icons/kebab.svg'
import IconTranscript from './icons/transcript.svg'

export default {
  props: [
    'title', 'description', 'image', 'playlist',
    'transcript_url', 'is_popup_visible', 'hide_resources'
  ],

  emits: ['toggle'],

  data() {
    return {
      is_info_visible: this.is_popup_visible || false
    }
  },

  watch: {
    // watch for external toggle flag
    'is_popup_visible'(value) {
      this.is_info_visible = value
      this._checkListener()
    }
  },

  components: {
    CardSlider,
    IconTranscript,
    IconKebab
  },

  computed: {
    cover_image() {
      return `background-image: url(${this.image})`
    },

    resources_as_cards() {
      const cards = this.playlist.resources.map((resource) => {
        return {
          id:           resource.id,
          image:        resource.image,
          type:         this._getResourceType(resource),
          headline:     resource.headline,
          activity:     resource.activity
        }
      })
      return cards
    },

    is_resource_carousel_visible() {
      return !this.hide_resources && !!this.playlist && !!this.playlist.configuration && !!this.playlist.configuration.sticky_player_resource_carousel_visible && this.playlist.resources && this.playlist.resources.length
    },

    is_resource_carousel_headline_visible() {
      return !!this.playlist && !!this.playlist.configuration.sticky_player_resource_carousel_headline_visible
    }

  },

  methods: {
    ...mapActions(useHomeStore, [
      'toggleResourceOverlay'
    ]),

    toggleInfo(e) {
      if (this.$attrs && this.$attrs.toggle) {
        // if there is an external toggle event - call it
        this.$emit('toggle', e)
      } else {
        // otherwise toggle local flag
        this.is_info_visible = !this.is_info_visible
        this._checkListener()
      }
    },

    infoListener(e) {
      const parent = e ? e.target.closest('.audio-player_info') : null
      if (parent && (parent === this.$el || parent.classList.contains('audio-player_info'))) { // if clicked into this particular(!) info popup
        return false
      } else {
        if (this.$attrs && this.$attrs.toggle) {
          // if there is an external toggle event - call it
          this.$emit('toggle', e)
        } else {
          // otherwise toggle local flag
          this.is_info_visible = false
          this._checkListener()
        }
      }
    },

    _checkListener() {
      if (this.is_info_visible) {
        document.body.addEventListener('click', this.infoListener)
      } else {
        document.body.removeEventListener('click', this.infoListener)
      }
    },

    resourceClick(resource) {
      this.toggleResourceOverlay({ resource })
    },

    _getResourceType(resource) {
      switch (resource.activity.type) {
        case 'activity_v1_videos': return 'video'
        case 'activity_v1_audios': return 'audio'
        case 'activity_v1_articles': return 'article'
        case 'activity_v1_resources':
          if (resource.activity.link.href.match(/pdf/) !== null) return 'PDF'
          if (resource.activity.link.href.match(/file/) !== null) return 'download'
          break
        default: return 'link'
      }
    }

  }
}
</script>

<style lang="sass" scoped>
.audio-player_info
  margin-right: 20px
  position: relative

  @media (max-width: 640px), (max-height: 697px) and (max-width: 1024px)
    position: static

.audio-player_info-toggle
  width: 24px
  height: 24px
  background: transparent
  border: none
  padding: 0
  margin: 0
  -webkit-appearance: none
  cursor: pointer

  svg
    width: 24px
    height: 24px

// info popup
.audio-player_popup--info
  position: absolute
  bottom: 100%
  width: 480px
  max-width: 100vw
  padding: 0
  background: #fff
  color: #333
  box-sizing: border-box
  z-index: 999
  box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.3)
  transform: translate(-15px, -15px)

  @media (max-width: 640px), (max-height: 697px) and (max-width: 1024px)
    left:   5px
    right:  5px
    bottom: 80px
    width:  calc(100vw - 10px)
    max-height: calc(100vh - 85px)
    transform: none

  &:before // triangle
    content: ""
    display: block
    position: absolute
    bottom: -6px
    left: 22px
    width: 12px
    height: 12px
    background: #fff
    transform: rotate(45deg)
    box-shadow: 0 -1px 8px rgba(0,0,0,.3)

  &:after
    content: ""
    display: block
    position: absolute
    bottom: 0
    left: 14px
    width: 30px
    height: 15px
    background: #fff

.audio-player_popup--gray-arrow
  &:before,
  &:after
    background: #eef0f0
    z-index: 1

.audio-player_info-cover
  width: 100%
  height: 0
  padding-top: 100%
  background-size: cover
  margin-bottom: 20px

.audio-player_info-container
  padding: 20px
  max-height: calc(100vh - 125px)
  overflow-y: auto
  overflow-x: hidden
  position: relative
  z-index: 2

.audio-player_info-inner
  display: flex
  flex-direction: row
  flex-wrap: nowrap

  @media (max-width: 480px)
    flex-wrap: wrap

    .audio-player_info-sidebar
      flex-basis: 100%
      display: flex
      flex-wrap: nowrap

    .audio-player_info-cover
      order: 0
      width: 150px
      height: 150px
      padding: 0
      margin: 0 20px 20px 0

    .audio-player_info-description
      flex-basis: 100%

  @media (max-width: 823px) and (orientation: landscape)
    .audio-player_info-sidebar
      flex-basis: 15%

.audio-player_info-sidebar
  flex: 0 0 150px
  padding-right: 20px

  .audio-player_cover
    width: 100%
    height: 0
    padding-top: 100%

  .audio-player_info-actions
    flex: 1

.info-action
  color: inherit
  text-decoration: none
  margin-bottom: 10px
  display: flex
  align-items: center

  &:last-child
    margin-bottom: 20px

  &:hover
    .info-action_title
      text-decoration: underline

.info-action_icon
    width: 24px
    height: 24px
    vertical-align: middle
    margin-right: .3em
    flex-shrink: 0
    flex-grow: 0

.info-action_title
  font-weight: 500

.audio-player_info-description
  flex: 1
  &.copy-four,
  .copy-four
    color: #0B0726

.audio-player_info-title
  font-weight: 600
  margin: 0

.audio-player_info-subtitle
  opacity: .8

.audio-player_info-subtitle-link
  color: inherit
  text-decoration: none
  &:hover,
  &:focus
    text-decoration: underline

.audio-player_info-resources
  background: #eef0f0
  text-align: center
  margin: 0 -20px -20px

  :deep(.card-slider)
    margin: 0 10px

    @media (max-width: 480px)
      margin: 0

    .card_headline
      font-size: .9em

      &.copy-three
        color: #4A4AD5

    .card
      margin: 10px 10px 20px

    .card_image
      margin-bottom: 10px

    .carousel__prev,
    .carousel__next
      margin-top: 0
      height: auto
      transform: translateY(-32px) scale(.5)
      opacity: .5
      display: block

      &.is-disabled
        display: none

      .chevron
        stroke: #0B0726

    .carousel__slide:first-of-type
      /* stylelint-disable-next-line annotation-no-unknown */
      margin-left: 0 !important

.audio-player_info-resources-headline
  text-transform: uppercase
  margin: 0
  padding: 10px 0 0
  font-weight: 500

  &.copy-four
    color: #0B0726

</style>
