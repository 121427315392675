<template>
<div>
<!-- Custom Page Title -->
<PageTitle
  v-if='!loading'
  :title='page_title'
/>

<!-- Preloader -->
<PageLoader v-if='loading' />

<OverviewPage v-if='!loading'>
  <!-- Hero -->
  <template #hero>
    <OverviewHero
      :image='learning_plan.overview_page.overview_image'
      :title='learning_plan.title'
      :subtitle='learning_plan.card_intro'
      :trailer_video='trailer_video'
      :trailer_text='trailer_text'
    />
  </template>

  <!-- Main Content -->
  <template #content-main>
    <!-- Overview -->
    <OverviewSection>
      <template #title>
        <h1
          v-if="!learning_plan.title"
          class='heading-three'
        >
          {{ $t('overview') }}
        </h1>

        <h2
          v-else
          class='heading-three'
        >
          {{ $t('overview') }}
        </h2>
      </template>

      <template #content>
        <div
          class="learning-plan-page_content-overview copy-one"
          v-html="learning_plan.overview_page.introduction"
        />
      </template>
    </OverviewSection>
    <!-- EndOverview -->

    <!-- Lessons -->
    <OverviewSection>
      <template #title>
        <h2 class='heading-five'>
          {{ $tc('learning_plan_lesson', 2) }}
        </h2>
      </template>

      <template #content>
        <div
          class="learning-plan-page_section-description copy-one"
          v-html="learning_plan.overview_page.lessons_introduction"
        />

        <a
          v-if="learning_plan.syllabus"
          :href="learning_plan.syllabus"
          class="learning-plan-page_download-syllabus copy-one"
          target="_blank"
        >
          <SyllabusIcon />
          {{ $t('download_syllabus') }}
        </a>

        <div class="learning-plan-page_lessons">
          <Lesson
            v-for="(lesson, index) in learning_plan.lessons"
            :key="index"
            :lesson="lesson"
            :index="index"
            :learning_plan='learning_plan'
          />
        </div>
      </template>
    </OverviewSection>
    <!-- End Lessons -->

    <!-- Instructors -->
    <OverviewSection
      v-if="learning_plan.instructors.length"
    >
      <template #title>
        <h2 class='heading-five'>
          {{ $t('instructors') }}
        </h2>
      </template>

      <template #content>
        <div class="learning-plan-page_instructors">
          <Instructor
            v-for="(instructor, index) in learning_plan.instructors"
            :key="index"
            :instructor="instructor"
          />
        </div>
      </template>
    </OverviewSection>
    <!-- End Lessons -->
  </template>
  <!-- Left Block End -->

  <!-- Info Block -->
  <template #content-info>
    <OverviewBadge
      :image='learning_plan.sticker_image'
    >
      <a
        v-if="!learning_plan.enrolled"
        :href='`/learning-plans/${learning_plan.identifier}/continue`'
        class="learning-plan-page_info-resume action-one"
        @click='authGateway($event)'
      >
        {{ $t('begin') }}
      </a>

      <a
        v-if="learning_plan.enrolled && !learning_plan.completion_date"
        :href="`/learning-plans/${learning_plan.identifier}/continue`"
        class="learning-plan-page_info-resume action-one"
      >
        {{ $t('resume') }}
      </a>
    </OverviewBadge>
  </template>
  <!-- End Info Block -->
</OverviewPage>
</div>
</template>

<script>
import { useHomeStore } from 'home/stores/home'
import { mapState, mapActions } from 'pinia'

import Lesson from './LearningPlanLesson'
import Instructor from './LearningPlanInstructor'
import PageLoader from 'components/PageLoader/PageLoader'
import PageTitle from 'components/Header/PageTitle'
import LearningPlan from 'home/models/learning_plan'
import SyllabusIcon from './pdf.svg'

import OverviewPage from 'home/components/shared/Overview/OverviewPage'
import OverviewSection from 'home/components/shared/Overview/OverviewSection'
import OverviewBadge from 'home/components/shared/Overview/OverviewBadge'
import OverviewHero from 'home/components/shared/Overview/OverviewHero'

export default {
  props: ['identifier'],

  components: {
    Lesson,
    Instructor,
    PageLoader,
    PageTitle,
    SyllabusIcon,

    OverviewPage,
    OverviewHero,
    OverviewSection,
    OverviewBadge
  },

  data() {
    return {
      learning_plan: null,
      loading: false
    }
  },

  created() {
    this.loadLearningPlan()
    this.syllabusImageUrl = require('./pdf.svg').default
  },

  mounted() {
    if (this.loading) {
      return false
    }
  },

  watch: {
    loading: function(value) {
      if (value) {
        return false
      }
    }
  },

  computed: {
    ...mapState(useHomeStore, [
      'user',
      'partner_name'
    ]),

    page_title() {
      return this.learning_plan.title +
        ' | ' +
        this.$route.name.replace(/[-_]/g, ' ').replace(/(^|\s)\w/g, char => char.toUpperCase()) +
        ' | ' +
        this.partner_name
    },

    trailer_text() {
      return this.learning_plan.trailer_cta || this.$t('watch_trailer')
    },

    trailer_video() {
      return this.learning_plan.trailer
    }
  },

  methods: {
    ...mapActions(useHomeStore, ['authGateway']),

    loadLearningPlan() {
      this.loading = true

      this.learning_plan = LearningPlan.load(
        this.identifier,
        () => {
          this.loading = false
        }
      )
    }
  }
}
</script>

<style lang='sass'>
  @import './LearningPlanPage'
</style>
