<template>
<div class='intention-setter_close-confirm'>
  <div
    v-if='trigger.image'
    class='intention-setter_close-confirm_image'
  >
    <img
      :src='trigger.image'
      :alt='trigger.title'
    >
  </div>

  <h2 class='intention-setter_close-confirm_title copy-one'>
    {{ trigger.title }}
  </h2>

  <div class='intention-setter_close-confirm_description copy-two'>
    {{ trigger.content }}
  </div>

  <button
    class='intention-setter_close-confirm_continue action-one'
    @click.prevent='continueIntentionSetter'
  >
    {{ trigger.cta }}
  </button>

  <button
    class='intention-setter_close-confirm_leave copy-three'
    @click.prevent='close'
  >
    {{ $t('leave_activity') }}
  </button>
</div>
</template>

<script>
export default {
  props: [
    'toggle',
    'trigger'
  ],

  methods: {
    continueIntentionSetter() {
      this.$parent.show_close_confirm = false
    },

    close() {
      this.toggle()
    }
  }
}
</script>

<style lang='sass' scoped>
  @import 'CloseConfirm'
</style>
