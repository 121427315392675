import WistiaClient from './wistia-client'
import YoutubeClient from './youtube-client'
import EmbedClient from './embed-client'
import TbkClient from './tbk-client'
import BaseClient from './base-client'

export default class TbkVideo {
  static new(type, code) {
    console.log(`Getting provider for ${type}`)
    switch (type) {
      case 'embed':
        return new EmbedClient(code)
      case 'wistia':
        return new WistiaClient(code)
      case 'tbk':
        return new TbkClient(code)
      case 'youtube':
        return new YoutubeClient(code)
      default:
        console.log(`No provider found for ${type}`)
        return new BaseClient(code)
    }
  }
}
