<template>
<div class='self-assessement'>
  <h2 class='body-four'>
    {{ label }}
  </h2>

  <div class='quiz-question'>
    <fieldset class='quiz-question_fieldset'>
      <legend class='quiz-question_text h-four'>
        {{ question.text }}
      </legend>

      <div
        class='quiz-question_container'
        :class="[question_was_submitted ? 'quiz-question_submitted' : 'quiz-question_not-submitted']"
      >
        <quiz-question-option
          v-for='(option, index) in options'
          :key='option.id'
          :option='option'
          :question='question'
          :select='select'
          :isOptionSelected='isOptionSelected'
          :index='index'
        />
      </div>
    </fieldset>

    <button
      class='quiz-question_next button-two'
      :disabled='!question_was_submitted'
      @click.prevent='submitButtonAction'
      v-html='is_question_last ? $t("get_your_results") : $t("next")'
    />
  </div>
</div>
</template>

<script>
import QuizQuestionOption from './quiz-v1-question-option'

export default {
  props: [
    'label',
    'activity_results',
    'question',
    'options',
    'nextQuestion',
    'is_question_last'
  ],

  components: {
    QuizQuestionOption
  },

  data() {
    return {
      question_result:  null
    }
  },

  computed: {

    question_was_submitted() {
      return this.question_result
    }

  },

  methods: {

    submitButtonAction() {
      this.activity_results[this.question.id] = this.question_result
      this.question_result = null
      this.nextQuestion()
    },

    select(option_id) {
      if (this.question_result === option_id) {
        this.question_result = undefined
      } else {
        this.question_result = option_id
      }
    },

    isOptionSelected(id) {
      return this.question_result === id
    }
  }
}
</script>

<style lang="sass">
@import 'lesson/styles/components/quiz/question'
</style>
