const QuizCommon = {
  props: [
    'label',
    'quiz',
    'activity_results'
  ],

  methods: {
    setQuestionResult(payload) {
      this.activity_results[this.question.id] = payload
    }
  }
}

export default QuizCommon
