<template>
  <div
    class='paired-comparison-list'
    :class='list_class'
  >
    <h2 class='paired-comparison_title body-four'>
      {{ step_header_title }}
    </h2>

    <div class='paired-comparison_text h-four'>
      {{ step_title }}
    </div>

    <h3 class='paired-comparison_info-text body-four-branded'>
      {{ step_directions }}
    </h3>

    <!-- Author Defined -->
    <template v-if='current_step === "select_1"'>
      <template v-if="activity.options_type === 'author_defined'">
        <list-selector
          :options=step_options
          :selectOption='selectOption'
          :isOptionSelected='isOptionSelected'
        />

        <div class='paired-comparison-list_counter'>
          <span>{{ current_selected_options.length }}</span>
          <span>{{ $t('of') }}</span>
          <span>{{ required_selections }}</span>
        </div>
      </template>

      <!-- User Defined -->
      <template v-if="activity.options_type === 'user_defined'">
        <list-adder
          :user_options="current_selected_options"
          :options_required="required_selections"
        />
      </template>
    </template>

    <template v-else-if='current_step === "select_2"'>
      <list-selector
        :options=step_options
        :selectOption='selectOption'
        :isOptionSelected='isOptionSelected'
      />

      <div class='paired-comparison-list_counter'>
        <span>{{ current_selected_options.length }}</span>
        <span>{{ $t('of') }}</span>
        <span>{{ required_selections }}</span>
      </div>
    </template>

    <button
      class='paired-comparison_button button-two'
      :disabled='nextButtonDisabled'
      @click.prevent='goToNextStep'
    >
      {{ button_text }}
    </button>

    <!-- Floating Button -->
    <template v-if='showFloatingButton'>
      <div class='paired-comparison-list_floating-next'>
        <div class='paired-comparison-list_floating-next_counter'>
          <span>{{ current_selected_options.length }}</span>
          <span>{{ $t('of') }}</span>
          <span>{{ required_selections }}</span>
        </div>

        <button
          class='paired-comparison_button button-two'
          :disabled='nextButtonDisabled'
          @click.prevent='goToNextStep'
        >
          {{ $t('next') }}
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import ListSelector from './select/list-selector'
import ListAdder from './select/list-adder'

export default {
  props: [
    'current_step',
    'setStep',
    'total_steps',
    'activity',
    'step_title',
    'step_directions',
    'activity_results',
    'scrollTop'
  ],

  data() {
    return {
      selected_options: {
        select_1: [],
        select_2: []
      },
      user_options: [],
      is_floating_button_visible: false
    }
  },

  components: {
    ListSelector,
    ListAdder
  },

  mounted() {
    this._updateFloatingButton()
    $(window).on('resize', () => this._updateFloatingButton())
  },

  updated() {
    this._updateFloatingButton()
  },

  computed: {
    current_step_number() {
      if (this.activity.step_1_selections &&
          this.activity.step_2_selections &&
          this.current_step === 'select_2') {
        return 2
      } else {
        return 1
      }
    },

    step_options() {
      if (this.current_step === 'select_1' ||
          (this.current_step === 'select_2' &&
          this.activity.options_type === 'author_defined' &&
          !this.activity.step_1_selections)) {
        return this.activity.options
      } else if (this.activity.options_type === 'user_defined') {
        return this.activity_results.select_1
      } else {
        return this.activity.options.filter((o) => {
          return this.activity_results.select_1.indexOf(o.id) > -1
        })
      }
    },

    current_selected_options() {
      return this.selected_options[this.current_step]
    },

    step_header_title() {
      return this.$t('step') + ` ${this.current_step_number} ` + this.$t('of') + ` ${this.total_steps + 1}`
    },

    button_text() {
      if (this.current_step_number === this.total_steps) {
        return this.$t('begin')
      } else {
        return this.$t('next')
      }
    },

    list_class() {
      return this.activity.options_type === 'author_defined'
        ? 'paired-comparison-list--author'
        : 'paired-comparison-list--user'
    },

    required_selections() {
      const current_step = this.current_step === 'select_1' ? 1 : 2

      return this.activity[`step_${current_step}_selections`]
    },

    nextButtonDisabled() {
      return (this.current_selected_options.length !== this.required_selections)
    },

    showFloatingButton() {
      if ($('.interactive-section_overlay_content').length && this.is_floating_button_visible) { return true }
      const viewportHeight = $('.activity-overlay').height()
      const listHeight = $('.paired-comparison-list').height()

      if (this.is_floating_button_visible && (listHeight - viewportHeight - 55) > this.scrollTop) {
        // 55 is for bottom margin : )
        return true
      } else return false
    }
  },

  methods: {

    goToNextStep() {
      this._storeCurrentStep()
      if (this.current_step_number === this.total_steps) {
        this.setStep('compare')
      } else {
        this.setStep('select_2')
      }
    },

    isOptionSelected(option) {
      return this.selected_options[this.current_step].indexOf(option.id) > -1
    },

    selectOption(option) {
      const index = this.selected_options[this.current_step].indexOf(option.id)
      if (index > -1) {
        this.selected_options[this.current_step].splice(index, 1)
      } else {
        this.selected_options[this.current_step].push(option.id)
      }
    },

    _storeCurrentStep() {
      this.activity_results[this.current_step] = this.current_selected_options
    },

    _updateFloatingButton() {
      const viewportWidth = $(window).width()
      if (viewportWidth >= 450) return false
      // if ($('.interactive-section_overlay_content').length) return false

      let viewportHeight = $(window).height()
      if ($('.interactive-section_overlay_content').length) {
        viewportHeight = $('.interactive-section_overlay').height()
      }
      const listHeight = $('.paired-comparison-list').height()

      this.is_floating_button_visible = (viewportHeight < listHeight)
    }

  }
}
</script>
